import { Constants } from './../constants';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tokenNotExpired } from 'angular2-jwt';


@Injectable()
export class ContactResponseService {
    contact: any;

    constructor(private http: HttpClient, public constants: Constants) { }

    //get by id
    getResponseByContactId(id,tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.get(this.constants.servercontactresponseGetById + '/' + id + '?tsp=' + timeStamp, { headers: headers });            
    }

    //get by responseId
    getResponseById(id,tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.get(this.constants.servercontactresponseGetByResponseId + '/' + id + '?tsp=' + timeStamp, { headers: headers });            
    }

    //put
    putResponse(response) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeaders();
        return this.http.put(this.constants.servercontactresponsePut + '?tsp=' + timeStamp, response, { headers: headers });            
    }

    //delete
    deleteResponse(id,tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.delete(this.constants.servercontactresponseDelete + '/' + id + '?tsp=' + timeStamp, { headers: headers });            
    }

    //bulk delete
    bulkDelete(deleteid, tokenFlag) {
        let timeStamp = +new Date();
        var headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.post(this.constants.servercontactresponseBulkDelete + '?tsp=' + timeStamp, deleteid, { headers: headers });            
    }
}