import { Title } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Constants } from './../constants';

@Injectable()
export class StreamService {

    constructor(private http: HttpClient, public constants: Constants) { }

    //get pages
    getDepartment(tokenFlag) {
        let timeStamp = +new Date();
        let headers = tokenFlag == true ? this.constants.getHeadersWithAuthorization(tokenFlag) : this.constants.getHeaders();
        return this.http.get(this.constants.serverdepartmentGetAll + '?tsp=' + timeStamp, { headers: headers })
            
    }

    //get page by title
    getDepartmentById(id, tokenFlag){
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.get(this.constants.serverdepartmentGetById + '/' + id + '?tsp=' + timeStamp, { headers: headers })
            
    }

    // add-update page
    putDepartment(department,tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.put(this.constants.serverdepartmentPut + '?tsp=' + timeStamp, department, { headers: headers })
            
    }

    // delete page
    deleteDepartment(id,tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.delete(this.constants.serverdepartmentDelete + '/' + id + '?tsp=' + timeStamp, { headers: headers })
            
    }

    //bulk delete
    bulkDelete(deleteid, tokenFlag) {
        let timeStamp = +new Date();
        var headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.post(this.constants.serverdepartmentBulkDelete + '?tsp=' + timeStamp, deleteid, { headers: headers })
            
    }

}