import { Constants } from './../../../constants';
import { Observable } from 'rxjs';
import { BookRequestService } from './../../../services/bookrequest.service';
import { BookService } from './../../../services/book.service';
import { DashboardService } from './../../../services/dashboard.service';
import { BookinstanceService } from './../../../services/bookinstance.service';
import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-home-slider',
  templateUrl: './home-slider.component.html',
  styleUrls: ['./home-slider.component.css']
})
export class HomeSliderComponent implements OnInit {
  @Input('totalUser') totalUser: any;
  @Input('totalSeeking') totalSeeking: any;
  @Input('totalcontribute') totalcontribute: any;
  @Input('totalRequestCount') totalRequestCount: any;
  @Input('totalAvailableBooks') totalAvailableBooks: Number = 0;
  @Input('todaysPickuprequest') todaysPickuprequest: any;
  @Input('ServedRequestCount') ServedRequestCount: any;
  constructor(
  ) { }

  ngOnInit() {
  }
}