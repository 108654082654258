import { element } from 'protractor';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { DialogService } from "ng2-bootstrap-modal";

import { ConfirmDialogComponent } from '../confirmDialog/confirmDialog.component';
import { Constants } from '../../../constants';

import { NotificationService } from './../../../services/notification.service';
import { UniversityService } from './../../../services/university.service';

class Universities {
    _id: any;
    name: String;
    email: String;
    password: String;
    issuperadmin: boolean;
    isdelete: boolean;
    isactive: boolean;
    isemailConfirmed: boolean;
}

@Component({
    selector: 'admin-Universities',
    templateUrl: './universities.component.html',
    styleUrls: ['./Universities.component.scss']
})

export class UniversitiesComponent implements OnInit {
    @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;
    deleteAll: Boolean;
    showdelete: Boolean;
    requestId: String[] = [];
    Universityid: any;
    email: String;
    isdelete: boolean;
    isactive: boolean;
    hide: Boolean = false;
    dtOptions = this.constants.dataTableSettings && {
        order: [2, 'asc'],
        columnDefs: [
            { width: 100, targets: 4 },
            {
                "targets": [0, 1, 7, 8],
                "orderable": false,
            }
        ],
        fnDrawCallback: function () {
            if ((document.getElementById("universitydata_paginate")) != null) {
                if ((document.getElementById("universitydata_paginate")).click) {
                    var table = $("#bookdata").DataTable();
                    var rowchecked = 0;
                    var ValueById = document.getElementsByName("sizecb")
                    for (var index = 0; index < ValueById.length; index++) {
                        if ($(ValueById[index]).prop("checked") == true) {
                            //check all conter var ++
                            rowchecked = rowchecked + 1;
                        }
                    }
                    if (ValueById.length == rowchecked) {
                        $("#checkAll").prop('checked', true);
                        $('.model').css('display', 'block');
                    } else {
                        $("#checkAll").prop('checked', false);
                    }
                    if (rowchecked == 0) {
                        $("#checkAll").prop('checked', false);
                        $('.model').css('display', 'none');
                    }
                    else if (rowchecked > 0) {
                        $('.model').css('display', 'block');
                    }
                }
            }
        }
    };

    constructor(
        public constants: Constants,
        private dialogService: DialogService,
        private notificationService: NotificationService,
        private router: Router,
        private UniversityService: UniversityService,
    ) {
    }
    ngAfterViewInit(): void {
    }

    //To Delete University
    showConfirm(id, name) {
        let disposable = this.dialogService.addDialog(ConfirmDialogComponent, {
            title: "Delete Confirmation",//this.constants.deleteConfirmationTitle"",
            message: "Are you sure you want to delete the selected record?",//this.constants.deleteConfirmation
        })
            .subscribe((isConfirmed) => {
                //Dialog result
                if (isConfirmed) {
                    this.UniversityService.deleteUniversity(id, true).subscribe((data: any) => {
                        if (data.success) {
                            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                                // Destroy the table first
                                dtInstance.destroy();
                                // Call the dtTrigger to rerender again
                                this.bindUniversities();
                            });
                            this.notificationService.showSuccess(data.msg);
                        }
                        else {
                            this.notificationService.showError(data.msg);
                        }
                    }, err => {
                        if (err.status == 401) {
                            this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
                        }
                        else {
                            this.router.navigate([this.constants.clientUrlNotFound]);
                        }
                    });

                }
            });

        //We can close dialog calling disposable.unsubscribe();
        //If dialog was not closed manually close it by timeout
        setTimeout(() => {
            disposable.unsubscribe();
        }, 10000);
    }

    Universities: Universities[] = [];

    // We use this trigger because fetching the list of persons can be quite long,
    // thus we ensure the data is fetched before rendering
    dtTrigger: Subject<Universities> = new Subject<Universities>();

    //define default get method
    ngOnInit() {
        if (this.constants.loadToken() != null && this.constants.loadToken() != undefined) {
            this.bindUniversities();
        }
        else {
            this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
        }
    }

    bindUniversities() {
        this.hide = true;
        this.UniversityService.getUniversities().subscribe((data: any) => {
            this.Universities = data.universities;
            this.dtTrigger.next();
            this.hide = false;
        });
    }

    checkAll(ev) {
        var elementArray = document.getElementsByName("sizecb");
        for (var index = 0; index < elementArray.length; index++) {
            var checkBox = <HTMLInputElement>elementArray[index]
            if (this.requestId.length == 0) {
                this.requestId.push(elementArray[index].id);
            }
            else {
                var resultArray = this.requestId.filter(data => data == elementArray[index].id);
                if (resultArray.length == 0) {
                    this.requestId.push(elementArray[index].id);
                } else {
                    var arrayindex = this.requestId.indexOf(elementArray[index].id);
                    this.requestId.splice(arrayindex, 1);
                }
            }
            checkBox.checked = ev.target.checked
        }
        if (this.requestId.length > 0) {
            // this.showdelete = true;
            $('.model').css('display', 'block');
        }
        else {
            // this.showdelete = false;
            $('.model').css('display', 'none');
        }
        if (!ev.target.checked) {
            // this.requestId = [];
            // this.showdelete = false;
            $('.model').css('display', 'none');
        }
    }

    recordChecked(ev) {
        var id = ev.currentTarget.id;
        var rowCount = 0;
        var elementArray = document.getElementsByName("sizecb");
        var resultArray = this.requestId.filter(data => data == id);
        var elementId = document.getElementById(id);
        if (resultArray != null && resultArray.length != 0) {
            var index = this.requestId.findIndex(x => x === id)
            this.requestId.splice(index, 1);
            // this.deleteAll = false;
            $("#checkAll").prop('checked', false);
        }
        else {
            this.requestId.push(id);
        }
        if (this.requestId.length > 0) {
            // this.showdelete = true;
            $('.model').css('display', 'block');
        }
        else {
            // this.showdelete = false;
            $('.model').css('display', 'none');
        }
        for (var index = 0; index < elementArray.length; index++) {
            var checkBox = <HTMLInputElement>elementArray[index]
            if (checkBox.checked == false) {
                rowCount = rowCount + 1;
            }
        }
        var selectedrow = elementArray.length - rowCount;
        if (selectedrow == elementArray.length) {
            // this.deleteAll = true;
            $("#checkAll").prop('checked', true);
        }
    }

    bulkDelete() {
        const deleteid = {
            deleteid: this.requestId
        };
        let disposable = this.dialogService.addDialog(ConfirmDialogComponent, {
            title: "Delete Confirmation",//this.constants.deleteConfirmationTitle"",
            message: "Are you sure you want to delete the selected record?",//this.constants.deleteConfirmation
        })
            .subscribe((isConfirmed) => {
                //Dialog result
                if (isConfirmed) {
                    this.UniversityService.bulkDelete(deleteid, true).subscribe((data: any) => {
                        if (data.success) {
                            this.bindUniversitiesDataTable();
                            this.requestId = [];
                            this.notificationService.showSuccess(data.msg);
                        }
                        else {
                            this.bindUniversitiesDataTable();
                            this.notificationService.showError(data.msg);
                        }
                    });
                }
            });

        //We can close dialog calling disposable.unsubscribe();
        //If dialog was not closed manually close it by timeout
        setTimeout(() => {
            disposable.unsubscribe();
        }, 10000);
    }

    bindUniversitiesDataTable() {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            // Destroy the table first
            dtInstance.destroy();
            // Call the dtTrigger to rerender again
            this.bindUniversities();
        });
    }
}
