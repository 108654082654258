import { AuthUserService } from './../../../services/authuser.service';
import { element } from 'protractor';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DialogService } from "ng2-bootstrap-modal";

import { ConfirmDialogComponent } from '../confirmDialog/confirmDialog.component';
import { Constants } from '../../../constants';
import { DataTableDirective } from 'angular-datatables';
import { NotificationService } from './../../../services/notification.service';
import { UserService } from './../../../services/user.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';

class Users {
    _id: any;
    name: String;
    email: String;
    password: String;
    issuperadmin: boolean;
    university: any;
    isdelete: boolean;
    isactive: boolean;
    isemailConfirmed: boolean;
}

@Component({
    selector: 'admin-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss']
})

export class UsersComponent implements OnInit {
     @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;
    @ViewChild('userModal', { static: false }) public userModal: ModalDirective;
    deleteAll: Boolean;
    showdelete: Boolean;
    isAbscondingUser: Boolean = false;
    requestId: String[] = [];
    userid: any;
    firstname: String;
    lastname: String;
    email: String;
    password: String;
    university: any;
    issuperadmin: boolean;
    isdelete: boolean;
    isactive: boolean;
    isemailConfirmed: boolean;
    userModalHeader: string;
    submitButtonText: string;
    userPassword: any;
    usersGroup: FormGroup; /**Form Instance */
    dtOptions = this.constants.dataTableSettings && {
        order: [9, 'desc'],
        columnDefs: [
            // { type: 'date', targets: 5 },
            {
                // "targets": [0, 1, 4, 10, 11, 12],
                "targets": [0, 1, 4, 12, 14, 13],
                "orderable": false,
            },
            {
                "targets": 9,
                type: 'num',
                render: function (data, type) {

                    if (type == 'sort') {
                        return Date.parse(data).valueOf();
                    }
                    return data;
                }
            }
        ],
        fnDrawCallback: function () {

            if ((document.getElementById("userdata_paginate")) != null) {
                if ((document.getElementById("userdata_paginate")).click) {
                    var rowchecked = 0;
                    var ValueById = document.getElementsByName("sizecb")
                    for (var index = 0; index < ValueById.length; index++) {
                        if ($(ValueById[index]).prop("checked") == true) {
                            //check all conter var ++
                            rowchecked = rowchecked + 1;
                        }
                    }
                    if (ValueById.length == rowchecked) {
                        $("#checkAll").prop('checked', true);
                        $('.model').css('display', 'block');
                    } else {
                        $("#checkAll").prop('checked', false);
                    }
                    if (rowchecked == 0) {
                        $("#checkAll").prop('checked', false);
                        $('.model').css('display', 'none');
                    }
                    else if(rowchecked > 0){
                        $('.model').css('display', 'block');
                    }
                }
            }
        }
    };
    hide: Boolean = false;
    constructor(
        public constants: Constants,
        private dialogService: DialogService,
        private notificationService: NotificationService,
        private router: Router,
        private fb: FormBuilder,
        private userService: UserService,
        public datepipe: DatePipe,
        private authUserService: AuthUserService
    ) {
        this.usersGroup = fb.group({
            'isAbscondingUser': [null, Validators.required]
        });
    }
    ngAfterViewInit(): void {

    }

    //To Delete User
    showConfirm(id, name) {
        let disposable = this.dialogService.addDialog(ConfirmDialogComponent, {
            title: "Delete Confirmation",//this.constants.deleteConfirmationTitle"",
            message: "Are you sure you want to delete the selected record?",//this.constants.deleteConfirmation
        })
            .subscribe((isConfirmed) => {
                //Dialog result
                if (isConfirmed) {
                    this.userService.deleteUser(id, true).subscribe((data: any) => {
                        if (data.success) {
                            this.constants.removeCacheData('DashboardCounts', 0);
                            this.bindUserDataTable();
                            this.notificationService.showSuccess(data.msg);
                        }
                        else {
                            this.notificationService.showError(data.msg);
                        }
                    }, err => {
                        if (err.status == 401) {
                            this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
                        }
                        else {
                            this.router.navigate([this.constants.clientUrlNotFound]);
                        }
                    });
                }
            });

        //We can close dialog calling disposable.unsubscribe();
        //If dialog was not closed manually close it by timeout
        setTimeout(() => {
            disposable.unsubscribe();
        }, 10000);
    }

    users: Users[] = [];

    // We use this trigger because fetching the list of persons can be quite long,
    // thus we ensure the data is fetched before rendering
    dtTrigger: Subject<Users> = new Subject<Users>();

    //define default get method
    ngOnInit() {
        
        this.bindUsers();
        
    }

    activeUser(id, isemailconfirmed) {
        let disposable = this.dialogService.addDialog(ConfirmDialogComponent, {
            title: "Email Activation Confirmation",//this.constants.deleteConfirmationTitle"",
            message: "Are you sure you want to confirm email of selected record?",//this.constants.deleteConfirmation
        })
            .subscribe((isConfirmed) => {
                //Dialog result
                if (isConfirmed) {
                    const user = {
                        _id: id,
                        isemailconfirmed: !isemailconfirmed
                    }
                    this.userService.activeUser(user, true).subscribe((data: any) => {
                        if (data.success) {
                            this.bindUserDataTable();
                            this.notificationService.showSuccess(data.msg);
                        }
                    }, err => {
                        if (err.status == 401) {
                            this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
                        }
                        else {
                            this.router.navigate([this.constants.clientUrlNotFound]);
                        }
                    });
                }
            });

        //We can close dialog calling disposable.unsubscribe();
        //If dialog was not closed manually close it by timeout
        setTimeout(() => {
            disposable.unsubscribe();
        }, 10000);

    }

    bindUserDataTable() {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            // Destroy the table first
            dtInstance.destroy();
            // Call the dtTrigger to rerender again
            this.bindUsers();
        });
    }

    bindUsers() {
        this.hide = true;
        this.userService.getUsers(this.isAbscondingUser, true).subscribe((data: any) => {
            // data.users.map((item) => {
            //     if (item.createdDate) {
            //         // item.createdDate = this.datepipe.transform(item.createdDate, "d MMM, y, h:mm");
            //         // item.createdDate = moment(item.createdDate).format('dd MMM, YY, h:mm');
            //         // item.createdDate = new Date(item.createdDate).toLocaleString();
            //         return  item.createdDate;
            //     }
            // })
            this.users = data.users;
            this.dtTrigger.next();
            this.hide = false;
        }, err => {
            if (err.status == 401) {
                this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
            }
            else {
                this.router.navigate([this.constants.clientUrlNotFound]);
            }
        });
    }

    resetPasswordMail(email) {
        this.hide = true;
        const user = {
            email: email
        }
        this.authUserService.forgetPassword(user).subscribe((data: any) => {
            if (data.success == false) {
                this.notificationService.showError(data.msg);
            }
            else {
                this.notificationService.showSuccess(data.msg);
            }
            this.hide = false;
        });
    }


    checkAll(ev) {
        var elementArray = document.getElementsByName("sizecb");
        for (var index = 0; index < elementArray.length; index++) {
            var checkBox = <HTMLInputElement>elementArray[index]
            if (this.requestId.length == 0) {
                this.requestId.push(elementArray[index].id);
            }
            else {
                var resultArray = this.requestId.filter(data => data == elementArray[index].id);
                if (resultArray.length == 0) {
                    this.requestId.push(elementArray[index].id);
                } else {
                    var arrayindex = this.requestId.indexOf(elementArray[index].id);
                    this.requestId.splice(arrayindex, 1);
                }
            }
            checkBox.checked = ev.target.checked
        }
        if (this.requestId.length > 0) {
            // this.showdelete = true;
            $('.model').css('display', 'block');
        }
        else {
            // this.showdelete = false;
            $('.model').css('display', 'none');
        }
        if (!ev.target.checked) {
            // this.requestId = [];
            // this.showdelete = false;
            $('.model').css('display', 'none');
        }
    }

    recordChecked(ev) {
        var id = ev.currentTarget.id;
        var rowCount = 0;
        var elementArray = document.getElementsByName("sizecb");
        var resultArray = this.requestId.filter(data => data == id);
        var elementId = document.getElementById(id);
        if (resultArray != null && resultArray.length != 0) {
            var index = this.requestId.findIndex(x => x === id)
            this.requestId.splice(index, 1);
            // this.deleteAll = false;
            $("#checkAll").prop('checked', false);
        }
        else {
            this.requestId.push(id);
        }
        if (this.requestId.length > 0) {
            // this.showdelete = true;
            $('.model').css('display', 'block');
        }
        else {
            // this.showdelete = false;
            $('.model').css('display', 'none');
        }
        for (var index = 0; index < elementArray.length; index++) {
            var checkBox = <HTMLInputElement>elementArray[index]
            if (checkBox.checked == false) {
                rowCount = rowCount + 1;
            }
        }
        var selectedrow = elementArray.length - rowCount;
        if (selectedrow == elementArray.length) {
            // this.deleteAll = true;
            $("#checkAll").prop('checked', true);
        }
    }

    bulkDelete() {
        const deleteid = {
            deleteid: this.requestId
        };
        let disposable = this.dialogService.addDialog(ConfirmDialogComponent, {
            title: "Delete Confirmation",//this.constants.deleteConfirmationTitle"",
            message: "Are you sure you want to delete the selected record?",//this.constants.deleteConfirmation
        })
            .subscribe((isConfirmed) => {
                //Dialog result
                if (isConfirmed) {
                    this.userService.bulkDelete(deleteid, true).subscribe((data: any) => {
                        if (data.success) {
                            this.constants.removeCacheData('DashboardCounts', 0);
                            this.bindUserDataTable();
                            this.requestId = [];
                            this.notificationService.showSuccess(data.msg);
                        }
                        else {
                            this.bindUserDataTable();
                            this.notificationService.showError(data.msg);
                        }
                    });
                }
            });

        //We can close dialog calling disposable.unsubscribe();
        //If dialog was not closed manually close it by timeout
        setTimeout(() => {
            disposable.unsubscribe();
        }, 10000);
    }
}
