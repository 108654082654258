import { DropdownService } from './../../../services/dropdown.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { HttpClient } from '@angular/common/http';
import { BookRequestService } from './../../../services/bookrequest.service';
import { BookService } from './../../../services/book.service';
import { ConfirmDialogComponent } from './../../../front/components/confirmDialog/confirmDialog.component';
import { Constants } from './../../../constants';
import { DataTableDirective } from 'angular-datatables';
import { DialogService } from 'ng2-bootstrap-modal';
import { FileUploader, FileSelectDirective } from 'ng2-file-upload';
import { NotificationService } from './../../../services/notification.service';
import { Subject } from 'rxjs';
import { Title } from '@angular/platform-browser';

class Books {
  isdelete: 0;
}

@Component({
  selector: 'app-verfiy-book-request',
  templateUrl: './verify-book-request.component.html',
  styleUrls: ['./verify-book-request.component.scss']
})
export class VerifyBookRequestComponent implements OnInit {
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  requestId: string;
  submitButtonText: string;
  rejectButtonText: string = this.constants.rejectRequest;
  hideBookDetails: Boolean = false;
  hideRequestDetails: Boolean = false;
  bookname: string;
  isbn: string;
  otherdetails: string;
  username: String;
  requeststatusid: String;
  requestdate: Date;
  status: String;
  requesttype: Boolean;
  email: String;
  disableButton: Boolean = false;
  search: String;
  dtOptions = this.constants.dataTableSettings;
  viewResult: Boolean = false;
  bookForm: FormGroup;
  bookid: any;
  title: String = '';
  subtitle: String = '';
  description: String = '';
  categoryid: String = '';
  language: String = 'English';
  author: String = '';
  publisher: String = '';
  isbnnumber: String = '';
  isdelete: Boolean;
  isactive: Boolean;
  imageurl: any;
  imageurlPath: String;
  imagename: string;
  showBookForm: Boolean = false;
  public uploader: FileUploader = new FileUploader({
    url: this.constants.serverbookrequestLinkToThis
    , allowedFileType: ["image"],
    authToken: this.constants.loadToken(),
  });
  // Change By Arpit for #1803
  constructor(
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private bookrequestService: BookRequestService,
    private bookService: BookService,
    private dropdownService: DropdownService,
    public constants: Constants,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private router: Router,
    fb: FormBuilder,
  ) {
    this.bookForm = fb.group({

      'bookid': [null, Validators.nullValidator],
      'title': [null, Validators.compose([Validators.required, Validators.maxLength(150)])],
      'subtitle': [null, Validators.compose([Validators.nullValidator, Validators.maxLength(100), Validators.pattern("^[a-zA-Z0-9!@#$&()\\-`.+,/\_]+( [a-zA-Z0-9!@#$&()\\-`.+,/\_]+)*$")])],
      'description': [null, Validators.nullValidator],
      'categoryid': [null, Validators.compose([Validators.nullValidator, Validators.maxLength(100), Validators.pattern("^[a-zA-Z0-9!@#$&()\\-`.+,/\_]+( [a-zA-Z0-9!@#$&()\\-`.+,/\_]+)*$")])],
      'language': [this.language, Validators.required],
      'author': [null, Validators.compose([Validators.nullValidator, Validators.maxLength(100), Validators.pattern("^[a-zA-Z0-9!@#$&()\\-`.+,/\_]+( [a-zA-Z0-9!@#$&()\\-`.+,/\_]+)*$")])],
      'publisher': [null, Validators.compose([Validators.nullValidator, Validators.maxLength(100), Validators.pattern("^[a-zA-Z0-9!@#$&()\\-`.+,/\_]+( [a-zA-Z0-9!@#$&()\\-`.+,/\_]+)*$")])],
      'imageurl': [null, Validators.nullValidator],
      'isbnnumber': [null, Validators.nullValidator],
      'isactive': [null, Validators.nullValidator],
    })
  }

  books: Books[] = [];

  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<Books> = new Subject<Books>();

  ngOnInit() {
    this.getLanguage();
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.uploader._fileTypeFilter = (file) => true;
    this.submitButtonText = this.constants.AddBook
    this.activatedRoute.params.subscribe((params: Params) => {
      this.requestId = params['id'];
      if (this.requestId != null) {
        this.getDataByRequestId(this.requestId);
      }
    });
  }

  getDataByRequestId(requestId) {
    this.hideRequestDetails = true;
    this.bookrequestService.getbookrquestById(requestId, true).subscribe((data:any) => {
      if (data.success) {
        if (!data.bookrequests[0].istransactioncomplete && data.bookrequests[0].bookid == '') {
          this.requestId = data.bookrequests[0]._id
          this.username = data.bookrequests[0].users[0].name
          this.bookname = data.bookrequests[0].bookid != [] ? data.bookrequests[0].bookname : data.bookrequests[0].books[0].title
          this.isbn = data.bookrequests[0].isbn ? data.bookrequests[0].isbn : this.constants.notAvailable
          this.otherdetails = data.bookrequests[0].otherdetails ? data.bookrequests[0].otherdetails : this.constants.notAvailable
          this.requestdate = data.bookrequests[0].requestdate
          this.requesttype = data.bookrequests[0].requesttype
          this.email = data.bookrequests[0].users[0].email
        }
        else {
          if (data.bookrequests[0].requesttype != true) {
            this.router.navigate([this.constants.clientUrlBookContribute]);
          } else {
            this.router.navigate([this.constants.clientUrlBookRequests]);
          }
        }
        this.hideRequestDetails = false;
      }
    }, err => {
      if (err.status == 401) {
        this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
      }
      else {
        this.router.navigate([this.constants.clientUrlNotFound]);
      }
    });
  }

  //To Delete Book
  showRejectConfirm() {
    let disposable = this.dialogService.addDialog(ConfirmDialogComponent, {
      title: "Reject Request",//this.constants.deleteConfirmationTitle"",  
      message: "Are you sure you want to reject this request?",//this.constants.deleteConfirmation
    })
      .subscribe((isConfirmed) => {
        //Dialog result
        if (isConfirmed) {
          this.rejectRequest();
        }
      });

    //We can close dialog calling disposable.unsubscribe();
    //If dialog was not closed manually close it by timeout
    setTimeout(() => {
      disposable.unsubscribe();
    }, 10000);
  }

  onSearchClick() {
    this.showBookForm = false;
    this.bookForm.reset();
    this.submitButtonText = this.constants.AddBook;
    this.hideBookDetails = true;
    this.viewResult = false;
    this.bookService.searchBook(this.search).subscribe((data:any) => {
      if (data.success) {
        this.books = data.book;
      }
      this.hideBookDetails = false;
      this.viewResult = true;
    });
  }

  onCancelClick() {
    window.history.go(-1);
  }

  rejectRequest() {
    const remarks = {
      bookrequestId: this.requestId,
      requeststatus: "Rejected",
      requesttype: this.requesttype,
      bookname: this.bookname.trim(),
      isbn: this.isbn,
      otherdetails: this.otherdetails,
      email: this.email,
      name: this.username,
    }

    this.bookrequestService.rejectManualRequest(remarks, true).subscribe((data:any) => {
      if (data.success) {
        this.notificationService.showSuccess(data.msg);
        this.router.navigate([this.constants.clientUrlRemarks, this.requestId]);
      } else {
        this.notificationService.showError(data.msg);
      }
    }, err => {
      if (err.status == 401) {
        this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
      }
      else {
        this.router.navigate([this.constants.clientUrlNotFound]);
      }
    });
  }

  onAddClick() {
    this.showBookForm = !this.showBookForm
    if (!this.showBookForm) {
      this.submitButtonText = this.constants.AddBook;
    } else {
      this.bookForm.reset();
      this.submitButtonText = this.constants.Close;
    }
  }

  LinkToThis(infoLink, searchbookid) {
    this.disableButton = true;
    var infoLinkId;
    if (infoLink != null) {
      infoLinkId = this.constants.getIdFromInfoLinks(infoLink);
    }
    const bookrequest = {
      searchKey: infoLinkId,
      googleid: infoLinkId,
      bookid: searchbookid,
      bookrequestId: this.requestId
    }
    this.bookrequestService.LinkToThis(bookrequest, true).subscribe((data:any) => {
      if (data.success) {
        this.constants.removeCacheData('DashboardCounts', 0);
        this.router.navigate([this.constants.clientUrlRemarks, this.requestId]);
        this.notificationService.showSuccess(data.msg);
      }
      else {
        this.notificationService.showError(data.msg);
        this.disableButton = false;
      }
    }, err => {
      if (err.status == 401) {
        this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
      }
      else {
        this.router.navigate([this.constants.clientUrlNotFound]);
      }
    });
  }

  languages: any[] = [];
  getLanguage() {
    this.languages = [];
    this.dropdownService.getLanguages().subscribe((data:any) => {
      if (data.success) {
        this.languages = data.languages
      }
    });
  }

  getBookConstValue(infoLink, searchbookid) {
    var infoLinkId;
    if (infoLink != null && infoLink != undefined) {
      infoLinkId = this.constants.getIdFromInfoLinks(infoLink);
    }

    const book = {
      searchKey: infoLinkId != undefined ? infoLinkId : '',
      googleid: infoLinkId != undefined ? infoLinkId : '',
      bookid: searchbookid != undefined ? searchbookid : '',
      title: this.title.trim(),
      subtitle: this.subtitle != undefined ? this.subtitle : '',
      description: this.description != undefined ? this.description : '',
      categoryid: this.categoryid != undefined ? this.categoryid : '',
      language: this.language,
      author: this.author != undefined ? this.author : '',
      publisher: this.publisher != undefined ? this.publisher : '',
      imageurl: this.imageurl != null ? this.imageurl : '',
      isbnnumber: this.isbnnumber != undefined ? this.isbnnumber : '',
      isdelete: 0,
      isactive: this.isactive ? true : false,
      file: this.uploader,
      bookrequestId: this.requestId,
      flag: 0
    }
    return book;
  }

  //Manually add books
  addBooks() {
    //upsert books        
    var itemLength = this.uploader.queue.length;
    if (itemLength != 0) {
      this.uploader.onBuildItemForm = (item, form) => {
        form.append('title', this.title);
        form.append('subtitle', this.subtitle);
        form.append('description', this.description);
        form.append('categoryid', this.categoryid);
        form.append('language', this.language);
        form.append('author', this.author);
        form.append('publisher', this.publisher);
        form.append('imageurl', '');
        form.append('isbnnumber', this.isbnnumber);
        form.append('isdelete', 0);
        form.append('isactive', this.isactive ? true : false);
        form.append('flag', 1);
        form.append('bookid', '');
        form.append("bookrequestId", this.requestId);
      };

      var filesize = this.uploader.queue[itemLength - 1].file.size;

      if (filesize > 2000000) {
        this.notificationService.showError(this.constants.strFileSize);
        return false;
      }
      else {
        this.uploader.uploadItem(this.uploader.queue[itemLength - 1]);
        this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
          if (status == 200) {
            this.router.navigate([this.constants.clientUrlRemarks, this.requestId]);
          }
          else if (status == 401) {
            this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
          }
          else {
            this.notificationService.showError(this.constants.strBookExist);
          }
        };
      }
    }
    else if (itemLength === undefined || itemLength === 0) {
      const book = {
        bookid: '',
        title: this.title.trim(),
        subtitle: this.subtitle != undefined ? this.subtitle : '',
        description: this.description != undefined ? this.description : '',
        categoryid: this.categoryid != undefined ? this.categoryid : '',
        language: this.language,
        author: this.author != undefined ? this.author : '',
        publisher: this.publisher != undefined ? this.publisher : '',
        imageurl: this.imageurl != null ? this.imageurl : '',
        isbnnumber: this.isbnnumber != undefined ? this.isbnnumber : '',
        isdelete: 0,
        isactive: this.isactive ? true : false,
        file: this.uploader,
        bookrequestId: this.requestId,
        flag: 0
      }
      this.bookrequestService.LinkToThis(book, true).subscribe((data:any) => {
        if (data.success) {
          this.constants.removeCacheData('DashboardCounts', 0);
          this.router.navigate([this.constants.clientUrlRemarks, this.requestId]);
          this.notificationService.showSuccess(data.msg);
        }
        else {
          this.notificationService.showError(data.msg);
        }
      }, err => {
        if (err.status == 401) {
          this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
        }
        else {
          this.router.navigate([this.constants.clientUrlNotFound]);
        }
      });
    }
  }

  //Check Image extention
  validImg: Boolean;
  extention(evt) {
    this.validImg = this.constants.extention(evt);
  }
}