import { AuthAdminService } from './../../../services/authadmin.service';
import { NotificationService } from './../../../services/notification.service';
import { ActivatedRoute, Params, RouteConfigLoadStart, Router } from '@angular/router';
import { FileUploader, FileSelectDirective } from 'ng2-file-upload';
import { BookService } from './../../../services/book.service';
import { Constants } from './../../../constants';
import { Component, EventEmitter, OnInit, Input, Output } from "@angular/core";

@Component({
  selector: "app-commonBookView",
  templateUrl: "./commonBookView.component.html",
  styleUrls: ["./commonBookView.component.scss"]
})

export class CommonBookViewComponent implements OnInit {
  @Input() bookid: any;
  @Input() searchKey: any;
  @Input() customBookid: any;
  @Input() descriptionVisible : boolean = true;
  googleid: any;
  title: String = '';
  subtitle: String = '';
  description: String = '';
  categoryid: String = '';
  language: String = '';
  author: String = '';
  publisher: String = '';
  identifier_13: String = '';
  isbnnumber: String;
  imageurl: any;
  imageurlPath: String;
  infoLink: any;
  user: any;
  imagename: string;
  isactive: Boolean;
  requesttype: boolean;
  hideAddBook: Boolean = true;

  public uploader: FileUploader = new FileUploader({
    url: this.constants.uploadFile,
    allowedFileType: ["image"],
    authToken: this.constants.loadToken(),
  });

  constructor(
    private activatedRoute: ActivatedRoute,
    private authAdmin: AuthAdminService,
    public constants: Constants,
    private router: Router,
    private notificationService: NotificationService,
    private bookService: BookService,
  ) {
    this.user = this.authAdmin.getAdminFromStorage();
  }

  ngOnInit() {
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    this.uploader._fileTypeFilter = (file) => true;
    if(this.bookid){
      this.searchBooksBy(this.searchKey, this.bookid)
    }else{
      this.getBookById(this.searchKey, this.customBookid)
    }
  }

  searchBooksBy(search, infoLinkId) {
      // Change by Arpit
    this.bookService.searchBookByGoogleId(search+'&'+infoLinkId).subscribe((data: any) => {
      if (data.success) {
        data.book.filter(item=> {
          var bookId = this.constants.getIdFromInfoLinks(item.infoLink);
           if(item.title === search && bookId === infoLinkId)data.book = item;
         });
        this.infoLink = data.book.infoLink;
        this.title = data.book.title && data.book.title != 'undefined' ? data.book.title : this.constants.notAvailable
        this.subtitle = data.book.subtitle && data.book.subtitle != 'undefined' ? data.book.subtitle : this.constants.notAvailable
        this.description = data.book.description && data.book.description != 'undefined' ? data.book.description : ''
        this.categoryid = data.book.categories && data.book.categories != 'undefined' ? data.book.categories[0] : this.constants.notAvailable
        this.language = data.book.language
        this.author = data.book.authors && data.book.authors != 'undefined' ? data.book.authors[0] : this.constants.notAvailable
        this.publisher = data.book.publisher && data.book.publisher != 'undefined' ? data.book.publisher : this.constants.notAvailable
        this.imageurlPath = data.book.imageLinks.thumbnail

        this.isactive = data.book.isactive;
        this.isbnnumber = this.constants.notAvailable;
        if (data.book.infoLink) {
          this.isbnnumber = data.book.industryIdentifiers ? data.book.industryIdentifiers[0].identifier : this.constants.notAvailable;
          this.isbnnumber += data.book.industryIdentifiers[1] ? ", " + data.book.industryIdentifiers[1].identifier : '';
        } else {
          this.isbnnumber = data.book.isbnnumber ? data.book.isbnnumber : this.constants.notAvailable;
        }
        this.hideAddBook = false;
      }
    });
  }

  getBookById(search, customBookId) {
    this.bookService.getBookById(customBookId).subscribe((data: any) => {
      if (data.success && data.book !== null) {
        this.googleid = data.book.googleid;
        this.title = data.book.title && data.book.title != 'undefined' ? data.book.title : this.constants.notAvailable
        this.subtitle = data.book.subtitle && data.book.subtitle != 'undefined' ? data.book.subtitle : this.constants.notAvailable
        this.description = data.book.description && data.book.description != 'undefined' ? data.book.description : ''
        this.categoryid = data.book.categoryid[0] && data.book.categoryid[0] != 'undefined' ? data.book.categoryid[0] : this.constants.notAvailable
        this.language = data.book.language != '' ? data.book.language : undefined
        this.author = data.book.author && data.book.author != 'undefined' ? data.book.author[0] : this.constants.notAvailable
        this.publisher = data.book.publisher && data.book.publisher != 'undefined' ? data.book.publisher[0] : this.constants.notAvailable
        if (data.book.imageurl != undefined && data.book.imageurl != "") {
          this.imageurlPath = this.constants.serveruploadspath + data.book.imageurl
          this.imagename = data.book.imageurl
        }
        this.isactive = data.book.isactive;
        this.isbnnumber = this.constants.notAvailable;
        if (data.book.isbnnumber) {
          if (data.book.isbnnumber.length >=1 && data.book.isbnnumber.length <=2) {
            this.isbnnumber = data.book.isbnnumber[0].identifier ? data.book.isbnnumber[0].identifier : this.constants.notAvailable;
            this.isbnnumber += data.book.isbnnumber[1] ? ", " + data.book.isbnnumber[1].identifier : '';
          }else{
            this.isbnnumber = data.book.isbnnumber ? data.book.isbnnumber : this.constants.notAvailable;
          }
        } else {
          this.isbnnumber = data.book.isbnnumber ? data.book.isbnnumber : this.constants.notAvailable;
        }
      } 
    });

  }

  insertBook(infoLink, title) {
    if (!infoLink) {
      infoLink = null;
    }
    var infoLinkId = this.constants.getIdFromInfoLinks(infoLink);
    const book = {
      searchKey: infoLinkId,
      googleid: infoLinkId,
      bookid: null,
      title: title,
      subtitle: this.subtitle != undefined ? this.subtitle : '',
      description: this.description != undefined ? this.description : '',
      categoryid: this.categoryid != undefined ? this.categoryid : '',
      language: this.language,
      author: this.author != undefined ? this.author : '',
      publisher: this.publisher != undefined ? this.publisher : '',
      imageurl: this.imageurl != null ? this.imageurl : '',
      isbnnumber: this.isbnnumber != undefined ? this.isbnnumber : '',
      isdelete: 0,
      isactive: this.isactive ? true : false,
      file: this.uploader
    }
    this.bookService.putBook(book, true).subscribe((data:any) => {
      if (data.success) {
        this.constants.removeCacheData('DashboardCounts', 0);
        this.router.navigate([this.constants.clientUrlBooks]);
        this.notificationService.showSuccess(data.msg);
      }
      else {
        this.notificationService.showError(data.msg);
      }
    }, err => {
      if (err.status == 401) {
        this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
      }
      else {
        this.router.navigate([this.constants.clientUrlNotFound]);
      }
    });
  }

}
