import { Title } from '@angular/platform-browser';
import { ConfirmDialogComponent } from './../../../front/components/confirmDialog/confirmDialog.component';
import { DialogService } from 'ng2-bootstrap-modal';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ContactResponseService } from './../../../services/contactresponseservice';
import { CustomValidators } from 'ng2-validation';
import { Component, Injectable, OnInit, ViewChild, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { Constants } from './../../../constants';
import { ContactService } from '../../../services/contact.service';
import { NotificationService } from './../../../services/notification.service';

class Contact {
  _id: any;
  name: String;
  email: String;
  message: String;
  contactNo: String;
  isdelete: Boolean;
}

class Response {
  _id: any;
  contactId: any;
  response: String;
  responsedate: Date;
}

@Component({
  selector: 'app-viewContact',
  templateUrl: './viewContact.component.html',
  styleUrls: ['./ViewContact.component.scss']
})
export class ViewContactComponent implements OnInit {
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  contactId: any;
  deleteAll: Boolean;
  requestId: String[] = [];
  strConst: String;
  mobWidth: any;
  name: String;
  email: String;
  message: String;
  contactNo: String;
  isdelete: Boolean;
  subject: String;
  response: String;
  responsedetail: String;
  visible: Boolean;
  submitButtonText: string;
  contactForm: FormGroup;
  hide: Boolean = false;
  modalRef: BsModalRef;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: false
  };
  disableButton: Boolean = false;
  dtOptions = this.constants.dataTableSettings && {
    columnDefs: [
      {
        "targets": [1,3],
        "orderable": false,
      },
      { targets: 2, orderable: true }
    ],
    fnDrawCallback: function () {
      if ((document.getElementById("responseData_paginate")).click) {
        var table = $("#responseData").DataTable();
        var rowchecked = 0;
        var elementArray = document.getElementsByName("sizecd");
        for (var index = 0; index < elementArray.length; index++) {
          if ($(elementArray[index]).prop("checked") == true) {
            //check all conter var ++
            rowchecked = rowchecked + 1;
          }
        }
        if (elementArray.length != 0) {
          if (elementArray.length == rowchecked) {
            $("#checkAll").prop('checked', true);
            $('.model').css('display', 'block');
          } else {
            $("#checkAll").prop('checked', false);
          }
        }
        if (rowchecked == 0) {
          $('.model').css('display', 'none');
        }
        else if (rowchecked > 0) {
          $('.model').css('display', 'block');
        }
      }
    }
  };
  constructor(
    private contactService: ContactService,
    public constants: Constants,
    private contactResponseService: ContactResponseService,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private modalService: BsModalService,
    private router: Router,
    fb: FormBuilder,
    private notificationService: NotificationService
  ) {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.contactId = params['id'];
      if (this.contactId == null) {
        this.contactForm = fb.group({
          'contactId': [null, Validators.nullValidator],
          'name': [null, Validators.compose([Validators.required, Validators.maxLength(50),])],
          'email': [null, Validators.compose([Validators.required, Validators.email])],
          'message': [null, Validators.required],
          'contactNo': [null, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern("[0-9]*")])],
        })
      }
      else {
        this.contactForm = fb.group({
          // 'subject': [null, Validators.required],
          'response': [null, Validators.required],
        })
      }
    });

    //check width of screen for responsive result
    this.mobWidth = (window.screen.width) + "px";
  }

  responseList = [];
  dtTrigger: Subject<Response> = new Subject<Response>();

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      let contactId = params['id'];
      if (this.contactId != null) {
        this.hide = true;
        this.contactService.getContactById(this.contactId, true).subscribe((data:any) => {
          this.name = data.contact.name;
          this.email = data.contact.email;
          this.message = data.contact.message;
          this.contactNo = data.contact.contactNo;
          this.submitButtonText = this.constants.Update;
          this.hide = false;
        }, err => {
          if (err.status == 401) {
            this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
          }
          else {
            this.router.navigate([this.constants.clientUrlNotFound]);
          }
        });
      }
      else {
        this.submitButtonText = this.constants.Save
      }

      this.bindresponse();
    });
  }

  //find response by contactId
  bindresponse() {
    if (this.contactId != null) {
      this.hide = true;
      this.contactResponseService.getResponseByContactId(this.contactId, true).subscribe((data:any) => {
        if (data.success) {
          this.responseList = data.contact;
          this.dtTrigger.next();
          this.hide = false;
        }
      })
    }
  }

  onSubmit() {
    if (this.constants.loadToken() != null && this.constants.loadToken() != undefined) {
      this.disableButton = true;
      const contact = {
        contactId: this.contactId,
        name: this.name,
        email: this.email,
        message: this.message,
        contactNo: this.contactNo,
        //isdelete: 0
      }

      this.contactService.putContact(contact).subscribe((data:any) => {
        if (data.success) {
          this.notificationService.showSuccess(data.msg);
          this.router.navigate([this.constants.clientUrlContactList]);
        }
        else {
          this.notificationService.showError(data.msg);
          this.disableButton = false;
        }
      });
    }
    else {
      this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
    }
  }

  sendResponse() {
    if (this.constants.loadToken() != null && this.constants.loadToken() != undefined) {
      const response = {
        contactId: this.contactId,
        name: this.name,
        subject: this.subject,
        email: this.email,
        response: this.response,
      }
      this.contactResponseService.putResponse(response).subscribe((data:any) => {
        if (data.success) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            // Destroy the table first
            dtInstance.destroy();
            // Call the dtTrigger to rerender again
            this.bindresponse();
          });
          this.notificationService.showSuccess(data.msg);
          this.contactForm.reset();
        } else {
          this.notificationService.showError(data.msg);
        }
      })
    } else {
      this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
    }
  }

  getTrimText(text) {
    if (text != '') {
      this.strConst = text.toString();
      if (this.strConst.length > 100) {
        this.strConst = this.strConst.substring(0, 100) + "...";
      }
      return this.strConst;
    }
    else {
      return this.constants.notAvailable;
    }
  }
  showResponse(id) {
    this.visible = !this.visible;
    if (this.visible) {
      this.contactResponseService.getResponseById(id, true).subscribe((data:any) => {
        if (data.success) {
          this.responsedetail = data.contact.response;
        }
      })
    } else {
      this.responsedetail = '';
    }
    // this.modalRef = this.modalService.show(template, Object.assign({}, this.config));
  }

  showConfirm(id) {

    let disposable = this.dialogService.addDialog(ConfirmDialogComponent, {
      title: "Delete Confirmation",//this.constants.deleteConfirmationTitle"",
      message: "Are you sure you want to delete the selected record?",//this.constants.deleteConfirmation
    })
      .subscribe((isConfirmed) => {
        //Dialog result
        if (isConfirmed) {
          this.contactResponseService.deleteResponse(id, true).subscribe((data:any) => {
            if (data.success) {
              this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Destroy the table first
                dtInstance.destroy();
                // Call the dtTrigger to rerender again
                this.bindresponse();
              });
              this.notificationService.showSuccess(data.msg);
            }
            else {
              this.notificationService.showError(data.msg);
            }
          }, err => {
            if (err.status == 401) {
              this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
            }
            else {
              this.router.navigate([this.constants.clientUrlNotFound]);
            }
          });

        }
      });

    //We can close dialog calling disposable.unsubscribe();
    //If dialog was not closed manually close it by timeout
    setTimeout(() => {
      disposable.unsubscribe();
    }, 10000);
  }

  checkAll(ev) {
    var elementArray = document.getElementsByName("sizecd");
    for (var index = 0; index < elementArray.length; index++) {
      var checkBox = <HTMLInputElement>elementArray[index]
      if (this.requestId.length == 0) {
        this.requestId.push(elementArray[index].id);
      }
      else {
        var resultArray = this.requestId.filter(data => data == elementArray[index].id);
        if (resultArray.length == 0) {
          this.requestId.push(elementArray[index].id);
        } else {
          var arrayindex = this.requestId.indexOf(elementArray[index].id);
          this.requestId.splice(arrayindex, 1);
        }
      }
      checkBox.checked = ev.target.checked
    }
    if (this.requestId.length > 0) {
      // this.showdelete = true;
      $('.model').css('display', 'block');
    }
    else {
      // this.showdelete = false;
      $('.model').css('display', 'none');
    }
    if (!ev.target.checked) {
      // this.requestId = [];
      // this.showdelete = false;
      $('.model').css('display', 'none');
    }
  }

  recordChecked(ev) {
    var id = ev.currentTarget.id;
    var rowCount = 0;
    var elementArray = document.getElementsByName("sizecd");
    var resultArray = this.requestId.filter(data => data == id);
    var elementId = document.getElementById(id);
    if (resultArray != null && resultArray.length != 0) {
      var indexdata = this.requestId.findIndex(x => x === id)
      this.requestId.splice(indexdata, 1);
      // this.deleteAll = false;
      $("#checkAll").prop('checked', false);
    }
    else {
      this.requestId.push(id);
    }
    if (this.requestId.length > 0) {
      // this.showdelete = true;
      $('.model').css('display', 'block');
    }
    else {
      // this.showdelete = false;
      $('.model').css('display', 'none');
    }
    for (var index = 0; index < elementArray.length; index++) {
      var checkBox = <HTMLInputElement>elementArray[index]
      if (checkBox.checked == false) {
        rowCount = rowCount + 1;
      }
    }
    var selectedrow = elementArray.length - rowCount;
    if (selectedrow == elementArray.length) {
      // this.deleteAll = true;
      $("#checkAll").prop('checked', true);
    }
  }

  bulkDelete() {
    const deleteid = {
      deleteid: this.requestId
    };
    let disposable = this.dialogService.addDialog(ConfirmDialogComponent, {
      title: "Delete Confirmation",//this.constants.deleteConfirmationTitle"",
      message: "Are you sure you want to delete the selected record?",//this.constants.deleteConfirmation
    })
      .subscribe((isConfirmed) => {
        //Dialog result
        if (isConfirmed) {
          this.contactResponseService.bulkDelete(deleteid, true).subscribe((data:any) => {
            if (data.success) {
              this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Destroy the table first
                dtInstance.destroy();
                // Call the dtTrigger to rerender again
                this.bindresponse();
                this.requestId = [];
              });
              this.notificationService.showSuccess(data.msg);
            }
            else {
              this.bindresponse();
              this.notificationService.showError(data.msg);
            }
          });
        }
      });

    //We can close dialog calling disposable.unsubscribe();
    //If dialog was not closed manually close it by timeout
    setTimeout(() => {
      disposable.unsubscribe();
    }, 10000);
  }
}