import { AuthAdminService } from './../../../services/authadmin.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { Constants } from './../../../constants';
import { HandleError } from './../../../error';
import { NotificationService } from './../../../services/notification.service';


@Component({
  selector: 'app-user-changepassword',
  templateUrl: './user-changepassword.component.html',
  styleUrls: ['./user-changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {
  _id: String;
  changepasswordForm: FormGroup;
  submitButtonText: string = this.constants.ChangePassword;
  password: String;
  newpassword: String;
  retypepassword: String;
  disableButton: Boolean = false;
  constructor(private authadminService: AuthAdminService,
    public constants: Constants,
    private errorHandler: HandleError,
    private notificationService: NotificationService,
    private router: Router,
    fb: FormBuilder) {
    let newpassword = new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(15), Validators.pattern("[A-Za-z0-9!@#$&()\\-`.+,/\\S]{1,25}")]);
    let confirmpassword = new FormControl('', [Validators.required, CustomValidators.equalTo(newpassword)]);

    this.changepasswordForm = fb.group({
      'password': [null, Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(15), Validators.pattern("[A-Za-z0-9!@#$&()\\-`.+,/\\S]{1,25}")])],
      'newpassword': newpassword,
      'retypepassword': confirmpassword,
    })
  }

  ngOnInit() {
    var user = this.authadminService.getAdminFromStorage();
    if (user != null) {
      this._id = user.id;
    }
  }
  onSubmit() {
    this.disableButton = true;
    const user = {
      _id: this._id,
      password: this.password,
      newpassword: this.newpassword,
    }
    this.authadminService.changePassword(user, true).subscribe((data:any) => {
      this.changepasswordForm.reset();
      if (data.success) {
        this.notificationService.showSuccess(data.msg);
      }
      else {
        this.notificationService.showError(data.msg);
      }
      this.disableButton = false;
    }, err => {
      if (err.status == 401) {
        this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
      }
      else {
        this.router.navigate([this.constants.clientUrlNotFound]);
      }
    });
  }
}