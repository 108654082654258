import { UniversityService } from './../../../services/university.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Constants } from './../../../constants';
import { NotificationService } from './../../../services/notification.service';
import { StreamService } from "./../../../services/stream.service";
import { DropdownService } from 'services/dropdown.service';

class University {
  _id: String;
  name: String;
}
class yearORsemesters {
  yearORsem: any;
}


@Component({
  selector: "app-addStream",
  templateUrl: "./addStream.component.html",
  styleUrls: ["./addStream.component.scss"]
})

export class AddStreamComponent implements OnInit {
  departmentId: any;
  streamAbbreviation: String;
  name: String;
  departmentForm: FormGroup;
  university: String;
  isSemester: String = "true";
  yearORSemester: Number;
  stream: String;
  hide: Boolean = false;
  disableButton: Boolean = false;
  submitButtonText: String = this.constants.Save;
  constructor(
    private activatedRoute: ActivatedRoute,
    public constants: Constants,
    private notificationService: NotificationService,
    private departmentService: StreamService,
    private universityService: UniversityService,
    private dropdownService: DropdownService,
    private router: Router,
    fb: FormBuilder
  ) {
    let description = new FormControl('', [Validators.required]);
    this.activatedRoute.params.subscribe((params: Params) => {
      this.departmentId = params['id'];
      if (this.departmentId == null) {
        this.departmentForm = fb.group({
          'streamAbbreviation': [null, Validators.required],
          'name': [null, Validators.required],
          'university': [null, Validators.required],
          'isSemester': [null, Validators.nullValidator],
          'yearORSemester': [null, Validators.compose([Validators.required])]
        });
      } else {
        this.departmentForm = fb.group({
          'streamAbbreviation': [null, Validators.required],
          'name': [null, Validators.required],
          'university': [null, Validators.required],
          'isSemester': [null, Validators.nullValidator],
          'yearORSemester': [null, Validators.compose([Validators.required])],
        });
      }
    })

  }

  ngOnInit() {
    this.getUniversity();
    this.getYearORSemester();
    this.activatedRoute.params.subscribe((params: Params) => {
      let departmentId = params['id'];
      if (departmentId != null) {
        this.hide = true;
          this.departmentService.getDepartmentById(departmentId, true).subscribe((data:any) => {
            this.streamAbbreviation = data.department.streamAbbreviation;
            this.name = data.department.name;
            this.university = data.department.universityId;
            this.isSemester = data.department.isSemester == true ? "true" : "false";
            this.yearORSemester = data.department.yearORsemester;
            this.submitButtonText = this.constants.Update;
            this.departmentId = departmentId;
            this.hide = false;
          }, err => {
            if (err.status == 401) {
              this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
            }
            else {
              this.router.navigate([this.constants.clientUrlNotFound]);
            }
          })
      }
    })
  }
  yearORsemesters: yearORsemesters[] = []
  getYearORSemester() {    
      this.dropdownService.getYearORSemester().subscribe((data:any) => {
          if (this.streamDuration == null) {
              this.yearORsemesters = data.yearORsem;
          } else {
              this.yearORsemesters = data.yearORsem.filter(item => item <= this.streamDuration);
          }
            });
              }

  streamDuration: Number;
  streamDetail() {
      this.departmentService.getDepartmentById(this.stream, true).subscribe((data:any) => {
          if (data.success) {
              this.isSemester = data.department.isSemester;
              this.streamDuration = data.department.yearORsemester;
              this.getYearORSemester();
          }
      });
  }

  universities: University[] = [];
  getUniversity() {
    this.universityService.getUniversities().subscribe((data:any) => {
      this.universities = data.universities;
    });
  }

  onSubmit() {
    this.disableButton = true;
    const department = {
      departmentId: this.departmentId,
      streamAbbreviation: this.streamAbbreviation,
      name: this.name.trim(),
      university: this.university,
      isSemester: this.isSemester == "true" ? 1 : 0,
      yearORSemester: this.yearORSemester,
    }
    this.departmentService.putDepartment(department, true).subscribe((data:any) => {
      if (data.success) {
        this.router.navigate([this.constants.clientUrlStream]);
        this.notificationService.showSuccess(data.msg);
      }
      else {
        this.notificationService.showError(data.msg);
        this.disableButton = false;
      }
    }, err => {
      if (err.status == 401) {
        this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
      }
      else {
        this.router.navigate([this.constants.clientUrlNotFound]);
      }
    })
  }
}
