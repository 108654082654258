import { Component, OnInit } from '@angular/core';
import { AuthUserService } from './../../../services/authuser.service';
import { NotificationService } from './../../../services/notification.service';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { Constants } from './../../../constants';
import { DashboardService } from './../../../services/dashboard.service';
import { HandleError } from './../../../error';


@Component({
  selector: 'app-social-login',
  templateUrl: './social-login.component.html',
  styleUrls: ['./social-login.component.scss']
})
export class SocialLoginComponent implements OnInit {
  linkedInToken = "";
  linkedInState = '';
  returnUrl: String;
  public socialuser;

  constructor(private authuserService: AuthUserService,
              public constants: Constants,
              private notificationService: NotificationService,
              private route: ActivatedRoute,
              private errorHandler: HandleError,
              private dashboardService: DashboardService,) { }

  ngOnInit() {
    let code = {
      tokenCode: this.route.snapshot.queryParams["code"],
      tokenState: this.route.snapshot.queryParams["state"],
      client_id: "86xa25wfg3ndax",
      client_secret: "cuj8zdpPAcydXASX",
      redirect_uri: "https://www.rippletoshare.com/socialLogin"
      // redirect_uri: "http://localhost:4200/socialLogin"
      // redirect_uri: "http://192.168.1.206:80/socialLogin"
    }
    this.LinkedInApi(code);
  }

  LinkedInApi(code){
    this.dashboardService.LinkedInApiUser(code, true).subscribe((data: any) => {
      if(data.Result != undefined){
        const user = {
          email: data.Result.email,
          name: data.Result.fname+' '+data.Result.lname,
          university: null,
          provider: 'linkedin',
          picture: data.Result.image
        }
        this.authuserService.externalUserLogin(user).subscribe((userdata:any) => {
          if (userdata.success) {
            this.socialuser = data.Result;
            this.returnUrl = this.constants.clientUserHome;
            console.log("User data from external login..."+userdata);
            this.authuserService.storeUserData(userdata.token, userdata.user);
            this.constants.removeCacheData('DashboardCounts', 0);
            window.location.href = this.returnUrl.toString();
          }
          else {
            this.returnUrl = this.constants.clientUrlSignIn;
            this.notificationService.showError(userdata.msg);
            window.location.href = this.returnUrl.toString();
          }
        },
          err => {
             this.errorHandler.handleErrors(err);
          });
      }else {
        this.returnUrl = this.constants.clientUrlSignIn;
        this.notificationService.showError("Unable to login through social account, Please try  again.");
        window.location.href = this.returnUrl.toString();
      }
      
    });
  }
}
