import { UserAddressService } from './../../../services/userAddress.service';
import { RequeststatushistoryserviceService } from './../../../services/requeststatushistoryservice.service';
import { BookRequestService } from './../../../services/bookrequest.service';
import { BookService } from './../../../services/book.service';
import { element, browser } from 'protractor';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import * as moment from 'moment';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { UserService } from './../../../services/user.service';
import { AuthUserService } from './../../../services/authuser.service';
import { Constants } from './../../../constants';
import { HandleError } from './../../../error';
import { NotificationService } from './../../../services/notification.service';

class Users {
  _id: any;
  title: String;
  userid: any;
  address: String;
}

@Component({
  selector: 'app-contribute-request',
  templateUrl: './contribute-request.component.html',
  styleUrls: ['./contribute-request.component.css']
})
export class ContributeRequestComponent implements OnInit {
  userForm: FormGroup;
  addressid: any;
  returnUrl: String;
  submitButtonText: string = this.constants.Contribute;
  bookid: String;
  userdata: any;
  userid: String;
  title: String;
  address: String;
  hide: Boolean = false;
  requestdate: Date = new Date();
  // mindate: any = new Date(this.requestdate.getFullYear(), this.requestdate.getMonth(), this.requestdate.getDate());
  requesttype: Boolean;
  contactnumber: String;
  preferreddate: Date;
  preferredtimeFrom: Date;
  preferredtimeTo: Date;
  pickupaddress: String;
  selected: any;
  isdropbyuser: Boolean;
  disableButton: Boolean = false;
  quantity: number;
  public drop = 'drop';
  public pickup = 'pickup';
  datepickerOpts = {
    startDate: new Date(),
    autoclose: true,
    todayBtn: 'linked',
    todayHighlight: true,
    format: 'd-M-yyyy'
  }
  constructor(
    private fb: FormBuilder,
    private userAddress: UserAddressService,
    public constants: Constants,
    private router: Router,
    private route: ActivatedRoute,
    private bookService: BookService,
    private notificationService: NotificationService,
    private bookrequestService: BookRequestService,
    private authuserservice: AuthUserService,
    private requeststatushistoryserviceService: RequeststatushistoryserviceService
  ) {
    this.userForm = fb.group({
      'contactnumber': [null, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern('[0-9]*')])],
      'pickupaddress': [null, Validators.nullValidator],
      'title': [null, Validators.nullValidator],
      // 'address': [null, Validators.nullValidator],
      'preferreddate': [null, Validators.nullValidator],
      'preferredtimeFrom': [null, Validators.nullValidator],
      'preferredtimeTo': [null, Validators.nullValidator],
      'quantity': [null, Validators.compose([Validators.required, Validators.min(1), Validators.max(10)])],
    })
  }
  ngOnInit() {
    this.isdropbyuser = true;
    this.route.queryParams
      .subscribe(params => {
        this.requesttype = params['flag'];
      });
    this.userdata = this.authuserservice.getUserFromStorage();
    this.contactnumber = this.userdata.contactno;
    this.bindGrid();
  }

  addUserAddress: any[] = [];
  bindGrid() {
    this.userid = this.userdata.id;
    this.pickupaddress = undefined;
    if (this.userid != null) {
      this.userAddress.getAddressById(this.userid, false).subscribe((data:any) => {
        this.addUserAddress = data.userAddress;
      }, err => {
        if (err.status == 401) {
          this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
        }
        else {
          this.router.navigate([this.constants.clientUrlNotFound]);
        }
      });
    }
  }

  onSubmitClick() {
    this.disableButton = true;
    this.route.queryParams
      .subscribe(params => {
        const bookrequest = {
          searchKey: params['id'],
          googleid: params['id'],
          isdelete: 0,
          bookid: params['cid'],
          userid: this.userdata.id,
          requesttype: this.requesttype,
          istransactioncomplete: 0,
          contactno: this.contactnumber,
          preferreddate: this.preferreddate != null && this.preferreddate != undefined ? this.preferreddate.toDateString() : '',
          preferredtime: this.preferredtimeFrom != null && this.preferredtimeFrom != undefined ? this.preferredtimeFrom.toLocaleTimeString() + " To " + this.preferredtimeTo.toLocaleTimeString() : '',
          address: this.pickupaddress,
          isdropbyuser: this.isdropbyuser,
          remarks: "",
          email: this.userdata.email,
          name: this.userdata.name,
          quantity: this.quantity,
          emailNotification: true
          }
          // Change by Arpit
        if (!bookrequest.isdropbyuser && this.hide) {
          const user = {
            addressid: this.addressid,
            userid: this.userid,
            title: this.title != undefined ? this.title.trim() : undefined,
            address: this.pickupaddress != undefined ? this.pickupaddress.trim() : undefined,
          }
          this.userAddress.addAddress(user, false).subscribe((data:any) => {
            if (!data.success) {
              this.notificationService.showError(data.msg);
              this.disableButton = false;
            }
          });
        }
        //upsert users    

        if (params['cid'] != '' && params['cid'] != undefined && params['cid'] != "undefined") {
          this.bookrequestService.putManualBookRequest(bookrequest, false).subscribe((data:any) => {
            if (data.success) {
              this.constants.removeCacheData('DashboardCounts', 0);
                // Change by Arpit
              this.constants.removeCacheData('DashboardBookList', 0);
              this.constants.removeCacheData('ListOfNeededBook', 0);
              this.notificationService.showSuccess(data.msg);
              this.router.navigate([this.constants.clientUrlUserDonations])
            }
            else {
              this.notificationService.showError(data.msg);
              this.disableButton = false;
            }
          },
            err => {
              if (err.status == 401) {
                this.router.navigate([this.constants.clientUrlUnauthorized]);
              }
              else {
                this.router.navigate([this.constants.clientUrlNotFound]);
              }
            });
        }
        else {
          this.bookrequestService.putBookRequest(bookrequest, false).subscribe((data:any) => {
            if (data.success) {
              this.constants.removeCacheData('DashboardCounts', 0);
                // Change by Arpit
              this.constants.removeCacheData('DashboardBookList', 0);
              this.constants.removeCacheData('ListOfNeededBook', 0);
              this.notificationService.showSuccess(data.msg);
              this.router.navigate([this.constants.clientUrlUserDonations])
            }
            else {
              this.notificationService.showError(data.msg);
              this.disableButton = false;
            }
          },
            err => {
              if (err.status == 401) {
                this.router.navigate([this.constants.clientUrlUnauthorized]);
              }
              else {
                this.router.navigate([this.constants.clientUrlNotFound]);
              }
            });
        }

      }, err => {
        if (err.status == 401) {
          this.router.navigate([this.constants.clientUrlUnauthorized]);
        }
        else {
          this.router.navigate([this.constants.clientUrlNotFound]);
        }
      });
  }

  onClick(value) {
    this.hide = !this.hide;
    if (this.hide) {
      this.userForm = this.fb.group({
        'contactnumber': [this.contactnumber, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern('[0-9]*')])],
        'pickupaddress': [null, Validators.required],
        'title': [null, Validators.required],
        // 'address': [null, Validators.required],
        'preferreddate': [null, Validators.required],
        'preferredtimeFrom': [null, Validators.required],
        'preferredtimeTo': [null, Validators.required],
        'quantity': [this.quantity, Validators.compose([Validators.required, Validators.min(1), Validators.max(10)])],
      });
      this.pickupaddress = '';
    }
  }

  onSelectionChange(value) {
    this.isdropbyuser = !this.isdropbyuser;
    var options = document.getElementsByClassName('doptions');
    for (var i = 0; i < options.length; i++) {
      if (options[i].id == value) {
        options[i].setAttribute("style", "display:block");
      } else {
        options[i].setAttribute("style", "display:none");
      }
    }
    if (!this.isdropbyuser) {
      this.userForm = this.fb.group({
        'contactnumber': [this.contactnumber, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern('[0-9]*')])],
        'pickupaddress': [null, Validators.required],
        'title': [null, Validators.nullValidator],
        'preferreddate': [null, Validators.required],
        'preferredtimeFrom': [null, Validators.nullValidator],
        'preferredtimeTo': [null, Validators.nullValidator],
        'quantity': [this.quantity, Validators.compose([Validators.required, Validators.min(1), Validators.max(10)])],
      });
      // this.pickupaddress = null;
    } else {
      this.userForm = this.fb.group({
        'contactnumber': [this.contactnumber, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern('[0-9]*')])],
        'pickupaddress': [null, Validators.nullValidator],
        'preferreddate': [null, Validators.nullValidator],
        'title': [null, Validators.nullValidator],
        // 'address': [null, Validators.nullValidator],
        'preferredtimeFrom': [null, Validators.nullValidator],
        'preferredtimeTo': [null, Validators.nullValidator],
        'quantity': [this.quantity, Validators.compose([Validators.required, Validators.min(1), Validators.max(10)])],
      });
    }
  }
}