import { Router } from '@angular/router';
import { BookService } from "../../../services/book.service";
import { DataTableDirective } from 'angular-datatables';
import { Constants } from './../../../constants';
import { DashboardService } from './../../../services/dashboard.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { Subject } from 'rxjs';
import * as _ from 'lodash';

class AvailableBook {
  title: string;
  bookInstanceCount: string;
}
class NeededBook {
  title: string;
  bookInstanceCount: string;
}
class Pages {
  pageId: any;
  title: String;
  description: String;
  isactive: String;
  isdelete: 0;
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    legend: {
      labels: { fontColor: '#ffffff' }
    },
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          fontColor: '#ffffff'
        },
      }],
      xAxes: [{
        ticks: {
          fontColor: '#ffffff',
        },
      }]
    }

  };

  public pieChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    legend: {
      labels: { fontColor: '#ffffff' }
    }
  };

  public barChartLabels: any[] = [];
  public barChartType: string = 'bar';
  public barChartColors: any[] = [
    {
      backgroundColor: "#00abff"
    },
    {
      backgroundColor: "#8bd22f"
    }
  ];
  public barChartLegend: boolean = true;
  public seekingChartLabels: any[] = [];
  public seekingChartData: any[] = [{ data: [], label: '' }, { data: [], label: '' }];
  public barChartData: any[] = [{ data: [], label: '' }, { data: [], label: '' }];
  listOfInstanceNeededBooks: NeededBook[] = [];
  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;
  dtOptions = this.constants.dataTableSettings && {
    order: [0, 'asc'],
    pagingType: 'simple',
  };
  dtTrigger: Subject<NeededBook> = new Subject<NeededBook>();
  // @ViewChild(DataTableDirective, {static: false}) dtElement1: DataTableDirective;
  listOfInstanceAvailbleBooks: AvailableBook[] = [];
  dtOptions1 = this.constants.dataTableSettings && {
    order: [0, 'asc'],
    pagingType: 'simple',
  };
  dtTrigger1: Subject<AvailableBook> = new Subject<AvailableBook>();

  requesttype: String;
  year: number;
  businessCount: any;
  month: any;
  // listOfInstanceNeededBooks: any;
  // listOfInstanceAvailbleBooks: any;
  hide: Boolean = false;
  showBusinessCount: boolean;

  years1: number[] = [];
  years: number[]
  _next: any;
  public yearForSeeking: any;
  // events
  public chartClicked(e: any): void {
  }

  public chartHovered(e: any): void {
  }

  // Doughnut
  public doughnutChartLabels: string[] = [];
  public doughnutChartData: number[] = [];
  public doughnutChartType: string = 'doughnut';

  // Pie
  public pieChartLabels: any[] = [];
  public pieChartColors: any[] = [
    {
      backgroundColor: ["#00abff", "#00a65a", "#f95372", "#8bd22f", "#605ca8", "#dd4b39"]
    }
  ];
  public pieChartData: any[] = [{ data: '' }, { data: '' }, { data: '' }, { data: '' }, { data: '' }, { data: '' }];
  public pieChartType: string = 'pie';

  constructor(
    private dashboardService: DashboardService,
    public constants: Constants,
    private bookService: BookService,
    private router: Router
  ) {
    this.getYear();
  }
  ngOnInit() {
    this.dashboardService.getDashboardCounts(true).subscribe((data: any) => {
      if (data.success) {
        this.businessCount = data.msg
        this.showBusinessCount = data.success;
      }
      this.onBarChartSeeking(new Date().getFullYear());
      this.onBarChartContribute(new Date().getFullYear());
      this.onPieChartStatus();
      this.bookInstanceCounts();
      this.bookInstanceAvailableCounts();
    }, err => {
      if (err.status == 401) {
        this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
      }
      else {
        this.router.navigate([this.constants.clientUrlNotFound]);
      }
    });
  }
  //get Years
  getYear() {
    this.dashboardService.getYear(true).subscribe((data: any) => {
      this.years = data.years[0].distinctDate;
      this.years.sort((a: any, b: any) => {
        if (a.year > b.year) return -1;
        else if (a.year < b.year) return 1;
        else return 0;
      });
    }, err => {
      if (err.status == 401) {
        this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
      }
      else {
        this.router.navigate([this.constants.clientUrlNotFound]);
      }
    });
  }


  onBarChartSeeking(yearForSeeking) {
    let requestType = {
      requestType: true,
      year: parseInt(yearForSeeking)
    }
    this.dashboardService.getDashboardMonthWiseCounts(requestType, true).subscribe((data: any) => {
      this.hide = true
      if (data.success) {
        this.seekingChartLabels = data.msg[0].month
        this.seekingChartData = [
          {
            data:
              data.msg[0].requestTotalCount
            ,
            label: "Total Request"
          },
          {
            data:
              data.msg[0].requestCompletedCount
            ,
            label: "Completed Request"
          }
        ]
      }
      this.hide = false;
    }, err => {
      if (err.status == 401) {
        this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
      }
      else {
        this.router.navigate([this.constants.clientUrlNotFound]);
      }
    });
  }


  onBarChartContribute(years) {
    let requestType = {
      requestType: false,
      year: parseInt(years)
    }
    this.dashboardService.getDashboardMonthWiseCounts(requestType, true).subscribe((data: any) => {
      this.hide = true;
      if (data.success) {
        this.barChartLabels = data.msg[0].month
        this.barChartData = [
          {
            data:
              data.msg[0].requestTotalCount
            ,
            label: "Total Request"
          },
          {
            data:
              data.msg[0].requestCompletedCount
            ,
            label: "Completed Request"
          }
        ]
      }
      this.hide = false;
    }, err => {
      if (err.status == 401) {
        this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
      }
      else {
        this.router.navigate([this.constants.clientUrlNotFound]);
      }
    });
  }

  onPieChartStatus() {
    this.dashboardService.getDashboardStatusWiseCounts(true).subscribe((data: any) => {
      this.hide = true;
      if (data.success) {
        let labels: any[] = [];
        let dataset: any[] = [];

        for (var index = 0; index < data.counts.length; index++) {
          this.pieChartLabels.push(data.counts[index].status);
          dataset.push(data.counts[index].count);
        }
        this.pieChartData = dataset;
      }
      this.hide = false;
    }, err => {
      if (err.status == 401) {
        this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
      }
      else {
        this.router.navigate([this.constants.clientUrlNotFound]);
      }
    });
  }

  bookInstanceCounts() {
    this.dashboardService.getDashboardInstanceCount(true).subscribe((data: any) => {
      this.hide = true;
      if (data.success) {
        this.listOfInstanceNeededBooks = data.msg;
        this.dtTrigger.next();
      }
      this.hide = false;
    }, err => {
      if (err.status == 401) {
        this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
      }
      else {
        this.router.navigate([this.constants.clientUrlNotFound]);
      }
    });
  }

  bookInstanceAvailableCounts() {
    this.bookService.getAvailableBooks().subscribe((data: any) => {
      if (data != null) {
        this.listOfInstanceAvailbleBooks = data.books;
        this.dtTrigger1.next();
      }
    });
  }
}