import { HttpClient } from '@angular/common/http';

import { Constants } from './../constants';
import { Injectable } from '@angular/core';

@Injectable()
export class BookService {
    authToken: any;
    book: any;

    constructor(private http: HttpClient, public constants: Constants) { }

    //get active books counts
    getActiveBooks() {
        let timeStamp = +new Date();
        let headers = this.constants.getHeaders();
        return this.http.get(this.constants.serverbookGetActiveBooksCounts + '?tsp=' + timeStamp, { headers: headers });            
    }

    //get all books
    getBooks(tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.get(this.constants.serverbookGetAll + '?tsp=' + timeStamp, { headers: headers });            
    }

    //get book by id
    getBookById(id) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeaders();
        return this.http.get(this.constants.serverbookGetById + '/' + id + '?tsp=' + timeStamp, { headers: headers });
    }

    //get multiple books by ID
    getBooksById(id) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeaders();
        return this.http.post(this.constants.serverGetBooksById + '?tsp=' + timeStamp, id, { headers: headers });
    }

    //update book     
    putBook(book, tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.post(this.constants.serverbookPut + '?tsp=' + timeStamp, book, { headers: headers });
    }

    //Create new book from verification request
    putCreateNewBook(book, tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.put(this.constants.serverbookputCreateNewBook + '?tsp=' + timeStamp, book, { headers: headers })
    }

    //delete book
    deleteBook(id, tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.delete(this.constants.serverbookDelete + '/' + id + '?tsp=' + timeStamp, { headers: headers });   
    }

    //bulk delete
    bulkDelete(deleteid, tokenFlag) {
        let timeStamp = +new Date();
        var headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.post(this.constants.serverbookBulkDelete + '?tsp=' + timeStamp, deleteid, { headers: headers }); 
    }

    //load token from storage
    loadToken() {
        const token = localStorage.getItem("id_token");
        this.authToken = token;
        return token;
    }

    //search books
    searchBook(search) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeaders();
        return this.http.post(this.constants.serverbookSearch + '?tsp=' + timeStamp, { search: search }, { headers: headers });
    }

    //search book by googleid serverbookSearchByGoogleId
    searchBookByGoogleId(id) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeaders();
        return this.http.get(this.constants.serverbookSearchByGoogleId + "/" + id + '?tsp=' + timeStamp);
    }

    //get Instance needed book.
    getRequestedBooks() {
        let timeStamp = +new Date();
        let headers = this.constants.getHeaders();
        return this.http.get(this.constants.serverbookRequestedBooks + '?tsp=' + timeStamp);
    }

    //get available book for front
    getAvailableBooks() {
        let timeStamp = +new Date();
        let headers = this.constants.getHeaders();
        return this.http.get(this.constants.serverbookAvailable + '?tsp=' + timeStamp);
    }

    //put manual book
    putManualBook(book, tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.put(this.constants.servermanualbookPut + '?tsp=' + timeStamp, book, { headers: headers });
    }

    ManualPutBookRequest(book, tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.post(this.constants.servermanualputbookrequest + '?tsp=' + timeStamp, book, { headers: headers });    
    }

        
    getInstancenoPerticularBook(id) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeaders();
        return this.http.get(this.constants.serverinstancenumberPerticularBook + "/" + id + '?tsp=' + timeStamp);
        }
    
}
