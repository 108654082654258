import { DropdownService } from './../../../services/dropdown.service';
import { ControlSidebarComponent } from './../control-sidebar/control-sidebar.component';
import { FileUploader, FileSelectDirective } from 'ng2-file-upload';
import { NotificationService } from './../../../services/notification.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Constants } from './../../../constants';
import { BookService } from './../../../services/book.service';
import { Router, ActivatedRoute, Params, NavigationStart, RoutesRecognized } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { BookinstanceService } from 'services/bookinstance.service';
import { Subject } from 'rxjs';
import { DialogService } from 'ng2-bootstrap-modal';
import { InstanceLogModalComponent } from '../InstancelogModal/InstanceLogModal.Component';
import { BookRequestService } from 'services/bookrequest.service';

class BookIntances {

}

@Component({
  selector: 'app-view-book-detail',
  templateUrl: './view-book-detail.component.html',
  styleUrls: ['./view-book-detail.component.scss']
})
export class ViewBookDetailComponent implements OnInit {
  searchKey: any;
  customBookid: any;
  bookid: any;
  googleid: any;
  title: String = '';
  subtitle: String = '';
  description: String = '';
  categoryid: String = '';
  language: String = '';
  author: String = '';
  publisher: String = '';
  identifier_13: String = '';
  isbnnumber: String;
  isdelete: Boolean;
  isactive: Boolean;
  submitButtonText: string;
  imageurl: any;
  imageurlPath: String;
  imagename: string;
  hide: Boolean = false;
  hidenblock: Boolean = false;
  isFromAddBook: Boolean;
  bookForm: FormGroup;
  priviusURL
  public pageHeader: any;

  public uploader: FileUploader = new FileUploader({
    url: this.constants.uploadFile
    , allowedFileType: ["image"],
    authToken: this.constants.loadToken(),
  });

  bookinstances: any[] = [];
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private bookService: BookService,
    public constants: Constants,
    private dialogService: DialogService,
    private router: Router,
    private bookInstanceService: BookinstanceService,
    private bookRequestService: BookRequestService,
    private dropdownService: DropdownService,
    private notificationService: NotificationService,
    fb: FormBuilder,
  ) {
    this.activatedRoute.queryParams
      .subscribe(params => {
        if (params['flag'] == "true") {
          this.hidenblock = true;
        }
          })
    // Change By Arpit for #1803
    this.bookForm = fb.group({

      'bookid': [null, Validators.nullValidator],
      'title': [null, Validators.compose([Validators.required, Validators.maxLength(150)])],
      'subtitle': [null, Validators.compose([Validators.nullValidator, Validators.maxLength(100), Validators.pattern("^[a-zA-Z0-9!@#'$&()\\-`.+,/\_]+( [a-zA-Z0-9!'@#$&()\\-`.+,/\_]+)*$")])],
      'description': [null, Validators.nullValidator],
      'categoryid': [null, Validators.compose([Validators.nullValidator, Validators.maxLength(100), Validators.pattern("^[a-zA-Z0-9!@'#$&()\\-`.+,/\_]+( [a-zA-Z0-9!@#'$&()\\-`.+,/\_]+)*$")])],
      'language': [null, Validators.required],
      'author': [null, Validators.compose([Validators.nullValidator, Validators.maxLength(100), Validators.pattern("^[a-zA-Z0-9!@'#$&()\\-`.+,/\_]+( [a-zA-Z0-9!@'#$&()\\-`.+,/\_]+)*$")])],
      'publisher': [null, Validators.compose([Validators.nullValidator, Validators.maxLength(100), Validators.pattern("^[a-zA-Z0-9!@'#$&()\\-`.+,/\_]+( [a-zA-Z0-9!@'#$&()\\-`.+,/\_]+)*$")])],
      'imageurl': [null, Validators.nullValidator],
      'isbnnumber': [null, Validators.nullValidator],
      'isactive': [null, Validators.nullValidator],
    });
  }

  ngOnInit() {
    this.pageHeader = this.constants.pageHeader.split('?');
    if (localStorage.getItem('currentURL') === this.constants.clientUrlBooksAdd) {
      this.isFromAddBook = true;
    }
    this.priviusURL = localStorage.getItem('currentURL');
    if (this.constants.loadToken() != null && this.constants.loadToken() != undefined) {
      this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
      this.uploader._fileTypeFilter = (file) => true;
      this.activatedRoute.queryParams.subscribe((params) => {
        if (params["id"]) {
          this.searchKey = params['searchKey'];
          this.bookid = params['id'];
          this.searchBooksBy(params['searchKey'], params['id']);
        } else {
          this.searchKey = params['searchKey'];
          this.customBookid = params['cid'];
          this.getBookById(params['searchKey'], params['cid']);
        }this.hide = false;
      });
    } else {
      this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
    }
    //get languages in drop dwon
    this.getLanguage();
    // Get Book Instances
    this.bindBookInstances();
  }

  searchBooksBy(search, infoLinkId) {
      this.hide = true;
      // Change by Arpit for #1803
    this.bookService.searchBookByGoogleId(search+'&'+infoLinkId).subscribe((data: any) => {
      if (data.success && data.book !== null) {
        data.book.filter(item=> {
          var bookId = this.constants.getIdFromInfoLinks(item.infoLink);
           if(item.title === search && bookId === infoLinkId)data.book = item;
         });
        this.googleid = data.book.googleid;
        this.title = data.book.title && data.book.title != 'undefined' ? this.getTrimSubText(data.book.title) : ''
        this.subtitle = data.book.subtitle && data.book.subtitle != 'undefined' ? this.getTrimSubText(data.book.subtitle) : ''
        this.description = data.book.description && data.book.description != 'undefined' ? data.book.description : ''
        this.categoryid = data.book.categoryid ? this.getTrimSubText(data.book.categoryid[0]) : ''
        this.language = data.book.language != '' ? data.book.language : undefined
        this.author = data.book.author && data.book.author != 'undefined' ? this.getTrimSubText(data.book.author[0]) : ''
        this.publisher = data.book.publisher && data.book.publisher != 'undefined' ? this.getTrimSubText(data.book.publisher[0]) : ''
        if (data.book.imageurl != undefined && data.book.imageurl != "") {
          this.imageurlPath = this.constants.serveruploadspath + data.book.imageurl
          this.imagename = data.book.imageurl
        }
        this.isactive = data.book.isactive;
        this.isbnnumber = '';
        if (data.book.isbnnumber) {
          if (data.book.isbnnumber.length >= 1 && data.book.isbnnumber.length <= 2) {
            this.isbnnumber = data.book.isbnnumber[0].identifier ? data.book.isbnnumber[0].identifier : '';
            this.isbnnumber += data.book.isbnnumber[1] ? ", " + data.book.isbnnumber[1].identifier : '';
          } else {
            this.isbnnumber = data.book.isbnnumber ? data.book.isbnnumber : '';
          }
        } else {
          this.isbnnumber = data.book.isbnnumber ? data.book.isbnnumber : '';
        }
      }
      this.hide = false;
    });
  }
  //Change By Arpit for #1803
  getBookById(search, customBookId) {
    this.hide = true;
    this.bookService.getBookById(customBookId).subscribe((data: any) => {
      if (data.success && data.book !== null) {
        this.googleid = data.book.googleid;
        this.title = data.book.title && data.book.title != 'undefined' ? this.getTrimSubText(data.book.title) : ''
        this.subtitle = data.book.subtitle && data.book.subtitle != 'undefined' ? this.getTrimSubText(data.book.subtitle) : ''
        this.description = data.book.description && data.book.description != 'undefined' ? data.book.description : ''
        this.categoryid = data.book.categoryid[0] && data.book.categoryid[0] != 'undefined' ? this.getTrimSubText(data.book.categoryid[0]) : ''
        this.language = data.book.language != '' ? data.book.language : undefined
        this.author = data.book.author && data.book.author != 'undefined' ? this.getTrimSubText(data.book.author[0]) : ''
        this.publisher = data.book.publisher && data.book.publisher != 'undefined' ? this.getTrimSubText(data.book.publisher[0]) : ''
        if (data.book.imageurl != undefined && data.book.imageurl != "") {
          this.imageurlPath = this.constants.serveruploadspath + data.book.imageurl
          this.imagename = data.book.imageurl
        }
        this.isactive = data.book.isactive;
        this.isbnnumber = '';
        if (data.book.isbnnumber) {
          if (data.book.isbnnumber.length >= 1 && data.book.isbnnumber.length <= 2) {
            this.isbnnumber = data.book.isbnnumber[0].identifier ? data.book.isbnnumber[0].identifier : '';
            this.isbnnumber += data.book.isbnnumber[1] ? ", " + data.book.isbnnumber[1].identifier : '';
          } else {
            this.isbnnumber = data.book.isbnnumber ? data.book.isbnnumber : '';
          }
        } else {
          this.isbnnumber = data.book.isbnnumber ? data.book.isbnnumber : '';
        }
      }
      this.hide = false;
    });

  }

  // Bind Book Instances
  bindBookInstances() {
    if (this.customBookid) {
      this.hide = true;
      this.bookInstanceService.getByBookId(this.customBookid, true).subscribe((data: any) => {
        this.bookinstances = data.bookinstances;
        this.dtTrigger.next();
        this.hide = false;
      }, err => {
        if (err.status == 401) {
          this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
        }
        else {
          this.router.navigate([this.constants.clientUrlNotFound]);
        }
      });
    }
  }

  languages: any[] = [];
  getLanguage() {
    this.languages = [];
    this.dropdownService.getLanguages().subscribe((data: any) => {
      if (data.success) {
        this.languages = data.languages
      }
    });
  }

  // Change By Arpit for #1803
  //Manually add books
  updateBook() {
    const book = {
      bookid: this.customBookid,
      title: this.title,
      subtitle: this.subtitle != undefined ? this.subtitle : '',
      description: this.description != undefined ? this.description : '',
      categoryid: this.categoryid != undefined ? this.categoryid : '',
      language: this.language,
      author: this.author != undefined ? this.author : '',
      publisher: this.publisher != undefined ? this.publisher : '',
      imageurl: this.imagename != null ? this.imagename : this.imageurl,
      isbnnumber: this.isbnnumber != undefined && this.isbnnumber != 'N/A' ? this.isbnnumber : '',
      isdelete: 0,
      isactive: this.isactive ? true : false,
    }
    //upsert books        
    var itemLength = this.uploader.queue.length;
    if (itemLength != 0) {
      this.uploader.onBuildItemForm = (item, form) => {
        form.append('bookid', this.customBookid);
        form.append('title', this.title);
        form.append('subtitle', this.subtitle);
        form.append('description', this.description);
        form.append('categoryid', this.categoryid);
        form.append('language', this.language);
        form.append('author', this.author);
        form.append('publisher', this.publisher);
        form.append('imageurl', '');
        form.append('isbnnumber', this.isbnnumber);
        form.append('isdelete', 0);
        form.append('isactive', this.isactive ? true : false);
        form.append('flag', 1);
      };
      
      var filesize = this.uploader.queue[itemLength - 1].file.size;

      if (filesize > 2000000) {
        this.notificationService.showError(this.constants.strFileSize);
        return false;
      }
      else {
        this.uploader.uploadItem(this.uploader.queue[itemLength - 1]);

        this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {

          if (status == 200) {
            this.router.navigate([this.constants.clientUrlBooks]);
            if (this.submitButtonText === this.constants.Save) {
              this.notificationService.showSuccess(this.constants.strBookInsert);
            }
            else {
              this.notificationService.showSuccess(this.constants.strBookUpdate);
            }
          }
          else if (status == 401) {
            this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
          }
          else {
            this.notificationService.showError(this.constants.strBookExist);
          }
        };
      }
    }
    else if (itemLength === undefined || itemLength === 0) {
      this.bookService.putBook(book, true).subscribe((data: any) => {
        if (data.success) {
          this.router.navigate([this.constants.clientUrlBooks]);
          if (this.submitButtonText === this.constants.Save) {
            this.notificationService.showSuccess(data.msg);
          }
          else {
            this.notificationService.showSuccess(data.msg);
          }
        }
        else {
          this.notificationService.showError(this.constants.strBookExist);
        }
      }, err => {
        if (err.status == 401) {
          this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
        }
        else {
          this.router.navigate([this.constants.clientUrlNotFound]);
        }
      });
    }
  }

  //To Display the block
  onClick() {
    this.hidenblock = !this.hidenblock;
  }

  onChange() {
    this.hide = !this.hide;
  }

  //Check Image extention
  validImg: Boolean;
  extention(evt) {
    this.validImg = this.constants.extention(evt);
  }

  // Call instance log modal for view log history.
  modal(instanceno) {
    if (instanceno !== null && instanceno !== undefined) {
      this.bookRequestService.getInstanceLogDetails(instanceno, true).subscribe((result: any) => {
        if (result !== null) {
          let disposable = this.dialogService.addDialog(InstanceLogModalComponent, {
            instanceNo: instanceno,
            // logHistory: ["Instance Log-1", "Instance Log-2", "Instance Log-3", "Instance Log-4", "Instance Log-5"]
            logHistory: result.instanceLog
          }).subscribe((isConfirmed) => {
            //Dialog result
            if (isConfirmed) {

            }
          });
        }
      });
    }
  }

  // Created By Arpit for trime the text if it is beond the limit.[for #1803]
  getTrimSubText(text) {
    if (text != '') {
      var TrimSubText = text.toString();
      if (TrimSubText.length > 100) {
        TrimSubText = TrimSubText.substring(0, 97) + "..";
      }
      return TrimSubText;
    }
  }
}