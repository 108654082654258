import { Title } from '@angular/platform-browser';
import { MetaService } from '@ngx-meta/core';
import { AuthUserService } from './../../../services/authuser.service';
import { element } from 'protractor';
import { Component, OnInit, Input, ViewChild, Injectable, Directive, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DialogService } from "ng2-bootstrap-modal";

import { Constants } from '../../../constants';
import { DataTableDirective } from 'angular-datatables';
import { NotificationService } from './../../../services/notification.service';
import { BookService } from './../../../services/book.service';

class Books {
  id: String;
  subtitle: String;
  authors: String;
  publisher: String;
  description: String;
  industryIdentifiers: any;
  categories: any;
  language: String;
  title: String;
  search: String;
  imageLinks: any;
}
@Component({
  selector: 'app-view-book-details',
  templateUrl: './view-book-details.component.html',
  styleUrls: ['./view-book-details.component.css']
})
export class ViewBookDetailsComponent implements OnInit {
  hide: Boolean = false;
  searchKeyword: String;
  isbn_13: String;
  isbn_10: String;
  constructor(
    public constants: Constants,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private authuserservice: AuthUserService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private route: ActivatedRoute,
    private bookService: BookService,
    private meta: MetaService
  ) { }



  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.searchKeyword = params['searchKey'];
        if (params["id"]) {
          this.searchBooksBy(params['searchKey'], params['id']);
        } else {
          if (params['searchKey'] != '') {
            this.getBookById(params['searchKey'], params['cid']);
          } else {
            this.getMultiBookById(params['searchKey'], params['mid']);
          }
        }
      });
  }

  book: Books = new Books();

  // dtTrigger: Subject<Books> = new Subject<Books>();

  searchBooksBy(search, infoLinkId) {
    this.hide = true;
    this.bookService.searchBookByGoogleId(search+'&'+infoLinkId).subscribe((data: any) => {
      if (data.book) {
          var result
          // Change by Arpit
        data.book.forEach(item => {
          var bookId = this.constants.getIdFromInfoLinks(item.infoLink);
          if (item.title === search && bookId === infoLinkId) {
            return result = item;            
          }
        });
        this.book = result;
        if (result.industryIdentifiers != undefined) {
          //this.isbn_13 = result.industryIdentifiers[0] ? result.industryIdentifiers[0].identifier : this.constants.notAvailable;
          this.isbn_10 = result.industryIdentifiers[0] ? result.industryIdentifiers[0].identifier : this.constants.notAvailable;
          this.isbn_10 += result.industryIdentifiers[1] ? ", " + result.industryIdentifiers[1].identifier : '';
        }
        else {
          this.isbn_10 = this.constants.notAvailable;
          // this.isbn_13 = this.constants.notAvailable;
        }
        this.hide = false;
      }
      else {
        this.hide = true;
        //this.router.navigate([this.constants.clientUrlUserSearch]);
      }
    });
  }

  getBookById(search, customBookId) {
    this.hide = true;
    this.bookService.getBookById(customBookId).subscribe((data: any) => {
      if (data.book) {
        var result = data.book;
        this.book.id = result._id;
        this.book.title = result.title;
        this.book.subtitle = result.subtitle;
        this.book.categories = result.categoryid;
        this.book.authors = result.author;
        this.book.publisher = result.publisher;
        this.book.description = result.description;
        this.book.imageLinks = new Object();
        this.book.imageLinks["thumbnail"] = result.imageurl;
        if (result.isbnnumber != undefined && result.isbnnumber != "") {

          if (result.isbnnumber[0].identifier) {
            this.isbn_10 = result.isbnnumber[0] ? result.isbnnumber[0].identifier : this.constants.notAvailable
            this.isbn_10 += result.isbnnumber[1] ? "," + result.isbnnumber[1].identifier : '';
          } else {
            this.isbn_10 = result.isbnnumber ? result.isbnnumber : this.constants.notAvailable;
          }
        }
        else {
          this.isbn_10 = this.constants.notAvailable;
          // this.isbn_13 = this.constants.notAvailable;
        }
        this.hide = false;
      }
      else {
        this.hide = true;
        // this.router.navigate([this.constants.clientUrlUserSearch]);
      }
    });

  }

  books = [];
  getMultiBookById(search, customBookId) {
    const requestId = {
      requestId: customBookId
    };
    this.bookService.getBooksById(requestId).subscribe((data: any) => {
      this.books = data.book;
    })
  }

  removeBooks(value: any) {
    var book = [];
    var id = value;
    book = this.books.filter(item => item._id !== id);
    this.books = book;
  }
}

