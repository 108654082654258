import { OurAssociatesService } from './../../../services/our-associates.service';
import { Constants } from './../../../constants';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { Component, OnInit, HostListener,  ElementRef } from "@angular/core";
import { Associates } from '../../../modal/ourAssociates';
import * as _ from 'lodash'; 

@Component({
  selector: "app-ourAssociatesDialog",
  templateUrl: "./ourAssociatesDialog.component.html",
  styleUrls: ["./ourAssociatesDialog.component.scss"]
})

export class OurAssociatesDialogComponent extends DialogComponent<Associates, boolean> implements OnInit {
  associates: Associates[];
  constructor(
    private _elementRef: ElementRef,
    dialogService: DialogService,
    public constants: Constants,
    private ourAssociatesService: OurAssociatesService
  ) {
    super(dialogService);
  }
  isModalOpen = false;
  ngOnInit() {
    this.ourAssociatesService.getAssociates(true).subscribe((data:any) => {
      if (data !== null) {
        var associatesDataFormat = _.each(data.associates,item =>item.serialNumber = parseInt(item.serialNumber));
        var dataOrder = _.orderBy(associatesDataFormat, ['serialNumber', 'companyName'], ['asc', 'asc']);
        this.associates = dataOrder as any;
      }
    })
  }

  @HostListener('document:click', ['$event.target'])
  _onclick(event) {
    if (this.isModalOpen === false) {
      this.isModalOpen = true;
    } else {
      const clickedInside = this._elementRef.nativeElement.contains(event);
      if (!clickedInside) {
        this.isModalOpen = false;
        this.close();
      }
    }
  }
}
