import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tokenNotExpired } from 'angular2-jwt';


import { Constants } from './../constants';

@Injectable()
export class DashboardService {
  template: any;

  constructor(private http: HttpClient, public constants: Constants) { }

  //get all templates
  getDashboardCounts(tokenFlag) {
    let timeStamp = +new Date();
    let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
    return this.http.get(this.constants.serverDashboardCounts + '?tsp=' + timeStamp, { headers: headers })
  }

  //
  getDashboardInstancesNeededAndAvailableBooks(tokenFlag) {
    let timeStamp = +new Date();
    let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
    return this.http.get(this.constants.serverDashboardInstancesNeededAndAvailableBooks + '?tsp=' + timeStamp, { headers: headers })
  }

  //get year
  getYear(tokenFlag) {
    let timeStamp = +new Date();
    let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
    return this.http.get(this.constants.serverDashboardYear + '?tsp=' + timeStamp, { headers: headers })
  }

  // get count monthwise
  getDashboardMonthWiseCounts(requestType, tokenFlag) {
    let timeStamp = +new Date();
    let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
    return this.http.post(this.constants.serverDashboardCountsByTypeAndYear + '?tsp=' + timeStamp, requestType, { headers: headers })
  }

  // get Statuswise Request Counts
  getDashboardStatusWiseCounts(tokenFlag) {
    let timeStamp = +new Date();
    let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
    return this.http.get(this.constants.serverDashboardCountsByStatus + '?tsp=' + timeStamp, { headers: headers })
  }

  // get Statuswise Request Counts
  getDashboardInstanceCount(tokenFlag) {
    let timeStamp = +new Date();
    let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
    return this.http.get(this.constants.serverDashboardInstancesCount + '?tsp=' + timeStamp, { headers: headers })
  }

  LinkedInApiUser(codes, tokenFlag){
    let timeStamp = +new Date();
    let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
    return this.http.post(this.constants.serverLinkedInApiData + '?tsp=' + timeStamp, {code : codes}, { headers: headers })
  }
}