import { UniversityService } from './../../../services/university.service';
import { StreamService } from './../../../services/stream.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { DropdownService } from './../../../services/dropdown.service';

import { Constants } from './../../../constants';
import { NotificationService } from './../../../services/notification.service';
import { SubjectService } from "./../../../services/subject.service";

class University_Stream {
    _id: String;
    name: String;
}

class yearORsemesters {
    yearORsem: any;
}

@Component({
    selector: "app-addSubject",
    templateUrl: "./addSubject.component.html",
    styleUrls: ["./addSubject.component.scss"]
})

export class AddSubjectComponent implements OnInit {
    subjectId: any;
    university: String;
    stream: String;
    isSemester: String;
    yearORSemester: Number;
    isOptionalSubject: Boolean;
    additionalInfo: String;
    code: String;
    name: String;
    subjectForm: FormGroup;
    hide: Boolean = false;
    disableButton: Boolean = false;
    submitButtonText: String = this.constants.Save;
    constructor(
        private activatedRoute: ActivatedRoute,
        public constants: Constants,
        private notificationService: NotificationService,
        private subjectService: SubjectService,
        private departmentService: StreamService,
        private dropdownService: DropdownService,
        private universityService: UniversityService,
        private router: Router,
        fb: FormBuilder
    ) {
        let description = new FormControl('', [Validators.required]);
        this.activatedRoute.params.subscribe((params: Params) => {
            this.subjectId = params['id'];
            if (this.subjectId == null) {
                this.subjectForm = fb.group({
                    'university': [null, Validators.required],
                    'stream': [null, Validators.required],
                    'yearORSemester': [null, Validators.required],
                    'code': [null, Validators.required],
                    'name': [null, Validators.required],
                    'additionalInfo': [null, Validators.compose([Validators.nullValidator, Validators.maxLength(500)])],
                    'isOptionalSubject': [null, Validators.nullValidator],
                });
            } else {
                this.subjectForm = fb.group({
                    'university': [null, Validators.required],
                    'stream': [null, Validators.required],
                    'yearORSemester': [null, Validators.required],
                    'code': [null, Validators.required],
                    'name': [null, Validators.required],
                    'additionalInfo': [null, Validators.compose([Validators.nullValidator, Validators.maxLength(500)])],
                    'isOptionalSubject': [null, Validators.nullValidator],
                });
            }
        })

    }

    ngOnInit() {
        this.activatedRoute.params.subscribe((params: Params) => {
            let subjectId = params['id'];
            if (subjectId != null) {
                this.hide = true;
                setTimeout(() => {
                    this.subjectService.getSubjectById(subjectId, true).subscribe((data:any) => {
                        this.university = data.subject.universityId;
                        this.stream = data.subject.streamId;
                        this.isSemester = data.subject.isSemester == true ? "true" : "false";
                        this.yearORSemester = data.subject.yearORsemester;
                        this.code = data.subject.subjectCode;
                        this.name = data.subject.subjectName;
                        this.additionalInfo = data.subject.additionalInfo;
                        this.isOptionalSubject = data.subject.isOptional;
                        this.submitButtonText = this.constants.Update;
                        this.subjectId = subjectId;
                        this.hide = false;
                        this.getStream();
                        this.streamDetail();
                    }, err => {
                        if (err.status == 401) {
                            this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
                        }
                        else {
                            this.router.navigate([this.constants.clientUrlNotFound]);
                        }
                    })
                }, 100);
            }
        })
        this.getUniversity();
    }

    resetStream() {
        this.stream = undefined;
        this.yearORSemester = undefined;
        this.getStream();
    }

    universities: University_Stream[] = [];
    getUniversity() {
        this.universityService.getUniversities().subscribe((data:any) => {
            this.universities = data.universities;
        });
    }

    streams: University_Stream[] = [];
    getStream() {
        this.dropdownService.getStream(this.university).subscribe((data:any) => {
            this.streams = data.streams;
        });
    }

    yearORsemesters: yearORsemesters[] = []
    getYearORSemester() {
        this.dropdownService.getYearORSemester().subscribe((data:any) => {
            if (this.streamDuration == null) {
                this.yearORsemesters = data.yearORsem;
            } else {
                this.yearORsemesters = data.yearORsem.filter(item => item <= this.streamDuration);
            }
        });
    }

    onSubmit() {
        this.disableButton = true;
        const subject = {
            subjectId: this.subjectId,
            university: this.university,
            stream: this.stream,
            isSemester: this.isSemester,
            yearORSemester: this.yearORSemester,
            code: this.code.trim(),
            name: this.name.trim(),
            additionalInfo: this.additionalInfo != undefined ? this.additionalInfo : '',
            isOptionalSubject: this.isOptionalSubject ? true : false,
        }
        this.subjectService.putSubject(subject, true).subscribe((data:any) => {
            if (data.success) {
                this.router.navigate([this.constants.clientUrlSubject]);
                this.notificationService.showSuccess(data.msg);
            }
            else {
                this.notificationService.showError(data.msg);
                this.disableButton = false;
            }
        }, err => {
            if (err.status == 401) {
                this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
            }
            else {
                this.router.navigate([this.constants.clientUrlNotFound]);
            }
        })
    }

    streamDuration: Number;
    streamDetail() {
        this.departmentService.getDepartmentById(this.stream, true).subscribe((data:any) => {
            if (data.success) {
                this.isSemester = data.department.isSemester;
                this.streamDuration = data.department.yearORsemester;
                this.getYearORSemester();
            }
        });
    }
}
