import { DashboardService } from './../../../services/dashboard.service';
import { Observable } from 'rxjs';
import { Constants } from './../../../constants';
import { Component, OnInit, Input } from '@angular/core';
import { BookService } from 'services/book.service';
import { UniversityService } from 'services/university.service';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { AuthUserService } from './../../../services/authuser.service';
import { NotificationService } from './../../../services/notification.service';

@Component({
  selector: 'app-home-features',
  templateUrl: './home-features.component.html',
  styleUrls: ['./home-features.component.css']
})
export class HomeFeaturesComponent implements OnInit {
  listOfInstanceNeededBooks: any;
  listOfInstanceAvailbleBooks: any;
  linkedInToken = "";
  linkedInState = '';
  returnUrl: String;
    // Create By Arpit for display university wise available book count
  universityWiseBookCount: any;
  universityWiseTotalAvailableBook: number = 0;
  hide: Boolean = true;
  totalNeededBooks: number = 0;
  hideNeededBooks: Boolean = false;
  @Input('totalAvailableBooks') totalAvailableBooks: number;
  private _dashboardBookList;
    //Change By Arpit added universityService
  constructor(
    private dashboardService: DashboardService,
    private bookService: BookService,
    private authuserService: AuthUserService,
    public constants: Constants,
    public universityService: UniversityService,
    private notificationService: NotificationService,
    private route: ActivatedRoute
  ) { }
  // Change by Arpit
  ngOnInit() {
    this.linkedInToken = this.route.snapshot.queryParams["code"];
    this.linkedInState = this.route.snapshot.queryParams["state"];
    console.log("In homeFeature component"+this.linkedInToken+this.linkedInState);
    // this.LinkedInApi(this.linkedInToken);
    this._dashboardBookList = this.constants.getCacheData('DashboardBookList');
    if (this._dashboardBookList === null || this._dashboardBookList === undefined) {
      this.dashboardService.getDashboardInstancesNeededAndAvailableBooks(true).subscribe((data: any) => {
        this._dashboardBookList = data;
        this.constants.setCacheData('DashboardBookList', data);
        this.getDashboardBookLsit();
      });
    } else {
      this.getDashboardBookLsit();
    }
    this.getNeededBookCount();
      // Add By Arpit
    this.getUniversityWiseAvailableBookCount();
  }
  // Change by Arpit
  getDashboardBookLsit() {
    if (this._dashboardBookList !== null) {
      this.hide = false;
      // this.listOfInstanceNeededBooks = this._dashboardBookList.Books.neededbook;
      this.listOfInstanceAvailbleBooks = this._dashboardBookList.Books.availablebook;
    }
  }
  // Change by Arpit
  getNeededBookCount() {
    this.listOfInstanceNeededBooks = this.constants.getCacheData('ListOfNeededBook');
    if (this.listOfInstanceNeededBooks === null || this.listOfInstanceNeededBooks === undefined) {
      this.bookService.getRequestedBooks().subscribe((data: any) => {
        if (data != null) {
          this.listOfInstanceNeededBooks = data.books;
          data.books.forEach((item) => {
            this.totalNeededBooks += parseInt(item.bookInstanceCount.toString());
          })
          this.constants.setCacheData('ListOfNeededBook', data.books);
        }
      });
    } else {
      this.listOfInstanceNeededBooks.forEach((item) => {
        this.totalNeededBooks += parseInt(item.bookInstanceCount.toString());
      })
    }
  }

  LinkedInApi(code){
    this.returnUrl = this.constants.clientUserHome;
    this.dashboardService.LinkedInApiUser(code, true).subscribe((data: any) => {
      if(data.Result.fname){
        console.log(data);
        
        const user = {
          // email: data["email"].trim(),
          name: data.Result.fname+' '+data.Result.lname,
          university: null,
          provider: 'linkedin',
          picture: data.Result.image
        }
        this.authuserService.externalUserLogin(user).subscribe((userdata:any) => {
          if (userdata.success) {
            console.log("User data ->" ,userdata)
            // this.socialuser = data;
            this.authuserService.storeUserData(userdata.token, userdata.user);
            this.constants.removeCacheData('DashboardCounts', 0);
            window.location.href = this.returnUrl.toString();
          }
          else {
            this.notificationService.showError(userdata.msg);
          }
        },
          err => {
            console.log("Error -> ",err);
            // this.errorHandler.handleErrors(err);
          });
      }
      
    });
  }
  // Create By Arpit
  getUniversityWiseAvailableBookCount() {
    this.universityWiseBookCount = this.constants.getCacheData('listOfUniversityWiseAvaliableBoookCount');
    if (this.universityWiseBookCount === null || this.universityWiseBookCount === undefined) {
    this.universityService.getUniversityWiseCount().subscribe((data: any) => {
      if (data.success) {
        this.universityWiseTotalAvailableBook = 0;
        this.universityWiseBookCount = data.count;
        data.count.forEach((count)=>{
          this.universityWiseTotalAvailableBook += count.bookinstancesCount;
        });
        this.constants.setCacheData('listOfUniversityWiseAvaliableBoookCount', data.count);
      }
    });
  }else{
    this.universityWiseBookCount.forEach((item) => {
      this.universityWiseTotalAvailableBook += parseInt(item.bookinstancesCount);
    })
  }
  }

}