import { ExcelService } from './../services/excel.service';
import { NgaModule } from './../app/theme/nga.module';
import { UsercontributionComponent } from './components/usercontribution/usercontribution.component';
import { BootstrapModalModule, DialogService } from 'ng2-bootstrap-modal';
import { InfiniteScrollModule } from 'angular2-infinite-scroll';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTrimModule } from 'ng2-trim-directive';

import { AddSyllabusComponent } from "./../admin/components/addSyllabus/addSyllabus.component";
import { SyllabusComponent } from "./../admin/components/syllabus/syllabus.component";
import { AddSubjectComponent } from "./../admin/components/addSubject/addSubject.component";
import { SubjectComponent } from "./../admin/components/subject/subject.component";
import { AddStreamComponent } from "./../admin/components/addStream/addStream.component";
import { StreamComponent } from "./../admin/components/stream/stream.component";
import { AddAdminComponent } from './components/addAdmin/addAdmin.component';
import { AddBooksComponent } from './components/addBooks/addBooks.component';
import { AddPagesComponent } from './../admin/components/addpages/addpages.component';
import { AddUniversityComponent } from "./../admin/components/addUniversity/addUniversity.component";
import { AddUserComponent } from './components/addUser/addUser.component';
import { AdminComponent } from './admin.component';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminService } from './../services/admin.service';
import { AdminuserComponent } from './components/adminuser/adminuser.component';
import { AuthAdminGuard } from './../guards/authadmin.guard';
import { AuthAdminService } from './../services/authadmin.service';
import { BookRequestComponent } from './components/book-request/book-request.component';
import { BookService } from './../services/book.service';
import { BookinstanceService } from './../services/bookinstance.service';
import { BooksComponent } from './components/books/books.component';
import { BreadcrumbService } from "ng2-breadcrumb/app/components/breadcrumbService";
import { CKEditorModule } from 'ng2-ckeditor';
import { ChangepasswordComponent } from './components/user-changepassword/user-changepassword.component';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { CommonModule, DatePipe } from '@angular/common';
import { ConfirmDialogComponent } from './components/confirmDialog/confirmDialog.component';
import { ContactService } from './../services/contact.service';
import { StreamService } from './../services/stream.service';
import { SubjectService } from './../services/subject.service';
import { ContactResponseService } from './../services/contactresponseservice';
import { ContactsComponent } from './components/contact/contacts.component';
import { ContributeBookComponent } from './components/contribute-book/contribute-book.component';
import { ControlSidebarComponent } from "./components/control-sidebar/control-sidebar.component";
import { CustomFormsModule } from 'ng2-validation'
import { DashboardService } from './../services/dashboard.service';
import { DataTablesModule } from 'angular-datatables';
import { DropdownService } from './../services/dropdown.service';
// import { FeedBackService } from './../services/feedback.service';
import { FeedbackModificationComponent } from './components/feedback-modification/feedback-modification.component';
import { FileSelectDirective, FileUploadModule } from 'ng2-file-upload';
import { FooterComponent } from "./components/footer/footer.component";
import { ForgetpasswordComponent } from "./../admin/shared/forgetpassword/forgetpassword.component";
import { HeaderbreadcrumbComponent } from './components/headerbreadcrumb/headerbreadcrumb.component';
import { HomeComponent } from "./../admin/components/home/home.component";
import { LoginComponent } from "./../admin/shared/login/login.component";
import { MainHeaderComponent } from "./components/main-header/main-header.component";
import { MainSideComponent } from "./components/main-side/main-side.component";
import { ModalDialogComponent } from './components/modalDialog/modalDialog.component';
import { ModalModule } from 'ngx-bootstrap';
import { Ng2BreadcrumbModule } from "ng2-breadcrumb/ng2-breadcrumb";
import { NgModule } from '@angular/core';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { NotificationService } from './../services/notification.service';
import { PageService } from './../services/page.service';
import { PagesComponent } from './components/pages/pages.component';
import { RatingModule } from 'ng2-rating';
import { RemarkdetailComponent } from './components/remarkdetail/remarkdetail.component';
// import { RequeststatushistoryserviceService } from './../services/requeststatushistoryservice.service';
import { ResetpasswordComponent } from "./../admin/shared/resetpassword/resetpassword.component";
import { OurAssociatesComponent } from './components/our-associates/ourAssociates.component';
import { AddOurAssociatesComponent } from './components/addOurAssociates/addOurAssociates.component';
import { CommonBookViewComponent } from './components/commonBookView/commonBookView.component';

import { OurAssociatesService } from './../services/our-associates.service';

import { ToastyModule } from "ng2-toasty";
import { SyllabusService } from "./../services/syllabus.service"
// import { UserAddressService } from './../services/userAddress.service';
import { AddUserAddressComponent } from './components/addUserAddress/addUserAddress.component';
import { ViewUserAddressComponent } from './components/viewUserAddress/viewUserAddress.component'
import { UniversitiesComponent } from './components/universities/universities.component';
import { UniversityService } from './../services/university.service';
import { UserService } from './../services/user.service';
import { UserrequestsComponent } from './components/userrequests/userrequests.component';
import { UsersComponent } from './components/users/users.component';
import { VerifyBookRequestComponent } from './components/verify-book-request/verify-book-request.component';
import { ViewBookDetailComponent } from './components/view-book-detail/view-book-detail.component';
import { ViewBookInstancesComponent } from './components/view-book-instances/view-book-instances.component';
import { ViewContactComponent } from './components/viewContact/viewContact.component';
import { ViewFeedbackComponent } from './components/view-feedback/view-feedback.component';
import { Ng2FilterPipeModule } from 'ng2-filter-pipe';
import { SelectModule } from 'ng2-select';
import { FlexLayoutModule } from '@angular/flex-layout';
import { InstanceLogModalComponent } from './components/InstancelogModal/InstanceLogModal.Component';
import { BookInstanceLogsComponent } from './components/bookInstanceLogs/bookInstanceLogs.component';

@NgModule({
  imports: [
    FileUploadModule,
    FlexLayoutModule,
    InputTrimModule,
    SelectModule,
    Ng2FilterPipeModule,
    AdminRoutingModule,
    BootstrapModalModule.forRoot({ container: document.body }),
    CKEditorModule,
    CommonModule,
    CustomFormsModule,
    DataTablesModule.forRoot(),
    FormsModule,
    RatingModule,
    ModalModule.forRoot(),
    Ng2BreadcrumbModule.forRoot(),
    ReactiveFormsModule,
    ToastyModule.forRoot(),
    ChartsModule,
    InfiniteScrollModule,
    NgaModule
  ],
  declarations: [
    AddOurAssociatesComponent,
    BookInstanceLogsComponent,
    InstanceLogModalComponent,
    OurAssociatesComponent,
    AddSyllabusComponent,
    CommonBookViewComponent,
    SyllabusComponent,
    AddSubjectComponent,
    SubjectComponent,
    AddStreamComponent,
    StreamComponent,
    AddUserAddressComponent,
    FeedbackModificationComponent,
    ViewFeedbackComponent,
    MainSideComponent,
    MainHeaderComponent,
    FooterComponent,
    ControlSidebarComponent,
    AddAdminComponent,
    AddPagesComponent,
    AddUniversityComponent,
    AddUserComponent,
    AdminComponent,
    AdminuserComponent,
    ChangepasswordComponent,
    ConfirmDialogComponent,
    ContactsComponent,
    ViewContactComponent,
    ViewBookDetailComponent,
    ForgetpasswordComponent,
    HomeComponent,
    LoginComponent,
    NotfoundComponent,
    PagesComponent,
    ResetpasswordComponent,
    RemarkdetailComponent,
    UniversitiesComponent,
    UsersComponent,
    AddBooksComponent,
    BooksComponent,
    BookRequestComponent,
    ContributeBookComponent,
    HeaderbreadcrumbComponent,
    UserrequestsComponent,
    ViewBookInstancesComponent,
    VerifyBookRequestComponent,
    ModalDialogComponent,
    UsercontributionComponent,
    ViewUserAddressComponent
  ],
  entryComponents: [
    ConfirmDialogComponent,
    ModalDialogComponent,
    InstanceLogModalComponent
  ],
  providers: [
    DatePipe,
    ExcelService,
    OurAssociatesService,
    SyllabusService,
    SubjectService,
    StreamService,
    // UserAddressService,
    AdminService,
    AuthAdminGuard,
    AuthAdminService,
    BreadcrumbService,
    ContactService,
    ContactResponseService,
    DialogService,
    // FeedBackService,
    NotificationService,
    PageService,
    UniversityService,
    UserService,
    BookService,
    BookinstanceService,
    DropdownService,
    DashboardService,
    ThemeService
    // RequeststatushistoryserviceService
  ],
  exports: [AdminComponent]
})
export class AdminModule { }