import { Constants } from './../../../../../../constants';
import { Router } from '@angular/router';
import { OurAssociatesDialogComponent } from './../../../../../../front/components/ourAssociatesDialog/ourAssociatesDialog.component';
import { DialogService } from 'ng2-bootstrap-modal';
import { Component, ViewChild, HostListener, ElementRef } from "@angular/core";

@Component({
  selector: "ba-our-associates",
  template: `
    <div *ngIf="route.url === '/home'" #baOurAssociates class="ba-our-associates">
    <i class="fa fa-angle-double-left" style="font-size: x-large" title="Show Our Associates"></i>
    <span class="font-style"><b>Associates</b></span>
    </div>
  `,
  styleUrls: ["./baOurAssociates.component.scss"]
})

export class BaOurAssociatesComponent {
  @ViewChild('baOurAssociates', {static: false}) _selector: ElementRef;
  route
  constructor(
    private router: Router,
    private constant: Constants,
    private dialogService: DialogService
  ) {
    this.route = router;
  }

  @HostListener('click')
  _onClick() {
    let disposable = this.dialogService.addDialog(OurAssociatesDialogComponent);
  }
}
