import { element } from 'protractor';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DialogService } from "ng2-bootstrap-modal";

import { ContactService } from '../../../services/contact.service';
import { Constants } from '../../../constants';
import { ConfirmDialogComponent } from '../confirmDialog/confirmDialog.component';
import { DataTableDirective } from 'angular-datatables';
import { NotificationService } from './../../../services/notification.service';

class Contact {
  contactId: any;
  name: String;
  email: String;
  message: String;
  contactNo: String;
  isdelete: Boolean;
}

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;
  @ViewChild('userModal', { static: false }) public userModal: ModalDirective;
  deleteAll: Boolean;
  showdelete: Boolean;
  isComposePopupVisible: Boolean = false;
  requestId: String[] = [];
  contactId: any;
  name: String;
  email: String;
  message: String;
  contactNo: String;
  isdelete: Boolean;
  dtOptions = this.constants.dataTableSettings && {
    order: [5, 'desc'],
    columnDefs: [
      {
        "targets": [0, 1, 6, 7],
        "orderable": false,
      },
      {
        "targets": 5,
        type: 'num',
        render: function (data, type) {

          if (type == 'sort') {
            return Date.parse(data).valueOf();
          }
          return data;
        }
      }
    ],
    fnDrawCallback: function () {
      if ((document.getElementById("contactdata_paginate")) != null) {
        if ((document.getElementById("contactdata_paginate")).click) {
          var table = $("#contactdata").DataTable();
          var rowchecked = 0;
          var ValueById = document.getElementsByName("sizecb")
          for (var index = 0; index < ValueById.length; index++) {
            if ($(ValueById[index]).prop("checked") == true) {
              //check all conter var ++
              rowchecked = rowchecked + 1;
            }
          }
          if (ValueById.length == rowchecked) {
            $("#checkAll").prop('checked', true);
            $('.model').css('display', 'block');
          } else {
            $("#checkAll").prop('checked', false);
          }
          if (rowchecked == 0) {
            $("#checkAll").prop('checked', false);
            $('.model').css('display', 'none');
          }
          else if (rowchecked > 0) {
            $('.model').css('display', 'block');
          }
        }
      }
    }
  };
  hide: Boolean = false;
  constructor(
    public constants: Constants,
    private contactService: ContactService,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private router: Router
  ) { }

  contact: Contact[] = [];

  dtTrigger: Subject<Contact> = new Subject<Contact>();

  showConfirm(contactId, name) {
    let disposable = this.dialogService.addDialog(ConfirmDialogComponent, {
      title: 'Delete Contact Details',
      message: 'Are you sure you want to delete the selected record?'
    })
      .subscribe((isConfirmed) => {

        //Dialog result
        if (isConfirmed) {
          this.contactService.deleteContact(contactId, true).subscribe((data: any) => {
            if (data.success) {
              this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Destroy the table first
                dtInstance.destroy();
                // Call the dtTrigger to rerender again
                this.refreshGrid();
              });
              this.notificationService.showSuccess(data.msg);
            }
            else {
              this.notificationService.showError(data.msg);
            }
          }, err => {
            if (err.status == 401) {
              this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
            }
            else {
              this.router.navigate([this.constants.clientUrlNotFound]);
            }
          });
        }
      });
    setTimeout(() => {
      disposable.unsubscribe();
    }, 10000);
  }

  ngOnInit() {
    this.refreshGrid();
  }

  refreshGrid() {
    this.hide = true;
    this.contactService.getContact(true).subscribe((data: any) => {
      this.contact = data.contacts
      this.dtTrigger.next();
      this.hide = false;
    }, err => {
      if (err.status == 401) {
        this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
      }
      else {
        this.router.navigate([this.constants.clientUrlNotFound]);
      }
    });
  }

  checkAll(ev) {
    var elementArray = document.getElementsByName("sizecb");
    for (var index = 0; index < elementArray.length; index++) {
      var checkBox = <HTMLInputElement>elementArray[index]
      if (this.requestId.length == 0) {
        this.requestId.push(elementArray[index].id);
      }
      else {
        var resultArray = this.requestId.filter(data => data == elementArray[index].id);
        if (resultArray.length == 0) {
          this.requestId.push(elementArray[index].id);
        }
        else {
          var arrayindex = this.requestId.indexOf(elementArray[index].id);
          this.requestId.splice(arrayindex, 1);
        }
      }
      checkBox.checked = ev.target.checked
    }
    if (this.requestId.length > 0) {
      $('.model').css('display', 'block');
    }
    else {
      $('.model').css('display', 'none');
    }
    if (!ev.target.checked) {
      $('.model').css('display', 'none');
    }
  }

  recordChecked(ev) {
    var id = ev.currentTarget.id;
    var rowCount = 0;
    var elementArray = document.getElementsByName("sizecb");
    var resultArray = this.requestId.filter(data => data == id);
    var elementId = document.getElementById(id);
    if (resultArray != null && resultArray.length != 0) {
      var index = this.requestId.findIndex(x => x === id)
      this.requestId.splice(index, 1);
      $("#checkAll").prop('checked', false);
    }
    else {
      this.requestId.push(id);
    }
    if (this.requestId.length > 0) {
      $('.model').css('display', 'block');
    }
    else {
      $('.model').css('display', 'none');
    }
    for (var index = 0; index < elementArray.length; index++) {
      var checkBox = <HTMLInputElement>elementArray[index]
      if (checkBox.checked == false) {
        rowCount = rowCount + 1;
      }
    }
    var selectedrow = elementArray.length - rowCount;
    if (selectedrow == elementArray.length) {
      $("#checkAll").prop('checked', true);
    }
  }

  bulkDelete() {
    const deleteid = {
      deleteid: this.requestId
    };
    let disposable = this.dialogService.addDialog(ConfirmDialogComponent, {
      title: "Delete Confirmation",//this.constants.deleteConfirmationTitle"",
      message: "Are you sure you want to delete the selected record?",//this.constants.deleteConfirmation
    })
      .subscribe((isConfirmed) => {
        //Dialog result
        if (isConfirmed) {
          this.contactService.bulkDelete(deleteid, true).subscribe((data: any) => {
            if (data.success) {
              this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Destroy the table first
                dtInstance.destroy();
                // Call the dtTrigger to rerender again
                this.refreshGrid();
                this.requestId = [];
              });
              this.notificationService.showSuccess(data.msg);
            }
            else {
              this.refreshGrid();
              this.notificationService.showError(data.msg);
            }
          });
        }
      });

    //We can close dialog calling disposable.unsubscribe();
    //If dialog was not closed manually close it by timeout
    setTimeout(() => {
      disposable.unsubscribe();
    }, 10000);
  }
}