import { AuthUserService } from './../services/authuser.service';
import { Constants } from './../constants';
import { element } from 'protractor';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Component, ViewContainerRef, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { GlobalState } from './global.state';
import { BaImageLoaderService, BaThemePreloader, BaThemeSpinner } from './theme/services';
import { BaThemeConfig } from './theme/theme.config';
import { layoutPaths } from './theme/theme.constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {

  isMenuCollapsed : boolean;
  bodyClasses: string = "skin-blue layout-top-nav";
  body = document.getElementsByTagName('body')[0];
  sessionUserName = document.getElementById('sessionUserName');
  constructor(private activatedRoute: ActivatedRoute,
    private authUserService: AuthUserService,
    public constants: Constants,
    private router: Router,
    private titleService: Title,
    private _state: GlobalState,
    private _imageLoader: BaImageLoaderService,
    private _spinner: BaThemeSpinner,
    private viewContainerRef: ViewContainerRef,
    private themeConfig: BaThemeConfig
  ) {
    themeConfig.config();
    this._loadImages();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data))
      .subscribe((event) => {
        this.titleService.setTitle(event['title'] + ' | ' + this.constants.applicationName);
        var adminDetails = this.authUserService.getUserFromStorage();
        // document.getElementById('sessionUsername').innerHTML = adminDetails.name;
      });
      this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
      this.isMenuCollapsed = isCollapsed;
    });
  }

  ngAfterViewInit() {
    // hide spinner once all loaders are completed
    BaThemePreloader.load().then((values) => {
      this._spinner.hide();
    });
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data))
      .subscribe((event) => {
        var adminDetails = this.authUserService.getUserFromStorage();
        if (adminDetails != null) {
          var userdetailsTag = document.getElementById('sessionUsername');
          if (userdetailsTag) {
            // document.getElementById('sessionUsername').innerHTML = adminDetails.name;
          }
        }
      });

  }

  private _loadImages(): void {
    // register some loaders
    // BaThemePreloader.registerLoader(this._imageLoader.load('assets/img/sky-bg.jpg'));
  }

  ngOnInit() {
    var script1 = document.createElement("script");
    script1.setAttribute("src", "https://cdn.ckeditor.com/4.7.1/full/ckeditor.js");
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data))
      .subscribe((event) => {
        if (!this.isAdmin()) {
          this.body.classList.add("skin-blue");
          this.body.classList.add("layout-top-nav");
          //this.body.classList.add("custombody");
        }
        this.titleService.setTitle(event['title'] + ' | ' + this.constants.applicationName);
      });

  }

  isAdmin() {
    return this.router.url.includes(this.constants.clientAdmin);
  }
}
