import { AuthUserService } from './../services/authuser.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Constants } from './../constants';

@Injectable()
export class RedirectionGuard implements CanActivate {
    constructor(private authuserService: AuthUserService,
        private router: Router,
        public constants: Constants) {
    }

    // check whether the user is logged in or not
    canActivate() {

        if (this.authuserService.loggedIn()) {
            this.router.navigate([this.constants.clientUserHome]);
            return false;
        } else {
            return true;
        }
    }
}
