import { NgModule, ModuleWithProviders } from "@angular/core";
import { Angular2SocialLoginAuthService, IProviders, IProvider } from "./angular2SocialLoginAuthService";

declare let gapi: any;
declare let IN: any;
declare let FB: any;

@NgModule({
    providers: [Angular2SocialLoginAuthService]
})
export class Angular2SocialLoginModule {
    static loadProvidersScripts(config: IProviders): Boolean {
        try {
            const loadProvidersScripts: Object = {
                google: (info: IProvider) => {
                    let d = document, gJs, ref: any = d.getElementsByTagName('script')[0];
                    gJs = d.createElement('script');
                    gJs.async = true;
                    gJs.src = "//apis.google.com/js/platform.js";

                    gJs.onload = function () {
                        try {
                            gapi.load('auth2', function () {

                                gapi.auth2.init({
                                    client_id: info["clientId"],
                                    scope: 'email'
                                })

                            })
                        }
                        catch (e) {
                            return false;
                        }
                    }
                    ref.parentNode.insertBefore(gJs, ref);
                },
                linkedin: (info: IProvider) => {
                    let lIN, d = document, ref: any = d.getElementsByTagName('script')[0];
                    lIN = d.createElement('script');
                    lIN.async = false;
                    lIN.src = "//platform.linkedin.com/in.js";
                    lIN.text = ("api_key: " + info["clientId"]);
                    ref.parentNode.insertBefore(lIN, ref);
                },
                facebook: (info: IProvider) => {
                    let d = document, fbJs, id = 'facebook-jssdk', ref: any = d.getElementsByTagName('script')[0];
                    fbJs = d.createElement('script');
                    fbJs.id = id;
                    fbJs.async = true;
                    fbJs.src = "https://connect.facebook.net/en_US/sdk.js";

                    fbJs.onload = function () {
                        try {
                            FB.init({
                                appId: info["clientId"],
                                status: true,
                                cookie: true,
                                xfbml: true,
                                version: info["apiVersion"]
                            });
                        }
                        catch (e) {
                            return false;
                        }
                    };
                    ref.parentNode.insertBefore(fbJs, ref);
                }
            }
            Object.keys(config).forEach((provider: string) => {
                (<any>loadProvidersScripts)[provider](config[provider]);
            });
            return true;
        }
        catch (ex) {
            return false;
        }

    }
}