import { OurAssociateListComponent } from './../front/components/ourAssociateList/ourAssociateList.component';
import { OurAssociatesDialogComponent } from './../front/components/ourAssociatesDialog/ourAssociatesDialog.component';
import { AppTranslationModule } from './app.translation.module';
import { UnauthorizedPageComponent } from './components/unauthorized-page/unauthorized-page.component';
import { ManualRequestComponent } from './../front/components/manual-request/manual-request.component';
import { Angular2SocialLoginModule } from './../services/angular2SocialLoginModule';
import { Angular2SocialLoginAuthService } from './../services/angular2SocialLoginAuthService';
import { CacheService } from 'ng2-cache';
import { FeedbackComponent } from './../front/components/feedback/feedback.component';
import { RatingModule } from "ng2-rating";
import { FeedBackService } from './../services/feedback.service';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { SeekingContributeHomeComponent } from './../front/components/seeking-contribute-home/seeking-contribute-home.component';
import { AvailablebooksComponent } from './../front/components/availablebooks/availablebooks.component';
import { RequeststatushistoryserviceService } from './../services/requeststatushistoryservice.service';
import { RedirectionGuard } from './../guards/redirection.guard';
import { UserService } from './../services/user.service';
import { UserAddressService } from './../services/userAddress.service';
import { AuthUserGuard } from './../guards/authuser.guard';
import { BookRequestService } from './../services/bookrequest.service';
import { AuthUserService } from './../services/authuser.service';
import { ViewUserAddressComponent } from './../front/components/viewUserAddress/viewUserAddress.component'
import { AddUserAddressComponent } from './../front/components/addUserAddress/addUserAddress.component'
import { HomeFeaturesComponent } from './../front/components/home-features/home-features.component';
import { HomeSliderComponent } from './../front/components/home-slider/home-slider.component';
import { MainHeaderComponent } from './../front/components/main-header/main-header.component';
import { MainFooterComponent } from './../front/components/main-footer/main-footer.component';
import { UserRequestComponent } from './../front/components/userrequest/userrequest.component';
import { SignupComponent } from './../front/shared/signup/signup.component';
import { ConfirmDialogComponent } from './../front/components/confirmDialog/confirmDialog.component';
import { EmailActivationComponent } from './../front/shared/emailactivation/emailactivation.component';
import { UserProfileComponent } from './../front/components/userprofile/userprofile.component';
import { FrontForgetpasswordComponent } from './../front/shared/forgetpassword/forgetpassword.component';
import { UserloginComponent } from './../front/shared/userlogin/userlogin.component';
import { RegisterComponent } from './../front/shared/register/register.component';
import { ResetPasswordComponent } from './../front/shared/resetpassword/resetpassword.component';
import { UserHomeComponent } from './../front/components/user-home/user-home.component';
import { MainSearchBarComponent } from './../front/components/main-searchBar/main-searchBar.component';
import { MainSearchResultComponent } from './../front/components/main-searchResult/main-searchResult.component';
import { UserChangepasswordComponent } from './../front/components/user-changepassword/user-changepassword.component';
import { HowItWorksComponent } from './../front/components/how-it-works/how-it-works.component';
import { PrivacyPolicyComponent } from './../front/components/privacy-policy/privacy-policy.component';
import { FacebookDataDeletionInstructionsComponent } from './../front/components/facebook-data-deletion-instructions/facebook-data-deletion-instructions.component';
import { UserdonationComponent } from './../front/components/userdonation/userdonation.component';
import { FaqComponent } from './../front/components/faq/faq.component';
import { ViewBookDetailsComponent } from './../front/components/view-book-details/view-book-details.component';
import { ContributeRequestComponent } from './../front/components/contribute-request/contribute-request.component';
import { ViewDetailComponent } from './../front/components/view-detail/view-detail.component';
import { SeekingRequestComponent } from './../front/components/seeking-request/seeking-request.component';
import { ViewRequestDetailsComponent } from './../front/components/view-request-details/view-request-details.component';
import { CommonModule } from '@angular/common';
import { CustomFormsModule } from 'ng2-validation';
import { Ng2OrderModule } from 'ng2-order-pipe';
import { ModalModule } from 'ngx-bootstrap';
import { Ng2BreadcrumbModule } from 'ng2-breadcrumb/ng2-breadcrumb';
import { RouterModule } from '@angular/router';
import { InfiniteScrollModule } from 'angular2-infinite-scroll';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DataTablesModule } from 'angular-datatables';
import { ToastyModule } from 'ng2-toasty';
import { ChartsModule } from 'ng2-charts';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AdminModule } from './../admin/admin.module';
import { AppRoutingModule } from './app-routing.module';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AppComponent } from './app.component';
import { SyllabusComponent } from './../front/components/syllabus/syllabus.component';

import { HandleError } from './../error';
import { RedirectionAdminGuard } from "../guards/redirectionadmin.guard";
import { ContactUsComponent } from "front/components/contact-us/contact-us.component";
import { MetaModule, MetaService } from '@ngx-meta/core';
import { SlimLoadingBarModule } from 'ng2-slim-loading-bar';
import { FlexLayoutModule } from '@angular/flex-layout';
// App is our top level component
import { AppState, InternalStateType } from './app.service';
import { GlobalState } from './global.state';
import { NgaModule } from './theme/nga.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NKDatetimeModule } from 'ng2-datetime/ng2-datetime';
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js';
import 'bootstrap-timepicker/js/bootstrap-timepicker.min.js';
import 'jquery/dist/jquery.min.js';
import { BaCardBlur } from '../app/theme/components/baCard/baCardBlur.directive';
import { ShareButtonsModule } from 'ngx-sharebuttons';
import { SocialLoginComponent } from '../front/components/social-login/social-login.component';

// Application wide providers
const APP_PROVIDERS = [
  AppState,
  GlobalState
];

export type StoreType = {
  state: InternalStateType,
  restoreInputValues: () => void,
  disposeOldHosts: () => void
};


@NgModule({
  declarations: [
    BaCardBlur,
    AppComponent,
    AddUserAddressComponent,
    FeedbackComponent,
    ContactUsComponent,
    HomeFeaturesComponent,
    HomeSliderComponent,
    MainHeaderComponent,
    MainFooterComponent,
    ConfirmDialogComponent,
    OurAssociatesDialogComponent,
    OurAssociateListComponent,
    SignupComponent,
    EmailActivationComponent,
    UserProfileComponent,
    UserRequestComponent,
    FrontForgetpasswordComponent,
    UserloginComponent,
    RegisterComponent,
    ResetPasswordComponent,
    UserHomeComponent,
    MainSearchBarComponent,
    MainSearchResultComponent,
    UserChangepasswordComponent,
    UserdonationComponent,
    FaqComponent,
    HowItWorksComponent,
    PrivacyPolicyComponent,
    FacebookDataDeletionInstructionsComponent,
    ViewBookDetailsComponent,
    ViewDetailComponent,
    SeekingRequestComponent,
    ContributeRequestComponent,
    ViewRequestDetailsComponent,
    AvailablebooksComponent,
    SeekingContributeHomeComponent,
    NotfoundComponent,
    ManualRequestComponent,
    UnauthorizedPageComponent,
    ViewUserAddressComponent,
    SyllabusComponent,
    SocialLoginComponent
  ],
  imports: [
    ShareButtonsModule.forRoot(),
    FlexLayoutModule,
    CommonModule,
    NKDatetimeModule,
    CustomFormsModule,
    Ng2OrderModule,
    RatingModule,
    DataTablesModule.forRoot(),
    FormsModule,
    ModalModule.forRoot(),
    Ng2BreadcrumbModule.forRoot(),
    ReactiveFormsModule,
    ToastyModule.forRoot(),
    RouterModule,
    BrowserModule,
    InfiniteScrollModule,
    AdminModule,
    AppRoutingModule,
    BrowserModule,
    DataTablesModule.forRoot(),
    FormsModule,
    HttpClientModule,
    ToastyModule.forRoot(),
    ChartsModule,
    MetaModule.forRoot(),
    Ng2SearchPipeModule,
    NgaModule.forRoot(),
    NgbModule.forRoot(),
    AppTranslationModule,
    SlimLoadingBarModule.forRoot(),
  ],
  entryComponents: [
    ConfirmDialogComponent,
    OurAssociatesDialogComponent
  ],
  providers: [Angular2SocialLoginAuthService, UserAddressService, CacheService, Angular2SocialLoginModule, MetaService, HandleError, RedirectionAdminGuard, AuthUserService, FeedBackService, BookRequestService, AuthUserGuard, UserService, RedirectionGuard, RequeststatushistoryserviceService, APP_PROVIDERS],
  bootstrap: [AppComponent]
})
export class AppModule { }
// platformBrowserDynamic().bootstrapModule(AppModule);