import { Constants } from './../constants';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tokenNotExpired } from 'angular2-jwt';

@Injectable()
export class ContactService {
    contact: any;

    constructor(private http: HttpClient, public constants: Constants) { }

    //get all
    getContact(tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.get(this.constants.servercontactGetAll + '?tsp=' + timeStamp, { headers: headers });            
    }

    //get by id
    getContactById(id,tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.get(this.constants.servercontactGetById + '/' + id + '?tsp=' + timeStamp, { headers: headers });            
    }

    //put
    putContact(contact) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeaders();
        return this.http.put(this.constants.servercontactPut + '?tsp=' + timeStamp, contact, { headers: headers });            
    }

    //delete
    deleteContact(id,tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.delete(this.constants.servercontactDelete + '/' + id + '?tsp=' + timeStamp, { headers: headers });            
    }

    //bulk delete
    bulkDelete(deleteid, tokenFlag) {
        let timeStamp = +new Date();
        var headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.post(this.constants.servercontactBulkDelete + '?tsp=' + timeStamp, deleteid, { headers: headers });            
    }

}