import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ActivatedRoute, Params, Router, CanActivate, RouterStateSnapshot } from '@angular/router';
import { FeedBackService } from './../../../services/feedback.service';
import { Constants } from './../../../constants';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthUserService } from './../../../services/authuser.service';
import { HandleError } from './../../../error';
import { NotificationService } from './../../../services/notification.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RatingModule } from "ng2-rating";
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { CacheService, CacheStoragesEnum } from 'ng2-cache';

class feedback {
  feedbackId: any;
  name: String;
  email: String;
  rating: Number;
  message: String;
  isvisible: Boolean;
}

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css'],
  providers: [CacheService]
})
export class FeedbackComponent implements OnInit {
  feedbackId: any;
  name: String;
  email: String;
  rating: Number;
  message: String;
  createdate: Date;
  isvisible: Boolean;
  userdata: any;
  userId: any;
  hide: Boolean = false;
  feedbackForm: FormGroup;
  buttonWriteReview: string = "Write Review";
  totalcount: any;
  avg: any;
  public disableButton: Boolean = false;
  //Chart Label and Data
  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    tooltips: { enabled: false },
    legend: { display: false },
    scaleShowValues: true,
    scaleValuePaddingX: 10,
    scaleValuePaddingY: 10,
    animation: {
      onComplete: function () {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;

        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';

        this.data.datasets.forEach(function (dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function (bar, index) {
            var data = dataset.data[index];
            ctx.fillText(data, bar._model.x, bar._model.y - 5);
          });
        });
      }
    },
    scales: {
      xAxes: [
        {
          display: false
        }
      ],
      yAxes: [
        {
          display: false
        }
      ]
    },
  };

  public barChartLabels: any[] = [];
  public barChartType: string = 'bar';
  public barChartLegend: boolean = true;
  public barChartData: any[] = [{ data: [] }];
  constructor(
    public constants: Constants,
    private feedbackservice: FeedBackService,
    private authservice: AuthUserService,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    fb: FormBuilder
  ) {
    this.feedbackForm = fb.group({
      'feedbackId': [null, Validators.nullValidator],
      'rating': [null, Validators.nullValidator],
      'message': [null, Validators.nullValidator],
    })
    this.getFeedBack();
  }


  ngOnInit() {
    this.userdata = this.authservice.getUserFromStorage();
    if (this.userdata) {
      this.name = this.userdata.name;
      this.email = this.userdata.email;
      this.userId = this.userdata.id;
      this.activatedRoute.params.subscribe((params: Params) => {
        var userid = params[this.userId];
      });
    }
    this.onreviewCount();
  }
  ratingcounts: any[] = [];
  ratings: any[] = [];
  avgCount: any;
  rate: any;
  onreviewCount() {
    this.feedbackservice.ratingWiseCount().subscribe((data:any) => {
      if (data.success) {
        this.ratingcounts = data.count[0].count;
        this.ratings = data.count[0].rating;
        this.totalcount = data.count[0].totalcount;
        this.avg = data.count[0].avg;
        this.avgCount = this.avg.toFixed(2);
        this.rate = this.ratings.filter(item => item !== 0).sort((n1, n2) => n2 - n1);
      }
    })
  }

  feedback: feedback[] = [];
  dtTrigger: Subject<feedback> = new Subject<feedback>();

  getFeedBack() {
    this.feedbackservice.getFeedBacks().subscribe((data:any) => {
      if (data.success) {
        this.feedback = data.feedbacks;
        this.dtTrigger.next();
        this.hide = false;
      }
    })
  }

  onSubmit() {
    this.disableButton = true;
    const feedback = {
      feedbackId: undefined,
      name: this.userdata.name,
      email: this.userdata.email,
      message: this.message,
      createdate: new Date(),
      rating: this.rating == undefined ? 0 : this.rating,
      isvisible: true,
      userId: this.userId,
    }
    if (this.rating == undefined && this.message == undefined) {
      this.notificationService.showError(this.constants.feedbackNotification);
      var review = document.getElementById("review");
      review.style.display = 'block';
      this.buttonWriteReview = "Close Review";
      this.disableButton = false;
    } else {
      this.feedbackservice.putFeedBacks(feedback, false).subscribe((data:any) => {
        if (data.success) {
          this.feedbackForm.reset();
          this.notificationService.showSuccess(data.msg);
          this.getFeedBack();
          this.onreviewCount();
          this.onChange()
          this.disableButton = false;
        }
        else {
          this.disableButton = false;
          this.notificationService.showError("ERROR");
        }
      },
        err => {
          if (err.status == 401) {
            this.router.navigate([this.constants.clientUrlUnauthorized]);
          }
          else {
            this.router.navigate([this.constants.clientUrlNotFound]);
          }
        })
    }
  }

  onChange() {
    if (this.authservice.loggedIn()) {
      var review = document.getElementById("review");
      if (review.style.display === 'none') {
        review.style.display = 'block';
        this.buttonWriteReview = "Close Review";
      } else {
        review.style.display = 'none';
        this.buttonWriteReview = "Write Review";
      }
    } else {
      this.router.navigate([this.constants.clientUrlSignIn], { queryParams: { returnUrl: this.constants.clientUrlUserFeedBack } });
    }
  }

  onReset() {
    this.feedbackForm.reset();
  }

}