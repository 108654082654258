import { ConfirmDialogComponent } from './../confirmDialog/confirmDialog.component';
import { DialogService } from 'ng2-bootstrap-modal';
import { FormGroup, FormControl, Validators, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { UserService } from './../../../services/user.service';
import { BookRequestService } from './../../../services/bookrequest.service';
import { AuthUserService } from './../../../services/authuser.service';
import { Constants } from '../../../constants';
import { HandleError } from './../../../error';
import { NotificationService } from './../../../services/notification.service';
import { ModalDialogComponent } from '../modalDialog/modalDialog.component';


class BookRequests {
  _id: any;
  bookid: any;
  userid: any;
  messagefromuser: any;
  requesttype: any;
}
class ContributeRequests {
  _id: any;
  bookid: any;
  userid: any;
  messagefromuser: any;
  requesttype: any;
}
class remarks {
  bookrequestid: any;
  personname: String;
  address: String;
  contactno: String;
  remarks: String;
  request: String;
  requeststatus: String;
  istransactioncomplete: Boolean;
  
}
@Component({
  selector: 'app-userrequests',
  templateUrl: './userrequests.component.html',
  styleUrls: ['./userrequests.component.scss']
})


export class UserrequestsComponent implements OnInit {
  @Input('contactnumber') contactnumber: any;
  @Input('email') email: any;
  @Input('name') name: any;
  @Input('_id') _id: any;
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  deleteAll: Boolean;
  showdelete: Boolean;
  requestId: String[] = [];
  isVisible: Boolean = false;
  tokenId: any;
  userid: any;
  address: String;
  preferredtime: any;
  preferreddate: any;
  userName: String;
  title: String;
  subtitle: String;
  description: String;
  categoryid: String;
  author: String;
  publisher: String;
  isbnnumber: String;
  imageurl: any;
  imageurlPath: String;
  imagename: string;
  requesttype: Boolean;
  requeststatus: any;
  requeststatusid: String;
  requestdate: Date;
  remarks: String;
  status: String;
  istransactioncomplete: Boolean;
  providedbyuser: any;
  bookid: any;
  isdropbyuser: Boolean;
  hideStatusDetails: Boolean = false;
  hideBookDetails: Boolean = false;
  disableButton: Boolean = false;
  isExistingRTSBook: boolean = false;
  existingInstanceNo: string;
  instanceNo: string;
  bookInstances: String[] = [];
  bookInstanceLogs: any = [];
  allocatedinstance: string;
  sendemail: boolean;


  dtOptions = this.constants.dataTableSettings && {
    order: [8, 'desc'],
    columnDefs: [
      {
        "targets": [1, 9],
        "orderable": false,
      },
      {
        "targets": 8,
        type: 'num',
        render: function (data, type) {

            if (type == 'sort') {
                return Date.parse(data).valueOf();
            }
            return data;
        }
    }
    ],
    fnDrawCallback: function () {
      if ((document.getElementById("userrequestdata_paginate")) != null) {
        if ((document.getElementById("userrequestdata_paginate")).click) {
          var table = $("#bookdata").DataTable();
          var rowchecked = 0;
          var ValueById = document.getElementsByName("sizerd")
          for (var index = 0; index < ValueById.length; index++) {
            if ($(ValueById[index]).prop("checked") == true) {
              //check all conter var ++
              rowchecked = rowchecked + 1;
            }
          }
          if (ValueById.length == rowchecked) {
            $("#checkAll").prop('checked', true);
            $('.model').css('display', 'block');
          } else {
            $('.model').css('display', 'none');
          }
          if (rowchecked == 0) {
            $("#checkAll").prop('checked', false);
            $('.model').css('display', 'none');
          }
          else if (rowchecked > 0) {
            $('.model').css('display', 'block');
          }
        }
      }
    }
  };
  
  bookrequestService: any;
  userservice: any;
  userdata: any;
  user: any;
  
  constructor(private authuserService: AuthUserService,
    private bookrequest: BookRequestService,
    private userService: UserService,
    public constants: Constants,
    private activatedRoute: ActivatedRoute,
    private errorHandler: HandleError,
    private notificationService: NotificationService,
    private router: Router,
    fb: FormBuilder,
    private dialogService: DialogService
  ) {

  }

  bookRequests: BookRequests[] = [];
  bookContributed: ContributeRequests[] = [];
  bookRequested: BookRequests[] = [];
  dtTrigger: Subject<any> = new Subject<any>()

  ngOnInit() {
    this.bindGrid();
  }

  formatDate(requestedate) {
    requestedate = new Date(requestedate).valueOf();
    return (requestedate);
  };

  return (id, returnBookRequestid, bookinstancenumber1) {
                const bookrequest = {
                    isdelete: 0,
                    bookid: id,
                    userid: this.userid,
                    requestdate: new Date(),
                    requesttype: false,
                    quantity: 1,
                    istransactioncomplete: 0,
                    contactno: this.contactnumber,
                    email: this.email,
                    name: this.name,
                    isdropbyuser: true,
                    returnBookRequestid: returnBookRequestid,
                    remarksOfCollectedBook:"The book is returned as donation.",
                    existingInstanceNo: bookinstancenumber1,
                }
                this.bookrequest.ReturnedAndaddtoContribution(bookrequest, true).subscribe((data: any) => {
                    if (data.success) {
                      
                      
                        // Change by Arpit
                        this.constants.removeCacheData('DashboardBookList', 0);
                        this.constants.removeCacheData('ListOfNeededBook', 0);
                        this.notificationService.showSuccess(data.msg);
                        window.location.reload();
                    } else {
                        this.notificationService.showError(data.msg);
                        this.disableButton = false;
                    }
                }, err => {
                    if (err.status == 401) {
                        this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
                    } else {
                        this.router.navigate([this.constants.clientUrlNotFound]);
                    }
                })
              //   const remarks = {
              //     remarks:"The book is returned as contribution.",
              //     status: "Collacted",
              //     providedbyuser: this.providedbyuser,
              //     bookid: this.bookid,
              //     bookrequestId: this.requestId,
              //     istransactioncomplete: 0,
              //     existingInstanceNo: bookinstancenumber1,
              // }
              // console.log("remarks", remarks);
              //     this.bookrequest.putremarks(remarks, true).subscribe((data: any) => { debugger;
              //       if (data.success) {
              //         this.constants.removeCacheData('DashboardCounts', 0);
              //         this.constants.removeCacheData('DashboardBookList', 0);
              //         this.constants.removeCacheData('ListOfNeededBook', 0);
              //         this.constants.removeCacheData('listOfUniversityWiseAvaliableBoookCount', 0);
              //         this.notificationService.showSuccess(data.msg);
              //       }
              //       else {
              //       this.notificationService.showError(data.msg);
              //       }
              //       this.disableButton = false;
              //     }, err => {
              //       if (err.status == 401) {
              //         this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
              //       }
              //       else {
              //         this.router.navigate([this.constants.clientUrlNotFound]);
              //       }
              //     });
                 }
            
    
       

  showConfirm(id) {

    let disposable = this.dialogService.addDialog(ConfirmDialogComponent, {
      title: "Delete Confirmation",//this.constants.deleteConfirmationTitle"",
      message: "Are you sure you want to delete the selected record?",//this.constants.deleteConfirmation
    })
      .subscribe((isConfirmed) => {
        //Dialog result
        if (isConfirmed) {
          this.bookrequest.deletebookrequest(id, true).subscribe((data:any) => {
              if (data.success) {
                // Change by Arpit
              this.constants.removeCacheData('DashboardBookList', 0);
              this.constants.removeCacheData('ListOfNeededBook', 0);
              this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Destroy the table first
                dtInstance.destroy();
                // Call the dtTrigger to rerender again
                this.bindGrid();
              });
              this.notificationService.showSuccess(data.msg);
            }
            else {
              this.notificationService.showError(data.msg);
            }
          }, err => {
            if (err.status == 401) {
              this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
            }
            else {
              this.router.navigate([this.constants.clientUrlNotFound]);
            }
          });

        }
      });

    //We can close dialog calling disposable.unsubscribe();
    //If dialog was not closed manually close it by timeout
    setTimeout(() => {
      disposable.unsubscribe();
    }, 10000);
  }

  bindGrid() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.userid = params['id'];
      if (this.userid != null) {
        this.bookrequest.getbookrquestByUserId(this.userid, true).subscribe((data:any) => {
          this.bookRequests = data.bookrequests;
          this.bookRequested = this.bookRequests.filter(item => item.requesttype == true);
          this.bookContributed = this.bookRequests.filter(item => item.requesttype == false);
          this.dtTrigger.next();
        }, err => {
          if (err.status == 401) {
            this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
          }
          else {
            this.router.navigate([this.constants.clientUrlNotFound]);
          }
        });
      }
    });
  }

  checkAll(ev) {
    var elementArray = document.getElementsByName("sizerd");
    for (var index = 0; index < elementArray.length; index++) {
      var checkBox = <HTMLInputElement>elementArray[index]
      if (this.requestId.length == 0) {
        this.requestId.push(elementArray[index].id);
      }
      else {
        var resultArray = this.requestId.filter(data => data == elementArray[index].id);
        if (resultArray.length == 0) {
          this.requestId.push(elementArray[index].id);
        } else {
          var arrayindex = this.requestId.indexOf(elementArray[index].id);
          this.requestId.splice(arrayindex, 1);
        }
      }
      checkBox.checked = ev.target.checked
    }
    if (this.requestId.length > 0) {
      // this.showdelete = true;
      $('.model').css('display', 'block');
    }
    else {
      // this.showdelete = false;
      $('.model').css('display', 'none');
    }
    if (!ev.target.checked) {
      // this.requestId = [];
      // this.showdelete = false;
      $('.model').css('display', 'none');
    }
  }

  recordChecked(ev) {
    var id = ev.currentTarget.id;
    var rowCount = 0;
    var elementArray = document.getElementsByName("sizerd");
    var resultArray = this.requestId.filter(data => data == id);
    var elementId = document.getElementById(id);
    if (resultArray != null && resultArray.length != 0) {
      var index = this.requestId.findIndex(x => x === id)
      this.requestId.splice(index, 1);
      // this.deleteAll = false;
      $("#checkAll").prop('checked', false);
    }
    else {
      this.requestId.push(id);
    }
    if (this.requestId.length > 0) {
      // this.showdelete = true;
      $('.model').css('display', 'block');
    }
    else {
      // this.showdelete = false;
      $('.model').css('display', 'none');
    }
    for (var index = 0; index < elementArray.length; index++) {
      var checkBox = <HTMLInputElement>elementArray[index]
      if (checkBox.checked == false) {
        rowCount = rowCount + 1;
      }
    }
    var selectedrow = elementArray.length - rowCount;
    if (selectedrow == elementArray.length) {
      // this.deleteAll = true;
      $("#checkAll").prop('checked', true);
    }
  }

  bulkDelete() {
    const deleteid = {
      deleteid: this.requestId
    };
    let disposable = this.dialogService.addDialog(ConfirmDialogComponent, {
      title: "Delete Confirmation",//this.constants.deleteConfirmationTitle"",
      message: "Are you sure you want to delete the selected record?",//this.constants.deleteConfirmation
    })
      .subscribe((isConfirmed) => {
        //Dialog result
        if (isConfirmed) {
          this.bookrequest.bulkDelete(deleteid, true).subscribe((data:any) => {
              if (data.success) {
                // Change by Arpit
              this.constants.removeCacheData('DashboardBookList', 0);
              this.constants.removeCacheData('ListOfNeededBook', 0);
              this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Destroy the table first
                dtInstance.destroy();
                // Call the dtTrigger to rerender again
                this.bindGrid();
                this.requestId = [];
              });
              this.notificationService.showSuccess(data.msg);
            }
            else {
              this.bindGrid();
              this.notificationService.showError(data.msg);
            }
          });
        }
      });

    //We can close dialog calling disposable.unsubscribe();
    //If dialog was not closed manually close it by timeout
    setTimeout(() => {
      disposable.unsubscribe();
    }, 10000);
  }
  bulkRequestsMail(){
    var userReqArray = [];
    this.requestId.forEach(elementRequest => {
      this.bookRequested.forEach(elementBook => {
        if(elementRequest == elementBook._id){
          userReqArray.push(elementBook);
        }
      });
    });
    this.bookrequest.bulkMailNotification(userReqArray, true).subscribe((data:any) => {
      if (data.success) {
        this.constants.removeCacheData('DashboardBookList', 0);
        this.constants.removeCacheData('ListOfNeededBook', 0);
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          // Destroy the table first
          dtInstance.destroy();
          // Call the dtTrigger to rerender again
          this.bindGrid();
          this.requestId = [];
        });
      this.notificationService.showSuccess(data.msg);
      }else{
        this.bindGrid();
        this.notificationService.showError(data.msg);
      }
    });
  }
}
