import { Router, ActivatedRoute, Params } from '@angular/router';
import { Component, OnInit, ErrorHandler } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';

import { AdminService } from './../../../services/admin.service';
import { AuthAdminService } from './../../../services/authadmin.service';
import { Constants } from './../../../constants';
import { HandleError } from './../../../error';
import { NotificationService } from './../../../services/notification.service';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {
  password: String;
  confirmpassword: String;
  resetPasswordForm: FormGroup;
  tokenId: any;
  isAdminFound: Boolean
  admins: any;
  submitButtonText: string = this.constants.ChangePassword;
  constructor(
    private activatedRoute: ActivatedRoute,
    private adminService: AdminService,
    private authService: AuthAdminService,
    public constants: Constants,
    private errorHandler: HandleError,
    private notificationService: NotificationService,
    private router: Router,
    fb: FormBuilder
  ) {
    let password = new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(15), Validators.pattern("^[a-zA-Z0-9!@#$&()\\-`.+,/\_]+( [a-zA-Z0-9!@#$&()\\-`.+,/\_]+)*$"
    )]);
    let confirmpassword = new FormControl('',[Validators.required, CustomValidators.equalTo(password)]);
    this.resetPasswordForm = new FormGroup({
      password: password,
      confirmpassword: confirmpassword
    });
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.tokenId = params['id'];
      if (this.tokenId != null)
        this.authService.getAdminByToken(this.tokenId).subscribe((data:any) => {
          if (data.success) {
            this.isAdminFound = true;
            this.admins = data.admins;
          }
          else {
            this.isAdminFound = false;
            this.notificationService.showError(data.msg);
            this.router.navigate([this.constants.clientAdminForgetPassword]);
          }
        },
          err => {
            this.errorHandler.handleErrors(err)
          });
    });
  }

  onSubmit() {
    const user = {
      password: this.password,
      confirmpassword: this.confirmpassword
    }


    if (this.isAdminFound) {
      this.admins.password = this.password;
      this.adminService.updateAdminPassword(this.admins).subscribe((data:any) => {

        if (data.success) {
          this.notificationService.showSuccess(data.msg);
          this.router.navigate([this.constants.clientLogin]);
        }
      },
        err => {
          this.errorHandler.handleErrors(err)
        });
    }
  }
}