import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tokenNotExpired } from 'angular2-jwt';


import { Constants } from './../constants';

@Injectable()
export class UserAddressService {
    authToken: any;
    user: any;
    constructor(private http: HttpClient, public constants: Constants) { }

    //get address by user
    getAddressById(id, tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.get(this.constants.serveruserAddressGetById + '/' + id + '?tsp=' + timeStamp, { headers: headers })
            
    }

    getById(id, tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.get(this.constants.serverAddressGetById + '/' + id + '?tsp=' + timeStamp, { headers: headers })
            
    }

    //add address
    addAddress(userAddress, tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.put(this.constants.serveruserAddressPut + '?tsp=' + timeStamp, userAddress, { headers: headers })
            
    }

    //delete address
    deleteUser(id, tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.delete(this.constants.serveruserAddressDelete + '/' + id + '?tsp=' + timeStamp, { headers: headers })
            
    }

    //bulk delete
    bulkDelete(deleteid, tokenFlag) {
        let timeStamp = +new Date();
        var headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.post(this.constants.serveruserAddressBulkDelete + '?tsp=' + timeStamp, deleteid, { headers: headers })
            
    }
}