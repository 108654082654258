import { PagesComponent } from './../../../admin/components/pages/pages.component';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { Constants } from './../../../constants';
import { Subject } from 'rxjs';
import { PageService } from "./../../../services/page.service";
class Pages {
  id: any;
  title: String;
  description: String;
  isactive: Boolean;
}

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css'],
})

export class FaqComponent implements OnInit {
  id: any;
  title: String = "FAQ";
  description: String;
  isactive: Boolean;
  hide: Boolean = false;

  constructor(
    public constants: Constants,
    private pageservice: PageService,
    private activatedRoute: ActivatedRoute
  ) {

  }

  pages: Pages[] = [];
  dtTrigger: Subject<Pages> = new Subject<Pages>();
  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      let title = params['title'];
      this.hide = true;
      if (this.title != null) {
        let data = this.constants.getCacheData('FAQ');
        if (data != null) {
          this.title = data.pages.title;
          this.description = data.pages.description;
          this.hide = false;
        } else {
          this.pageservice.getPageByTitle(this.title).subscribe((data:any) => {
            this.title = data.pages.title;
            this.description = data.pages.description;
            this.hide = false;
            this.constants.setCacheData('FAQ', data);
          })
        }
      }
    })
  }
}