import { AddOurAssociatesComponent } from './components/addOurAssociates/addOurAssociates.component';
import { OurAssociatesComponent } from './components/our-associates/ourAssociates.component';
import { UnauthorizedPageComponent } from './../app/components/unauthorized-page/unauthorized-page.component';
import { VerifyBookRequestComponent } from './components/verify-book-request/verify-book-request.component';
import { ChangepasswordComponent } from './components/user-changepassword/user-changepassword.component';
import { Title } from '@angular/platform-browser';
import { RemarkdetailComponent } from './components/remarkdetail/remarkdetail.component';
import { ContributeBookComponent } from './components/contribute-book/contribute-book.component';
import { BookRequestComponent } from './components/book-request/book-request.component';
import { Constants } from './../constants';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BreadcrumbComponent, BreadcrumbService } from 'ng2-breadcrumb/ng2-breadcrumb';

import { AddUserAddressComponent } from './components/addUserAddress/addUserAddress.component';
import { AddAdminComponent } from './components/addAdmin/addAdmin.component';
import { AddPagesComponent } from "./../admin/components/addpages/addpages.component";
import { AddUniversityComponent } from './components/addUniversity/addUniversity.component';
import { AddUserComponent } from './components/addUser/addUser.component';
import { AdminComponent } from './admin.component';
import { AdminuserComponent } from './components/adminuser/adminuser.component';
import { AuthAdminGuard } from "./../guards/authadmin.guard";
import { ContactsComponent } from './components/contact/contacts.component';
import { ViewContactComponent } from './components/viewContact/viewContact.component';
import { ForgetpasswordComponent } from './shared/forgetpassword/forgetpassword.component';
import { HomeComponent } from './../admin/components/home/home.component';
import { LoginComponent } from './shared/login/login.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { PagesComponent } from "./../admin/components/pages/pages.component";
import { AddStreamComponent } from "./../admin/components/addStream/addStream.component";
import { StreamComponent } from "./../admin/components/stream/stream.component";
import { AddSyllabusComponent } from "./../admin/components/addSyllabus/addSyllabus.component";
import { SyllabusComponent } from "./../admin/components/syllabus/syllabus.component";
import { AddSubjectComponent } from "./../admin/components/addSubject/addSubject.component";
import { SubjectComponent } from "./../admin/components/subject/subject.component";
import { ResetpasswordComponent } from './shared/resetpassword/resetpassword.component';
import { UniversitiesComponent } from './components/universities/universities.component';
import { UsersComponent } from './components/users/users.component';
import { BooksComponent } from './components/books/books.component';
import { AddBooksComponent } from './components/addBooks/addBooks.component';
import { ViewBookDetailComponent } from './components/view-book-detail/view-book-detail.component';
import { ViewBookInstancesComponent } from "./components/view-book-instances/view-book-instances.component";
import { ViewFeedbackComponent } from './components/view-feedback/view-feedback.component';
import { FeedbackModificationComponent } from './components/feedback-modification/feedback-modification.component';
import { ViewUserAddressComponent } from './components/viewUserAddress/viewUserAddress.component'
import { BookInstanceLogsComponent } from './components/bookInstanceLogs/bookInstanceLogs.component';


@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: 'secure',
                component: AdminComponent,
                children: [
                    { path: '', redirectTo: 'home', pathMatch: 'full' },
                    { path: 'home', component: HomeComponent, data: { title: 'Dashboard' } },
                    { path: 'adminUser', component: AdminuserComponent, data: { title: 'Administrators' } },
                    { path: 'adminUser/addAdmin', component: AddAdminComponent, data: { title: 'Add Administrator' } },
                    { path: 'adminUser/updateAdmin/:id', component: AddAdminComponent, data: { title: 'Update Administrator' } },
                    { path: 'changePassword', component: ChangepasswordComponent, data: { title: 'Change Password' } },

                    { path: 'books', component: BooksComponent, data: { title: 'Books' } },
                    { path: 'books/addBooks', component: AddBooksComponent, data: { title: 'Add Book' } },
                    { path: 'books/viewBookDetail', component: ViewBookDetailComponent, data: { title: 'View Book Detail' } },
                    { path: 'books/updateBooks/:id', component: AddBooksComponent, data: { title: 'Update Book' } },
                    { path: 'books/viewInstanceDetails/:id', component: ViewBookInstancesComponent, data: { title: 'View Book Instance Detail' } },
                    { path: 'logs', component: BookInstanceLogsComponent, data: { title: 'Logs' } },
                    { path: 'users/searchBooks/:id', component: AddBooksComponent, data: { title: 'Search Books' } },

                    { path: 'bookrequest', component: BookRequestComponent, data: { title: 'Book Seeking Requests' } },
                    { path: 'contributebook', component: ContributeBookComponent, data: { title: 'Donate Book Requests' } },
                    { path: 'verifybookrequest/:id', component: VerifyBookRequestComponent, data: { title: 'Verify Book Request' } },
                    { path: 'contributebook/bookrequestdetail/:id', component: RemarkdetailComponent, data: { title: 'Book Request Details' } },
                    { path: 'bookrequest/bookrequestdetail/:id', component: RemarkdetailComponent, data: { title: 'Book Request Details' } },
                    { path: 'bookrequestdetail/:id', component: RemarkdetailComponent, data: { title: 'Book Request Details' } },
                    { path: 'users', component: UsersComponent, data: { title: 'Users' } },
                    { path: 'users/addUsers', component: AddUserComponent, data: { title: 'Add User' } },
                    { path: 'users/updateUsers/:id', component: AddUserComponent, data: { title: 'Update User' } },

                    // Add/Update User Multiple Address.
                    { path: 'users/addAddress/:id', component: AddUserAddressComponent, data: { title: 'Add Address' } },
                    { path: 'users/updateUserAddress/:id/:aid', component: AddUserAddressComponent, data: { title: 'Update Address' } },

                    // View/Update Contact
                    { path: 'contact', component: ContactsComponent, data: { title: 'Contact Us' } },
                    { path: 'contact/updateContact/:id', component: ViewContactComponent, data: { title: 'View Contact Detail' } },

                    { path: 'associates', component: OurAssociatesComponent, data: { title: 'Associates' } },
                    { path: 'associates/addAssociates', component: AddOurAssociatesComponent, data: { title: 'Add Associates' } },
                    { path: 'associates/updateAssociates/:id', component: AddOurAssociatesComponent, data: { title: 'Update Associates' } },
                    
                    { path: 'subject', component: SubjectComponent, data: { title: 'Subject' } },
                    { path: 'subject/addSubject', component: AddSubjectComponent, data: { title: 'Add Subject' } },
                    { path: 'subject/updateSubject/:id', component: AddSubjectComponent, data: { title: 'Update Subject' } },

                    { path: 'syllabus', component: SyllabusComponent, data: { title: 'Syllabus' } },
                    { path: 'syllabus/addSyllabus', component: AddSyllabusComponent, data: { title: 'Add Syllabus' } },
                    { path: 'syllabus/updateSyllabus/:id', component: AddSyllabusComponent, data: { title: 'Update Syllabus' } },


                    { path: 'stream', component: StreamComponent, data: { title: 'Stream' } },
                    { path: 'stream/addStream', component: AddStreamComponent, data: { title: 'Add Stream' } },
                    { path: 'stream/updateStream/:id', component: AddStreamComponent, data: { title: 'Update Stream' } },

                    { path: 'pages', component: PagesComponent, data: { title: 'Pages' } },
                    { path: 'pages/addPages', component: AddPagesComponent, data: { title: 'Add Page' } },
                    { path: 'pages/updatePages/:id', component: AddPagesComponent, data: { title: 'Update Page' } },

                    { path: 'universities', component: UniversitiesComponent, data: { title: 'Universities' } },
                    { path: 'universities/addUniversity', component: AddUniversityComponent, data: { title: 'Add University' } },
                    { path: 'universities/updateUniversity/:id', component: AddUniversityComponent, data: { title: 'Update University' } },

                    { path: 'unauthorized', component: UnauthorizedPageComponent, data: { title: 'Unauthorized' } },

                    { path: 'viewFeedback', component: ViewFeedbackComponent, data: { title: 'Feedback Detail' } },
                    { path: 'viewFeedback/updateFeedback/:id', component: FeedbackModificationComponent, data: { title: 'Update FeedBack Detail' } },
                ],
                canActivate: [AuthAdminGuard]
            }
        ])
    ],
    providers: [Constants],
    exports: [
        RouterModule
    ]
})
export class AdminRoutingModule {
    constructor(public constants: Constants,
        private breadcrumbService: BreadcrumbService) {
        breadcrumbService.addFriendlyNameForRoute(this.constants.clientAdmin, 'Home');
        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlDashboard1, 'Dashboard');
        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlAdminChangePassword, 'Change Password');

        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlAdminList, 'Administrators');
        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlAdminAdd, 'Add Administrator');
        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlAdminUpdate, 'Update Administrator');
        breadcrumbService.hideRouteRegex(this.constants.clientUrlAdminUpdate + '/[a-zA-Z0-9]');

        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlBooks, 'Books');
        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlBooksAdd, 'Add Book');
        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlBooksUpdate, 'Update Book');
        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlBooksView, 'View Book Detail');
        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlBookInstanceView, 'View Book Instance Detail');
        breadcrumbService.hideRouteRegex(this.constants.clientUrlBooksUpdate + '/[a-zA-Z0-9]');
        breadcrumbService.hideRouteRegex(this.constants.clientUrlBooksView + '/[a-zA-Z0-9]');
        breadcrumbService.hideRouteRegex(this.constants.clientUrlBookInstanceView + '/[a-zA-Z0-9]');

        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlLogs, 'Logs');

        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlBookRequests, 'Book Seeking Requests');
        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlBookContribute, 'Donate Book Requests');
        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlVerifyBookRequest, 'Verify Book Request');
        breadcrumbService.hideRouteRegex(this.constants.clientUrlVerifyBookRequest + '/[a-zA-Z0-9]');

        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlRemarksSeeking, 'Book Request Detail');
        breadcrumbService.hideRouteRegex(this.constants.clientUrlRemarksSeeking + '/[a-zA-Z0-9]');
        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlRemarksContribute, 'Book Request Detail');
        breadcrumbService.hideRouteRegex(this.constants.clientUrlRemarksContribute + '/[a-zA-Z0-9]');
        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlRemarks, 'Book Request Detail');
        breadcrumbService.hideRouteRegex(this.constants.clientUrlRemarks + '/[a-zA-Z0-9]');
        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlAdminSearchBooks, 'Search Books');
        breadcrumbService.hideRouteRegex(this.constants.clientUrlAdminSearchBooks + '/[a-zA-Z0-9]');

        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlCategoryList, 'Category');

        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlContactList, 'Contact Us');
        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlContactAdd, 'Add Contact Details');
        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlContactUpdate, 'Update Contact Details');
        breadcrumbService.hideRouteRegex(this.constants.clientUrlContactUpdate + '/[a-zA-Z0-9]');

        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlUserList, 'Users');
        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlUsersAdd, 'Add User');
        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlUserUpdate, 'Update User');
        breadcrumbService.hideRouteRegex(this.constants.clientUrlUserUpdate + '/[a-zA-Z0-9]');

        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlAddress, 'Add Address');
        breadcrumbService.hideRouteRegex(this.constants.clientUrlAddress + '/[a-zA-Z0-9]');        
        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlModifyUserAddress, 'Update Address');
        breadcrumbService.hideRouteRegex(this.constants.clientUrlModifyUserAddress + '/[a-zA-Z0-9]');

        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlAssociatesList, 'Our Associates');
        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlAssociatesAdd, 'Add Associates');
        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlAssociatesUpdate, 'Update Associates');        
        breadcrumbService.hideRouteRegex(this.constants.clientUrlAssociatesUpdate + '/[a-zA-Z0-9]');

        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlStream, 'Stream');
        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlAddStream, 'Add Stream');
        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlUpdateStream, 'Update Stream');
        breadcrumbService.hideRouteRegex(this.constants.clientUrlUpdateStream + '/[a-zA-Z0-9]');

        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlSubject, 'Subject');
        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlAddSubject, 'Add Subject');
        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlUpdateSubject, 'Update Subject');
        breadcrumbService.hideRouteRegex(this.constants.clientUrlUpdateSubject + '/[a-zA-Z0-9]');

        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlSyllabus, 'Syllabus');
        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlAddSyllabus, 'Add Syllabus');
        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlUpdateSyllabus, 'Update Syllabus');
        breadcrumbService.hideRouteRegex(this.constants.clientUrlUpdateSyllabus + '/[a-zA-Z0-9]');

        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlPages, 'Pages');
        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlPagesAdd, 'Add Page');
        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlPagesUpdate, 'Update Page');
        breadcrumbService.hideRouteRegex(this.constants.clientUrlPagesUpdate + '/[a-zA-Z0-9]');

        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlUniversityList, 'Universities');
        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlUniversityAdd, 'Add University');
        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlUniversityUpdate, 'Update University');
        breadcrumbService.hideRouteRegex(this.constants.clientUrlUniversityUpdate + '/[a-zA-Z0-9]');

        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlTemplateList, 'Template');
        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlTemplateAdd, 'Add Template');
        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlTemplateUpdate, 'Update Template');
        breadcrumbService.hideRouteRegex(this.constants.clientUrlTemplateUpdate + '/[a-zA-Z0-9]');

        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlAdminUnauthorized, 'Unauthorized');

        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlFeedBack, 'Feedback');
        breadcrumbService.addFriendlyNameForRoute(this.constants.clientUrlFeedBackUpdate, 'Update Feedback');
        breadcrumbService.hideRouteRegex(this.constants.clientUrlFeedBackUpdate + '/[a-zA-Z0-9]');
    }
}