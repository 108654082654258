import { UniversityService } from './../../../services/university.service';
import { StreamService } from './../../../services/stream.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownService } from './../../../services/dropdown.service';

import { Constants } from './../../../constants';
import { NotificationService } from './../../../services/notification.service';
import { SubjectService } from "./../../../services/subject.service";
import { SyllabusService } from "./../../../services/syllabus.service";
import { BookService } from "./../../../services/book.service";

import { SelectModule } from 'ng2-select';

class University_Stream {
    _id: String;
    name: String;
}

class yearORsemesters {
    yearORsem: any;
}

@Component({
    selector: "app-addSyllabus",
    templateUrl: "./addSyllabus.component.html",
    styleUrls: ["./addSyllabus.component.scss"],
})

export class AddSyllabusComponent implements OnInit {
    @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
    syllabusId: any;
    subjectId: any;
    bookId: any;
    university: String;
    stream: String;
    isSemester: Boolean;
    yearORSemester: any;
    bookName: String;
    isReference: Boolean;
    code: String;
    subjectName: String;
    syllabusForm: FormGroup;
    hide: Boolean = false;
    disableButton: Boolean = false;
    settings = {};
    submitButtonText: String = this.constants.Save;
    constructor(
        private activatedRoute: ActivatedRoute,
        public constants: Constants,
        private notificationService: NotificationService,
        private subjectService: SubjectService,
        private universityService: UniversityService,
        private dropdownService: DropdownService,
        private departmentService: StreamService,
        private syllabusService: SyllabusService,
        private bookService: BookService,
        private router: Router,
        fb: FormBuilder
    ) {
        this.activatedRoute.params.subscribe((params: Params) => {
            this.subjectId = params['id'];
            if (this.subjectId == null) {
                this.syllabusForm = fb.group({
                    'university': [null, Validators.required],
                    'stream': [null, Validators.required],
                    'yearORSemester': [null, Validators.required],
                    'code': [null, Validators.required],
                    'name': [null, Validators.nullValidator],
                    'isReference': [null, Validators.nullValidator],
                    'selectedBooks': [null, Validators.nullValidator],
                });
            } else {
                this.syllabusForm = fb.group({
                    'university': [null, Validators.required],
                    'stream': [null, Validators.required],
                    'yearORSemester': [null, Validators.required],
                    'code': [null, Validators.required],
                    'name': [null, Validators.nullValidator],
                    'isReference': [null, Validators.nullValidator],
                    'selectedBooks': [null, Validators.nullValidator],
                });
            }
        })

    }
    dtOptions = this.constants.dataTableSettings;
    dtTrigger: Subject<any> = new Subject<any>();

    ngOnInit() {
        this.settings = {
            text: "Select Books",
            enableSearchFilter: true,
            classes: "myclass custom-class",
            showCheckbox: true
        };
        this.activatedRoute.params.subscribe((params: Params) => {
            this.syllabusId = params['id'];
            if (this.syllabusId != null) {
                this.hide = true;
                setTimeout(() => {
                    this.syllabusService.getSyllabusBySubjectCode(this.syllabusId, true).subscribe((data:any) => {
                        this.code = data.syllabus.subjectId["0"]._id;
                        this.stream = data.syllabus.subjectId["0"].streamId["0"];
                        this.university = data.syllabus.subjectId["0"].universityId["0"];
                        this.yearORSemester = data.syllabus.subjectId["0"].yearORsemester;
                        this.getSubject(this.code);
                        data.syllabus.book.forEach(booktitle => {
                            this.booksValue.push({ bookId: booktitle.bookId._id, title: booktitle.bookId.title, isReference: booktitle.isReference })
                        })

                        this.submitButtonText = this.constants.Update;
                        this.hide = false;
                        this.getStream();
                        this.streamDetail();
                        this.getSubjectCode();
                    }, err => {
                        if (err.status == 401) {
                            this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
                        }
                        else {
                            this.router.navigate([this.constants.clientUrlNotFound]);
                        }
                    })
                }, 100);
            }
        })
        this.getActiveBooks();
        this.getUniversity();
    }

    universities: University_Stream[] = [];
    getUniversity() {
        this.universityService.getUniversities().subscribe((data:any) => {
            this.universities = data.universities;
        });
    }

    resetStream() {
        this.stream = undefined;
        this.yearORSemester = undefined;
        this.code = undefined;
        this.getStream();
    }

    resetYearOrSemester() {
        this.yearORSemester = undefined;
        this.code = undefined;
        this.streamDetail();
    }

    resetSubject() {
        this.code = undefined;
        this.getSubjectCode();
    }

    streams: University_Stream[] = [];
    getStream() {
        this.dropdownService.getStream(this.university).subscribe((data:any) => {
            this.streams = data.streams;
        });
    }

    yearORsemesters: yearORsemesters[] = []
    getYearORSemester() {
        this.dropdownService.getYearORSemester().subscribe((data:any) => {
            if (this.streamDuration == null) {
                this.yearORsemesters = data.yearORsem;
            } else {
                this.yearORsemesters = data.yearORsem.filter(item => item <= this.streamDuration);
            }
        });
    }

    streamDuration: Number;
    streamDetail() {
        this.departmentService.getDepartmentById(this.stream, true).subscribe((data:any) => {
            if (data.success) {
                this.isSemester = data.department.isSemester;
                this.streamDuration = data.department.yearORsemester;
                this.getYearORSemester();
            }
        });
    }

    // get SubjectCode from subject collections
    subjectCode = [];
    getSubjectCode() {
        let subject = {
            university: this.university,
            stream: this.stream,
            yearORSemester: this.yearORSemester
        }
        this.subjectService.getSubjectName(subject, true).subscribe((data:any) => {
            if (data.success) {
                this.subjectCode = data.subject;
            }
        })
    }

    getSubject(id) {
        this.subjectService.getSubjectBySubjectCode(id, true).subscribe((data:any) => {
            if (data.success) {
                this.subjectId = data.subject[0]._id;
                this.subjectName = data.subject[0].subjectName;
            }
        })
    }

    bookList: any = [];
    getActiveBooks() {
        this.bookService.getBooks(true).subscribe((data:any) => {
            var flag
            setTimeout(() => {
                data.books.forEach(books => {
                    for (var index = 0; index < this.booksValue.length; index++) {
                        if (books.title == this.booksValue[index].title) {
                            flag = true;
                            break;
                        } else {
                            flag = false;
                        }
                    }
                    if (flag != true) {
                        this.bookList.push({ id: books._id, text: books.title });
                    }
                });
            }, 100)
        })
    }

    booksValue: any[] = [];
    checkDuplication: any[] = [];
    selectedBooksValue: any[] = [];
    selected(value) {
        var flag;
        this.booksValue.forEach(books => {
            if (books.title == value.text) {
                flag = true
            }
        })
        if (flag != true) {
            this.booksValue.push({ bookId: value.id, title: value.text });
        }
    }

    public removed(value: any): void {
        var books = [];
        this.bookList = this.bookList;
        var id = value.id ? value.id : value.bookId;
        books = this.booksValue.filter(item => item.bookId !== id);
        this.booksValue = books;
    }

    public isRef(value) {
        var book = this.booksValue.find(x => x.bookId == value);
        book.isReference = !book.isReference;
    }

    onSubmit() {
        this.disableButton = true;
        const syllabus = {
            syllabusId: this.syllabusId,
            subjectId: this.code,
            streamId: this.stream,
            yearORSemester: this.yearORSemester,
            book: this.booksValue
        };
        this.syllabusService.putSyllabus(syllabus, true).subscribe((data:any) => {
            if (data.success) {
                this.constants.removeCacheData('listOfUniversityWiseAvaliableBoookCount', 0);
                this.router.navigate([this.constants.clientUrlSyllabus]);
                this.notificationService.showSuccess(data.msg);
            }
            else {
                this.notificationService.showError(data.msg);
                this.disableButton = false;
            }
        }, err => {
            if (err.status == 401) {
                this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
            }
            else {
                this.router.navigate([this.constants.clientUrlNotFound]);
            }
        })
    }
}
