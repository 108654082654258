import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tokenNotExpired } from 'angular2-jwt';


import { Constants } from './../constants';

@Injectable()
export class DropdownService {
    authToken: any;
    book: any;

    constructor(private http: HttpClient, public constants: Constants) { }

    //get all University
    getUniversity() {
        let timeStamp = +new Date();
        let headers = this.constants.getHeaders();
        return this.http.get(this.constants.serverActiveUniversityGet + '?tsp=' + timeStamp, { headers: headers })
            
    }

    //get all Stream
    getStream(id) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeaders();
        return this.http.get(this.constants.serverActiveStreamGet + '/' + id + '?tsp=' + timeStamp, { headers: headers })
            
    }

    //get all Stream
    getYearORSemester() {
        let timeStamp = +new Date();
        let headers = this.constants.getHeaders();
        return this.http.get(this.constants.serverActiveYearORSemesterGet + '?tsp=' + timeStamp, { headers: headers })
            
    }

    //get Request Status
    getRequestStatus() {
        let timeStamp = +new Date();
        let headers = this.constants.getHeaders();
        return this.http.get(this.constants.servergetrequeststatus + '?tsp=' + timeStamp, { headers: headers })
            
    }

    //get Languages
    getLanguages() {
        let timeStamp = +new Date();
        let headers = this.constants.getHeaders();
        return this.http.get(this.constants.servergetlanguages + '?tsp=' + timeStamp, { headers: headers })
            
    }
}