import { Router, ActivatedRoute, Params } from '@angular/router';
import { NotificationService } from './../../../services/notification.service';
import { FileUploader, FileSelectDirective, FileItem } from 'ng2-file-upload';
import { OurAssociatesService } from './../../../services/our-associates.service';
import { Constants } from './../../../constants';
import { Component, OnInit } from "@angular/core";
import * as _ from 'lodash';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
  selector: "app-addOurAssociates",
  templateUrl: "./addOurAssociates.component.html",
  styleUrls: ["./addOurAssociates.component.scss"]
})

export class AddOurAssociatesComponent implements OnInit {
  associatesid: any;
  serialNumber: number;
  isProfile = 'Profile';
  isLogo = 'Logo';
  inValidLogo;
  isValidProfilePic;
  associatesFormGroup;
  public associaterLogo = null;
  public associaterProfilePic = null;
  private _submitButtonText: string = this.constants.Save;
  hide: Boolean = false;
  logoUrl: String;
  profileUrl: String;
  get submitButtonText(): string { return this._submitButtonText };

  constructor(
    private ourAssociatesService: OurAssociatesService,
    private notificationService: NotificationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public constants: Constants,
    fb: FormBuilder
  ) {
    //To pre-fill edit user data
    this.activatedRoute.params.subscribe((params: Params) => {
      this.associatesid = params['id'];
      if (this.associatesid !== undefined) {
        this.hide = true;
        this.ourAssociatesService.getAssociatesById(this.associatesid, true).subscribe((data: any) => {
          this.associatesFormGroup.controls['serialNumber'].setValue(data.associates.serialNumber);
          this.associatesFormGroup.controls['companyName'].setValue(data.associates.companyName);
          this.associatesFormGroup.controls['companyDetail'].setValue(data.associates.companyDetail);
          this.associatesFormGroup.controls['companyEmail'].setValue(data.associates.companyEmail);
          this.associatesFormGroup.controls['companyContactNo'].setValue(data.associates.companyContactNo);
          this.associatesFormGroup.controls['companyWebSite'].setValue(data.associates.companyWebSite);
          this.associatesFormGroup.controls['associateName'].setValue(data.associates.associateName);
          this.associatesFormGroup.controls['associateMessage'].setValue(data.associates.associateMessage);
          if (data.associates.associaterLogo !== undefined && data.associates.associaterLogo !== "") {
            this.logoUrl = this.constants.serveruploadspath + data.associates.associaterLogo
            this.associaterLogo = data.associates.associaterLogo
          }
          if (data.associates.associaterProfilePic !== undefined && data.associates.associaterProfilePic !== "") {
            this.profileUrl = this.constants.serveruploadspath + data.associates.associaterProfilePic
            this.associaterProfilePic = data.associates.associaterProfilePic
          }
        },
          (err) => {
            if (err.status === 401)
              this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
          }
        );
        this._submitButtonText = this.constants.Update;
        this.hide = false;
      }
      else {
        this._submitButtonText = this.constants.Save;
      }
    });

    this.associatesFormGroup = fb.group({
      'associatesid': [null, Validators.nullValidator],
      'serialNumber': [null, Validators.required],
      'companyName': [null, Validators.compose([Validators.required])],
      'companyDetail': [null, Validators.compose([Validators.nullValidator])],
      'companyEmail': [null, Validators.compose([Validators.required, Validators.email])],
      'companyContactNo': [null, Validators.compose([Validators.nullValidator, Validators.minLength(10), Validators.pattern(this.constants.numberValidation)])],
      'companyWebSite': [null, Validators.compose([Validators.required, Validators.pattern(this.constants.url)])],
      'associateName': [null, Validators.compose([Validators.required])],
      'associateMessage': [null, Validators.compose([Validators.nullValidator])],
      'associaterLogo': [null, Validators.nullValidator],
      'associaterProfilePic': [null, Validators.nullValidator],
    });
  }

  //Upload Image and logo
  public uploader: FileUploader = new FileUploader({
    url: this.constants.associatesUpload,
    allowedFileType: ["image"],
    authToken: this.constants.loadToken(),
  });

  ngOnInit() {
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    this.uploader._fileTypeFilter = (file) => true;
  }

  onSelect(value, event) {
    var reader = new FileReader();
    if (this.isLogo === value) {
      this.inValidLogo = this.constants.extention(event);
    } else {
      this.isValidProfilePic = this.constants.extention(event);
    }

    reader.onload = (event: any) => { // called once readAsDataURL is completed
      if (this.isLogo === value) {
        if (!this.inValidLogo) {
          this.logoUrl = event.target.result;
          this.associaterLogo = undefined;
        }
      } else {
        if (!this.isValidProfilePic) {
          this.isProfile = value;
          this.profileUrl = event.target.result;
          this.associaterProfilePic = undefined;
        }
      }
    }
    event.target.files[0].imgPrifix = value;
    reader.readAsDataURL(event.target.files[0]); // read file as data url   
  }

  addResponse(response) {
    if (this.associaterLogo === undefined) {
      this.associaterLogo = response.replace(/^"(.*)"$/, '$1');
    } else {
      this.associaterProfilePic = response.replace(/^"(.*)"$/, '$1');
    }
    if (this.associaterLogo !== undefined && this.associaterProfilePic !== undefined) {
      this.addOurAssociates(this.associaterProfilePic, this.associaterLogo);
    }
  }

  updateResponse(response) {
    if (this.associatesid !== undefined && this.associaterLogo === undefined) {
      this.associaterLogo = response.replace(/^"(.*)"$/, '$1');
    } else {
      this.associaterProfilePic = response.replace(/^"(.*)"$/, '$1');
    }
    if (this.associatesid !== undefined && this.associaterLogo !== undefined && this.associaterProfilePic !== undefined) {
      this.addOurAssociates(this.associaterProfilePic, this.associaterLogo);
    }
  }

  addOurAssociates(profilePic, logo) {
    const associates = {
      associatesid: this.associatesid,
      serialNumber: this.associatesFormGroup.get('serialNumber').value ? this.associatesFormGroup.get('serialNumber').value.trim() : null,
      companyName: this.associatesFormGroup.get('companyName').value ? this.associatesFormGroup.get('companyName').value.trim() : null,
      companyDetail: this.associatesFormGroup.get('companyDetail').value ? this.associatesFormGroup.get('companyDetail').value.trim() : null,
      companyEmail: this.associatesFormGroup.get('companyEmail').value,
      companyContactNo: this.associatesFormGroup.get('companyContactNo').value,
      companyWebSite: this.associatesFormGroup.get('companyWebSite').value,
      associateName: this.associatesFormGroup.get('associateName').value ? this.associatesFormGroup.get('associateName').value.trim() : null,
      associateMessage: this.associatesFormGroup.get('associateMessage').value ? this.associatesFormGroup.get('associateMessage').value.trim() : null,
      associaterLogo: logo ? logo : this.associaterLogo,
      associaterProfilePic: profilePic ? profilePic : this.associaterProfilePic
    }
    this.ourAssociatesService.putAssociates(associates, true).subscribe((data: any) => {
      if (data.success) {
        this.router.navigate([this.constants.clientUrlAssociatesList]);
        this.notificationService.showSuccess(data.msg);
      } else {
        this.notificationService.showError(data.msg);
        this.uploader.queue = [];
      }
    })
  }

  onSubmit() {
    var itemLength = this.uploader.queue.length;
    if (itemLength !== 0) {
      this.uploader.onBuildItemForm = (item, form) => {
        form.append('associatesid', this.associatesid !== null ? this.associatesid : '');
        form.append('serialNumber', this.associatesFormGroup.get('serialNumber').value);
        form.append('companyName', this.associatesFormGroup.get('companyName').value);
        form.append('companyDetail', this.associatesFormGroup.get('companyDetail').value);
        form.append('companyEmail', this.associatesFormGroup.get('companyEmail').value);
        form.append('companyContactNo', this.associatesFormGroup.get('companyContactNo').value);
        form.append('companyWebSite', this.associatesFormGroup.get('companyWebSite').value);
        form.append('associateName', this.associatesFormGroup.get('associateName').value);
        form.append('associateMessage', this.associatesFormGroup.get('associateMessage').value);
        form.append('associaterLogo', '');
        form.append('associaterProfilePic', '');
        form.append('flag', 1);
        form.append('isdelete', 0);
      }
      var filesize = this.uploader.queue[itemLength - 1].file.size;
      if (filesize > 2000000) {
        this.notificationService.showError(this.constants.strFileSize);
        return false;
      } else {
        this.setImagePrifix(this.uploader.queue);
        this.uploader.uploadAll();
        this.uploader.onSuccessItem = (item: any, response: any, status: any, headers: any) => {
          if (status === 200) {
            if (this.associatesid === undefined) {
              this.addResponse(response);
            } else {
              this.updateResponse(response);
            }
          }
          else if (status === 401) {
            this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
          }
        };
      }
    } else {
      this.addOurAssociates(undefined, undefined);
    }
  }

  setImagePrifix(listOfImages) {
    var ourAssociatesImages = listOfImages;
    this.uploader.queue = [];
    ourAssociatesImages.forEach((item) => {
      var data = item.file.rawFile as any
      var imgPrifix = data.imgPrifix;
      if (this.uploader.queue.length == 0) {
        this.uploader.queue.push(item);
      } else {
        let updateItem;
        this.uploader.queue.filter(item => {
          let uploadImagePrifix = item.file.rawFile as any;
          if (uploadImagePrifix.imgPrifix == imgPrifix) {
            updateItem = item.file.rawFile
          }
        })
        if (!updateItem) {
          this.uploader.queue.push(item);
        } else {
          for (let index = 0; index < this.uploader.queue.length; index++) {
            let uploadImagePrifix = this.uploader.queue[index].file.rawFile as any;
            if (updateItem.imgPrifix == uploadImagePrifix.imgPrifix) {
              this.uploader.queue[index] = item;
            }
          };
        }
      }
    });
  }
}
