import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tokenNotExpired } from 'angular2-jwt';

import { Constants } from './../constants';

@Injectable()
export class AuthAdminService {
  authToken: any;
  admin: any;

  constructor(private http: HttpClient, public constants: Constants, private router: Router) { }

  //authenticate the user
  authenticateAdmin(admin) {
    let timeStamp = +new Date();
    let headers = this.constants.getHeaders();
    return this.http.post(this.constants.serveradminAuthenticate + '?tsp=' + timeStamp, admin, { headers: headers });
  }

  //forget password for admin
  forgetPassword(admin) {
    let timeStamp = +new Date();
    let headers = this.constants.getHeaders();
    return this.http.post(this.constants.serveradminForgetPassword + '?tsp=' + timeStamp, admin, { headers: headers });
  }

  //get admin by token
  getAdminByToken(tokenId) {
    let timeStamp = +new Date();
    let headers = this.constants.getHeaders();
    return this.http.get(this.constants.serveradminGetByToken + '/' + tokenId + '?tsp=' + timeStamp, { headers: headers });
  }

  //logging of the message
  updateLog(msg) {
    let timeStamp = +new Date();
    let headers = this.constants.getHeaders();
    return this.http.post(this.constants.serveruserInsertLog + '?tsp=' + timeStamp, msg, { headers: headers });
  }

  //store the data to local storage
  storeUserData(token, admin) {
    localStorage.setItem("id_token", token);
    localStorage.setItem('admin', JSON.stringify(admin));
    this.authToken = token;
    this.admin = admin;
  }

  //load token from local storage
  loadToken() {
    const token = localStorage.getItem("id_token");
    this.authToken = token;
    return token;
  }

  // check whether the user is logged in or not
  loggedIn() {

    const token = localStorage.getItem("id_token");
    return tokenNotExpired("id_token", token);
  }

  //logout the admin 
  logout() {
    this.authToken = null;
    this.admin = null;
    localStorage.removeItem("admin");
    localStorage.removeItem("id_token");
  }

  //get admin details from local storage
  getAdminFromStorage() {
    var token = localStorage.getItem("admin");
    if (token != null) {
      return JSON.parse(token);
    }
    else {
      return null;
    }
  }

  //Change Password
  changePassword(user,tokenFlag) {
    let timeStamp = +new Date();
    let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
    return this.http.post(this.constants.serveradminChangePassword + '?tsp=' + timeStamp, user, { headers: headers });
  }
}
