import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tokenNotExpired } from 'angular2-jwt';

import { Constants } from './../constants';

@Injectable()
export class AuthUserService {
  authToken: any;
  user: any;

  constructor(private http: HttpClient, public constants: Constants, private router: Router) {
  }

  //authenticate the user
  authenticateUser(user) {
    let timeStamp = +new Date();
    let headers = this.constants.getHeaders();
    return this.http.post(this.constants.serveruserAuthenticate + '?tsp=' + timeStamp, user, { headers: headers });
  }

  //forget password for user
  forgetPassword(user) {
    let timeStamp = +new Date();
    let headers = this.constants.getHeaders();
    return this.http.post(this.constants.serveruserForgetPassword + '?tsp=' + timeStamp, user, { headers: headers });
  }

  //get user by token
  getUserByToken(tokenId) {
    let timeStamp = +new Date();
    let headers = this.constants.getHeaders();
    return this.http.get(this.constants.serveruserGetByToken + '/' + tokenId + '?tsp=' + timeStamp);
  }

  //logging of the message
  updateLog(msg) {
    let timeStamp = +new Date();
    let headers = this.constants.getHeaders();
    return this.http.post(this.constants.serveruserInsertLog + '?tsp=' + timeStamp, msg, { headers: headers });
  }

  //store the data to local storage
  storeUserData(token, user) {
    localStorage.setItem("userid_token", token);
    localStorage.setItem('user', JSON.stringify(user));
    this.authToken = token;
    this.user = user;
  }

  //load token from local storage
  loadToken() {
    const token = localStorage.getItem("userid_token");
    this.authToken = token;
    return token;
  }

  // check whether the user is logged in or not
  loggedIn() {
    const token = localStorage.getItem("userid_token");
    return tokenNotExpired("userid_token", token);
  }

  //logout the user 
  logout() {
    this.authToken = null;
    this.user = null;
    localStorage.removeItem("user");
    localStorage.removeItem("userid_token");
  }

  //email activation...
  emailavtication(user) {
    let timeStamp = +new Date();
    let headers = this.constants.getHeaders();
    return this.http.post(this.constants.serveruseremailActivation + '?tsp=' + timeStamp, user, { headers: headers })

  }

  //get user details from local storage
  getUserFromStorage() {
    var token = localStorage.getItem("user");
    const userId_token = localStorage.getItem("userid_token");

    if (token != null && token != "undefined" && tokenNotExpired("userid_token", userId_token)) {
      return JSON.parse(token);
    }
    else {
      localStorage.removeItem("user");
      localStorage.removeItem("userid_token");
      return null;
    }
  }

  //Change Password
  changePassword(user, tokenFlag) {
    let timeStamp = +new Date();
    let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
    return this.http.post(this.constants.serveruserChangePassword + '?tsp=' + timeStamp, user, { headers: headers })

  }

  //External User Login
  externalUserLogin(user) {
    let timeStamp = +new Date();
    let headers = this.constants.getHeaders();
    return this.http.post(this.constants.serveruserexternalUserLogin + '?tsp=' + timeStamp, user, { headers: headers })

  }
  //check URL Access
  checkURL(url) {
    let headers = this.constants.getHeaders();
    return this.http.post(this.constants.serveruserCheckURL, url)

  }
}
