import { HeaderbreadcrumbComponent } from './../headerbreadcrumb/headerbreadcrumb.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Component, EventEmitter, Injectable, Input, OnInit, Output, ViewChild, ElementRef, Directive, AfterViewInit, AfterViewChecked, HostListener } from '@angular/core';
import { FileUploader, FileSelectDirective } from 'ng2-file-upload';
/* For Validation */
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';

import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { ConfirmDialogComponent } from '../confirmDialog/confirmDialog.component';

/* For Validation */
import { AuthAdminService } from './../../../services/authadmin.service';
import { AuthUserService } from './../../../services/authuser.service';
import { BookRequestService } from './../../../services/bookrequest.service';
import { BookinstanceService } from './../../../services/bookinstance.service';
import { BookService } from './../../../services/book.service';
import { Constants } from './../../../constants';
import { CustomValidators } from 'ng2-validation';
import { DataTableDirective } from 'angular-datatables';
import { DropdownService } from './../../../services/dropdown.service';
import { NotificationService } from './../../../services/notification.service';
import { Subject } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { UserService } from './../../../services/user.service';

class Book {
  _id: any;
  title: String;
  subtitle: String;
  description: String;
  categoryid: String;
  language: String;
  author: String;
  publisher: String;
  imageurl: String;
  isbnnumber: String;
  isdelete: Boolean;
  isactive: Boolean;
  flag: Boolean;
}

class Books {
  title: String;
  categoryid: String;
  author: String;
  search: String;
}

class Languages {
  _id: String;
  name: String;
}

@Component({
  selector: 'app-addBook',
  templateUrl: './addBooks.component.html',
  styleUrls: ['./addBooks.component.scss']
})
@Directive({
  selector: '[myMatchHeight]'
})
export class AddBooksComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;
  requesttype: boolean;
  emailNotification: boolean;
  viewType: String;
  dtOptions = this.constants.dataTableSettings;
  tblResult: DataTableDirective;
  searchKeyword: String;
  bookid: any;
  title: String = '';
  subtitle: String = '';
  description: String = '';
  categoryid: String = '';
  language: String;
  author: String = '';
  publisher: String = '';
  isbnnumber: String = '';
  isdelete: Boolean;
  isactive: Boolean;
  strConst: String;
  ShowGrid: Boolean = true;
  infiniteScrollDisabled: Boolean = false;
  showLoader: Boolean = false;

  //Manually add books schema
  submitButtonText: string = this.constants.Save;
  bookForm: FormGroup;
  search: String;
  imageurl: any;
  flag: Boolean = false;
  imageurlPath: String;
  imagename: string;
  hide: Boolean = false;
  disableButton: Boolean = false;
  inRequestMode: Boolean = false;
  hidenblock: Boolean = false;
  userid: any;
  name: String;
  email: String;
  university: String;
  contactnumber: String;
  mobHeight: any;
  mobWidth: any;

  public uploader: FileUploader = new FileUploader({
    url: this.constants.uploadFile,
    allowedFileType: ["image"],
    authToken: this.constants.loadToken(),
  });
  // public uploader
  el: HTMLElement
  // Change By Arpit for #1803
  constructor(
    private activatedRoute: ActivatedRoute,
    public constants: Constants,
    private notificationService: NotificationService,
    private router: Router,
    private dialogService: DialogService,
    private bookService: BookService,
    private dropdownService: DropdownService,
    private authadminservice: AuthAdminService,
    private userService: UserService,
    private bookrequestService: BookRequestService,
    private bookinstanceService: BookinstanceService,
    private http: HttpClient,
    el: ElementRef,
    fb: FormBuilder,
  ) {
    //check width of screen for responsive result
    this.mobWidth = (window.screen.width);

    this.el = el.nativeElement
    this.bookForm = fb.group({
      'bookid': [null, Validators.nullValidator],
      'title': [null, Validators.compose([Validators.required, Validators.maxLength(150)])],
      'subtitle': [null, Validators.compose([Validators.nullValidator, Validators.maxLength(100), Validators.pattern("^[a-zA-Z0-9!@#$&()\\-`.+,/\_]+( [a-zA-Z0-9!@#$&()\\-`.+,/\_]+)*$")])],
      'description': [null, Validators.nullValidator],
      'categoryid': [null, Validators.compose([Validators.nullValidator, Validators.maxLength(100), Validators.pattern("^[a-zA-Z0-9!@#$&()\\-`.+,/\_]+( [a-zA-Z0-9!@#$&()\\-`.+,/\_]+)*$")])],
      'language': [null, Validators.required],
      'author': [null, Validators.compose([Validators.nullValidator, Validators.maxLength(100), Validators.pattern("^[a-zA-Z0-9!@#$&()\\-`.+,/\_]+( [a-zA-Z0-9!@#$&()\\-`.+,/\_]+)*$")])],
      'publisher': [null, Validators.compose([Validators.nullValidator, Validators.maxLength(100), Validators.pattern("^[a-zA-Z0-9!@#$&()\\-`.+,/\_]+( [a-zA-Z0-9!@#$&()\\-`.+,/\_]+)*$")])],
      'imageurl': [null, Validators.compose([Validators.nullValidator])],
      'isbnnumber': [null, Validators.nullValidator],
      'isactive': [null, Validators.nullValidator],
    })
  }

  ngOnInit() {
    localStorage.setItem('currentURL', this.router.url);
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    this.uploader._fileTypeFilter = (file) => true;
    this.activatedRoute.params.subscribe((params: Params) => {
      this.userid = params['id'];
      if (this.userid) {
        this.inRequestMode = true;
        this.userService.getUserById(this.userid, true).subscribe((data: any) => {
          this.name = data.user.name
          this.email = data.user.email
          this.university = data.user.university != null ? data.user.university : undefined
          this.contactnumber = data.user.contactnumber
        });
      }
    });

    this.activatedRoute.queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        if (params['flag'] === undefined) {
          this.requesttype = true;
        }
        else {
          this.requesttype = params['flag'];
        }
      });

    //get languages
    this.getLanguage();
  }

  books: Books[] = [];
  allBooks: Books[] = [];
  dtTrigger: Subject<Books> = new Subject<Books>();

  searchBooksBy(search) {
    if (search != undefined && search != null) {
      this.searchKeyword = search;
      if (this.dtElement == undefined || this.dtElement.dtInstance == undefined) {
        this.hide = true;
        this.bookService.searchBook(search).subscribe((data: any) => {
          if (data.success) {
            if (data != null) {
              if (data.book != null) {
                this.infiniteScrollDisabled = false;
                this.allBooks = [];
                this.books = [];
                this.allBooks = data.book;
                if (this.allBooks.length > 12) {
                  for (var index = 0; index < 12; index++) {
                    this.books.push(this.allBooks[index]);
                  }
                }
                else {
                  this.infiniteScrollDisabled = true;
                  for (var index = 0; index < this.allBooks.length; index++) {
                    this.books.push(this.allBooks[index]);
                  }
                }
              }
              else {
                this.books = data.book;
              }
              this.flag = false;
              this.dtTrigger.next();
              this.hidenblock = false;
            }
          }
          else {
            //failure block
            this.flag = true;
            this.hidenblock = false;
          }
          this.hide = false;
        });
      }
    }
    else {
      this.hide = false;
    }
  }

  searchBooksByRTS(search) {
    
    if (search != undefined && search != null) {
      this.searchKeyword = search;
      
        this.bookinstanceService.searchBookRTS(search).subscribe((data: any) => {
          
          let mappedArrayObj = data.bookinstances[0].books.map(obj => { 
            let newObj = {
              _id: obj._id,
              authors: obj.author,
              imageLinks: obj.imageurl,
              title: obj.title,
              subtitle: obj.subtitle,
              publisher: obj.publisher,
              categories: obj.categoryid,  
            };
            return newObj;
         });

          if (data.success) {
              this.books = mappedArrayObj;             
              }
              else{
                this.books = mappedArrayObj;               
              }
        });
      }
    
      else {
        this.hide = false;
      }
    }


  //Check Image extention
  validImg: Boolean;
  extention(evt) {
    this.validImg = this.constants.extention(evt);
  }

  SendRequest(infoLink, title) {
    if (this.authadminservice.getAdminFromStorage() == null) {
      this.notificationService.showError(this.constants.strLoginrequired);
    }
    else {

      if (infoLink == null && infoLink == undefined) {
        this.onSubmit(null, title)
      }
      else {
        var infoLinkId = this.constants.getIdFromInfoLinks(infoLink);
        this.onSubmit(infoLinkId, title)
      }
    }
  }

  languages: any[] = [];
  getLanguage() {
    this.languages = [];
    this.dropdownService.getLanguages().subscribe((data: any) => {
      if (data.success) {
        this.languages = data.languages
      }
    });
  }

  onSubmit(infoLinkId, title) {
    this.disableButton = true;
    const book = {
      searchKey: infoLinkId,
      googleid: infoLinkId,
      bookid: this.bookid,
      title: title,
      requesttype: this.requesttype,
      subtitle: this.subtitle != undefined ? this.subtitle : '',
      description: this.description != undefined ? this.description : '',
      categoryid: this.categoryid != undefined ? this.categoryid : '',
      language: this.language,
      author: this.author != undefined ? this.author : '',
      publisher: this.publisher != undefined ? this.publisher : '',
      imageurl: this.imageurl != null ? this.imageurl : '',
      isbnnumber: this.isbnnumber != undefined ? this.isbnnumber : '',
      isdelete: 0,
      isactive: this.isactive ? true : false,
      file: this.uploader,
      userid: this.userid
    }
    this.bookService.putBook(book, true).subscribe((data: any) => {
      if (data.success) {
        this.constants.removeCacheData('DashboardCounts', 0);
        this.router.navigate([this.constants.clientUrlBooks]);
        this.notificationService.showSuccess(data.msg);
      }
      else {
        this.notificationService.showError(data.msg);
        this.disableButton = false;
      }
    }, err => {
      if (err.status == 401) {
        this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
      }
      else {
        this.router.navigate([this.constants.clientUrlNotFound]);
      }
    });
  }

  //Manually add books
  addBooks() {
    const book = {
      bookid: this.bookid,
      title: this.title,
      subtitle: this.subtitle != undefined ? this.subtitle : '',
      description: this.description != undefined ? this.description : '',
      categoryid: this.categoryid != undefined ? this.categoryid : '',
      language: this.language,
      author: this.author != undefined ? this.author : '',
      publisher: this.publisher != undefined ? this.publisher : '',
      imageurl: this.imagename != null ? this.imagename : this.imageurl,
      isbnnumber: this.isbnnumber != undefined ? this.isbnnumber : '',
      isdelete: 0,
      isactive: this.isactive ? true : false,
      userid: this.userid
    }
    //upsert books        
    var itemLength = this.uploader.queue.length;
    if (itemLength != 0) {
      this.uploader.onBuildItemForm = (item, form) => {
        form.append('bookid', this.bookid != null ? this.bookid : '');
        form.append('title', this.title);
        form.append('subtitle', this.subtitle);
        form.append('description', this.description);
        form.append('categoryid', this.categoryid);
        form.append('language', this.language);
        form.append('author', this.author);
        form.append('publisher', this.publisher);
        form.append('imageurl', '');
        form.append('isbnnumber', this.isbnnumber);
        form.append('isdelete', 0);
        form.append('isactive', this.isactive ? true : false);
        form.append('flag', 1);
        form.append('userid', this.userid);
      }

      var filesize = this.uploader.queue[itemLength - 1].file.size;

      if (filesize > 2000000) {
        this.notificationService.showError(this.constants.strFileSize);
        return false;
      }
      else {
        this.uploader.uploadItem(this.uploader.queue[itemLength - 1]);
        this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
          if (status == 200) {
            this.router.navigate([this.constants.clientUrlBooks]);
            if (this.submitButtonText === this.constants.Save) {
              this.constants.removeCacheData('DashboardCounts', 0);
              this.notificationService.showSuccess(this.constants.strBookInsert);
            }
            else {
              this.notificationService.showSuccess(this.constants.strBookUpdate);
            }
          }
          else if (status == 401) {
            this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
          }
          else {
            this.notificationService.showError(this.constants.strBookExist);
            this.bookForm.reset();
          }
        };
      }
    }
    else if (itemLength === undefined || itemLength === 0) {
      this.bookService.putBook(book, true).subscribe((data: any) => {
        if (data.success) {

          this.router.navigate([this.constants.clientUrlBooks]);
          if (this.submitButtonText === this.constants.Save) {
            this.notificationService.showSuccess(data.msg);
          }
          else {
            this.notificationService.showSuccess(data.msg);
          }
        }
        else {
          this.notificationService.showError(this.constants.strBookExist);
          this.bookForm.reset();
        }
      },
        (err) => {
          if (err.status == 401) {
            this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
          }
          else if (err.status == 404) {
            this.router.navigate([this.constants.clientUrlNotFound]);
          } else {
            this.notificationService.showError(this.constants.strBookExist);
            this.bookForm.reset();
          }
        });
    }
  }

  sendManualRequest(requestType, infoLink, id) {
    sessionStorage.setItem('dialogBoxbtn', 'true');
    let disposable = this.dialogService.addDialog(ConfirmDialogComponent, {
      title: "E-mail notification confirmation",//this.constants.EmailNotificationConfirmationTitle"",
      message: "Do you wish to send notification to user?",//this.constants.EmailNotificationConfirmation
    }).subscribe((isConfirmed) => {
      sessionStorage.clear();
        if(isConfirmed == true){
          this.emailNotification = true;
        }else{
          this.emailNotification = false;
        }
    this.disableButton = true;
    if (infoLink != null) {
      var infoLinkId = this.constants.getIdFromInfoLinks(infoLink);
      this.activatedRoute.queryParams
        .subscribe(params => {
          const bookrequest = {
            searchKey: infoLinkId,
            googleid: infoLinkId,
            isdelete: 0,
            bookid: '',
            userid: this.userid,
            requestdate: new Date(),
            requesttype: requestType,
            emailNotification: this.emailNotification,
            istransactioncomplete: 0,
            contactno: this.contactnumber,
            preferredtime: null,
            address: null,
            isdropbyuser: (requestType.toString() == 'true') ? 1 : 0,
            remarks: null,
            email: this.email,
            name: this.name,
            quantity: 1,
          }
          this.bookrequestService.putBookRequest(bookrequest, true).subscribe((data: any) => {
              if (data.success) {
                // Change by Arpit
              this.constants.removeCacheData('DashboardBookList', 0);
              this.constants.removeCacheData('ListOfNeededBook', 0);
              this.notificationService.showSuccess(data.msg);
              this.notificationService.showSuccess(this.constants.strBookRequestSend);
              this.router.navigate([this.constants.clientUrlUserUpdate, this.userid])
            }
            else {
              this.notificationService.showError(data.msg);
              this.disableButton = false;
            }
          }
            , err => {
              if (err.status == 401) {
                this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
              }
              else {
                this.router.navigate([this.constants.clientUrlNotFound]);
              }
            });
        });
    } else {
      if (id) {
        this.bookService.getBookById(id).subscribe((data: any) => {
          const bookrequest = {
            title: data.book.title,
            subtitle: this.subtitle != undefined ? this.subtitle : '',
            description: this.description != undefined ? this.description : '',
            categoryid: this.categoryid != undefined ? this.categoryid : '',
            language: this.language,
            author: this.author != undefined ? this.author : '',
            publisher: this.publisher != undefined ? this.publisher : '',
            imageurl: this.imagename != null ? this.imagename : this.imageurl,
            isbnnumber: this.isbnnumber != undefined ? this.isbnnumber : '',
            isdelete: 0,
            isactive: this.isactive ? true : false,
            // bookrequest detail
            bookid: '' ? '' : id,
            userid: this.userid,
            requestdate: new Date(),
            requesttype: requestType,
            emailNotification: this.emailNotification,
            istransactioncomplete: 0,
            contactno: this.contactnumber,
            preferredtime: null,
            address: null,
            isdropbyuser: (requestType.toString() == 'true') ? 1 : 0,
            remarks: null,
            email: this.email,
            name: this.name,
            quantity: 1,
          }
          //upsert books        
          var itemLength = this.uploader.queue.length;
          if (itemLength != 0) {
            this.uploader.onBuildItemForm = (item, form) => {
              form.append('bookid', '' ? '' : this.bookid);
              form.append('title', this.title);
              form.append('subtitle', this.subtitle);
              form.append('description', this.description);
              form.append('categoryid', this.categoryid);
              form.append('language', this.language);
              form.append('author', this.author);
              form.append('publisher', this.publisher);
              form.append('imageurl', '');
              form.append('isbnnumber', this.isbnnumber);
              form.append('isdelete', 0);
              form.append('isactive', this.isactive ? true : false);
              form.append('flag', 1);
              //bookrequest detail
              form.append('userid', this.userid);
              form.append('requestdate', new Date());
              form.append('requesttype', requestType);
              form.append('istransactioncomplete', 0);
              form.append('contactno', this.contactnumber);
              form.append('preferredtime', null);
              form.append('address', null);
              form.append('isdropbyuser', (requestType.toString() == 'true') ? 1 : 0);
              form.append('remarks', null);
              form.append('email', this.email);
              form.append('name', this.name);
              form.append('quantity', 1)
            };

            var filesize = this.uploader.queue[itemLength - 1].file.size;
            if (filesize > 2000000) {
              this.notificationService.showError(this.constants.strFileSize);
              return false;
            }
            else {
              this.uploader.uploadItem(this.uploader.queue[itemLength - 1]);

              this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
                if (status == 200) {
                  this.notificationService.showSuccess(this.constants.strBookInsert);
                  this.router.navigate([this.constants.clientUrlUserUpdate, this.userid])
                }
                else if (status == 401) {
                  this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
                }
                else {
                  this.notificationService.showError(this.constants.strBookExist);
                  this.disableButton = false;
                }
              };
            }
          }
          else if (itemLength === undefined || itemLength === 0) {
            this.bookService.putBook(bookrequest, true).subscribe((data: any) => {
              if (data.success) {
                this.notificationService.showSuccess(data.msg);
                this.router.navigate([this.constants.clientUrlUserUpdate, this.userid])
              }
              else {
                this.notificationService.showError(data.msg);
                this.disableButton = false;
              }
            },
              err => {
                if (err.status == 401) {
                  this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
                }
                else {
                  this.router.navigate([this.constants.clientUrlNotFound]);
                }
              });
          }
        });
      } else {
        const bookrequest = {
          title: this.title,
          subtitle: this.subtitle != undefined ? this.subtitle : '',
          description: this.description != undefined ? this.description : '',
          categoryid: this.categoryid != undefined ? this.categoryid : '',
          language: this.language,
          author: this.author != undefined ? this.author : '',
          publisher: this.publisher != undefined ? this.publisher : '',
          imageurl: this.imagename != null ? this.imagename : this.imageurl,
          isbnnumber: this.isbnnumber != undefined ? this.isbnnumber : '',
          isdelete: 0,
          isactive: this.isactive ? true : false,
          //bookrequest detail
          bookid: '',
          userid: this.userid,
          requestdate: new Date(),
          requesttype: requestType,
          emailNotification: this.emailNotification,
          istransactioncomplete: 0,
          contactno: this.contactnumber,
          preferredtime: null,
          address: null,
          isdropbyuser: (requestType.toString() == 'true') ? 1 : 0,
          remarks: null,
          email: this.email,
          name: this.name,
          quantity: 1,
        }
        //upsert books        
        var itemLength = this.uploader.queue.length;
        if (itemLength != 0) {
          this.uploader.onBuildItemForm = (item, form) => {
            form.append('bookid', '');
            form.append('title', this.title);
            form.append('subtitle', this.subtitle);
            form.append('description', this.description);
            form.append('categoryid', this.categoryid);
            form.append('language', this.language);
            form.append('author', this.author);
            form.append('publisher', this.publisher);
            form.append('imageurl', '');
            form.append('isbnnumber', this.isbnnumber);
            form.append('isdelete', 0);
            form.append('isactive', this.isactive ? true : false);
            form.append('flag', 1);
            //bookrequest detail
            form.append('userid', this.userid);
            form.append('requestdate', new Date());
            form.append('requesttype', requestType);
            form.append('istransactioncomplete', 0);
            form.append('contactno', this.contactnumber);
            form.append('preferredtime', null);
            form.append('address', null);
            form.append('isdropbyuser', (requestType.toString() == 'true') ? 1 : 0);
            form.append('remarks', null);
            form.append('email', this.email);
            form.append('name', this.name);
            form.append('quantity', 1)
          };

          var filesize = this.uploader.queue[itemLength - 1].file.size;

          if (filesize > 2000000) {
            this.notificationService.showError(this.constants.strFileSize);
            return false;
          }
          else {
            this.uploader.uploadItem(this.uploader.queue[itemLength - 1]);

            this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {

              if (status == 200) {
                this.notificationService.showSuccess(this.constants.strBookRequestSend);
                this.router.navigate([this.constants.clientUrlUserUpdate, this.userid])
              }
              else if (status == 401) {
                this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
              }
              else {
                this.notificationService.showError(this.constants.strBookExist);
                this.bookForm.reset();
              }
            };
          }
        }
        else if (itemLength === undefined || itemLength === 0) {
          this.bookService.putBook(bookrequest, true).subscribe((data: any) => {
            if (data.success) {
              this.notificationService.showSuccess(data.msg);
              this.router.navigate([this.constants.clientUrlUserUpdate, this.userid])
            }
            else {
              this.notificationService.showError(data.msg);
              this.bookForm.reset();
            }
          }, err => {
            if (err.status == 401) {
              this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
            }
            else {
              this.router.navigate([this.constants.clientUrlNotFound]);
            }
          });
        }
      }
    }
  });
    setTimeout(() => {
        disposable.unsubscribe();
    }, 10000);
  }

  //To Display the block
  onClick() {
    this.hidenblock = !this.hidenblock;
  }

  onGrid(ShowGrid) {
    this.ShowGrid = ShowGrid;
  }

  onScroll() {
    if (this.books.length == this.allBooks.length) {
      this.infiniteScrollDisabled = true;
      this.showLoader = false;
    } else {
      this.showLoader = true;
      setTimeout(() => {
        var startIndex = this.books.length;
        var endIndex = this.books.length + 12;
        if (endIndex > this.allBooks.length) {
          endIndex = endIndex - (endIndex - (this.allBooks.length))
        }
        for (var index = startIndex; index < endIndex; index++) {
          this.books.push(this.allBooks[index]);
        }
        this.showLoader = false;
      }, 3000);
    }
  }
}