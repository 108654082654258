import { Constants } from './../../../constants';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.css']
})
export class MainFooterComponent implements OnInit {

  constructor(public constants: Constants) { }

  ngOnInit() {
  }

  onNavigateToPlayStore(){
    window.open("https://goo.gl/nfJ1hS", "_blank");
  }

  onNavigateToAppStore(){
    window.open("https://goo.gl/i7xjb1", "_blank");
  }

}