import { Injectable } from '@angular/core';
import { ToastyService, ToastyConfig, ToastOptions, ToastData } from 'ng2-toasty';
import { interval, Subscription } from 'rxjs'
import { take } from 'rxjs/operators';

@Injectable()
export class NotificationService {

    interval = 1000;
    subscription: Subscription;
    toastOptions: ToastOptions = {
        title: "",
        msg: "",
        showClose: true,
    };

    constructor(private toastyService: ToastyService, private toastyConfig: ToastyConfig) {
        this.toastyConfig.theme = 'bootstrap';
        this.toastyConfig.limit = 1;
    }

    showInfo(message) {
        if (message != undefined) {
            this.toastOptions.msg = message;
            this.toastyService.info(this.toastOptions);
        }
    }

    showSuccess(message) {
        if (message != undefined) {
            this.toastOptions.msg = message;
            this.toastyService.success(this.toastOptions);
        }
    }

    showWait(message) {
        if (message != undefined) {
            this.toastOptions.msg = message;
            this.toastyService.wait(this.toastOptions);
        }
    }

    showError(message) {
        if (message != undefined) {
            this.toastOptions.msg = message;
            this.toastyService.error(this.toastOptions);
        }
    }

    showWarning(message) {
        if (message != undefined) {
            this.toastOptions.msg = message;
            this.toastyService.warning(this.toastOptions);
        }
    }

    // not in use just for configuration Sample
    showNotification(message, type) {
        let Interval = interval(1000);
        let subscription: Subscription;

        let toastOptions: ToastOptions = {
            title: "",
            msg: message,
            showClose: true,
            onAdd: (toast: ToastData) => {
                // Run the timer with 1 second iterval
                let observable = Interval;
                // Start listen seconds beat
                subscription = observable.subscribe((count: number) => {
                    // Update title of toast
                    toast.title = "";
                    // Update message of toast
                    toast.msg = message;
                    // Extra condition to hide Toast after 10 sec
                    if (count > 10) {
                        // We use toast id to identify and hide it
                        this.toastyService.clear(toast.id);
                    }
                });

            },
            onRemove: function (toast: ToastData) {
                // Stop listenning
                subscription.unsubscribe();
            }
        };

        switch (type) {
            case 'default': this.toastyService.default(toastOptions); break;
            case 'info': this.toastyService.info(toastOptions); break;
            case 'success': this.toastyService.success(toastOptions); break;
            case 'wait': this.toastyService.wait(toastOptions); break;
            case 'error': this.toastyService.error(toastOptions); break;
            case 'warning': this.toastyService.warning(toastOptions); break;
        }
    }
}