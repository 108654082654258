import { Constants } from './../../../constants';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-seeking-contribute-home',
  templateUrl: './seeking-contribute-home.component.html',
  styleUrls: ['./seeking-contribute-home.component.css']
})
export class SeekingContributeHomeComponent implements OnInit {
  search: any;
  searchKeyword: String;
  searchKeywordContent: String;
  hide: Boolean = false;
  constructor(
    public constants: Constants,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        if (params['search']) {
          this.search = params['search'];
          this.searchKeywordContent = params['search'];
        }
      });
  }

  onSubmit() {
    if (this.search != undefined) {
      this.router.navigate([this.constants.clientUrlUserSearch], { queryParams: { search: this.search } });
    }
    // else {
    //     this.router.navigate([this.constants.clientUrlUserSearch]);
    // }
  }

}