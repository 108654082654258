import { Router } from '@angular/router';
import { Constants } from './../constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
@Injectable()
export class BookinstanceService {
    bookinstance: any;
    constructor(private http: HttpClient, public constants: Constants) { }

    //put bookinstance
    putbookinstance(bookinstance, tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.put(this.constants.serverbookinstancePut + '?tsp=' + timeStamp, bookinstance, { headers: headers });            
    }

    //add bookinstance
    addbookinstance(Instances, tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.put(this.constants.serveraddBookinstancePut + '?tsp=' + timeStamp, Instances, { headers: headers });            
    }

    getInstanceCount() {
        let timeStamp = +new Date();
        let headers = this.constants.getHeaders();
        return this.http.get(this.constants.servergetInstanceCounts + '?tsp=' + timeStamp, { headers: headers });
    }

    //get book by id
    getByBookId(bookid, tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.get(this.constants.servergetBookInstanceByBookId + '/' + bookid + '?tsp=' + timeStamp, { headers: headers });
    }

    //get new instance number for display 
    getInstanceNo(tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.get(this.constants.servergetInstanceNo + '?tsp=' + timeStamp, { headers: headers });
    }

    //get available bookinstances for dropdown by bookid
    getAvailableBookInstanceByBookId(bookid) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeaders();
        return this.http.get(this.constants.servergetAvailableBookInstanceByBookId + '/' + bookid + '?tsp=' + timeStamp, { headers: headers })
    }

    //get Instance List
    getInstanceList(tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.get(this.constants.servergetInstanceList + '?tsp=' + timeStamp, { headers: headers })
    }

    //search books by RTS number
    searchBookRTS(search) {
    let timeStamp = +new Date();
    let headers = this.constants.getHeaders();
    return this.http.get(this.constants.serverbookSearchByRTS + "/" + search + '?tsp=' + timeStamp);
    
}
}