import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Constants } from './../../../constants';
import { NotificationService } from './../../../services/notification.service';
import { PageService } from "./../../../services/page.service";

@Component({
    selector: 'app-pages',
    templateUrl: './addpages.component.html',
    styleUrls: ['./addpages.component.css']
})

export class AddPagesComponent implements OnInit {
    pageId: any;
    title: String;
    description: String;
    isactive: String;
    pageForm: FormGroup;
    hide: Boolean = false;
    disableButton: Boolean = false;
    submitButtonText: String = this.constants.Save;
    constructor(
        private activatedRoute: ActivatedRoute,
        public constants: Constants,
        private notificationService: NotificationService,
        private pageService: PageService,
        private router: Router,
        fb: FormBuilder
    ) {
        let description = new FormControl('', [Validators.required]);
        this.activatedRoute.params.subscribe((params: Params) => {
            this.pageId = params['id'];
            if (this.pageId == null) {
                this.pageForm = fb.group({
                    'title': [null, Validators.compose([Validators.required, Validators.maxLength(150),])],
                    'description': description,
                    'isactive': [null, Validators.nullValidator]
                });
            } else {
                this.pageForm = fb.group({
                    'title': [null, Validators.compose([Validators.nullValidator, Validators.maxLength(150),])],
                    'description': description,
                    'isactive': [null, Validators.nullValidator]
                });
            }
        })

    }

    ngOnInit() {
        this.activatedRoute.params.subscribe((params: Params) => {
            let pageId = params['id'];
            if (pageId != null) {
                this.hide = true;
                setTimeout(() => {
                    this.pageService.getPageById(pageId, true).subscribe((data:any) => {
                        this.title = data.page.title;
                        this.description = data.page.description;
                        this.isactive = data.page.isactive;
                        this.submitButtonText = this.constants.Update;
                        this.pageId = pageId;
                        this.hide = false;
                    }, err => {
                        if (err.status == 401) {
                            this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
                        }
                        else {
                            this.router.navigate([this.constants.clientUrlNotFound]);
                        }
                    })
                }, 100);
            }
        })
    }


    onSubmit() {
        this.disableButton = true;
        const page = {
            pageId: this.pageId,
            title: this.title.trim(),
            description: this.description.trim(),
            isactive: this.isactive ? true : false,
        }
        this.pageService.putPage(page, true).subscribe((data:any) => {
            if (data.success) {
                this.constants.removeCacheData('FAQ', 0);
                this.router.navigate([this.constants.clientUrlPages]);
                this.notificationService.showSuccess(data.msg);
            }
            else {
                this.notificationService.showError(data.msg);
                this.disableButton = false;
            }
        }, err => {
            if (err.status == 401) {
                this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
            }
            else {
                this.router.navigate([this.constants.clientUrlNotFound]);
            }
        })
    }
}