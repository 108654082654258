import { Component, OnInit, ViewChild } from "@angular/core";
import { DataTableDirective } from "angular-datatables";
import { Constants } from "../../../constants";
import { BookinstanceService } from "services/bookinstance.service";
import { Subject } from "rxjs";
import { InstanceLogModalComponent } from "../InstancelogModal/InstanceLogModal.Component";
import { DialogService } from "ng2-bootstrap-modal";
import { BookRequestService } from "services/bookrequest.service";

@Component({
    selector: 'app-bookInstanceLogs',
    templateUrl: './bookInstanceLogs.component.html',
    styleUrls: ['./bookInstanceLogs.component.scss']
})
export class BookInstanceLogsComponent implements OnInit {
    @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
    dtOptions = this.constants.dataTableSettings && {
        order: [[1, 'asc']]
    };
    bookInstanceLogs: any = [];
    hide: Boolean = false;
    constructor(
        private bookInstanceService: BookinstanceService,
        private bookRequestService: BookRequestService,
        public constants: Constants,
        private dialogService: DialogService
    ) { }
    ngOnInit() {
        this.bindBookInstanceLogs();
    }
    dtTrigger: Subject<any> = new Subject<any>();
    bindBookInstanceLogs() {
        this.hide = true;
        this.bookInstanceService.getInstanceList(true).subscribe((result: any) => {
            if (result !== null) {
                this.bookInstanceLogs = result.logs;
                this.dtTrigger.next();
                this.hide = false;
            }
        });
    }

    // Call instance log modal for view log history.
    modal(instanceno) {
        if (instanceno !== null && instanceno !== undefined) {
            this.bookRequestService.getInstanceLogDetails(instanceno, true).subscribe((result: any) => {
                if (result !== null) {
                    let disposable = this.dialogService.addDialog(InstanceLogModalComponent, {
                        instanceNo: instanceno,
                        logHistory: result.instanceLog
                    }).subscribe((isConfirmed) => {
                        //Dialog result
                        if (isConfirmed) {

                        }
                    });
                }
            });
        }
    }
}