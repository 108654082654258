import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormsModule, ReactiveFormsModule, AbstractControl } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';

import { AuthAdminService } from './../../../services/authadmin.service';
import { Constants } from './../../../constants';
import { HandleError } from './../../../error';
import { NotificationService } from './../../../services/notification.service';

@Component({
  selector: 'app-forgetpassword',
  templateUrl: './forgetpassword.component.html',
  styleUrls: ['./forgetpassword.component.scss']
})
export class ForgetpasswordComponent implements OnInit {
  email: AbstractControl;
  forgetPasswordForm: FormGroup;
  submitButtonText: string = this.constants.ResetMyPassword;
  constructor(private authService: AuthAdminService,
    private router: Router,
    public constants: Constants,
    private errorHandler: HandleError,
    private notificationService: NotificationService,
    fb: FormBuilder
  ) {
    this.forgetPasswordForm = fb.group({
      'email': [null, Validators.compose([Validators.required, Validators.email])]
    })
       this.email = this.forgetPasswordForm.controls['email'];
  }

  ngOnInit() {
  }

  onSubmit() {
    const user = {
      email: this.email.value
    }
    this.authService.forgetPassword(user).subscribe((data:any) => {
      if (data.success == false) {
        this.notificationService.showError(data.msg);
      }
      else {
        this.notificationService.showSuccess(data.msg);
        this.router.navigate([this.constants.clientLogin]);
      }
    },
      err => {
        this.notificationService.showError(this.constants.errorOccur);
        this.errorHandler.handleErrors(err)
      });
  }

}