import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tokenNotExpired } from 'angular2-jwt';


import { Constants } from './../constants';

@Injectable()
export class UniversityService {
  authToken: any;
  University: any;

  constructor(private http: HttpClient, public constants: Constants) { }

  //get All Universities
  getUniversities() {
    let timeStamp = +new Date();
    let headers = this.constants.getHeaders();
    return this.http.get(this.constants.serveruniversityGetAll + '?tsp=' + timeStamp, { headers: headers })
      
  }

  //get University By Id
  getUniversityById(id, tokenFlag) {
    let timeStamp = +new Date();
    let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
    return this.http.get(this.constants.serveruniversityGetById + '/' + id + '?tsp=' + timeStamp, { headers: headers })
      
  }

  //get All Available Book Count University Wise.
  //Create By Arpit
  getUniversityWiseCount() {
    let timeStamp = +new Date();
    let headers = this.constants.getHeaders();
    return this.http.get(this.constants.serverGetUniversityWiseCount + '?tsp=' + timeStamp, { headers: headers })
      
  }

  //put university
  putUniversity(University, tokenFlag) {
    let timeStamp = +new Date();
    let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
    return this.http.put(this.constants.serveruniversityPut + '?tsp=' + timeStamp, University, { headers: headers })
      
  }

  //delete university
  deleteUniversity(id, tokenFlag) {
    let timeStamp = +new Date();
    let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
    return this.http.delete(this.constants.serveruniversityDelete + '/' + id + '?tsp=' + timeStamp, { headers: headers })
      
  }

  //bulk delete
  bulkDelete(deleteid, tokenFlag) {
    let timeStamp = +new Date();
    var headers = this.constants.getHeadersWithAuthorization(tokenFlag);
    return this.http.post(this.constants.serveruniversityBulkDelete + '?tsp=' + timeStamp, deleteid, { headers: headers })
      
  }
}
