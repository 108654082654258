import { AuthAdminService } from './../services/authadmin.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

import { Constants } from './../constants';

@Injectable()
export class AuthAdminGuard implements CanActivate {
    constructor(private authService: AuthAdminService,
        private router: Router,
        public constants: Constants) {
    }

    // check whether the user is logged in or not
    canActivate() {
        if (this.authService.loggedIn()) {
            return true;
        } else {
            this.router.navigate([this.constants.clientLogin]);
            return false;
        }
    }
}
