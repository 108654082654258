
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';

import { AuthUserService } from './../../../services/authuser.service';
import { Constants } from './../../../constants';
import { HandleError } from './../../../error';
import { NotificationService } from './../../../services/notification.service';

@Component({
  selector: 'app-forgetpassword',
  templateUrl: './forgetpassword.component.html',
  styleUrls: ['./forgetpassword.component.css']
})
export class FrontForgetpasswordComponent implements OnInit {
  email: String;
  disableButton: Boolean = false;
  forgetPasswordForm: FormGroup;
  submitButtonText: string = this.constants.ResetMyPassword;
  constructor(private authService: AuthUserService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public constants: Constants,
    private errorHandler: HandleError,
    private notificationService: NotificationService,
    fb: FormBuilder
  ) {
    this.forgetPasswordForm = fb.group({
      'email': [null, Validators.compose([Validators.required, Validators.email])]
    })
  }

  ngOnInit() {
  }

  onSubmit() {
    this.disableButton = true;
    const user = {
      email: this.email
    }
    this.authService.forgetPassword(user).subscribe((data:any) => {
      if (data.success == false) {
        this.notificationService.showError(data.msg);
        this.disableButton = false;
      }
      else {
        this.notificationService.showSuccess(data.msg);
        this.router.navigate([this.constants.clientUrlSignIn]);
      }
    },
      err => {
        this.notificationService.showError(this.constants.errorOccur);
        this.disableButton = false;
        this.errorHandler.handleErrors(err)
      });
  }

}