import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from './../../../constants';
import { NotificationService } from './../../../services/notification.service';
import { AuthUserService } from './../../../services/authuser.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-detail',
  templateUrl: './view-detail.component.html',
  styleUrls: ['./view-detail.component.css']
})
export class ViewDetailComponent implements OnInit {
  public repoUrl;
  showFacebook: Boolean = false;
  showLinkedIn: Boolean = false;
  showGooglePlus: Boolean = false;
  constructor(
    private authuserService: AuthUserService,
    private notificationService: NotificationService,
    public constants: Constants,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.repoUrl = window.location.href;
  }

  ngOnInit() {    
  }

  SendRequest(flag) {
    var infoLinkId;
    var id;
    var cid;
    this.route.queryParams
      .subscribe(params => {
        infoLinkId = params['searchKey'];
        id = params['id'];
        cid = params['cid'];
        if (cid != undefined && id == undefined) {
          if (flag) {
            this.router.navigate([this.constants.clientUrlUserSeekingRequest], { queryParams: { flag: flag, searchKey: infoLinkId, cid: cid } });
          }
          else {
            this.router.navigate([this.constants.clientUrlUserContributeRequest], { queryParams: { flag: flag, searchKey: infoLinkId, cid: cid } });
          }
        } else {
          if (flag) {
            this.router.navigate([this.constants.clientUrlUserSeekingRequest], { queryParams: { flag: flag, searchKey: infoLinkId, id: id } });
          }
          else {
            this.router.navigate([this.constants.clientUrlUserContributeRequest], { queryParams: { flag: flag, searchKey: infoLinkId, id: id } });
          }
        }
      });
  }
}