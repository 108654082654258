import { Angular2SocialLoginModule } from './../../../services/angular2SocialLoginModule';
import { Angular2SocialLoginAuthService } from './../../../services/angular2SocialLoginAuthService';
import { element } from 'protractor';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';

import { UserService } from './../../../services/user.service';
import { AuthUserService } from './../../../services/authuser.service';
import { Constants } from './../../../constants';
import { HandleError } from './../../../error';
import { NotificationService } from './../../../services/notification.service';

function _window(): any {
  return window;
}
//declare let OAuth: any;
let providers = {
  "google": {
    //Old clientId -> "clientId": "694334295673-umn9biti6m82h490laebguufb4t2r8nb.apps.googleusercontent.com"
    "clientId": "280366287536-2q2gpliafu3s9ejm9fooj0udcvbomo68.apps.googleusercontent.com" //280366287536-2q2gpliafu3s9ejm9fooj0udcvbomo68.apps.googleusercontent.com
  },
  "linkedin": {
    //Old clientId -> "clientId": "81p10ekuroob3i"
    "clientId": "86xa25wfg3ndax"
  },
  "facebook": {
    "clientId": "344483542636899",
    "apiVersion": "v2.1"
  }
};

@Component({
  selector: 'app-userlogin',
  templateUrl: './userlogin.component.html',
  styleUrls: ['./userlogin.component.css']
})
export class UserloginComponent implements OnInit, OnDestroy {
  email: String;
  password: String;
  loginForm: FormGroup;
  user: any;
  public socialuser;
  sub: any;
  isemailconfirmed: Boolean;
  isactive: true;
  returnUrl: String;
  submitButtonText: string = this.constants.Signin;
  hideSocialLogin: Boolean = false;
  showFacebook: Boolean = false;
  showLinkedIn: Boolean = false;
  showGooglePlus: Boolean = false;

  linkedInCredentials = {
    clientId: "86xa25wfg3ndax",
    // redirectUrl: "http://localhost:4200/socialLogin"  // comment this line when in production.
    // redirectUrl: "http://192.168.1.206:80/socialLogin"
    redirectUrl: "https://www.rippletoshare.com/socialLogin"
  };
  constructor(private authuserService: AuthUserService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    public constants: Constants,
    private route: ActivatedRoute,
    private errorHandler: HandleError,
    private notificationService: NotificationService,
    private router: Router,
    public _auth: Angular2SocialLoginAuthService,
    fb: FormBuilder) {
    // // let password = new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(15), Validators.pattern("^[a-zA-Z0-9!@#$&()\\-`.+,/\_]+( [a-zA-Z0-9!@#$&()\\-`.+,/\_]+)*$")]);
    this.loginForm = fb.group({
      'email': [null, Validators.compose([Validators.required, Validators.email])],
      'password': [null, Validators.required],
    });

    this.hideSocialLogin = Angular2SocialLoginModule.loadProvidersScripts(providers);
  }

  ngOnInit() {
    const oauthScript = document.createElement('script');
    oauthScript.src = 'https://cdn.rawgit.com/oauth-io/oauth-js/c5af4519/dist/oauth.js';

    document.body.appendChild(oauthScript);
    this.user = this.authuserService.getUserFromStorage()
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || this.constants.clientUserHome;
    // const checkFacebook = ({
    //   url: 'https://www.facebook.com/'
    // })
    // const checkGooglePlus = ({
    //   url: 'https://plus.google.com/'
    // })
    // const checkLinkedin = ({
    //   url: 'https://in.linkedin.com/'
    // })
    // this.authuserService.checkURL(checkFacebook).subscribe((data:any) => {      
    //   this.showFacebook = data.flag;
    // });
    // this.authuserService.checkURL(checkGooglePlus).subscribe((data:any) => {
    //   this.showGooglePlus = data.flag;
    // });
    // this.authuserService.checkURL(checkLinkedin).subscribe((data:any) => {
    //   this.showLinkedIn = data.flag;
    // });
  }

  onSubmit() {
    //Login User.
    const user = {
      email: this.email.trim(),
      password: this.password.trim()
    }

    this.authuserService.authenticateUser(user).subscribe((data:any) => {

      if (data.success) {
        this.authuserService.storeUserData(data.token, data.user);
        this.router.navigateByUrl(this.returnUrl.toString());
        this.notificationService.showSuccess(data.msg);
      }
      else {
        this.notificationService.showError(data.msg);
      }
    },
      err => {
        this.errorHandler.handleErrors(err);
      });
  }

  signIn(provider) {
    this.sub = this._auth.login(provider).subscribe(
      (data) => {    
        if (data == false) {
          this.notificationService.showError(this.constants.socialLoginException + provider);
        } else {
          if (data["name"] != undefined) {
            const user = {
              email: data["email"] != undefined ? data["email"].trim() : '',
              name: data["name"] != undefined ? data["name"].trim() : '',
              university: null,
              provider: provider,
              picture: data["image"] != undefined ? data["image"] : ''
            }
            this.authuserService.externalUserLogin(user).subscribe((userdata:any) => {
              if (userdata.success) {
                this.socialuser = data;
                this.authuserService.storeUserData(userdata.token, userdata.user);
                this.constants.removeCacheData('DashboardCounts', 0);
                window.location.href = this.returnUrl.toString();
              }
              else {
                this.notificationService.showError(userdata.msg);
              }
            },
              err => {
                this.errorHandler.handleErrors(err);
              });
          }
        }
      },
      (err) => {            
        this.notificationService.showError(this.constants.socialLoginException + provider);
      }
    )
  }
  LinkedInLogin(){
    window.location.href = `https://www.linkedin.com/uas/oauth2/authorization?response_type=code&client_id=${
      this.linkedInCredentials.clientId
    }&redirect_uri=${this.linkedInCredentials.redirectUrl}&state=987654321&scope=r_liteprofile%20r_emailaddress%20w_member_social`;
  }
  logout() {
    this._auth.logout().subscribe(
      (data) => { this.socialuser = null; }
    )
  }

  ngOnDestroy() {
    // this.sub.unsubscribe();
  }
}