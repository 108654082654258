import { CacheService } from 'ng2-cache';
import { ConfirmDialogComponent } from './../confirmDialog/confirmDialog.component';
import { DialogService } from 'ng2-bootstrap-modal';
// import { Angular2AdminLTEPage } from './../../../../e2e/app.po';
import { FormGroup, FormControl, Validators, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ModalDirective } from 'ngx-bootstrap/modal';
import * as moment from 'moment';

import { UserService } from './../../../services/user.service';
import { BookRequestService } from './../../../services/bookrequest.service';
import { AuthUserService } from './../../../services/authuser.service';
import { Constants } from '../../../constants';
import { HandleError } from './../../../error';
import { NotificationService } from './../../../services/notification.service';

class BookRequests {
    _id: any;
    bookid: any;
    userid: any;
    messagefromuser: any;
    requesttype: any;
}
@Component({
    selector: 'app-userrequest',
    templateUrl: './userrequest.component.html',
    styleUrls: ['./userrequest.component.scss']
})



export class UserRequestComponent implements OnInit {
    @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;
    deleteAll: Boolean;
    showdelete: Boolean;
    requestId: String[] = [];
    tokenId: any;
    userid: any;

    dtOptions = this.constants.dataTableSettings && {
        columnDefs: [
            {
                "targets": [0, 1, 7],
                "orderable": false,
            },
            {
                "targets": 6,
                type: 'num',
                render: function (data, type) {

                    if (type == 'sort') {
                        return Date.parse(data).valueOf();
                    }
                    return data;
                }
            }
        ],
        order: [6, 'desc'],
        language: {
            "emptyTable": "No data available"
        },
        fnDrawCallback: function () {
            if ((document.getElementById("bookrequestdata_paginate")).click != null) {
                var table = $("#bookdata").DataTable();
                var rowchecked = 0;
                var ValueById = document.getElementsByName("sizecb")
                for (var index = 0; index < ValueById.length; index++) {
                    if ($(ValueById[index]).prop("checked") == true) {
                        //check all conter var ++
                        rowchecked = rowchecked + 1;
                    }
                }
                if (ValueById.length == rowchecked) {
                    $("#checkAll").prop('checked', true);
                    $('.model').css('display', 'block');
                } else {
                    $("#checkAll").prop('checked', false);
                }
                if (rowchecked == 0) {
                    $("#checkAll").prop('checked', false);
                    $('.model').css('display', 'none');
                }
                else if (rowchecked > 0) {
                    $('.model').css('display', 'block');
                }
            }
        }
    };

    constructor(private authuserService: AuthUserService,
        private bookrequest: BookRequestService,
        private userService: UserService,
        public constants: Constants,
        private activatedRoute: ActivatedRoute,
        private errorHandler: HandleError,
        private notificationService: NotificationService,
        private router: Router,
        private _cacheService: CacheService,
        fb: FormBuilder,
        private dialogService: DialogService
    ) {

    }

    bookRequests: BookRequests[] = [];
    bookContributed: BookRequests[] = [];
    bookRequested: BookRequests[] = [];
    dtTrigger: Subject<BookRequests> = new Subject<BookRequests>();

    ngOnInit() {
        this.bindGrid();
    }

    formatDate(requestedate) {
        requestedate = moment(requestedate).format('D/MMM/YYYY HH:mm:ss');
        return (requestedate);
    };
    showConfirm(id) {

        let disposable = this.dialogService.addDialog(ConfirmDialogComponent, {
            title: "Delete Confirmation",//this.constants.deleteConfirmationTitle"",
            message: "Are you sure you want to delete the selected record?",//this.constants.deleteConfirmation
        })
            .subscribe((isConfirmed) => {
                //Dialog result
                if (isConfirmed) {
                    this.bookrequest.deletebookrequest(id, false).subscribe((data: any) => {
                        if (data.success) {
                            this.constants.removeCacheData('DashboardCounts', 0);
                            // Change by Arpit
                            this.constants.removeCacheData('DashboardBookList', 0);
                            this.constants.removeCacheData('ListOfNeededBook', 0);
                            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                                // Destroy the table first
                                dtInstance.destroy();
                                // Call the dtTrigger to rerender again
                                this.bindGrid();
                            });
                            this.notificationService.showSuccess(data.msg);
                        }
                        else {
                            this.notificationService.showError(data.msg);
                        }
                    },
                        err => {
                            if (err.status != null) {
                                if (err.status == 401) {
                                    this.router.navigate([this.constants.clientUrlUnauthorized]);
                                }
                                else {
                                    this.router.navigate([this.constants.clientUrlNotFound]);
                                }
                            }
                        });
                }
            });

        //We can close dialog calling disposable.unsubscribe();
        //If dialog was not closed manually close it by timeout
        setTimeout(() => {
            disposable.unsubscribe();
        }, 10000);
    }
    bindGrid() {
        this.tokenId = this.authuserService.getUserFromStorage();
        this.userid = this.tokenId.id;
        if (this.userid != null) {
            this.bookrequest.getbookrquestByUserId(this.userid, false).subscribe((data: any) => {
                this.bookRequests = data.bookrequests;
                this.bookRequested = this.bookRequests.filter(item => item.requesttype == true);
                this.dtTrigger.next();
            },
                err => {
                    if (err.status != null) {
                        if (err.status == 401) {
                            this.router.navigate([this.constants.clientUrlUnauthorized]);
                        }
                        else {
                            this.router.navigate([this.constants.clientUrlNotFound]);
                        }
                    }
                });
        }

    }

    checkAll(ev) {
        var elementArray = document.getElementsByName("sizecb");
        for (var index = 0; index < elementArray.length; index++) {
            var checkBox = <HTMLInputElement>elementArray[index]
            if (this.requestId.length == 0) {
                this.requestId.push(elementArray[index].id);
            }
            else {
                var resultArray = this.requestId.filter(data => data == elementArray[index].id);
                if (resultArray.length == 0) {
                    this.requestId.push(elementArray[index].id);
                } else {
                    var arrayindex = this.requestId.indexOf(elementArray[index].id);
                    this.requestId.splice(arrayindex, 1);
                }
            }
            checkBox.checked = ev.target.checked
        }
        if (this.requestId.length > 0) {
            // this.showdelete = true;
            $('.model').css('display', 'block');
        }
        else {
            // this.showdelete = false;
            $('.model').css('display', 'none');
        }
        if (!ev.target.checked) {
            // this.requestId = [];
            $('.model').css('display', 'none');
        }
    }

    recordChecked(ev) {
        var id = ev.currentTarget.id;
        var rowCount = 0;
        var elementArray = document.getElementsByName("sizecb");
        var resultArray = this.requestId.filter(data => data == id);
        var elementId = document.getElementById(id);
        if (resultArray != null && resultArray.length != 0) {
            var index = this.requestId.findIndex(x => x === id)
            this.requestId.splice(index, 1);
            // this.deleteAll = false;
            $("#checkAll").prop('checked', false);
        }
        else {
            this.requestId.push(id);
        }
        if (this.requestId.length > 0) {
            // this.showdelete = true;
            $('.model').css('display', 'block');
        }
        else {
            // this.showdelete = false;
            $('.model').css('display', 'none');
        }
        for (var index = 0; index < elementArray.length; index++) {
            var checkBox = <HTMLInputElement>elementArray[index]
            if (checkBox.checked == false) {
                rowCount = rowCount + 1;
            }
        }
        var selectedrow = elementArray.length - rowCount;
        if (selectedrow == elementArray.length) {
            // this.deleteAll = true;
            $("#checkAll").prop('checked', true);
        }
    }

    bulkDelete() {
        const deleteid = {
            deleteid: this.requestId
        };
        let disposable = this.dialogService.addDialog(ConfirmDialogComponent, {
            title: "Delete Confirmation",//this.constants.deleteConfirmationTitle"",
            message: "Are you sure you want to delete the selected record?",//this.constants.deleteConfirmation
        })
            .subscribe((isConfirmed) => {
                //Dialog result
                if (isConfirmed) {
                    this.bookrequest.bulkDelete(deleteid, false).subscribe((data: any) => {
                        if (data.success) {
                            this.constants.removeCacheData('DashboardCounts', 0);
                            // Change By Arpit
                            this.constants.removeCacheData('DashboardBookList', 0);
                            this.constants.removeCacheData('ListOfNeededBook', 0);
                            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                                // Destroy the table first
                                dtInstance.destroy();
                                // Call the dtTrigger to rerender again
                                this.bindGrid();
                                this.requestId = [];
                            });
                            this.notificationService.showSuccess(data.msg);

                        }
                        else {
                            this.bindGrid();
                            this.notificationService.showError(data.msg);
                        }
                    });
                }
            });

        //We can close dialog calling disposable.unsubscribe();
        //If dialog was not closed manually close it by timeout
        setTimeout(() => {
            disposable.unsubscribe();
        }, 10000);
    }
}