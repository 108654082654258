import { ActivatedRoute, Params, Router } from '@angular/router';
import { Component, EventEmitter, Injectable, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { AdminComponent } from './../../admin.component';
import { AdminService } from './../../../services/admin.service';
import { AuthAdminService } from './../../../services/authadmin.service';
import { Constants } from '../../../constants';
import { CustomValidators } from 'ng2-validation';
import { HandleError } from './../../../error';
import { NotificationService } from './../../../services/notification.service';

class Admins {
  _id: any;
  name: String;
  email: String;
  password: String;
  conformpassword: String;
  contactno: String;
  isdelete: boolean;
  isactive: boolean;
  emailtoken: String;
  emailtokenexpiretime: String;
}

@Component({
  selector: 'app-addAdmin',
  templateUrl: './addAdmin.component.html',
  styleUrls: ['./addAdmin.component.scss']
})

export class AddAdminComponent implements OnInit {
  adminForm: FormGroup;
  currentUser: any;
  _id: any;
  adminid: any;
  name: String;
  email: String;
  password: String;
  confirmPassword: String;
  contactno: String;
  isdelete: boolean;
  isactive: boolean;
  emailtoken: String;
  emailtokenexpiretime: String;
  submitButtonText: string;
  pageHeader: string = "Add Admin";
  hide: Boolean = false;
  disableButton: Boolean = false;
  constructor(private activatedRoute: ActivatedRoute,
    private adminService: AdminService,
    private authAdminService: AuthAdminService,
    public constants: Constants,
    private errorHandler: HandleError,
    private notificationService: NotificationService,
    private router: Router,
    fb: FormBuilder) {
    let password = new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(15), Validators.pattern("^[a-zA-Z0-9!@#$&()\\-`.+,/\_]+( [a-zA-Z0-9!@#$&()\\-`.+,/\_]+)*$")]);
    let confirmpassword = new FormControl('', [Validators.required, CustomValidators.equalTo(password)]);
    this.currentUser = this.authAdminService.getAdminFromStorage();
    this.activatedRoute.params.subscribe((params: Params) => {
      this.adminid = params['id'];
      if (this.adminid != null) {
        this.adminForm = fb.group({
          'adminid': [null, Validators.nullValidator],
          'name': [null, Validators.compose([Validators.required, Validators.maxLength(50),])],
          'contactno': [null, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern('[0-9]*')])],
          'email': [null, Validators.compose([Validators.required, Validators.email])],
          'isactive': [null, Validators.nullValidator],
          'password': [null, Validators.nullValidator],
          'confirmpassword': [null, Validators.nullValidator],
        })
      }
      else {
        this.adminForm = fb.group({
          'adminid': [null, Validators.nullValidator],
          'name': [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
          'contactno': [null, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern('[0-9]*')])],
          'isactive': [null, Validators.nullValidator],
          'email': [null, Validators.compose([Validators.required, Validators.email])],
          password: password,
          confirmpassword: confirmpassword
        })
      }
    },
      err => {
        this.errorHandler.handleErrors(err)
      });

  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.adminid = params['id'];
      if (this.adminid != null) {
        this.hide = true;
        this.adminService.getAdminById(this.adminid, true).subscribe((data:any) => {
          this.name = data.admin.name;
          this.email = data.admin.email;
          this.adminid = data.admin._id;
          this.contactno = data.admin.contactno;
          this.isactive = data.admin.isactive;
          this.password = data.admin.password;
          this.confirmPassword = data.admin.confirmPassword;
          this.submitButtonText = this.constants.Update;
          this.hide = false;
        },
          err => {
            if (err.status == 401) {
              this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
            }
            else {
              this.router.navigate([this.constants.clientUrlNotFound]);
            }
          });
      } else {
        this.submitButtonText = this.constants.Save;
      }
    },
      err => {
        if (err.status == 401) {
          this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
        }
        else {
          this.router.navigate([this.constants.clientUrlNotFound]);
        }
      });
  }

  onSubmit() {
    this.disableButton = true;
    const admin = {
      _id: this.adminid,
      name: this.name.trim(),
      email: this.email.trim(),
      password: this.password.trim(),
      confirmPassword: this.confirmPassword,
      contactno: this.contactno.trim(),
      isactive: this.isactive ? true : false,
      isdelete: this.isdelete,
      emailtoken: this.emailtoken,
      emailtokenexpiretime: this.emailtokenexpiretime
    }

    this.adminService.putAdmin(admin, true).subscribe((data:any) => {
      if (data.success) {
        var adminDetails = this.authAdminService.getAdminFromStorage();
        if (adminDetails.id == this.adminid) {
          var admin = {
            id: this.adminid,
            name: this.name.trim(),
            contactno: this.contactno.trim(),
            email: this.email.trim()
          }
          this.authAdminService.storeUserData(this.authAdminService.loadToken(), admin);
        }
        this.router.navigate([this.constants.clientUrlAdminList]);
        this.notificationService.showSuccess(data.msg);
      }
      else {
        this.notificationService.showError(data.msg);
        this.disableButton = false;
      }
    },
      err => {
        if (err.status == 401) {
          this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
        }
        else {
          this.router.navigate([this.constants.clientUrlNotFound]);
        }
      })
  }


  checkCurrentUser(_id) {
    if (_id == this.currentUser.id) {
      return false;
    }
    return true;
  }
}