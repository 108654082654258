import { AuthUserService } from './../../../services/authuser.service';
import { CustomValidators } from 'ng2-validation';
import { Component, Injectable, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
/* For Validation */
import { FormGroup, FormControl, Validators, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { Constants } from './../../../constants';
import { NotificationService } from './../../../services/notification.service';
import { UserAddressService } from './../../../services/userAddress.service';


class Users {
  _id: any;
  title: String;
  userid: any;
  address: String;
}
@Component({
  selector: 'app-addUserAddress',
  templateUrl: './addUserAddress.component.html',
  styleUrls: ['./addUserAddress.component.css']
})
export class AddUserAddressComponent implements OnInit {

  userid: any;
  addressid: any;
  user: any;
  title: String;
  address: String;
  isdelete: Boolean;
  isactive: Boolean;
  submitButtonText: string;
  userForm: FormGroup;
  hide: Boolean = false;
  disableButton: Boolean = false;
  constructor(
    private authuserService: AuthUserService,
    private activatedRoute: ActivatedRoute,
    public constants: Constants,
    private notificationService: NotificationService,
    private router: Router,
    // private route: ActivatedRoute,
    private userAddressService: UserAddressService,
    fb: FormBuilder,
  ) {
    let password = new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(15), Validators.pattern("^[a-zA-Z0-9!@#$&()\\-`.+,/\_]+( [a-zA-Z0-9!@#$&()\\-`.+,/\_]+)*$")]);
    let confirmpassword = new FormControl('', CustomValidators.equalTo(password));

    this.userForm = fb.group({
      'userid': [null, Validators.nullValidator],
      'title': [null, Validators.compose([Validators.required, Validators.maxLength(50),])],
      'address': [null, Validators.required]
    })
  }
  ngOnInit() {
    //To pre-fill add/edit user address data
       this.activatedRoute.params.subscribe(params => {
        // Defaults to 0 if no query param provided.
        if (params["id"]) {     
          this.userid = params["id"];
        }
      });
    this.activatedRoute.params.subscribe((params: Params) => {
      this.addressid = params['aid'];
      if (this.addressid != null) {
        this.hide = true;
        this.userAddressService.getById(this.addressid, true).subscribe((data:any) => {
          this.title = data.userAddress.title
          this.address = data.userAddress.address
           this.userid = data.userAddress.userid[0]
        },
          (err) => {
            if (err.status == 401)
              this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
          }
        );
        this.submitButtonText = this.constants.Update;
        this.hide = false;
      }
      else {
        this.submitButtonText = this.constants.Save;
      }
    });
  }

  onSubmit() {
    this.disableButton = true;
    const user = {
      addressid: this.addressid,
      userid: this.userid,
      title: this.title.trim(),
      address: this.address.trim(),
    }
    //upsert users    
    this.userAddressService.addAddress(user, true).subscribe((data:any) => {
      if (data.success) {
        this.router.navigate([this.constants.clientUrlUserUpdate, this.userid]);
        this.notificationService.showSuccess(data.msg);
      }
      else {
        this.notificationService.showError(data.msg);
        this.disableButton = false;
      }
    }, err => {
      if (err.status == 401) {
        this.router.navigate([this.constants.clientUrlUnauthorized]);
      }
      else {
        this.router.navigate([this.constants.clientUrlNotFound]);
      }
    });
  }
}