import { NotificationService } from './../../../services/notification.service';
import { ConfirmDialogComponent } from './../confirmDialog/confirmDialog.component';
import { DialogService } from 'ng2-bootstrap-modal';
import { BookRequestService } from './../../../services/bookrequest.service';
import { Constants } from './../../../constants';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-request-details',
  templateUrl: './view-request-details.component.html',
  styleUrls: ['./view-request-details.component.css']
})
export class ViewRequestDetailsComponent implements OnInit {
  requestId: any;
  userName: String;
  title: String;
  subtitle: String;
  description: String;
  categoryid: String;
  author: String;
  publisher: String;
  isbnnumber: String;
  imageurl: any;
  imageurlPath: String;
  imagename: string;
  requesttype: Boolean;
  requeststatus: String;
  requestdate: Date;
  istransactioncomplete: Boolean;
  constructor(
    private activatedRoute: ActivatedRoute,
    public constants: Constants,
    private router: Router,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private bookrequestService: BookRequestService,
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      let requestId = params['id'];
      if (requestId != null) {
        this.bookrequestService.getbookrquestById(requestId, false).subscribe((data:any) => {
          if (data.success) {
            this.requestId = data.bookrequests[0]._id
            this.userName = data.bookrequests[0].users[0].name
            this.requesttype = data.bookrequests[0].requesttype
            this.requestdate = data.bookrequests[0].requestdate
            this.requeststatus = data.bookrequests[0].bookrequesthistory
            this.title = data.bookrequests[0].bookid != '' ? data.bookrequests[0].books[0].title : data.bookrequests[0].bookname
            this.subtitle = data.bookrequests[0].bookid != '' ? data.bookrequests[0].books[0].subtitle : this.constants.notAvailable
            this.author = data.bookrequests[0].bookid != '' ? data.bookrequests[0].books[0].author : this.constants.notAvailable
            this.publisher = data.bookrequests[0].bookid != '' ? data.bookrequests[0].books[0].publisher : this.constants.notAvailable
            this.imageurl = data.bookrequests[0].bookid != '' ? data.bookrequests[0].books[0].imageurl : ''
            this.categoryid = data.bookrequests[0].bookid != '' ? data.bookrequests[0].books[0].categoryid : this.constants.notAvailable
            this.istransactioncomplete = data.bookrequests[0].istransactioncomplete
          }
        },
          err => {
            if (err.status == 401) {
              this.router.navigate([this.constants.clientUrlUnauthorized]);
            }
            else {
              this.router.navigate([this.constants.clientUrlNotFound]);
            }
          });
      }
    });
  }

  showConfirm(id) {

    let disposable = this.dialogService.addDialog(ConfirmDialogComponent, {
      title: "Delete Confirmation",//this.constants.deleteConfirmationTitle"",
      message: "Are you sure you want to delete the selected record?",//this.constants.deleteConfirmation
    })
      .subscribe((isConfirmed) => {
        //Dialog result
        if (isConfirmed) {
          this.bookrequestService.deletebookrequest(id, false).subscribe((data:any) => {
              if (data.success) {
              // Change by Arpit
              this.constants.removeCacheData('DashboardBookList', 0);
              this.constants.removeCacheData('ListOfNeededBook', 0);
              if (this.requesttype == true) {
                this.router.navigate([this.constants.clientUrlUserRequests]);
              } else {
                this.router.navigate([this.constants.clientUrlUserDonations]);
              }
              this.notificationService.showSuccess(data.msg);
            } else {
              this.notificationService.showError(data.msg);
            }
          },
            err => {
              if (err != null) {
                if (err.status == 401) {
                  this.router.navigate([this.constants.clientUrlUnauthorized]);
                }
                else {
                  this.router.navigate([this.constants.clientUrlNotFound]);
                }
              }
            });

        }
      });

    //We can close dialog calling disposable.unsubscribe();
    //If dialog was not closed manually close it by timeout
    setTimeout(() => {
      disposable.unsubscribe();
    }, 10000);
  }

}