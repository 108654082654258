import { DropdownService } from './../../../services/dropdown.service';
import { BookRequestComponent } from './../../../admin/components/book-request/book-request.component';
import { Title } from '@angular/platform-browser';
import { AuthUserService } from './../../../services/authuser.service';
import { element } from 'protractor';
import { Component, EventEmitter, Injectable, Input, OnInit, Output, ViewChild, ElementRef, Directive, AfterViewInit, AfterViewChecked, HostListener } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DialogService } from "ng2-bootstrap-modal";
import { HttpParams } from '@angular/common/http';

import { Constants } from '../../../constants';
import { DataTableDirective } from 'angular-datatables';
import { NotificationService } from './../../../services/notification.service';
import { BookService } from './../../../services/book.service';
import { MainSearchBarComponent } from "../main-searchBar/main-searchBar.component";

class Books {
    title: String;
    categoryid: String;
    author: String;
    search: String;
    categories: any;
    language: string;
}

@Component({
    selector: 'app-main-searchResult',
    templateUrl: './main-searchResult.component.html',
    styleUrls: ['./main-searchResult.component.css']
})

export class MainSearchResultComponent implements OnInit {
    public repoUrl;
    private _albums = [];
    bodyGridListItems = document.getElementsByClassName('productsItems');
    @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
    viewType: String;
    dtOptions = this.constants.dataTableSettings;
    tblResult: DataTableDirective;
    searchKeyword: String;
    searchKeywordContent: String;
    hide: Boolean = false;
    el: HTMLElement
    strConst: String;
    ShowGrid: Boolean = true;
    infiniteScrollDisabled: Boolean = false;
    showLoader: Boolean = false;
    search: String;
    mobWidth: any;
    // strLanguageArray: string[] = [];
    private _subscription: Subscription;
    constructor(
        private dropdownService: DropdownService,
        public constants: Constants,
        private dialogService: DialogService,
        private notificationService: NotificationService,
        private authuserservice: AuthUserService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private route: ActivatedRoute,
        private bookService: BookService,
        el: ElementRef, ) {
        this.el = el.nativeElement
        this.viewType = "list";
        this.repoUrl = window.location.href;

        //check width of screen for responsive result
        this.mobWidth = (window.screen.width);
    }

    books: Books[] = [];
    allBooks: Books[] = [];
    resultBooks: Books[] = [];
    dtTrigger: Subject<Books> = new Subject<Books>();

    ngOnInit() {
        this.route.queryParams
            .subscribe(params => {
                // Defaults to 0 if no query param provided.
                if (params['search']) {
                    this.searchKeyword = params['search'];
                    this.searchKeywordContent = params['search'];
                    this.searchBooksBy(params['search']);
                } else {
                    this.searchKeyword = "No Keyword Found";
                }
            });
    }

    viewImage(img) {
        var modal = document.getElementById('myModal');
        modal.style.display = "block";
        document.getElementById("img01").setAttribute('src', img);
    }

    closeView() {
        var modal = document.getElementById('myModal');
        modal.style.display = "none";
    }

    onSubmit(id) {
        this.router.navigate([this.constants.clientUrlBookRequest, id]);
    }
    SendRequest(flag, infoLink, title, bookid) {
        var infoLinkId = this.constants.getIdFromInfoLinks(infoLink);

        if (flag) {
            if (bookid == null) {
                this.router.navigate([this.constants.clientUrlUserSeekingRequest], { queryParams: { flag: flag, searchKey: title, id: infoLinkId, } });
            }
            else {
                this.router.navigate([this.constants.clientUrlUserSeekingRequest], { queryParams: { flag: flag, searchKey: title, cid: bookid, } });
            }
        }
        else {
            if (bookid == null) {
                this.router.navigate([this.constants.clientUrlUserContributeRequest], { queryParams: { flag: flag, searchKey: title, id: infoLinkId } });
            }
            else {
                this.router.navigate([this.constants.clientUrlUserContributeRequest], { queryParams: { flag: flag, searchKey: title, cid: bookid } });
            }
        }
    }
    onContribute(id) {
        this.router.navigate([this.constants.clientUrlBookRequest, id], { queryParams: { flag: false } });
    }

    searchBooksBy(search) {
        if (this.dtElement == undefined || this.dtElement.dtInstance == undefined) {
            this.hide = true;
            this.bookService.searchBook(search).subscribe((data:any) => {
                if (data.success) {
                    this.infiniteScrollDisabled = false;
                    this.books = [];
                    this.allBooks = [];
                    if (data.book != null) {
                        this.allBooks = data.book;
                        if (this.allBooks.length < 12) {
                            this.infiniteScrollDisabled = true;
                        }
                        if (this.allBooks.length > 12) {
                            for (var index = 0; index < 12; index++) {
                                this.books.push(this.allBooks[index]);
                            }
                        } else {
                            for (var index = 0; index < this.allBooks.length; index++) {
                                this.books.push(this.allBooks[index]);
                            }
                        }
                        this.resultBooks = this.books;
                        this.dtTrigger.next();
                    }
                    else {
                        this.books = data.book;
                        this.resultBooks = this.books;
                    }
                    this.hide = false;
                }
                this.hide = false;
            });
        }
    }

    getViewDetailsUrl(title, infoLink) {
        var url = window.location.origin + this.constants.clientUrlUserSearchDetail + '?' + "searchKey=" + encodeURIComponent(title) + "&id=" + this.constants.getIdFromInfoLinks(infoLink);

        // fbs_click(t) {
        var u = window.location.origin + this.constants.clientUrlUserSearchDetail + '?' + "searchKey=" + encodeURIComponent(title) + "&id=" + this.constants.getIdFromInfoLinks(infoLink);
        var t = "Share and contribute";
        //window.open('http://www.facebook.com/sharer.php?u=' + encodeURIComponent(u) + '&t=' + encodeURIComponent(t), 'sharer', 'toolbar=0,status=0,width=626,height=436');
        console.log('http://www.facebook.com/sharer.php?u=' + encodeURIComponent(u) + '&t=' + encodeURIComponent(t));
        return false;
    }

    onGrid(ShowGrid) {
        this.ShowGrid = ShowGrid;
    }

    onScroll() {
        if (this.books.length == this.allBooks.length) {
            this.infiniteScrollDisabled = true;
            this.showLoader = false;
        } else {
            this.showLoader = true;
            setTimeout(() => {
                var startIndex = this.books.length;
                var endIndex = this.books.length + 12;
                if (endIndex > this.allBooks.length) {
                    endIndex = endIndex - (endIndex - (this.allBooks.length))
                }
                for (var index = startIndex; index < endIndex; index++) {
                    this.books.push(this.allBooks[index]);
                }
                this.resultBooks = this.books;
                this.showLoader = false;
            }, 3000);
        }
    }

    searchBook(searchKey) {
        this.books = this.resultBooks;
        if (searchKey.trim() != '') {            
            this.books = this.books.filter(data => {
                let searchTearmUpperCase = searchKey;
                let mediNameUpperCase = data.title.toUpperCase();
                return mediNameUpperCase.includes(searchTearmUpperCase);
            })
        }
    }
}
