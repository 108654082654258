import { Constants } from './../../../constants';
import { OurAssociatesService } from './../../../services/our-associates.service';
import { Component, OnInit } from "@angular/core";
import { Associates } from '../../../modal/ourAssociates';
import * as _ from 'lodash';

@Component({
  selector: "app-ourAssociateList",
  templateUrl: "./ourAssociateList.component.html",
  styleUrls: ["./ourAssociateList.component.scss"]
})

export class OurAssociateListComponent implements OnInit {
  associates: Associates[] = [];
  public search: any;
  private _listOfAssociates;
  mobWidth: any;
  constructor(
    public constants: Constants,
    private ourAssociatesService: OurAssociatesService
  ) {
  }

  ngOnInit() {
    this.ourAssociatesService.getAssociates(true).subscribe((data: any) => {
      if (data !== null) {
        var associatesDataFormat = _.each(data.associates,item =>item.serialNumber = parseInt(item.serialNumber));
        var dataOrder = _.orderBy(associatesDataFormat, ['serialNumber', 'companyName'], ['asc', 'asc']);
        this.associates = dataOrder as any;
        this._listOfAssociates = data.associates;
      }
    });
  }

  searchAssociate(searchKey) {
    this.associates = this._listOfAssociates;
    if (searchKey.trim() != '') {

      this.associates = this.associates.filter(data => {
        let searchTearmUpperCase = searchKey.toUpperCase();
        let mediNameUpperCase = data.companyName.toUpperCase();
        return mediNameUpperCase.includes(searchTearmUpperCase);
      })
    }
  }
}