import { Constants } from './../../../constants';
import { Component, Input, OnInit, OnChanges, AfterViewInit } from '@angular/core';
import { BreadcrumbService } from 'ng2-breadcrumb/app/components/breadcrumbService';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AuthAdminService } from "services/authadmin.service";
import { map, filter, mergeMap } from 'rxjs/operators'; 

@Component({
  selector: 'app-headerbreadcrumb',
  template: `
  <div class="">  
  <ul class="breadcrumb al-breadcrumb pull-right">
  <li *ngFor="let url of _urls; let last = last" [ngClass]="{'breadcrumb-item': useBootstrap, 'active': last}"> <!-- disable link of last item -->
                <a role="button" class="text-danger" *ngIf="!last && url == prefix" (click)="navigateTo('/')">{{url}}</a>
                <a role="button" class="text-primary" *ngIf="!last && url != prefix" (click)="navigateTo(url)">{{friendlyName(url)}}</a>
                <span *ngIf="last">{{friendlyName(url)}}</span>
                <span *ngIf="last && url == prefix">{{friendlyName('/')}}</span>
  </li>
  </ul>
</div>

    `,
  styleUrls: ['./headerbreadcrumb.component.scss']
})
export class HeaderbreadcrumbComponent implements AfterViewInit {
  @Input() useBootstrap: boolean = true;
  @Input() prefix: string = '';

  public _urls: string[];
  public _routerSubscription: any;
  public title: string = '';
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    public constants: Constants,
    private authAdminService: AuthAdminService,
    private breadcrumbService: BreadcrumbService
  ) {
    this._urls = new Array();

    if (this.prefix.length > 0) {
      this._urls.unshift(this.prefix);
    }
// code for fix the issue - breadcrumb is not working on page refresh/reload 
    if (this.router.navigated) {
      this._urls.length = 0; //Fastest way to clear out array
      this.generateBreadcrumbTrail(this.router.routerState.snapshot.url);
  }
//end
    this._routerSubscription = this.router.events.subscribe((navigationEnd: NavigationEnd) => {

      if (navigationEnd instanceof NavigationEnd) {
        this._urls.length = 0; //Fastest way to clear out array
        this.generateBreadcrumbTrail(navigationEnd.urlAfterRedirects ? navigationEnd.urlAfterRedirects : navigationEnd.url);
         this.constants.pageHeader = this.breadcrumbService.getFriendlyNameForRoute(this._urls[this._urls.length - 1]);
      }
     

    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data))
      .subscribe((event) => {
        this.titleService.setTitle(event['title'] + ' | ' + this.constants.applicationName);
        this.title = event['title'];
        var adminDetails = this.authAdminService.getAdminFromStorage();
      });
  }

  ngAfterViewInit(): void {



  }

  ngOnChanges(changes: any): void {
    if (!this._urls) {
      return;
    }

    this._urls.length = 0;
    this.generateBreadcrumbTrail(this.router.url);
  }

  generateBreadcrumbTrail(url: string): void {
    if (!this.breadcrumbService.isRouteHidden(url)) {
      //Add url to beginning of array (since the url is being recursively broken down from full url to its parent)
      this._urls.unshift(url);
    }
    //console.log("url", url.substr(0, url.lastIndexOf('/')));
    if (url.lastIndexOf('/') > 0) {
      this.generateBreadcrumbTrail(url.substr(0, url.lastIndexOf('/'))); //Find last '/' and add everything before it as a parent route
    } else if (this.prefix.length > 0) {
      this._urls.unshift(this.prefix);
    }
  }

  navigateTo(url: string): void {
    this.router.navigateByUrl(url);
  }

  friendlyName(url: string): string {
      if(url.includes('?')){
     var rewriteURL = url.split('?') as any;
     url = rewriteURL[0]
    }
    return !url ? '' : this.breadcrumbService.getFriendlyNameForRoute(url);
  }

  ngOnDestroy(): void {
    this._routerSubscription.unsubscribe();
  }
}
