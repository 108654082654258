
import { DropdownService } from './../../../services/dropdown.service';

import { CustomValidators } from 'ng2-validation';
import { Component, Injectable, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
/* For Validation */
import { FormGroup, FormControl, Validators, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { Constants } from './../../../constants';
import { NotificationService } from './../../../services/notification.service';
import { UserService } from './../../../services/user.service';

class Users {
  _id: any;
  name: String;
  email: String;
  password: String;
  university: String;
  contactnumber: String;
}
class Universities {
  _id: String;
  universityname: String;
}
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})

export class RegisterComponent implements OnInit {
  userid: any;
  name: String;
  email: String;
  password: String;
  confirmpassword: String;
  university: String;
  contactnumber: String;
  submitButtonText: string = this.constants.Register;
  userForm: FormGroup;
  disableButton: Boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    public constants: Constants,
    private notificationService: NotificationService,
    private router: Router,
    private userService: UserService,
    private dropdownService: DropdownService,
    fb: FormBuilder,
  ) {
    let password = new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(15), Validators.pattern("^[a-zA-Z0-9!@#$&()\\-`'.+,/\_]+( [a-zA-Z0-9!@#$&()\\-`'.+,/\_]+)*$"
    )]);
    let confirmpassword = new FormControl('', CustomValidators.equalTo(password));

    this.userForm = fb.group({
      'name': [null, Validators.compose([Validators.required, Validators.maxLength(50),])],
      'email': [null, Validators.compose([Validators.required, Validators.email])],
      'userid': [null, Validators.nullValidator],
      'university': [null, Validators.nullValidator],
      'contactnumber': [null, Validators.compose([Validators.required, Validators.maxLength(12), Validators.minLength(10), Validators.pattern('[0-9]*')])],
      'password': password,
      'confirmpassword': confirmpassword,
    })


  }
  universities: Universities[] = [];
  ngOnInit() {
    this.getUniversities();
  }

  getUniversities() {
    this.dropdownService.getUniversity().subscribe((data:any) => {
      this.universities = data.universities;
    });
  }

  onSubmit() {
    this.disableButton = true;
    const user = {
      userId: this.userid,
      name: this.name.trim(),
      email: this.email.trim(),
      password: this.password.trim(),
      university: this.university != undefined ? this.university : null,
      contactnumber: this.contactnumber,
      isdelete: 0,
      isactive: 0,
      isemailconfirmed: 0,
      isabscondinguser: 0,
    }
    //Register users        
    this.userService.registerUser(user).subscribe((data:any) => {
      if (data.success) {
        this.constants.removeCacheData('DashboardCounts', 0);
        this.notificationService.showSuccess(data.msg);
        this.router.navigate([this.constants.clientUrlSignIn]);
      }
      else {
        this.notificationService.showError(data.msg);
        this.disableButton = false;
      }
    });
  }
}







