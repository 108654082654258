import { Constants } from './constants';
import { AuthAdminService } from './services/authadmin.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class HandleError extends ErrorHandler {
    constructor(private authService: AuthAdminService,
        public constants: Constants,
        private router: Router) {
        super()
    }

    handleErrors(error) {
        var date = new Date();
        var msg = "Error Occur On " + window.location.href.toString() + " Error is " + error + " Date Is " + date.toString();
        this.authService.updateLog({ "msg": msg.toString() }).subscribe(profile => {
        });
        this.router.navigate([this.constants.clientUrlNotFound]);
    }
}