import { CustomValidators } from 'ng2-validation';
import { Component, Injectable, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { Constants } from './../../../constants';
import { ContactService } from '../../../services/contact.service';
import { NotificationService } from './../../../services/notification.service';

class Contact {
  _id: any;
  name: String;
  email: String;
  message: String;
  contactNo: String;
  isdelete: Boolean;
}

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  contactId: any;
  name: String;
  email: String;
  message: String;
  contactNo: String;
  isdelete: Boolean;
  submitButtonText: string = this.constants.SendMessage;
  contactForm: FormGroup;
  disableButton: Boolean = false;

  constructor(
    private contactService: ContactService,
    public constants: Constants,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    fb: FormBuilder,
    private notificationService: NotificationService
  ) {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.contactId = params['id'];
      if (this.contactId == null) {
        this.contactForm = fb.group({
          'contactId': [null, Validators.nullValidator],
          'name': [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
          'email': [null, Validators.compose([Validators.required, Validators.email])],
          'message': [null, Validators.required],
          'contactNo': [null, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern("[0-9]*")])],
        })
      }
    });
  }

  ngOnInit() {
  }

  onSubmit() {
    this.disableButton = true;
    const contact = {
      contactId: this.contactId,
      name: this.name.trim(),
      email: this.email.trim(),
      message: this.message.trim(),
      contactNo: this.contactNo.trim(),
      //isdelete: 0
    }
    this.contactService.putContact(contact).subscribe((data:any) => {
      if (data.success) {
        this.notificationService.showSuccess(data.msg);
        this.contactForm.reset();
      }
      else {
        this.notificationService.showError(data.msg);
        this.disableButton = false;
      }
    });
  }
}