import { Angular2SocialLoginAuthService } from './../../../services/angular2SocialLoginAuthService';
import { UserService } from './../../../services/user.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Component, OnInit, HostListener } from '@angular/core';
import { DialogService } from 'ng2-bootstrap-modal';

import $ from 'jquery/dist/jquery';
import { AuthUserService } from "./../../../services/authuser.service";
import { Constants } from './../../../constants';
import { ConfirmDialogComponent } from './../confirmDialog/confirmDialog.component';
import { NotificationService } from "./../../../services/notification.service";
import { filter, map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.css']
})

export class MainHeaderComponent implements OnInit {
  user: any;
  userid: any;
  username: String = "Guest User";
  email: String;
  picture: String;
  isCollapse: Boolean = false;

  constructor(public authuserService: AuthUserService,
    public constants: Constants,
    private dialogService: DialogService,
    private userService: UserService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private _auth: Angular2SocialLoginAuthService
  ) {
  }

  ngOnInit() {

  }

  onchange() {
    document.getElementById('navbar-collapse').classList.remove('in');
  }

  ngAfterViewInit() {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    //
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data))
      .subscribe((event) => {
        if (this.authuserService.loggedIn() == true) {
          this.user = this.authuserService.getUserFromStorage();
          this.userid = this.user.id;
          this.userService.getUserById(this.userid, false).subscribe((data: any) => {
            if (data.success && data.user != null) {
              this.username = data.user.name;
              this.email = data.user.email;
              this.picture = data.user.picture;
            } else {
              this.notificationService.showError([this.constants.loginProblem]);
            }
          })
        }
      });
  }

  onLogout() {
    let disposable = this.dialogService.addDialog(ConfirmDialogComponent, {
      title: 'Logout',
      message: 'Are you sure you want to logout ?'
    })
      .subscribe((isConfirmed) => {
        //Dialog result
        if (isConfirmed) {
          this.authuserService.logout();
          this.notificationService.showInfo("You are logged out successfully");
          this.router.navigate([this.constants.clientUrlSignIn]);
          this._auth.logout().subscribe(
            (data) => { }
          )
        }
      });

    //We can close dialog calling disposable.unsubscribe();
    //If dialog was not closed manually close it by timeout
    setTimeout(() => {
      disposable.unsubscribe();
    }, 10000);
  }
}