import { ActivatedRoute, Params, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { BookRequestService } from './../../../services/bookrequest.service';
import { BookinstanceService } from './../../../services/bookinstance.service';
import { BookService } from './../../../services/book.service';
import { ConfirmDialogComponent } from './../../../front/components/confirmDialog/confirmDialog.component';
import { Constants } from './../../../constants';
import { DialogService } from 'ng2-bootstrap-modal';
import { DropdownService } from './../../../services/dropdown.service';
import { NotificationService } from './../../../services/notification.service';
import { Title } from '@angular/platform-browser';
import { element } from 'protractor';
import * as moment from 'moment';
import { empty } from 'rxjs';

class remarks {
  bookrequestid: any;
  personname: String;
  address: String;
  contactno: String;
  remarks: String;
  request: String;
  requeststatus: String;
  istransactioncomplete: Boolean;
}

class bookinstance {
  bookinstanceid: any;
  providedbyuser: String;
  instanceno: String;
  isallocated: Boolean;
  isobsolete: Boolean;
  bookid: String;
}

@Component({
  selector: 'app-remarkdetail',
  templateUrl: './remarkdetail.component.html',
  styleUrls: ['./remarkdetail.component.css']
})
export class RemarkdetailComponent implements OnInit {
  requestId: any;
  address: String;
  preferredtime: any;
  preferreddate: any;
  userName: String;
  title: String;
  subtitle: String;
  description: String;
  categoryid: String;
  author: String;
  publisher: String;
  isbnnumber: String;
  imageurl: any;
  imageurlPath: String;
  imagename: string;
  requesttype: Boolean;
  requeststatus: any;
  requeststatusid: String;
  requestdate: Date;
  remarks: String;
  status: String;
  istransactioncomplete: Boolean;
  providedbyuser: any;
  bookid: any;
  bookremark: FormGroup; /**Form Instance */
  isapproved: Boolean;
  submitButtonText: String;
  email: String;
  mobWidth: any;
  isdropbyuser: Boolean;
  hideStatusDetails: Boolean = false;
  hideBookDetails: Boolean = false;
  disableButton: Boolean = false;
  isExistingRTSBook: boolean = false;
  existingInstanceNo: string;
  userid: any;
  instanceNo: string;
  bookInstances: String[] = [];
  bookInstanceLogs: any = [];
  allocatedinstance: string;
  sendemail: boolean;
  constructor(
    public constants: Constants,
    private bookrequestService: BookRequestService,  
    private BookService: BookService,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private bookinstanceservice: BookinstanceService,
    private dropdownservice: DropdownService,
    private router: Router,
    private fb: FormBuilder,
    private dialogService: DialogService,
    private bookInstanceService: BookinstanceService,
  ) {
    this.mobWidth = (window.screen.width);
    this.submitButtonText = this.constants.Update;
    this.bookremark = fb.group({
      'remarks': [null, Validators.required],
      'requeststatus': [null, Validators.required],
      'istransactioncomplete': [null, Validators.nullValidator],
      'allocatedinstance': [null, Validators.nullValidator],
      'sendemail': [null, Validators.nullValidator],
      'existingInstanceNo': [null, Validators.nullValidator]
    })
  }
  ngOnInit() {
    localStorage.setItem('currentURL', this.router.url);
    this.activatedRoute.params.subscribe((params: Params) => {
      this.requestId = params['id'];
      if (this.requestId != null) {
        this.getDataByRequestId(this.requestId);
      }
    });
  }

  formatDate(requestedate) {
    requestedate = moment(requestedate).format('D/MMM/YYYY');
    return (requestedate);
  };

  getDataByRequestId(requestId) {
    this.hideBookDetails = true;
    this.hideStatusDetails = true;
    this.bookrequestService.getbookrquestById(requestId, true).subscribe((data: any) => {
      if (data.success) {
        this.requestId = data.bookrequests[0]._id
        this.providedbyuser = data.bookrequests[0].userid
        this.bookid = data.bookrequests[0].bookid
        this.userName = data.bookrequests[0].users[0].name
        this.email = data.bookrequests[0].users[0].email
        this.requesttype = data.bookrequests[0].requesttype
        this.address = data.bookrequests[0].address
        this.preferredtime = data.bookrequests[0].preferredtime
        this.preferreddate = data.bookrequests[0].preferreddate
        this.requestdate = data.bookrequests[0].requestdate
        this.requeststatus = data.bookrequests[0].bookrequesthistory
        this.title = data.bookrequests[0].bookid != '' ? data.bookrequests[0].books[0].title : data.bookrequests[0].bookname
        this.subtitle = data.bookrequests[0].bookid != '' ? data.bookrequests[0].books[0].subtitle : this.constants.notAvailable
        this.author = data.bookrequests[0].bookid != '' ? data.bookrequests[0].books[0].author : this.constants.notAvailable
        this.publisher = data.bookrequests[0].bookid != '' ? data.bookrequests[0].books[0].publisher : this.constants.notAvailable
        this.imageurl = data.bookrequests[0].bookid != '' ? data.bookrequests[0].books[0].imageurl : this.constants.notAvailable
        this.categoryid = data.bookrequests[0].bookid != '' ? data.bookrequests[0].books[0].categoryid : this.constants.notAvailable
        this.isdropbyuser = data.bookrequests[0].isdropbyuser
        this.istransactioncomplete = data.bookrequests[0].istransactioncomplete
        if (data.bookrequests[0].istransactioncomplete) {
          this.instanceNo = data.bookrequests[0].instanceno ? data.bookrequests[0].instanceno : this.constants.notAvailable;
        }
        this.getStatus(); /*this should be called after get databyrequestid() method */
        this.hideBookDetails = false;
        this.hideStatusDetails = false;
      }
      this.getBookInstances(this.bookid);
    }, err => {
      if (err.status == 401) {
        this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
      }
      else {
        this.router.navigate([this.constants.clientUrlNotFound]);
      }
    });

  }

  bookRequestStatus: String[] = [];

  getStatus() {
    this.bookRequestStatus = [];
    this.dropdownservice.getRequestStatus().subscribe((data: any) => {
      if (data.success) {
        if (this.requesttype || this.requesttype == true) {
          this.bookRequestStatus = data.requeststatus.filter(item => item != this.constants.collected && item != this.constants.Requested);
        }
        else {
          this.bookRequestStatus.push(this.constants.Collected);
        }

        if (this.requeststatus != null) {
          this.bookRequestStatus = this.bookRequestStatus.filter(item => item !== this.requeststatus[0].status);
        }
      }
    });
  }

  onSubmit() {
    this.disableButton = true;
    this.putRemarks();
    this.getStatus();
  }

  putRemarks() {
    const remarks = {
      bookrequestId: this.requestId,
      requestdate: this.requestdate,
      remarks: this.remarks,
      requeststatus: this.requeststatusid,
      providedbyuser: this.providedbyuser,
      bookid: this.bookid,
      requesttype: this.requesttype,
      title: this.title.trim(),
      subtitle: this.subtitle != undefined ? this.subtitle : '',
      description: this.description != undefined ? this.description : '',
      categoryid: this.categoryid != undefined ? this.categoryid : '',
      author: this.author != undefined ? this.author : '',
      publisher: this.publisher != undefined ? this.publisher : '',
      imageurl: this.imageurl != null ? this.imageurl : '',
      email: this.email,
      name: this.userName,
      isdropbyuser: this.isdropbyuser ? true : false,
      instanceno: this.instanceNo,
      allocatedinstance: this.allocatedinstance,
      sendemail: this.sendemail != undefined ? this.sendemail : 'false',
      existingInstanceNo: this.existingInstanceNo,
      
    }

    this.bookrequestService.putremarks(remarks, true).subscribe((data: any) => {
      if (data.success) {debugger;
        this.constants.removeCacheData('DashboardCounts', 0);
        this.constants.removeCacheData('DashboardBookList', 0);
        this.constants.removeCacheData('ListOfNeededBook', 0);
        this.constants.removeCacheData('listOfUniversityWiseAvaliableBoookCount', 0);
        this.bookremark.reset();
        this.getDataByRequestId(this.requestId);
        this.notificationService.showSuccess(data.msg);
        // Change By Arpit for #1820.
        this.requeststatusid = undefined;
      } else {
        this.notificationService.showError(data.msg);

      }
      this.disableButton = false;
    }, err => {
      if (err.status == 401) {
        this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
      }
      else {
        this.router.navigate([this.constants.clientUrlNotFound]);
      }
    });
  }

  goBack() {
    // window.history.go(-1);
    if (this.requesttype) {
      this.router.navigate([this.constants.clientUrlBookRequests]);
    } else {
      this.router.navigate([this.constants.clientUrlBookContribute]);

    }
  }

  //To Delete Book
  showConfirm(id) {
    let disposable = this.dialogService.addDialog(ConfirmDialogComponent, {
      title: "Delete Confirmation",//this.constants.deleteConfirmationTitle"",
      message: "Are you sure you want to delete the selected record?",//this.constants.deleteConfirmation
    })
      .subscribe((isConfirmed) => {
        //Dialog result
        if (isConfirmed) {
          this.bookrequestService.deleteRequestStatusHistory(id, true).subscribe((data: any) => {
            if (data.success) {
              this.notificationService.showSuccess(data.msg);
              this.getDataByRequestId(this.requestId);
            }
            else {
              this.notificationService.showError(data.msg);
            }
          }, err => {
            if (err.status == 401) {
              this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
            }
            else {
              this.router.navigate([this.constants.clientUrlNotFound]);
            }
          });
        }
      });

    //We can close dialog calling disposable.unsubscribe();
    //If dialog was not closed manually close it by timeout
    setTimeout(() => {
      disposable.unsubscribe();
    }, 10000);
  }

  GetInstanceNo(status) {
      if (status === this.constants.collected) {
      this.bookinstanceservice.getInstanceNo(true).subscribe((data: any) => {
        if (data.success) {
          this.instanceNo = data.instanceNo;
        }
        else {
          this.instanceNo = null;
          this.notificationService.showError(data.msg);
        }
      }, err => {
        if (err.status == 401) {
          this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
        }
        else {
          this.router.navigate([this.constants.clientUrlNotFound]);
        }
      });
    }
    else if (status == this.constants.donated) {
      this.getBookInstances(this.bookid);
      this.bookremark = this.fb.group({
        'remarks': [this.remarks, Validators.required],
        'requeststatus': [this.requeststatusid, Validators.required],
        'istransactioncomplete': [this.istransactioncomplete, Validators.nullValidator],
        'allocatedinstance': [this.bookInstances, Validators.required]
      })
    } else {
      this.toggleInstanceNoValidation(false);
    }
  }

  instanceCount;
  getBookInstances(bookid) {
    this.bookinstanceservice.getAvailableBookInstanceByBookId(bookid).subscribe((data: any) => {
      if (data.success) {
        this.bookInstances = data.bookinstances;
        this.instanceCount = this.bookInstances.length;
      }
      else {
        this.instanceNo = null;
        this.notificationService.showError(data.msg);
      }
    });
  }

  toggleInstanceNoValidation(val) {
    this.isExistingRTSBook = val;
    if (this.isExistingRTSBook) {
      this.bookremark = this.fb.group({
        'remarks': [this.remarks, Validators.required],
        'requeststatus': [this.requeststatusid, Validators.required],
        'istransactioncomplete': [this.istransactioncomplete, Validators.nullValidator],
        'allocatedinstance': [this.bookInstances, Validators.nullValidator],
        'existingInstanceNo': [this.existingInstanceNo, Validators.required],
        'sendemail': [null, Validators.nullValidator],
      })
      this.bindBookInstanceLogs();
    }
    else {
      this.existingInstanceNo = '';
      this.bookremark = this.fb.group({
        'remarks': [this.remarks, Validators.required],
        'requeststatus': [this.requeststatusid, Validators.required],
        'istransactioncomplete': [this.istransactioncomplete, Validators.nullValidator],
        'allocatedinstance': [this.bookInstances, Validators.nullValidator],
        'existingInstanceNo': [this.existingInstanceNo, Validators.nullValidator],
        'sendemail': [null, Validators.nullValidator],
      })
    }
  }

  //Created By Arpit for #1785
  bindBookInstanceLogs() { debugger;
    this.BookService.getInstancenoPerticularBook(this.bookid).subscribe((result: any) => {
         if (result !== null) {
           console.log("Result", result);
        this.bookInstanceLogs = result.book[0].instanceno;
        
        this.existingInstanceNo = undefined;
      }
    });
  }

}