import { Router, ActivatedRoute, Params } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';

import { UserService } from './../../../services/user.service';
import { AuthUserService } from './../../../services/authuser.service';
import { Constants } from './../../../constants';
import { HandleError } from './../../../error';
import { NotificationService } from './../../../services/notification.service';

@Component({
  selector: 'app-emailactivation',
  templateUrl: './emailactivation.component.html',
  styleUrls: ['./emailactivation.component.css']
})
export class EmailActivationComponent implements OnInit {
  email: String;
  password: String;
  loginForm: FormGroup;
  tokenId: any;
  isemailconfirmed: Boolean;
  isactive: true;
  submitButtonText: string = this.constants.Signin;
  constructor(private authuserService: AuthUserService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    public constants: Constants,
    private errorHandler: HandleError,
    private notificationService: NotificationService,
    private router: Router,
    fb: FormBuilder) {
   
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.tokenId = params['id'];
      const user = {
        tokenId: this.tokenId,
      }
      if (this.tokenId != null)    
      this.authuserService.emailavtication(user).subscribe((data:any) => {
            if (data.success) {
                this.notificationService.showSuccess(data.msg);
            }
        });
    })
  }  
}