import { BookRequestComponent } from './../book-request/book-request.component';
import { DialogService } from 'ng2-bootstrap-modal';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { AdminService } from './../../../services/admin.service';
import { AuthAdminService } from './../../../services/authadmin.service';
import { ConfirmDialogComponent } from './../confirmDialog/confirmDialog.component';
import { Constants } from '../../../constants';
import { HandleError } from './../../../error';
import { NotificationService } from './../../../services/notification.service';

class Admins {
  _id: any;
  name: String;
  email: String;
  password: String;
  conformpassword: String;
  contactno: String;
  isdelete: boolean;
  isactive: boolean;
  emailtoken: String;
  emailtokenexpiretime: String;
}

@Component({
  selector: 'app-adminuser',
  templateUrl: './adminuser.component.html',
  styleUrls: ['./adminuser.component.scss']
})
export class AdminuserComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;
  deleteAll: Boolean;
  showdelete: Boolean;
  requestId: String[] = [];
  currentUser: any;
  _id: any;
  name: String;
  email: String;
  password: String;
  conformpassword: String;
  contactno: String;
  isdelete: boolean;
  isactive: boolean;
  emailtoken: String;
  emailtokenexpiretime: String;
  userModalHeader: string;
  submitButtonText: string;
  dtOptions = this.constants.dataTableSettings && {
    order: [],
    columnDefs: [
      {
        "targets": [0, 1, 5, 6],
        "orderable": false,
      }],
    fnDrawCallback: function () {
      if ((document.getElementById("admindata_paginate")).click) {
        var table = $("#bookdata").DataTable();
        var rowchecked = 0;
        var ValueById = document.getElementsByName("sizecb")
        for (var index = 0; index < ValueById.length; index++) {
          if ($(ValueById[index]).prop("checked") == true) {
            rowchecked = rowchecked + 1;
          }
        }
        if (ValueById.length == rowchecked) {
          $("#checkAll").prop('checked', true);
          $('.model').css('display', 'block');
        } else {
          $("#checkAll").prop('checked', false);
        }
        if (rowchecked == 0) {
          $("#checkAll").prop('checked', false);
          $('.model').css('display', 'none');
        }
        else if (rowchecked > 0) {
          $('.model').css('display', 'block');
        }
      }
    }
  };
  dtTrigger: Subject<Admins> = new Subject<Admins>();
  confirmTitle = this.constants.deleteConfirmationTitle;
  confirmMsg = this.constants.deleteConfirmation;
  hide: Boolean = false;
  constructor(
    private adminService: AdminService,
    private authAdminService: AuthAdminService,
    public constants: Constants,
    private dialogService: DialogService,
    private errorHandler: HandleError,
    private notificationService: NotificationService,
    private router: Router,
  ) {
    this.currentUser = this.authAdminService.getAdminFromStorage();
    this.confirmTitle = this.constants.deleteConfirmationTitle;
    this.confirmMsg = this.constants.deleteConfirmation;
  }
  admins: Admins[] = [];

  ngOnInit() {
    this.currentUser = this.authAdminService.getAdminFromStorage();
    this.bindAdminUsers();
  }

  //To Delete User
  showConfirm(id, name) {
    let disposable = this.dialogService.addDialog(ConfirmDialogComponent, {
      title: "Delete Confirmation",//this.constants.deleteConfirmationTitle"",
      message: "Are you sure you want to delete the selected record?",//this.constants.deleteConfirmation
    })
      .subscribe((isConfirmed) => {
        //Dialog result
        if (isConfirmed) {
          this.adminService.deleteAdmin(id, true).subscribe((data: any) => {
            if (data.success) {
              this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Destroy the table first
                dtInstance.destroy();
                // Call the dtTrigger to rerender again
                this.bindAdminUsers();
              });
              this.notificationService.showSuccess(data.msg);
            }
            else {
              this.notificationService.showError(data.msg);
            }
          }, err => {
            if (err.status == 401) {
              this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
            }
            else {
              this.router.navigate([this.constants.clientUrlNotFound]);
            }
          });
        }
      },
        err => {
          this.errorHandler.handleErrors(err);
        });

    //We can close dialog calling disposable.unsubscribe();
    //If dialog was not closed manually close it by timeout
    setTimeout(() => {
      disposable.unsubscribe();
    }, 10000);
  }

  bindAdminUsers() {
    this.hide = true;
    this.adminService.getAdmin(true).subscribe((data: any) => {
      this.hide = false;
      this.admins = data.admins;
      this.dtTrigger.next();
    },
      err => {
        if (err.status == 401) {
          this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
        }
        else {
          this.router.navigate([this.constants.clientUrlNotFound]);
        }
      });
  }

  checkCurrentUser(_id) {
    if (_id == this.currentUser.id) {
      return false;
    }
    return true;
  }

  checkAll(ev) {
    var elementArray = document.getElementsByName("sizecb");
    for (var index = 0; index < elementArray.length; index++) {
      var checkBox = <HTMLInputElement>elementArray[index]
      if (this.requestId.length == 0) {
        this.requestId.push(elementArray[index].id);
      }
      else {
        var resultArray = this.requestId.filter(data => data == elementArray[index].id);
        if (resultArray.length == 0) {
          this.requestId.push(elementArray[index].id);
        } else {
          var arrayindex = this.requestId.indexOf(elementArray[index].id);
          this.requestId.splice(arrayindex, 1);
        }
      }
      checkBox.checked = ev.target.checked
    }
    if (this.requestId.length > 0) {
      $('.model').css('display', 'block');
    }
    else {
      $('.model').css('display', 'none');
    }
    if (!ev.target.checked) {
      $('.model').css('display', 'none');
    }
  }

  recordChecked(ev) {
    var id = ev.currentTarget.id;
    var rowCount = 0;
    var elementArray = document.getElementsByName("sizecb");
    var resultArray = this.requestId.filter(data => data == id);
    var elementId = document.getElementById(id);
    if (resultArray != null && resultArray.length != 0) {
      var index = this.requestId.findIndex(x => x === id)
      this.requestId.splice(index, 1);
      $("#checkAll").prop('checked', false);
    }
    else {
      this.requestId.push(id);
    }
    if (this.requestId.length > 0) {
      $('.model').css('display', 'block');
    }
    else {
      $('.model').css('display', 'none');
    }
    for (var index = 0; index < elementArray.length; index++) {
      var checkBox = <HTMLInputElement>elementArray[index]
      if (checkBox.checked == false) {
        rowCount = rowCount + 1;
      }
    }
    var selectedrow = elementArray.length - rowCount;
    if (selectedrow == elementArray.length) {
      $("#checkAll").prop('checked', true);
    }
  }

  bulkDelete() {
    const deleteid = {
      deleteid: this.requestId
    };
    let disposable = this.dialogService.addDialog(ConfirmDialogComponent, {
      title: "Delete Confirmation",//this.constants.deleteConfirmationTitle"",
      message: "Are you sure you want to delete the selected record?",//this.constants.deleteConfirmation
    })
      .subscribe((isConfirmed) => {
        //Dialog result
        if (isConfirmed) {
          this.adminService.bulkDelete(deleteid, true).subscribe((data: any) => {
            if (data.success) {
              this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Destroy the table first
                dtInstance.destroy();
                // Call the dtTrigger to rerender again
                this.bindAdminUsers();
                this.requestId = [];
              });
              this.notificationService.showSuccess(data.msg);
            } else {
              this.bindAdminUsers();
              this.notificationService.showError(data.msg);
            }
          })
        }
      },
        err => {
          this.errorHandler.handleErrors(err);
        });

    //We can close dialog calling disposable.unsubscribe();
    //If dialog was not closed manually close it by timeout
    setTimeout(() => {
      disposable.unsubscribe();
    }, 10000);
  }
}