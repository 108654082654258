import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { NotificationService } from './../../../services/notification.service';
import { OurAssociatesService } from './../../../services/our-associates.service';
import { DialogService } from 'ng2-bootstrap-modal';
import { Constants } from './../../../constants';
import { Component, OnInit, ViewChild } from "@angular/core";
import { ConfirmDialogComponent } from '../confirmDialog/confirmDialog.component';
import { Associates } from '../../../modal/ourAssociates';
import * as _ from 'lodash';

@Component({
  selector: "app-ourAssociates",
  templateUrl: "./ourAssociates.component.html",
  styleUrls: ["./ourAssociates.component.scss"]
})

export class OurAssociatesComponent implements OnInit {
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  requestId: String[] = [];
  hide: Boolean = false;
  strConst: String;
  associates: Associates[] = [];
  dtOptions = this.constants.dataTableSettings && {
    order:[2, 'asc'],
    columnDefs: [
      // { type: 'date', targets: 5 },
      {
        "targets": [0, 1, 3, 10, 12, 13],
        "orderable": false,
      }
    ],
    fnDrawCallback: function () {

      if ((document.getElementById("associatesdata_paginate")) != null) {
        if ((document.getElementById("associatesdata_paginate")).click) {
          var rowchecked = 0;
          var ValueById = document.getElementsByName("sizecb")
          for (var index = 0; index < ValueById.length; index++) {
            if ($(ValueById[index]).prop("checked") == true) {
              //check all conter var ++
              rowchecked = rowchecked + 1;
            }
          }
          if (ValueById.length == rowchecked) {
            $("#checkAll").prop('checked', true);
            $('.model').css('display', 'block');
          } else {
            $("#checkAll").prop('checked', false);
          }
          if (rowchecked == 0) {
            $("#checkAll").prop('checked', false);
            $('.model').css('display', 'none');
          }
          else if (rowchecked > 0) {
            $('.model').css('display', 'block');
          }
        }
      }
    }
  };

  constructor(
    public constants: Constants,
    private dialogService: DialogService,
    private ourAssociatesService: OurAssociatesService,
    private router: Router,
    private notificationService: NotificationService
  ) {
    this.bindAssociater();
  }

  ngOnInit() {

  }

  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<Associates> = new Subject<Associates>();

  bindAssociater() {
    this.hide = true;
    this.ourAssociatesService.getAssociates(true).subscribe((data:any) => {
      var dataOrder = _.orderBy(data.associates, ['serialNumber', 'companyName'], ['asc', 'asc']);
      this.associates = dataOrder as any;
      this.dtTrigger.next();
      this.hide = false;
    }, err => {
      if (err.status === 401) {
        this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
      }
      else {
        this.router.navigate([this.constants.clientUrlNotFound]);
      }
    });
  }

  //To Delete User
  showConfirm(id, name) {
    let disposable = this.dialogService.addDialog(ConfirmDialogComponent, {
      title: "Delete Confirmation",//this.constants.deleteConfirmationTitle"",
      message: "Are you sure you want to delete the selected record?",//this.constants.deleteConfirmation
    })
      .subscribe((isConfirmed) => {
        //Dialog result
        if (isConfirmed) {
          this.ourAssociatesService.deleteAssociates(id, true).subscribe((data:any) => {
            if (data.success) {
              this.bindUserDataTable();
              this.notificationService.showSuccess(data.msg);
            }
            else {
              this.notificationService.showError(data.msg);
            }
          }, err => {
            if (err.status === 401) {
              this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
            }
            else {
              this.router.navigate([this.constants.clientUrlNotFound]);
            }
          });
        }
      });

    //We can close dialog calling disposable.unsubscribe();
    //If dialog was not closed manually close it by timeout
    setTimeout(() => {
      disposable.unsubscribe();
    }, 10000);
  }

  checkAll(ev) {
    var elementArray = document.getElementsByName("sizecb");
    for (var index = 0; index < elementArray.length; index++) {
      var checkBox = <HTMLInputElement>elementArray[index]
      if (this.requestId.length === 0) {
        this.requestId.push(elementArray[index].id);
      }
      else {
        var resultArray = this.requestId.filter(data => data == elementArray[index].id);
        if (resultArray.length === 0) {
          this.requestId.push(elementArray[index].id);
        } else {
          var arrayindex = this.requestId.indexOf(elementArray[index].id);
          this.requestId.splice(arrayindex, 1);
        }
      }
      checkBox.checked = ev.target.checked
    }
    if (this.requestId.length > 0) {
      // this.showdelete = true;
      $('.model').css('display', 'block');
    }
    else {
      // this.showdelete = false;
      $('.model').css('display', 'none');
    }
    if (!ev.target.checked) {
      // this.requestId = [];
      // this.showdelete = false;
      $('.model').css('display', 'none');
    }
  }

  recordChecked(ev) {
    var id = ev.currentTarget.id;
    var rowCount = 0;
    var elementArray = document.getElementsByName("sizecb");
    var resultArray = this.requestId.filter(data => data == id);
    var elementId = document.getElementById(id);
    if (resultArray != null && resultArray.length != 0) {
      var index = this.requestId.findIndex(x => x === id)
      this.requestId.splice(index, 1);
      // this.deleteAll = false;
      $("#checkAll").prop('checked', false);
    }
    else {
      this.requestId.push(id);
    }
    if (this.requestId.length > 0) {
      // this.showdelete = true;
      $('.model').css('display', 'block');
    }
    else {
      // this.showdelete = false;
      $('.model').css('display', 'none');
    }
    for (var index = 0; index < elementArray.length; index++) {
      var checkBox = <HTMLInputElement>elementArray[index]
      if (checkBox.checked === false) {
        rowCount = rowCount + 1;
      }
    }
    var selectedrow = elementArray.length - rowCount;
    if (selectedrow === elementArray.length) {
      // this.deleteAll = true;
      $("#checkAll").prop('checked', true);
    }
  }

  bulkDelete() {
    const deleteid = {
      deleteid: this.requestId
    };
    let disposable = this.dialogService.addDialog(ConfirmDialogComponent, {
      title: "Delete Confirmation",//this.constants.deleteConfirmationTitle"",
      message: "Are you sure you want to delete the selected record?",//this.constants.deleteConfirmation
    })
      .subscribe((isConfirmed) => {
        //Dialog result
        if (isConfirmed) {
          this.ourAssociatesService.bulkDelete(deleteid, true).subscribe((data:any) => {
            if (data.success) {
              this.bindUserDataTable();
              this.notificationService.showSuccess(data.msg);
              this.requestId = [];
            }
            else {
              this.bindUserDataTable();
              this.notificationService.showError(data.msg);
            }
          });
        }
      });

    //We can close dialog calling disposable.unsubscribe();
    //If dialog was not closed manually close it by timeout
    setTimeout(() => {
      disposable.unsubscribe();
    }, 10000);
  }

  bindUserDataTable() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.bindAssociater()
    });
  }

  getTrimText(text) {
    if (text != '' && text != null && text != undefined) {
        this.strConst = text.toString();
            if (this.strConst.length > 35) {
                this.strConst = this.strConst.substring(0, 35) + "...";
            }
        return this.strConst;
    }
    else {
        return this.constants.notAvailable;
    }
}
}
