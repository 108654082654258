import { Component, OnInit } from '@angular/core';
import { Constants } from './../../../constants';
import { GlobalState } from '../../../app/global.state';

@Component({
  selector: 'admin-main-side',
  templateUrl: './main-side.component.html',
  styleUrls: ['./main-side.component.css']
})
export class MainSideComponent implements OnInit {
  public isScrolled: boolean = false;
  public isMenuCollapsed: boolean = false;
  constructor(
    public constants: Constants,
    private _state: GlobalState
  ) {
    this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
      this.isMenuCollapsed = isCollapsed;
    });
  }

  ngOnInit() {
  }

  toggleMenu() {    
    this.isMenuCollapsed = !this.isMenuCollapsed;
    this._state.notifyDataChanged('menu.isCollapsed', this.isMenuCollapsed);
    return false;
  }

}