import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Constants } from './../constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import "rxjs/add/operator/map";
@Injectable()
export class RequeststatushistoryserviceService {
    bookrequest: any;
    remarks: any;
    constructor(private http: HttpClient, public constants: Constants) { }

    putRequestStatusHistory(requestStatusHistory, tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.put(this.constants.serverrequeststatushistoryput + '?tsp=' + timeStamp, requestStatusHistory, { headers: headers })
            
    }
}
