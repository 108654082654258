// Use the @Injectable decorator for your Constants class

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CacheService, CacheStoragesEnum } from 'ng2-cache';
import { Injectable } from '@angular/core';

@Injectable()
export class Constants {

  applicationName: string = 'Ripple To Share';
  /*-------------- Server Constants Starts ----------------------*/
  // serverbaseUrl: string = 'http://localhost:3000/'; 
  // serverbaseUrl: string = 'http://192.168.1.206:3000/'; 
  // serverbaseUrl: string = 'http://192.168.1.206:9001/';
  serverbaseUrl: string = '';
  pageHeader: string = '';
  serveruserInsertLog: string = this.serverbaseUrl + 'users/updateLog';

  //for user Urls
  serveruserRegister: string = this.serverbaseUrl + 'users/register'; 
  serveruserGetAll: string = this.serverbaseUrl + 'users/get';
  serveruserGetById: string = this.serverbaseUrl + 'users/getById';
  serveruserPut: string = this.serverbaseUrl + 'users/put';
  serveruserProfileUpdate: string = this.serverbaseUrl + 'users/userProfile'; 
  serveruserDelete: string = this.serverbaseUrl + 'users/delete';
  serveruserBulkDelete: string = this.serverbaseUrl + 'users/bulkdelete';
  serveruserActiveUser: string = this.serverbaseUrl + 'users/activeUser';
  serveruserChangePassword: string = this.serverbaseUrl + 'users/userChangePassword';
  serveruserAuthenticate: string = this.serverbaseUrl + 'users/authenticate'; 
  serveruserGetByToken = this.serverbaseUrl + 'users/getByToken';
  serveruserForgetPassword: string = this.serverbaseUrl + 'users/forgotPassword';
  serveruserResetPassword: string = this.serverbaseUrl + 'users/resetPassword';
  serveruseremailActivation: string = this.serverbaseUrl + 'users/emailactivation';
  serveruserexternalUserLogin: string = this.serverbaseUrl + 'users/externalUserLogin'; 
  serveruserCheckURL: string = this.serverbaseUrl + 'users/checkurl';

  //userAddress
  serveruserAddressGetById: string = this.serverbaseUrl + 'userAddress/get';
  serverAddressGetById: string = this.serverbaseUrl + 'userAddress/getById';
  serveruserAddressPut: string = this.serverbaseUrl + 'userAddress/put';
  serveruserAddressDelete: string = this.serverbaseUrl + 'userAddress/delete';
  serveruserAddressBulkDelete: string = this.serverbaseUrl + 'userAddress/bulkdelete';

  //Contact
  servercontactGetAll: string = this.serverbaseUrl + 'contact/get';
  servercontactGetById: string = this.serverbaseUrl + 'contact/getById';
  servercontactPut: string = this.serverbaseUrl + 'contact/put';
  servercontactDelete: string = this.serverbaseUrl + 'contact/delete';
  servercontactBulkDelete: string = this.serverbaseUrl + 'contact/bulkdelete';

  //Contact Reponse
  servercontactresponseGetById: string = this.serverbaseUrl + 'response/getById';
  servercontactresponseGetByResponseId: string = this.serverbaseUrl + 'response/getByResponseId';
  servercontactresponsePut: string = this.serverbaseUrl + 'response/put';
  servercontactresponseDelete: string = this.serverbaseUrl + 'response/delete';
  servercontactresponseBulkDelete: string = this.serverbaseUrl + 'response/bulkdelete';

  //pages
  serverpageGetAll: string = this.serverbaseUrl + 'pages/get';
  serverpageGetById: string = this.serverbaseUrl + 'pages/getById';
  serverpageGetByTitle: string = this.serverbaseUrl + 'pages/getByTitle';
  serverpagePut: string = this.serverbaseUrl + 'pages/put';
  serverpageDelete: string = this.serverbaseUrl + 'pages/delete';
  serverpageBulkDelete: string = this.serverbaseUrl + 'pages/bulkdelete';

  //department
  serverdepartmentGetAll: string = this.serverbaseUrl + 'streams/get';
  serverdepartmentGetById: string = this.serverbaseUrl + 'streams/getById';
  serverdepartmentPut: string = this.serverbaseUrl + 'streams/put';
  serverdepartmentDelete: string = this.serverbaseUrl + 'streams/delete';
  serverdepartmentBulkDelete: string = this.serverbaseUrl + 'streams/bulkdelete';

  //syllabus
  serversyllabusGetAll: string = this.serverbaseUrl + 'syllabus/get';
  serversyllabusGetById: string = this.serverbaseUrl + 'syllabus/getById';
  serversyllabusPut: string = this.serverbaseUrl + 'syllabus/put';
  serversyllabusgetSyllabus: string = this.serverbaseUrl + 'syllabus/getSyllabus';
  serversyllabusDelete: string = this.serverbaseUrl + 'syllabus/delete';
  serversyllabusBulkDelete: string = this.serverbaseUrl + 'syllabus/bulkdelete';

  //subject
  serversubjectGetAll: string = this.serverbaseUrl + 'subjects/get';
  serversubjectGet: string = this.serverbaseUrl + 'subjects/getSubject';
  serversubjectGetById: string = this.serverbaseUrl + 'subjects/getById';
  serverGetBySubjectCode: string = this.serverbaseUrl + 'subjects/getBySubjectCode';
  serversubjectPut: string = this.serverbaseUrl + 'subjects/put';
  serversubjectDelete: string = this.serverbaseUrl + 'subjects/delete';
  serversubjectBulkDelete: string = this.serverbaseUrl + 'subjects/bulkdelete';
  serversubjectGetAllSemORYearWise: string = this.serverbaseUrl + 'subjects/avlBookCount';

  /*University Module */
  serveruniversityGetAll: string = this.serverbaseUrl + 'universities/get';
  serveruniversityGetById = this.serverbaseUrl + 'universities/getById';
  /** Create By Arpit */
  serverGetUniversityWiseCount = this.serverbaseUrl + 'universities/getUniversityWiseCount';
  serveruniversityPut: string = this.serverbaseUrl + 'universities/put';
  serveruniversityDelete: string = this.serverbaseUrl + 'universities/delete';
  serveruniversityBulkDelete: string = this.serverbaseUrl + 'universities/bulkdelete';

  //admin
  serveradminGetAll: string = this.serverbaseUrl + 'admins/get';
  serveradminGetById: string = this.serverbaseUrl + 'admins/getById';
  serveradminAuthenticate: string = this.serverbaseUrl + 'admins/authenticate';
  serveradminGetByToken = this.serverbaseUrl + 'admins/getByToken';
  serveradminPut: string = this.serverbaseUrl + 'admins/put';
  serveradminDelete: string = this.serverbaseUrl + 'admins/delete';
  serveradminBulkDelete: string = this.serverbaseUrl + 'admins/bulkdelete';
  serveradminForgetPassword: string = this.serverbaseUrl + 'admins/forgotPassword';
  serveradminResetPassword: string = this.serverbaseUrl + 'admins/resetPassword';
  serveradminChangePassword: string = this.serverbaseUrl + 'admins/changePassword';

  //Our Associates
  getServerAssociates: string = this.serverbaseUrl + 'ourAssociates/get';
  serverAssociatesGetById: string = this.serverbaseUrl + 'ourAssociates/getById';
  putServerAssociates: string = this.serverbaseUrl + 'ourAssociates/put';
  deleteServerAssociates: string = this.serverbaseUrl + 'ourAssociates/delete';
  bulkDeleteServerAssociates: string = this.serverbaseUrl + 'ourAssociates/bulkdelete';



  /*Template Module*/
  serverAllTemplates: string = this.serverbaseUrl + 'templates/get';
  serverDeleteTemplate: string = this.serverbaseUrl + 'templates/delete';
  serverUpdateTemplate: string = this.serverbaseUrl + 'templates/update';
  serverGetByIdTemplate = this.serverbaseUrl + 'templates/getById';
  serverAddTemplate: string = this.serverbaseUrl + 'templates/add';
  serverGetByNameTemplate: string = this.serverbaseUrl + 'templates/getBySubject';

  /*Book Module */
  serverbookGetActiveBooksCounts: string = this.serverbaseUrl + 'books/getBooksCounts';
  serverbookGetAll: string = this.serverbaseUrl + 'books/get';
  serverbookGetById = this.serverbaseUrl + 'books/getById';
  serverGetBooksById = this.serverbaseUrl + 'books/getBooksById';
  serverbookPut: string = this.serverbaseUrl + 'books/put';
  serverbookDelete: string = this.serverbaseUrl + 'books/delete';
  serverbookBulkDelete: string = this.serverbaseUrl + 'books/bulkdelete';
  serverbookSearch = this.serverbaseUrl + 'books/getBooksBySearch';
  serverbookSearchByGoogleId = this.serverbaseUrl + 'books/getBooksByGoogleId';
  serverbookRequestedBooks = this.serverbaseUrl + 'books/getRequestedBooks';
  serverbookAvailable = this.serverbaseUrl + 'books/getAvailableBooks';
  serverbookputCreateNewBook = this.serverbaseUrl + 'books/putCreateNewBook';
  servermanualbookPut: string = this.serverbaseUrl + "books/manualbook";
  serverinstancenumberPerticularBook = this.serverbaseUrl + 'books/getInstancenoPerticularBook';

  /*BookRequest Module*/
  serverbookrequestGetAll: string = this.serverbaseUrl + 'bookrequests/get';
  serverbookrequestGetBySearch: string = this.serverbaseUrl + 'bookrequests/getBySearch';
  serverbookrequestGetByUserId = this.serverbaseUrl + 'bookrequests/getByUserId';
  serverbookrequestGetById = this.serverbaseUrl + 'bookrequests/getById';
  serverbookrequestDelete: string = this.serverbaseUrl + 'bookrequests/delete';
  serverbookrequestBulkDelete: string = this.serverbaseUrl + 'bookrequests/bulkdelete';
  serverbookrequestPut: string = this.serverbaseUrl + 'bookrequests/put';
  servermanualbookrequestPut: string = this.serverbaseUrl + "bookrequests/manualrequest";
  servermanualbulckbookrequestPut: string = this.serverbaseUrl + "bookrequests/bulkrequest";
  servergetrequeststatus: string = this.serverbaseUrl + 'dropdownhelper/requeststatus';
  servergetlanguages: string = this.serverbaseUrl + 'dropdownhelper/getlanguage';
  serverbookrequestLinkToThis: string = this.serverbaseUrl + 'bookrequests/linktothis';
  serverrejectmanualrequestPut: string = this.serverbaseUrl + "bookrequests/rejectmanualrequest";
  serverReturnedAndaddtoContributionPut: string = this.serverbaseUrl + "bookrequests/ReturnedAndaddtoContribution";
  servermanualputbookrequest: string = this.serverbaseUrl + "bookrequests/manualputbookrequest";
  servergetInstanceLogDetails: string = this.serverbaseUrl + "bookrequests/getInstanceLogDetails";
  serverBulkMailNotification: string = this.serverbaseUrl + "bookrequests/bulkMailNotify";


  /*Request Status History Module */
  serverrequeststatushistoryput: string = this.serverbaseUrl + 'requeststatushistories/put';
  serverrequeststatushistorydelete: string = this.serverbaseUrl + 'requeststatushistories/delete';

  /* Admin Dashboard Module*/
  serverDashboardYear: string = this.serverbaseUrl + 'dashboard/getYear';
  serverDashboardCounts: string = this.serverbaseUrl + 'dashboard/getDashBoardCounts';
  serverDashboardCountsByTypeAndYear: string = this.serverbaseUrl + 'dashboard/getRequestsCountByTypeAndYear';
  serverDashboardCountsByStatus: string = this.serverbaseUrl + 'dashboard/getStatusWiseRequestCount';
  serverDashboardInstancesCount: string = this.serverbaseUrl + 'dashboard/getInstanceCounts';
  serverLinkedInApiData: string = this.serverbaseUrl + 'dashboard/LinkedInApi';
  serverDashboardInstancesNeededAndAvailableBooks: string = this.serverbaseUrl + 'dashboard/getInstanceNeededAndAvailableBooks';

  /*BookInstance Module*/
  serverbookinstancePut: string = this.serverbaseUrl + 'bookinstances/put';
  serveraddBookinstancePut: string = this.serverbaseUrl + 'bookinstances/add';
  servergetBookInstanceByBookId: string = this.serverbaseUrl + 'bookinstances/getByBookId';
  servergetInstanceNo: string = this.serverbaseUrl + 'bookinstances/getInstanceNo';
  servergetAvailableBookInstanceByBookId: string = this.serverbaseUrl + 'bookinstances/getAvailableBookInstanceByBookId';
  servergetInstanceCounts: string = this.serverbaseUrl + 'bookinstances/getCounts';
  servergetInstanceList: string = this.serverbaseUrl + "bookinstances/getInstanceList";
  serverbookSearchByRTS: string = this.serverbaseUrl + "bookinstances/getbookByInstanceNumber";

  /* FeedBack Module*/
  servergetFeedBack: string = this.serverbaseUrl + 'feedbacks/get';
  serverFeedBackbyId: string = this.serverbaseUrl + 'feedbacks/getbyId';
  serverFeedBackbyuserId: string = this.serverbaseUrl + 'feedbacks/getbyuserId';
  serverputFeedBack: string = this.serverbaseUrl + 'feedbacks/put';
  serverFeedBackDelete: string = this.serverbaseUrl + 'feedbacks/delete';
  serverFeedBackBulkDelete: string = this.serverbaseUrl + 'feedbacks/bulkdelete';
  serverFeedBackCount: string = this.serverbaseUrl + 'feedbacks/getReviewsCounts';

  /*Contributed Request */
  servercontributedput: string = this.serverbaseUrl + 'bookrequests/remarks';
  serverputbulkupdateremarks: string = this.serverbaseUrl + 'bookrequests/bulkupdateremarks';

  /*Dropdown Helper Module */
  serverActiveUniversityGet: string = this.serverbaseUrl + 'dropdownhelper/getUniversities';
  serverActiveStreamGet: string = this.serverbaseUrl + 'dropdownhelper/getStream';
  serverActiveYearORSemesterGet: string = this.serverbaseUrl + 'dropdownhelper/yearORsem';

  /*Static Folder Path*/
  serveruploadspath: string = this.serverbaseUrl + 'uploads/';

  servertemplateGetAll: string = this.serverbaseUrl + 'templates/get';
  servertemplateGetById: string = this.serverbaseUrl + 'templates/getById';
  servertemplateGetByTitle: string = this.serverbaseUrl + 'templates/getByTitle';
  servertemplatePut: string = this.serverbaseUrl + 'templates/put';
  servertemplateDelete: string = this.serverbaseUrl + 'templates/delete';
  /*-------------- Server Constants Complete ----------------------*/

  //check image valid or not
  extention(evt) {
    let validImg: Boolean;
    try {
      var file = evt.target.files[0].type;
      if (file === 'image/jpg' || file === 'image/jpeg' || file === 'image/png' && file != undefined) {
        return validImg = false;
      } else {
        return validImg = true;
      }
    } catch (err) {
      return validImg = true;
    }

  }

  //load token
  loadToken() {
    const token = localStorage.getItem("id_token");
    return token;
  }

  //Get Headers
  getHeaders() {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return headers;
  }

  getHeadersWithAuthorization(flag) {
    let headers = this.getHeaders();
    if (flag) {
      if (this.loadToken() != null && this.loadToken() != undefined) {
        headers = headers.append('Authorization', this.loadToken());
      }
    } else {
      if (localStorage.getItem("userid_token").toString() != null && localStorage.getItem("userid_token").toString() != undefined)
        headers = headers.append('Authorization', localStorage.getItem("userid_token"));
    }
    return headers;
  }

  /*-------------- Client Constants Starts ----------------------*/
  //DataTable settings
  dataTableSettings: DataTables.Settings = {
    pagingType: 'simple_numbers',
    pageLength: 10,
    ordering: true,
    paging: true,
    scrollCollapse: true,
    autoWidth: false,
    columnDefs: [
      { targets: "no-sort", orderable: false },
    ],
  };

  //Client Routing Paths
  clientLogin = '/secure/login';
  clientAdmin = '/secure';

  clientAdminForgetPassword = this.clientAdmin + '/forgotpassword';
  clientAdminResetPassword = this.clientAdmin + '/resetpassword';
  clientUrlDashboard1 = this.clientAdmin + '/home';
  clientUrlProfile = this.clientAdmin + '/profile';

  // admin module
  clientUrlAdminList = this.clientAdmin + '/adminUser';
  clientUrlAdminAdd = this.clientAdmin + '/adminUser/addAdmin';
  clientUrlAdminUpdate = this.clientAdmin + '/adminUser/updateAdmin';
  clientUrlAdminChangePassword = this.clientAdmin + '/changePassword';

  // user module
  clientUrlUserList = this.clientAdmin + '/users';
  clientUrlUsersAdd = this.clientAdmin + '/users/addUsers';
  clientUrlUserUpdate = this.clientAdmin + '/users/updateUsers';

  // user multiple address
  clientUrlAddress = this.clientAdmin + '/users/addAddress';
  clientUrlModifyUserAddress = this.clientAdmin + '/users/updateUserAddress';

  //category module
  clientUrlCategoryList = this.clientAdmin + '/category';
  clientUrlCategoryAdd = this.clientAdmin + '/category/addCategory';
  clientUrlCategoryUpdate = this.clientAdmin + '/category/updateCategory';

  // Contact module
  clientUrlContactList = this.clientAdmin + '/contact';
  clientUrlContactAdd = this.clientAdmin + '/contact/addContact';
  clientUrlContactUpdate = this.clientAdmin + '/contact/updateContact';

  //department module
  clientUrlStream = this.clientAdmin + '/stream';
  clientUrlAddStream = this.clientAdmin + '/stream/addStream';
  clientUrlUpdateStream = this.clientAdmin + '/stream/updateStream';

  //Our Associates
  clientUrlAssociatesList = this.clientAdmin + '/associates';
  clientUrlAssociatesAdd = this.clientAdmin + '/associates/addAssociates';
  clientUrlAssociatesUpdate = this.clientAdmin + '/associates/updateAssociates';

  //subject module
  clientUrlSubject = this.clientAdmin + '/subject';
  clientUrlAddSubject = this.clientAdmin + '/subject/addSubject';
  clientUrlUpdateSubject = this.clientAdmin + '/subject/updateSubject';

  //syllabus module
  clientUrlSyllabus = this.clientAdmin + '/syllabus';
  clientUrlAddSyllabus = this.clientAdmin + '/syllabus/addSyllabus';
  clientUrlUpdateSyllabus = this.clientAdmin + '/syllabus/updateSyllabus';

  //pages module
  clientUrlPages = this.clientAdmin + '/pages';
  clientUrlPagesAdd = this.clientAdmin + '/pages/addPages';
  clientUrlPagesUpdate = this.clientAdmin + '/pages/updatePages';

  //university module
  clientUrlUniversityList = this.clientAdmin + '/universities';
  clientUrlUniversityAdd = this.clientAdmin + '/universities/addUniversity';
  clientUrlUniversityUpdate = this.clientAdmin + '/universities/updateUniversity';

  //template module
  clientUrlTemplateList = this.clientAdmin + '/template';
  clientUrlTemplateAdd = this.clientAdmin + '/template/addTemplate';
  clientUrlTemplateUpdate = this.clientAdmin + '/template/updateTemplate';

  //FeedBack module
  clientUrlFeedBack = this.clientAdmin + '/viewFeedback';
  clientUrlFeedBackUpdate = this.clientAdmin + '/viewFeedback/updateFeedback';

  //book module
  clientUrlBooks = this.clientAdmin + '/books';
  clientUrlBooksAdd = this.clientAdmin + '/books/addBooks';
  clientUrlBooksView = this.clientAdmin + '/books/viewBookDetail';
  clientUrlBooksUpdate = this.clientAdmin + '/books/updateBooks';
  clientUrlBookInstanceView = this.clientAdmin + '/books/viewInstanceDetails';

  //book instance logs list
  clientUrlLogs = this.clientAdmin + '/logs'

  //Book Request Module
  clientUrlBookRequests = this.clientAdmin + '/bookrequest';
  clientUrlBookContribute = this.clientAdmin + '/contributebook';
  clientUrlAdminSearchBooks = this.clientAdmin + '/users/searchBooks';

  //Remarks Request Module
  clientUrlRemarks = this.clientAdmin + '/bookrequestdetail'
  clientUrlRemarksSeeking = this.clientAdmin + '/bookrequest/bookrequestdetail'
  clientUrlRemarksContribute = this.clientAdmin + '/contributebook/bookrequestdetail'

  clientUrlNotFound = this.clientAdmin + '/notfound';
  clientUrlVerifyBookRequest = this.clientAdmin + '/verifybookrequest';
  clientUrlAdminUnauthorized = this.clientAdmin + '/unauthorized';

  /*-------------- Client Constants Complete ----------------------*/
  constructor(
    private http: HttpClient,
    private _cacheService: CacheService,
  ) {
  }

  setCacheData(key, value) {
    let result: boolean = this._cacheService.set(key, value, { maxAge: 5 * 60 });
  }
  getCacheData(key) {
    let data: any | null = this._cacheService.get(key);
    return data;
  }
  removeCacheData(key, flag) {
    if (flag == 0) {
      let result: boolean = this._cacheService.set(key, null);
    } else {
      this._cacheService.remove(key)
    }
  }

  /*-------------- Submit ButtonName start -----------------*/
  users = "Users";
  availableInstances = "Available Instances";
  neededInstances = "Needed Instances";
  neededBookContribution = "Donate";
  availableBookRequest = "Request";
  dateOnly = "dd-MMM-yy";
  addAddress = "Add Address";
  updateAddress = "Update Address";
  Update = "Update";
  Reset = "Reset"
  Save = "Save";
  Send = "Send";
  View = "View";
  sem = "Sem";
  submit = "Submit";
  year = "Year";
  Register = "Sign Up";
  Signin = "Sign In";
  Cancel = "Cancel";
  Close = "Close Book";
  Request = "Request";
  Contribute = "Donate";
  ChangePassword = "Change Password";
  SendRequest = "Send Request";
  UpdateProfile = "Update Profile";
  feedbackNotification = "Please give your valuable commets or rating's";
  strBookInsert = "Book has been inserted successfully";
  strBookUpdate = "Book has been updated successfully";
  strBookRequestSend = "Book request has been sent Successfully";
  strErrorOccur = "An error occured while processing request";
  strBookExist = "Book already exist";
  strAssociatesInsert = "Associates has been inserted successfully";
  strAssociatesUpdate = "Associates has been updated successfully";
  AssociatesExist = "Associates already Exist";
  BookExist = "Already Exist";
  seekingRequestYearly = "Book Requests";
  contributedRequestYearly = "Book Donations";
  listofbookatDashboard = "List of Books requested but not available";
  StatusWisechar = "Status"
  strFileSize = "File size should not be more than 2MB";
  loginProblem = "There is some problem with Login";
  ResetMyPassword = "Retrieve Password";
  strLoginrequired = "Please login first to send request";
  availabelBooks = "Available Books";
  UavailabelBooks = "Universities Available Books";
  notAvailableBook = "Requested Books";
  universityWiseAvailableBook = "Available Books (University-wise)";
  collected = "Collected";
  donated = "Delivered"
  fileSize = 2000000;
  CreateNewInstance = "Create new book";
  Seeking = "Seeker";
  Contributed = "Donators";
  notAvailable = 'N/A';
  DateFormat = 'dd MMM, y, hh:mm a';
  uploadFile = this.serverbaseUrl + 'books/put';
  associatesUpload = this.serverbaseUrl + 'ourassociates/uploads';
  GetBookDetailsFromISBN = "Get book details from ISBN";
  Edit = "Edit";
  Delete = "Delete";
  AddBook = "Add Book";
  ViewDetail = "View";
  ViewMore = "View More";
  Active = "Active";
  InActive = "In Active";
  Status = "Status";
  LinkToThis = "Link To This";
  ContributeRequest = "Donate Book Request";
  BookRequest = "Book Request";
  Search = "Search";
  GoBack = "Go Back";
  Language_en = "English";
  Language_gu = "Gujarati";
  Language_hi = "Hindi";
  SendMessage = "Send Message";
  Add = "Add";
  Collected = "Collected";
  Delivered = "Delivered";
  Requested = "Requested";
  OnHold = "On Hold";
  Rejected = "Rejected";
  available = "availablebook";
  notavailable = "notavailablebook";
  AvailableBooks = "Available Books";
  notAvailablebook = "Requested but not available";
  yes = "Yes";
  no = "No";
  rejectRequest = "Reject Request";
  socialLoginException = "Unable to login with ";
  semester = "Semester";
  viewBookDetailPageHeader="VIEW BOOK DETAIL";
  selectDuration="Select Duration";

  // Validation pattern List
  stringValidation = "^[A-Za-z]{1}[ A-Za-z0-9_@.',_()#&+-]*$";
  numberValidation = "[0-9]*";
  url = "^((ftp|http|https):\/\/)?[a-zA-Z0-9\-\.]+\.(com|org|net|mil|edu|COM|ORG|NET|MIL|EDU|co|in)$";
  removeFrontSpace = "^[a-zA-Z0-9!@#$&()\\-`.+,/\_]+([a-zA-Z0-9!@#$&()\\-`.+,/\_ ])*$";

  /*-------------- Submit ButtonName end -----------------*/
  /*-------------- Common message constants start -----------------*/
  profileUpdate = "Your profile has been updated successfully.";
  errorOccur: "An error occurred while processing. Please try again later.";
  deleteConfirmationTitle: "Delete Confirmation";
  deleteConfirmation: "Are you sure you want to delete the selected record?";
  deleteTemplateConfirmation: "Are you sure you want to delete the selected template?";
  /*-------------- Common message constants end -----------------*/

  /*-------------- User constants Start -----------------*/
  //Sign Up Module
  clientUser = '';
  clientUserHome = this.clientUser + '/home';
  clientsocialLogin = this.clientUser + '/socialLogin';
  clientUrlSignUp = this.clientUser + '/signup';
  clientUrlSignIn = this.clientUser + '/login';
  clientUrlUserprofile = this.clientUser + '/userprofile';
  clientUrlUserAddress = this.clientUser + '/addUserAddress';
  clientUrlupdateUserAddress = this.clientUser + '/updateUserAddress';
  clientUrlUserresetPassword = this.clientUser + '/userchangepassword';
  clientUrlUserforgetpassword = this.clientUser + '/forgotpassword';
  clientUrlUserContactUs = this.clientUser + '/contact';
  clientUrlUserHowItWorks = this.clientUser + '/howitworks';
  clientUrlUserPrivacyPolicy = this.clientUser + '/privacypolicy';
  clientUrlUserFacebookDataDeletionInstructions = this.clientUser + '/facebook-data-deletion-instructions';
  clientUrlUserSyllabus = this.clientUser + '/studentzone';
  clientUrlUserFeedBack = this.clientUser + '/feedback';
  clientUrlUserFaq = this.clientUser + '/faq';
  clientUrlUserRequests = this.clientUser + '/myrequests';
  clientUrlUserDonations = this.clientUser + '/mycontributions';
  clientUserOurAssociate = this.clientUser + '/ourassociate';
  clientUrlUserSearch = this.clientUser + '/search';
  clientUrlSearchBook = this.clientUser + '/searchbooks';
  clientUrlUserSearchDetail = this.clientUser + '/viewbookdetails';
  clientUrlUserSeekingRequest = this.clientUser + '/seekingrequest';
  clientUrlUserContributeRequest = this.clientUser + '/contributerequest';
  clientUrlBookSeekingRequestDetail = this.clientUser + '/requestdetails';
  clientUrlUserAvailableBooks = this.clientUser + '/availablebooks';
  clientUrlUserRequiredBooks = this.clientUser + '/requiredbooks';
  clientUrlManualRequest = this.clientUser + "/manualrequest";
  //BookRequest Module...
  clientUrlBookRequest = this.clientUser + '/bookrequest';
  clientUrlUnauthorized = this.clientUser + '/unauthorized';
  /*-------------- Common message constants end -----------------*/

  /*----------------- Trim Text ------------------*/
  getTrimText(text, mobWidth) {
    if (text != '') {
      var trimText = text.toString();
      if (mobWidth > 1200 && mobWidth < 1400) {
        if (trimText.length > 10) {
          trimText = trimText.substring(0, 10) + "..";
        }
      } else if (mobWidth < 1200) {
        if (trimText.length > 16) {
          trimText = trimText.substring(0, 16) + "..";
        }
      } else {
        if (trimText.length > 30) {
          trimText = trimText.substring(0, 24) + "..";
        }
      }
      return trimText;
    }
    else {
      return this.notAvailable;
    }
  }

  getTrimSubText(text, mobWidth) {
    if (text != '') {
      var TrimSubText = text.toString();
      if (mobWidth > 1200 && mobWidth < 1400) {
        if (TrimSubText.length > 20) {
          TrimSubText = TrimSubText.substring(0, 12) + "..";
        }
      } else if (mobWidth >= 768 && mobWidth < 1200) {
        if (TrimSubText.length > 7) {
          TrimSubText = TrimSubText.substring(0, 6) + "..";
        }
      } else {
        if (TrimSubText.length > 30) {
          TrimSubText = TrimSubText.substring(0, 24) + "..";
        }
      }
      return TrimSubText;
    }
    else {
      return this.notAvailable;
    }
  }
  /*----------------- Trim Text End ------------------*/

  /*----------------- Get Id from Infolink ------------------*/
  getIdFromInfoLinks(url) {
    if (url != null) {
      if ((url.split('?').length > 1)) {
        let params = new URLSearchParams(url.split('?')[1]);
        let id = params.get('id');
        return id;
      }
      return url;
    }
  }
  /*----------------- End of Get Id from Infolink ------------------*/
}
