import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormsModule, ReactiveFormsModule, AbstractControl } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';

import { AuthAdminService } from './../../../services/authadmin.service';
import { Constants } from './../../../constants';
import { HandleError } from './../../../error';
import { NotificationService } from './../../../services/notification.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public email: AbstractControl;
  public password: AbstractControl;
  loginForm: FormGroup;
  submitButtonText: string = this.constants.Signin;
  constructor(private authService: AuthAdminService,
    public constants: Constants,
    private errorHandler: HandleError,
    private notificationService: NotificationService,
    private router: Router,
    fb: FormBuilder
  ) {
    this.loginForm = fb.group({
      'email': [null, Validators.compose([Validators.required, Validators.email])],
      'password': [null, Validators.required],
    })
    this.email = this.loginForm.controls['email'];
    this.password = this.loginForm.controls['password'];
  }

  ngOnInit() {
  }

  onLoginSubmit() {
    const user = {
      email: this.email.value,
      password: this.password.value
    }    
    this.authService.authenticateAdmin(user).subscribe((data:any) => {
      if (data.success) {
        this.authService.storeUserData(data.token, data.Admin);
        this.router.navigate([this.constants.clientAdmin]);
      }
      else {
        this.notificationService.showError(data.msg);
      }
    },
      err => {
        this.errorHandler.handleErrors(err);
      });
  }

}