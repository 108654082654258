import { RequeststatushistoryserviceService } from './../../../services/requeststatushistoryservice.service';
import { BookRequestService } from './../../../services/bookrequest.service';
import { BookService } from './../../../services/book.service';
import { element, browser } from 'protractor';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';

import { UserService } from './../../../services/user.service';
import { AuthUserService } from './../../../services/authuser.service';
import { Constants } from './../../../constants';
import { HandleError } from './../../../error';
import { NotificationService } from './../../../services/notification.service';


@Component({
  selector: 'app-seeking-request',
  templateUrl: './seeking-request.component.html',
  styleUrls: ['./seeking-request.component.css']
})
export class SeekingRequestComponent implements OnInit {
  userForm: FormGroup;
  returnUrl: String;
  submitButtonText: string = this.constants.Request;
  bookid: String;
  userdata: any;
  userid: String;
  bookdetail: any;
  requestdate: Date;
  requesttype: Boolean;
  contactnumber: String;
  disableButton: Boolean = false;

  constructor(fb: FormBuilder,
    public constants: Constants,
    private router: Router,
    private route: ActivatedRoute,
    private bookService: BookService,
    private notificationService: NotificationService,
    private bookrequestService: BookRequestService,
    private authuserservice: AuthUserService,
    private requeststatushistoryserviceService: RequeststatushistoryserviceService
  ) {
    this.userForm = fb.group({
      'contactnumber': [null, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern('[0-9]*')])],
    })
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.requesttype = params['flag'];
      });
    this.userdata = this.authuserservice.getUserFromStorage();
    this.contactnumber = this.userdata.contactno;
  }
  onSubmitClick() {
    this.disableButton = true;
    this.route.queryParams
      .subscribe(params => {
        const bookrequest = {
          searchKey: params['id'],
          googleid: params['id'],
          isdelete: 0,
          bookid: params['cid'] != undefined ? params['cid'] : params['mid'],
          userid: this.userdata.id,
          requestdate: new Date(),
          requesttype: this.requesttype,
          istransactioncomplete: 0,
          contactno: this.contactnumber,
          preferredtime: '',
          address: '',
          isdropbyuser: 0,
          remarks: "",
          email: this.userdata.email,
          name: this.userdata.name,
          emailNotification: true
        }
        if (params['cid'] != '' && params['cid'] != undefined && params['cid'] != "undefined") {
          this.bookrequestService.putManualBookRequest(bookrequest, false).subscribe((data:any) => {
            if (data.success) {
                this.constants.removeCacheData('DashboardCounts', 0);
                // Change by Arpit
              this.constants.removeCacheData('DashboardBookList', 0);
              this.constants.removeCacheData('ListOfNeededBook', 0);
              this.notificationService.showSuccess(data.msg);
              this.router.navigate([this.constants.clientUrlUserRequests]);
            }
            else {
              this.notificationService.showError(data.msg);
              this.disableButton = false;
            }
          },
            err => {
              if (err.status == 401) {
                this.router.navigate([this.constants.clientUrlUnauthorized]);
              }
              else {
                this.router.navigate([this.constants.clientUrlNotFound]);
              }
            });
        } else if (params['mid'] != '' && params['mid'] != undefined && params['mid'] != "undefined") {
          this.bookrequestService.putBulckBookRequest(bookrequest, false).subscribe((data:any) => {
            if (data.success) {
                this.constants.removeCacheData('DashboardCounts', 0);
                // Change by Arpit              
              this.constants.removeCacheData('DashboardBookList', 0);
              this.constants.removeCacheData('ListOfNeededBook', 0);
              this.notificationService.showSuccess(data.msg);
              this.router.navigate([this.constants.clientUrlUserRequests]);
            }
            else {
              this.notificationService.showError(data.msg);
              this.disableButton = false;
            }
          });
        }
        else {
          this.bookrequestService.putBookRequest(bookrequest, false).subscribe((data:any) => {
            if (data.success) {
                this.constants.removeCacheData('DashboardCounts', 0);
                // Change by Arpit
              this.constants.removeCacheData('DashboardBookList', 0);
              this.constants.removeCacheData('ListOfNeededBook', 0);              
              this.notificationService.showSuccess(data.msg);
              this.router.navigate([this.constants.clientUrlUserRequests]);
            }
            else {
              this.notificationService.showError(data.msg);
              this.disableButton = false;
            }
          },
            err => {
              if (err.status == 401) {
                this.router.navigate([this.constants.clientUrlUnauthorized]);
              }
              else {
                this.router.navigate([this.constants.clientUrlNotFound]);
              }
            });
        }
      });
  }
}
