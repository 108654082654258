import { DropdownService } from './../../../services/dropdown.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { UserService } from './../../../services/user.service';
import { BookRequestService } from './../../../services/bookrequest.service';
import { AuthUserService } from './../../../services/authuser.service';
import { CustomValidators } from 'ng2-validation';
import { Constants } from '../../../constants';
import { HandleError } from './../../../error';
import { NotificationService } from './../../../services/notification.service';
class user {
    _id: any;
    name: String;
    email: String;
    password: String;
    confirmpassword: String;
    university: String;
    contactnumber: String;
}
class Universities {
    _id: String;
    universityname: String;
}
@Component({
    selector: 'userprofile',
    templateUrl: './userprofile.component.html',
    styleUrls: ['./userprofile.component.css']
})
export class UserProfileComponent implements OnInit {
    userid: any;
    name: String;
    email: String;
    university: String;
    password: String;
    contactno: String;
    provider: String;
    user: any;
    submitButtonText: String = this.constants.UpdateProfile;
    userForm: FormGroup;
    hide: Boolean = false;
    constructor(private authuserService: AuthUserService,
        private bookrequest: BookRequestService,
        private userService: UserService,
        public constants: Constants,
        private activatedRoute: ActivatedRoute,
        private errorHandler: HandleError,
        private notificationService: NotificationService,
        private dropdownService: DropdownService,
        private router: Router,
        fb: FormBuilder
    ) {
        let password = new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(15)]);
        let confirmpassword = new FormControl('', CustomValidators.equalTo(password));
        this.activatedRoute.params.subscribe((params: Params) => {
            this.userForm = fb.group({
                'userid': [null, Validators.nullValidator],
                'name': [null, Validators.compose([Validators.required, Validators.maxLength(50),])],
                //'email': [null, Validators.compose([Validators.required, Validators.pattern("[a-z0-9.%]+@[a-z0-9.]+\.[a-z]{2,3}$")])],
                // 'password': password,
                // 'confirmpassword': confirmpassword,
                'university': [null, Validators.nullValidator],
                'contactno': [null, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern('[0-9]*')])],
            });
        });
        this.getUniversities();
        this.user = this.authuserService.getUserFromStorage();
        this.userid = this.user.id;
        this.hide = true;
        this.userService.getUserById(this.userid, false).subscribe((data:any) => {
            this.name = data.user.name;
            this.email = data.user.email;
            this.university = data.user.university != null ? data.user.university : undefined;
            this.contactno = data.user.contactnumber;
            this.provider = data.user.provider;
            this.hide = false;
        },
            err => {
                if (err.status == 401) {
                    this.router.navigate([this.constants.clientUrlUnauthorized]);
                }
                else {
                    this.router.navigate([this.constants.clientUrlNotFound]);
                }
            })
    }

    bookrequests: String[];
    universities: Universities[] = [];
    ngOnInit() {
    }
    getUniversities() {
        this.dropdownService.getUniversity().subscribe((data:any) => {
            this.universities = data.universities;
        });
    }
    onSubmit() {
        const user = {
            userId: this.user.id,
            name: this.name.trim(),
            password: this.password,
            contactno: this.contactno.trim(),
            university: this.university != undefined ? this.university : '',
        }

        this.userService.updateProfile(user, false).subscribe((data:any) => {
            if (data.success) {
                this.notificationService.showSuccess(this.constants.profileUpdate);
                var userDetails = this.authuserService.getUserFromStorage();
                if (userDetails.id == this.user.id) {
                    var user = {
                        id: this.user.id,
                        name: this.name.trim(),
                        contactno: this.contactno.trim(),
                        email: this.email.trim(),
                        university: this.university != undefined ? this.university : '',
                        provider: this.provider
                    }
                    this.authuserService.storeUserData(this.authuserService.loadToken(), user);
                }
                this.router.navigate([this.constants.clientUrlUserprofile]);
            }
            else {
                this.notificationService.showError(data.msg);
            }
        },
            err => {
                if (err.status == 401) {
                    this.router.navigate([this.constants.clientUrlUnauthorized]);
                }
                else {
                    this.router.navigate([this.constants.clientUrlNotFound]);
                }
            })
    }
}

