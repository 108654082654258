import { DashboardService } from './../../../services/dashboard.service';
import { DropdownService } from './../../../services/dropdown.service';
import { ConfirmDialogComponent } from './../confirmDialog/confirmDialog.component';
import { Router, ActivatedRoute, Params, RoutesRecognized } from '@angular/router';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';
import { DataTableDirective } from 'angular-datatables';
import { Constants } from './../../../constants';
import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { BookService } from "../../../services/book.service";
import { DEFAULT_ENCODING } from 'crypto';

class Books {
  title: String;
  googleid: String;
  authors: String;
  search: String;
  imageurl: String;
  subtitle: String;
  publisher: String;
  bookInstanceCount: String;
  categories: any;
  language: string;
}

@Component({
  selector: 'app-availablebooks.component.ts',
  templateUrl: './availablebooks.component.html',
  styleUrls: ['./availablebooks.component.css']
})
export class AvailablebooksComponent implements OnInit {
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  @HostListener("window:scroll", [])
  // strConst: String;
  totalAvailableBooks: number = 0;
  books: Books[] = [];
  hide: Boolean = false;
  ShowGrid: Boolean = true;
  infiniteScrollDisabled: Boolean = false;
  showLoader: Boolean = false;
  mobWidth: any;
  strCategoryList: String = '';
  strCategoryArray: string[] = [];
  strLanguageArray: string[] = [];
  search: String;
  categoryFilter: string;
  languageFilter: string;
  filterFlag: Boolean = false;
  constructor(private bookService: BookService,
    private activatedRoute: ActivatedRoute,
    private dashboardService: DashboardService,
    public constants: Constants,
    private location: Location,
    private router: Router,
    private dropdownService: DropdownService, ) {
    //check width of screen for responsive result
    this.mobWidth = (window.screen.width);
  }
  availableBook;
  dtOptions = this.constants.dataTableSettings;
  dtTrigger: Subject<Books> = new Subject<Books>();
  allBooks: Books[] = [];
  resultBooks: Books[] = [];
  isAvailable: Boolean;
  ngOnInit() {
    this.hide = true;
    var currentURL = this.location.path();
    if (currentURL === '/availablebooks') {
      this.isAvailable = true;
      this.getAvailableBooks();
      this.getLanguage();
    } else {
      this.isAvailable = false;
      this.getRequestedBooks();
      //get language list
      this.getLanguage();
    }
  }

  getAvailableBooks() {
    this.bookService.getAvailableBooks().subscribe((data:any) => {
      if (data != null) {
        this.dashboardService.getDashboardCounts(true).subscribe((data:any) => {
          this.totalAvailableBooks = data.msg.AB;
        });
        this.setBookLength(data)
      }
      this.hide = false;
    });
  }

  getRequestedBooks() {
    this.bookService.getRequestedBooks().subscribe((data:any) => {
      if (data != null) {
        data.books.forEach((item) => {
          this.totalAvailableBooks += parseInt(item.bookInstanceCount.toString());
        })
        this.setBookLength(data)
      }
      this.hide = false;
    });
  }

  viewImage(img) {
    var modal = document.getElementById('myModal');
    modal.style.display = "block";
    this.imageExists(img, function(exists) {
      if(exists){
        document.getElementById("img01").setAttribute('src', img);
      }else{
      document.getElementById("img01").setAttribute('src', '../assets/img/noimage.gif');
      }
    });
    
  }

  imageExists(url, callback) {
    var img = new Image();
    img.onload = function() { callback(true); };
    img.onerror = function() { callback(false); };
    img.src = url;
  }

  closeView() {
    var modal = document.getElementById('myModal');
    modal.style.display = "none";
  }

  onWindowScroll() {
    this.onScroll()
  }

  setBookLength(data) {
    if (data.books != null) {
      this.infiniteScrollDisabled = false;
      this.allBooks = [];
      this.books = [];
      this.allBooks = data.books;
      if (this.allBooks.length < 12) {
        this.infiniteScrollDisabled = true;
        for (var index = 0; index < this.allBooks.length; index++) {
          this.books.push(this.allBooks[index]);
          this.strLanguageArray.push(this.allBooks[index].language);
          if (this.allBooks[index].categories != '' && this.allBooks[index].categories != this.constants.notAvailable) {
            this.strCategoryList += this.allBooks[index].categories[0] + "/";
          }
        }
      }
      else {
        for (var index = 0; index < 12; index++) {
          this.books.push(this.allBooks[index]);
          this.strLanguageArray.push(this.allBooks[index].language);
          if (this.allBooks[index].categories != '' && this.allBooks[index].categories != this.constants.notAvailable) {
            this.strCategoryList += this.allBooks[index].categories[0] + "/";
          }
        }
      }
      this.getCategory();
      this.resultBooks = this.books;
      this.dtTrigger.next();
    }
    else {
      this.books = data.books;
    }
    this.hide = false;
  }

  languages: any[] = [];
  getLanguage() {
    this.languages = [];
    this.dropdownService.getLanguages().subscribe((data:any) => {
      if (data.success) {
        this.languages = data.languages
      }
    });
  }

  SendRequest(flag, infoLinkId, title) {
    // if (this.authuserservice.getUserFromStorage() == null) {
    //     this.notificationService.showError(this.constants.strLoginrequired);
    // }
    // else {
    if (flag) {
      this.router.navigate([this.constants.clientUrlUserSeekingRequest], { queryParams: { flag: flag, searchKey: title, cid: infoLinkId } });
    }
    else {
      this.router.navigate([this.constants.clientUrlUserContributeRequest], { queryParams: { flag: flag, searchKey: title, cid: infoLinkId } });
    }
    //}
  }
  
  onGrid(ShowGrid) {
    this.ShowGrid = ShowGrid;
  }

  onScroll() {
    if (this.books.length == this.allBooks.length) {
      this.infiniteScrollDisabled = true;
      this.showLoader = false;
    } else {
      this.showLoader = true;
      setTimeout(() => {
        var startIndex = this.books.length;
        var endIndex = this.books.length + 12;
        if (endIndex > this.allBooks.length) {
          endIndex = endIndex - (endIndex - (this.allBooks.length))
        }
        for (var index = startIndex; index < endIndex; index++) {
          this.books.push(this.allBooks[index]);
          this.strLanguageArray.push(this.allBooks[index].language);
          this.resultBooks = this.books;
          if (this.allBooks[index].categories != '' && this.allBooks[index].categories != this.constants.notAvailable) {
            this.strCategoryList += this.allBooks[index].categories[0] + "/";
          }
          this.totalBookCount(this.resultBooks);
        }
        this.getCategory();
        this.showLoader = false;
      }, 3000);
    }
  }

  searchBook(searchKey) {
    this.books = this.resultBooks;
    if (searchKey.trim() != '') {
      if (this.categoryFilter != '' && this.filterFlag) {
        this.filterBook();
      }
      this.books = this.books.filter(data => {
        let searchTearmUpperCase = searchKey.toUpperCase();
        let mediNameUpperCase = data.title.toUpperCase();
        return mediNameUpperCase.includes(searchTearmUpperCase);
      })
      this.totalBookCount(this.books);      
    } else {
      if (this.filterFlag) {
        this.filterBook();
      }
    }
  }

  getCategory() {
    var categoryArray = [];
    categoryArray = this.strCategoryList.split('/');
    for (var index = 0; index < categoryArray.length; index++) {
      if (categoryArray[index] != "") {
        this.strCategoryArray[index] = categoryArray[index].trim();
      }
    }
    this.strCategoryArray = this.strCategoryArray.filter(function (indexElement, index, self) {
      return index == self.indexOf(indexElement);
    }).sort()
  }

  filterBook() {
    this.filterFlag = true;
    this.books = this.resultBooks;
    this.books = this.books.filter(item =>
      (item.categories[0].includes(this.categoryFilter) || this.categoryFilter == '' || this.categoryFilter == undefined) && (item.language == this.languageFilter || this.languageFilter == undefined || this.languageFilter == '')
    )
    this.totalBookCount(this.books);
  }
  resetData() {
    this.books = this.resultBooks;
    this.categoryFilter = undefined;
    // this.getLanguage();
    this.languageFilter = undefined;
    this.totalAvailableBooks = 0;
    var currentURL = this.location.path();
    if (currentURL === '/availablebooks') {
      this.getAvailableBooks();
    }else{
      this.getRequestedBooks();
    }
  }

  totalBookCount(books){
    this.totalAvailableBooks = 0;
    books.forEach((item) => {
      this.totalAvailableBooks += parseInt(item.bookInstanceCount.toString());
    })
  }

}
