import { CustomValidators } from 'ng2-validation';
import { Component, Injectable, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { Constants } from './../../../constants';
import { NotificationService } from './../../../services/notification.service';
import { UniversityService } from './../../../services/university.service';

class Users {
  _id: any;
  universityname: String;
  address: String;
  pincode: String;
  email: String;
  contactnumber: String;
  isdelete: Boolean;
  isactive: Boolean;
}

@Component({
  selector: 'app-addUniversity',
  templateUrl: './addUniversity.component.html',
  styleUrls: ['./addUniversity.component.css']
})
export class AddUniversityComponent implements OnInit {
  universityid: any;
  universityname: String;
  address: String;
  pincode: String;
  email: String;
  contactnumber: String;
  isdelete: Boolean;
  isactive: Boolean;
  submitButtonText: string;
  universityForm: FormGroup;
  hide: Boolean = false;
  disableButton: Boolean = false;
  constructor(private activatedRoute: ActivatedRoute,
    public constants: Constants,
    private notificationService: NotificationService,
    private router: Router,
    private universityService: UniversityService,
    fb: FormBuilder,
  ) {

    this.universityForm = fb.group({
      'universityid': [null, Validators.nullValidator],
      'universityname': [null, Validators.compose([Validators.required, Validators.maxLength(150),])],
      'address': [null, Validators.compose([Validators.required])],
      'pincode': [null, Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern('[0-9]*')])],
      'email': [null, Validators.compose([Validators.required, Validators.email])],
      'contactnumber': [null, Validators.compose([Validators.compose([Validators.nullValidator, Validators.minLength(10), Validators.maxLength(12), Validators.pattern('[0-9]*')])])],
      'isactive': [null, Validators.nullValidator],
    });
  }

  ngOnInit() {
    //To pre-fill edit user data
    this.activatedRoute.params.subscribe((params: Params) => {
      this.universityid = params['id'];
      if (this.universityid != null) {
        this.hide = true;
        this.universityService.getUniversityById(this.universityid, true).subscribe((data:any) => {
          this.universityname = data.university.universityname
          this.address = data.university.address
          this.pincode = data.university.pincode
          this.email = data.university.email
          this.contactnumber = data.university.contactnumber
          this.isactive = data.university.isactive
          this.universityid = data.university._id
        });
        this.submitButtonText = this.constants.Update;
        this.hide = false;
      }
      else {
        this.submitButtonText = this.constants.Save;
      }
    }, err => {
      if (err.status == 401) {
        this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
      }
      else {
        this.router.navigate([this.constants.clientUrlNotFound]);
      }
    });
  }

  onSubmit() {
    this.disableButton = true;
    const university = {
      universityid: this.universityid,
      universityname: this.universityname.trim(),
      address: this.address.trim(),
      pincode: this.pincode.trim(),
      email: this.email.trim(),
      contactnumber: this.contactnumber,
      isdelete: 0,
      isactive: this.isactive ? true : false,
    }

    //Upsert University
    this.universityService.putUniversity(university, true).subscribe((data:any) => {
      if (data.success) {
        this.router.navigate([this.constants.clientUrlUniversityList]);
        this.notificationService.showSuccess(data.msg);
      }
      else {
        this.notificationService.showError(data.msg);
        this.disableButton = false;
      }
    }, err => {
      if (err.status == 401) {
        this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
      }
      else {
        this.router.navigate([this.constants.clientUrlNotFound]);
      }
    });
  }
}