import { BaMenuService } from './../app/theme/services/baMenu/baMenu.service';
import { AuthAdminService } from './../services/authadmin.service';
import { Constants } from './../constants';
import { element } from 'protractor';
import { map, filter, mergeMap } from 'rxjs/operators';
// import 'rxjs/add/operator/filter';
// 
// import 'rxjs/add/operator/mergeMap';
import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, Input, HostListener } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, Routes } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { NotificationService } from './../services/notification.service';
import { PAGES_MENU } from './admin.menu';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, AfterViewInit {
  bodyClasses: string = "skin-blue sidebar-mini";
  body = document.getElementsByTagName('body')[0];
  sessionUsername = document.getElementById('sessionUsername');

  title: string;
  constructor(
    private activatedRoute: ActivatedRoute,
    private notification: NotificationService,
    private authAdminService: AuthAdminService,
    public constants: Constants,
    private router: Router,
    private titleService: Title,
    private inputChild: ElementRef,
    private _menuService: BaMenuService) {
    this.router.events.subscribe(url => {
      // Your action/function will go here
      var CURRENT_URL = this.router.url;
      var inputnodes = this.inputChild.nativeElement.firstChild.childNodes;
      for (var index = 0; index < inputnodes.length; index++) {
        var element = inputnodes[index];
      }
    });
  }

  selectParentMenu(menuItem) {
    if (menuItem.classList.contains("sidebar-menu") == false) {
      menuItem.classList.add("active");
      this.selectParentMenu(menuItem.parentElement);
    }
  }


  ngOnInit() {
    this._menuService.updateMenuByRoutes(<Routes>PAGES_MENU);
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data),
      ).subscribe((event) => {
        this.titleService.setTitle(event['title'] + ' | ' + this.constants.applicationName);
        this.title = event['title'];
        var adminDetails = this.authAdminService.getAdminFromStorage();
        $(".layout-boxed > .wrapper").css('overflow', 'hidden');
        var footer_height = $('.main-footer').outerHeight() || 0;
        var neg = $('.main-header').outerHeight() + footer_height;
        var window_height = $(window).height();
        var sidebar_height = $(".sidebar").height() || 0;
        if ($("body").hasClass("fixed")) {
          $(".content-wrapper, .right-side").css('min-height', window_height - footer_height);
        } else {
          var postSetWidth;
          if (window_height >= sidebar_height) {
            $(".content-wrapper, .right-side").css('min-height', window_height - neg);
            postSetWidth = window_height - neg;
          } else {
            $(".content-wrapper, .right-side").css('min-height', sidebar_height);
            postSetWidth = sidebar_height;
          }
        }
      });

    this.body.classList.add("skin-blue");
    this.body.classList.add("sidebar-mini");

  }
  ngOnDestroy() {
    //remove the the body classes
    this.body.classList.remove("skin-blue");
    this.body.classList.remove("sidebar-mini");
  }
  @Input() position: number = 400;
  @Input() showSpeed: number = 500;
  @Input() moveSpeed: number = 1000;
  ngAfterViewInit() {
  }

  @HostListener('window:scroll')
  _onWindowScroll(): void {
  }
}