import { Title } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Constants } from './../constants';

@Injectable()
export class PageService {

    constructor(private http: HttpClient, public constants: Constants) { }

    //get pages
    getPages(tokenFlag) {
        let timeStamp = +new Date();
        let headers = tokenFlag == true ? this.constants.getHeadersWithAuthorization(tokenFlag) : this.constants.getHeaders();
        return this.http.get(this.constants.serverpageGetAll + '?tsp=' + timeStamp, { headers: headers })
            
    }

    //get page by title
    getPageByTitle(title) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeaders();
        return this.http.get(this.constants.serverpageGetByTitle + '/' + title + '?tsp=' + timeStamp, { headers: headers })
            
    }

    //get page by id
    getPageById(id, tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.get(this.constants.serverpageGetById + '/' + id + '?tsp=' + timeStamp, { headers: headers })
            
    }

    // add-update page
    putPage(page, tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.put(this.constants.serverpagePut + '?tsp=' + timeStamp, page, { headers: headers })
            
    }

    // delete page
    deletePage(id, tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.delete(this.constants.serverpageDelete + '/' + id + '?tsp=' + timeStamp, { headers: headers })
            
    }

    //bulk delete
    bulkDelete(deleteid, tokenFlag) {
        let timeStamp = +new Date();
        var headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.post(this.constants.serverpageBulkDelete + '?tsp=' + timeStamp, deleteid, { headers: headers })
            
    }

}