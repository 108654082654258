import { UniversitiesComponent } from './../universities/universities.component';
import { DropdownService } from './../../../services/dropdown.service';

import { CustomValidators } from 'ng2-validation';
import { Component, Injectable, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
/* For Validation */
import { FormGroup, FormControl, Validators, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { Constants } from './../../../constants';
import { NotificationService } from './../../../services/notification.service';
import { UserService } from './../../../services/user.service';

class Users {
  _id: any;
  name: String;
  email: String;
  password: String;
  university: String;
  contactnumber: String;
  isdelete: Boolean;
  isactive: Boolean;
  isemailconfirmed: Boolean;
  isabscondinguser: Boolean;
}
class Universities {
  _id: String;
  universityname: String;
}
@Component({
  selector: 'app-addUser',
  templateUrl: './addUser.component.html',
  styleUrls: ['./addUser.component.css']
})

export class AddUserComponent implements OnInit {
  userid: any;
  name: String;
  email: String;
  password: String;
  university: String;
  contactnumber: String;
  isdelete: Boolean;
  isactive: Boolean;
  isemailconfirmed: Boolean;
  isabscondinguser: Boolean;
  submitButtonText: string;
  provider: String;
  userForm: FormGroup;
  hide: Boolean = false;
  disableButton: Boolean = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    public constants: Constants,
    private notificationService: NotificationService,
    private router: Router,
    private userService: UserService,
    private dropdownService: DropdownService,
    fb: FormBuilder,
  ) {
    let password = new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(15), Validators.pattern("^[a-zA-Z0-9!@#$&()\\-`.+,/\_]+( [a-zA-Z0-9!@#$&()\\-`.+,/\_]+)*$")]);
    let confirmpassword = new FormControl('', CustomValidators.equalTo(password));

    this.activatedRoute.params.subscribe((params: Params) => {
      this.userid = params['id'];
      if (this.userid == null) {
        this.userForm = fb.group({
          'name': [null, Validators.compose([Validators.required, Validators.maxLength(50),])],
          'email': [null, Validators.compose([Validators.required, Validators.email])],
          'userid': [null, Validators.nullValidator],
          'university': [null, Validators.nullValidator],
          'contactnumber': [null, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern('[0-9]*')])],
          'isactive': [null, Validators.nullValidator],
          'isemailconfirmed': [null, Validators.nullValidator],
          'isabscondinguser': [null, Validators.nullValidator],
          'password': password,
          'confirmpassword': confirmpassword,
        })
      }
      else {
        this.userForm = fb.group({
          'name': [null, Validators.compose([Validators.required, Validators.maxLength(50),])],
          'email': [null, Validators.compose([Validators.required, Validators.email])],
          'userid': [null, Validators.nullValidator],
          'university': [null, Validators.nullValidator],
          'contactnumber': [null, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern('[0-9]*')])],
          'isactive': [null, Validators.nullValidator],
          'isemailconfirmed': [null, Validators.nullValidator],
          'isabscondinguser': [null, Validators.nullValidator],
        })
      }
    });
  }
  universities: Universities[] = [];
  ngOnInit() {
    this.getUniversities();
    //To pre-fill edit user data
    this.activatedRoute.params.subscribe((params: Params) => {
      this.userid = params['id'];
      if (this.userid != null) {
        this.hide = true;
        this.userService.getUserById(this.userid, true).subscribe((data:any) => {         
          this.name = data.user.name
          this.email = data.user.email
          this.university = data.user.university != null ? data.user.university : undefined
          this.contactnumber = data.user.contactnumber
          this.isactive = data.user.isactive
          this.provider = data.user.provider
          this.isabscondinguser = data.user.isabscondinguser
          this.isemailconfirmed = data.user.isemailconfirmed
        },
          (err) => {
            if (err.status == 401)
              this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
          }
        );
        this.submitButtonText = this.constants.Update;
        this.hide = false;
      }
      else {
        this.submitButtonText = this.constants.Save;
      }
    });
  }

  getUniversities() {
    this.dropdownService.getUniversity().subscribe((data:any) => {
      this.universities = data.universities;
    });
  }

  onSubmit() {
    this.disableButton = true;
    const user = {
      userId: this.userid,
      name: this.name.trim(),
      email: this.email.trim(),
      password: this.password,
      university: this.university != undefined ? this.university : '',
      contactnumber: this.contactnumber,
      isdelete: 0,
      isactive: this.isactive ? true : false,
      isemailconfirmed: this.isemailconfirmed ? true : false,
      isabscondinguser: this.isabscondinguser ? true : false,
    }

    //upsert users    
    this.userService.putUser(user, true).subscribe((data:any) => {
      if (data.success) {
        this.router.navigate([this.constants.clientUrlUserList]);
        this.notificationService.showSuccess(data.msg);
      }
      else {
        this.notificationService.showError(data.msg);
        this.disableButton = false;
      }
    }, err => {
      if (err.status == 401) {
        this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
      }
      else {
        this.router.navigate([this.constants.clientUrlNotFound]);
      }
    });
  }
}