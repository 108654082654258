import { Title } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Constants } from './../constants';

@Injectable()
export class SyllabusService {

    constructor(private http: HttpClient, public constants: Constants) { }

    //get pages
    getSyllabus(tokenFlag) {
        let timeStamp = +new Date();
        let headers = tokenFlag == true ? this.constants.getHeadersWithAuthorization(tokenFlag) : this.constants.getHeaders();
        return this.http.get(this.constants.serversyllabusGetAll + '?tsp=' + timeStamp, { headers: headers })
            
    }

    //get Subject By SubjectCode
    getSyllabusBySubjectCode(id, tokenFlag){
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.get(this.constants.serversyllabusGetById + '/' + id + '?tsp=' + timeStamp, { headers: headers })
            
    }

    //get Syllabus
    getStudentSyllabus(syllabus){
        let timeStamp = +new Date();
        let headers = this.constants.getHeaders();
        return this.http.post(this.constants.serversyllabusgetSyllabus + '?tsp=' + timeStamp, syllabus, { headers: headers })
            
    }

    // add-update page
    putSyllabus(syllabus,tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.put(this.constants.serversyllabusPut + '?tsp=' + timeStamp, syllabus, { headers: headers })
            
    }

    // delete page
    deleteSyllabus(id,tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.delete(this.constants.serversyllabusDelete + '/' + id + '?tsp=' + timeStamp, { headers: headers })
            
    }

    //bulk delete
    bulkDelete(deleteid, tokenFlag) {
        let timeStamp = +new Date();
        var headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.post(this.constants.serversyllabusBulkDelete + '?tsp=' + timeStamp, deleteid, { headers: headers })
            
    }

}