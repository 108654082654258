import { Title } from '@angular/platform-browser';


export const PAGES_MENU = [
    {
        path: 'secure',
        children: [
            {
                path: 'home',
                data: {
                    menu: {
                        title: 'Dashboard',
                        icon: 'fa fa-dashboard',
                        selected: false,
                        expanded: false,
                        order: 0
                    }
                }
            },
            {
                path: 'adminUser',
                data: {
                    menu: {
                        title: 'Administrator',
                        icon: 'fa fa-user',
                        selected: false,
                        expanded: false,
                        order: 0
                    }
                }
            },
            {
                path: 'users',
                data: {
                    menu: {
                        title: 'Users',
                        icon: 'fa fa-users',
                        selected: false,
                        expanded: false,
                        order: 0
                    }
                },
            },
            {
                path: 'books',
                data: {
                    menu: {
                        title: 'Books',
                        icon: 'fa fa-book',
                        selected: false,
                        expanded: false,
                        order: 0
                    }
                }
            },
            {
                path: 'logs',
                data: {
                    menu: {
                        title: 'Book Instance Logs',
                        icon: 'fa fa-history',
                        selected: false,
                        expanded: false,
                        order: 0
                    }
                }
            },
            {
                path: '',
                data: {
                    menu: {
                        title: 'Book Requests',
                        icon: 'fa fa-circle-o',
                        selected: false,
                        expanded: false,
                        order: 100,
                    }
                },
                children: [
                    {
                        path: 'contributebook',
                        data: {
                            menu: {
                                title: 'Donate',
                            }
                        }
                    },
                    {
                        path: 'bookrequest',
                        data: {
                            menu: {
                                title: 'Seeking',
                            }
                        }
                    }
                ]
            },
            {
                path: '',
                data: {
                    menu: {
                        title: 'Lookups',
                        icon: 'fa fa-circle-o',
                        selected: false,
                        expanded: false,
                        order: 100,
                    }
                },
                children: [
                    {
                        path: 'contact',
                        data: {
                            menu: {
                                title: 'Contact Us',
                            }
                        }
                    },
                    {
                        path: 'pages',
                        data: {
                            menu: {
                                title: 'Pages',
                            }
                        }
                    },
                    {
                        path: 'universities',
                        data: {
                            menu: {
                                title: 'University',
                            }
                        }
                    },
                    {
                        path: 'stream',
                        data: {
                            menu: {
                                title: 'Stream',
                            }
                        }
                    },
                    {
                        path: 'subject',
                        data: {
                            menu: {
                                title: 'Subject',
                            }
                        }
                    },
                    {
                        path: 'syllabus',
                        data: {
                            menu: {
                                title: 'Syllabus',
                            }
                        }
                    },
                    {
                        path: 'associates',
                        data: {
                            menu: {
                                title: 'Our Associates',
                            }
                        }
                    },
                    {
                        path: 'viewFeedback',
                        data: {
                            menu: {
                                title: 'Feedback',
                            }
                        }
                    }
                ]
            },
        ]
    }
];
