import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tokenNotExpired } from 'angular2-jwt';


import { Constants } from './../constants';

@Injectable()
export class OurAssociatesService {
    associates: any;
    constructor(private http: HttpClient, public constants: Constants) { }

    //get all admin users 
    getAssociates(tokenFlag) {
        let timeStamp = +new Date();
        var headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.get(this.constants.getServerAssociates + '?tsp=' + timeStamp, { headers: headers })
            
    }

    //get admin by id
    getAssociatesById(id,tokenFlag) {
        let timeStamp = +new Date();
        var headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.get(this.constants.serverAssociatesGetById + '/' + id + '?tsp=' + timeStamp, { headers: headers })
            
    }

    //update admin
    putAssociates(associates,tokenFlag) {
        let timeStamp = +new Date();
        var headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.put(this.constants.putServerAssociates + '?tsp=' + timeStamp, associates, { headers: headers })
            
    }

    //delete admin 
    deleteAssociates(id,tokenFlag) {
        let timeStamp = +new Date();
        var headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.delete(this.constants.deleteServerAssociates + '/' + id + '?tsp=' + timeStamp, { headers: headers })
            
    }
    
    //bulk delete
    bulkDelete(deleteid,tokenFlag) {
        let timeStamp = +new Date();
        var headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.post(this.constants.bulkDeleteServerAssociates + '?tsp=' + timeStamp, deleteid, { headers: headers })
            
    }
}