import { ActivatedRoute, Params, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { AuthUserService } from './../../../services/authuser.service';
import { BookRequestService } from './../../../services/bookrequest.service';
import { ConfirmDialogComponent } from './../confirmDialog/confirmDialog.component';
import { Constants } from '../../../constants';
import { DataTableDirective } from 'angular-datatables';
import { DialogService } from 'ng2-bootstrap-modal';
import { DropdownService } from './../../../services/dropdown.service';
import { HandleError } from './../../../error';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NotificationService } from './../../../services/notification.service';
import { Subject } from 'rxjs';
import { UserService } from './../../../services/user.service';

class BookRequests {
  _id: any;
  bookid: any;
  userid: any;
  messagefromuser: any;
  requesttype: any;
}

@Component({
  selector: 'app-contribute-book',
  templateUrl: './contribute-book.component.html',
  styleUrls: ['./contribute-book.component.scss']
})
export class ContributeBookComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;
  dtOptions = this.constants.dataTableSettings && {
    order: [7, 'desc'],
    columnDefs: [
      {
        "targets": [0, 1, 3],
        "orderable": false,
      },
      { "width": "15%", "targets": 2 },
      {
        "targets": 7,
        type: 'num',
        render: function (data, type) {

          if (type == 'sort') {
            return Date.parse(data).valueOf();
          }
          return data;
        }
      }
    ],
    fnDrawCallback: function () {
      if ((document.getElementById("bookrequests_paginate")) != null) {
        if ((document.getElementById("bookrequests_paginate")).click) {
          var table = $("#bookdata").DataTable();
          var rowchecked = 0;
          var ValueById = document.getElementsByName("sizecb")
          for (var index = 0; index < ValueById.length; index++) {
            if ($(ValueById[index]).prop("checked") == true) {
              //check all conter var ++
              rowchecked = rowchecked + 1;
            }
          }
          if (ValueById.length == rowchecked) {
            $("#checkAll").prop('checked', true);
            $('.model').css('display', 'block');
          } else {
            $("#checkAll").prop('checked', false);
          }
          if (rowchecked == 0) {
            $("#checkAll").prop('checked', false);
            $('.model').css('display', 'none');
          }
          else if (rowchecked > 0) {
            $('.model').css('display', 'block');
          }
        }
      }
    }
  };
  requestStatus: String[];
  status: string;
  userKeyword: string;
  bookKeyword: string;
  transactionCompleted: Boolean;
  hide: Boolean = false;
  updateStatusVal: Boolean = false;
  deleteAll: Boolean;
  updateAllStatusVal: Boolean;
  showRemarks: Boolean = false;
  showdelete: Boolean = false;
  submitButtonText: string;
  requestId: String[] = [];;
  requesttype: Boolean = true;
  sendemail: Boolean;
  requeststatus: any;
  requeststatusid: String;
  requestdate: Date;
  remarks: String;
  istransactioncomplete: Boolean;
  bookremark: FormGroup; /**Form Instance */
  disableButton: Boolean = false;

  constructor(private authuserService: AuthUserService,
    private bookrequest: BookRequestService,
    private userService: UserService,
    public constants: Constants,
    private activatedRoute: ActivatedRoute,
    private errorHandler: HandleError,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private dropdownservice: DropdownService,
    private router: Router,
    private fb: FormBuilder,
  ) {
    this.submitButtonText = this.constants.Update;
    this.bookremark = fb.group({
      'remarks': [null, Validators.required],
      'requeststatus': [null, Validators.required],
      'istransactioncomplete': [null, Validators.nullValidator],
      'sendemail': [null, Validators.nullValidator],
      'allocatedinstance': [null, Validators.nullValidator]
    })
  }
  bookRequestStatus: String[] = [];
  getStatusForUpdate() {
    this.bookRequestStatus = [];
    this.bookRequestStatus.push(this.constants.Collected);
    // this.dropdownservice.getRequestStatus().subscribe((data:any) => {
    //   if (data.success) {
    //     this.bookRequestStatus = data.requeststatus.filter(item => item != this.constants.donated && item != this.constants.collected);
    //   }
    // });
  }

  showConfirm(id) {

    let disposable = this.dialogService.addDialog(ConfirmDialogComponent, {
      title: "Delete Confirmation",//this.constants.deleteConfirmationTitle"",
      message: "Are you sure you want to delete the selected record?",//this.constants.deleteConfirmation
    })
      .subscribe((isConfirmed) => {
        //Dialog result
        if (isConfirmed) {
          this.bookrequest.deletebookrequest(id, true).subscribe((data: any) => {
            if (data.success) {
                this.constants.removeCacheData('DashboardCounts', 0);
                // Change by Arpit
              this.constants.removeCacheData('DashboardBookList', 0);
              this.constants.removeCacheData('ListOfNeededBook', 0);
              this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Destroy the table first
                dtInstance.destroy();
                // Call the dtTrigger to rerender again
                this.bindSearchRequest();
              });
              this.notificationService.showSuccess(data.msg);
            }
            else {
              this.notificationService.showError(data.msg);
            }
          }, err => {
            if (err.status == 401) {
              this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
            }
            else {
              this.router.navigate([this.constants.clientUrlNotFound]);
            }
          });
        }
      });

    //We can close dialog calling disposable.unsubscribe();
    //If dialog was not closed manually close it by timeout
    setTimeout(() => {
      disposable.unsubscribe();
    }, 10000);
  }

  bookRequests: BookRequests[] = [];

  dtTrigger: Subject<BookRequests> = new Subject<BookRequests>();

  ngOnInit() {
    localStorage.setItem('currentURL', this.router.url);
    this.getRequestStatus();
    this.bindSearchRequest();
    this.getStatusForUpdate();
  }

  getRequestStatus() {
    this.dropdownservice.getRequestStatus().subscribe((data: any) => {
      if (data.success) {
        this.requestStatus = data.requeststatus.filter(item => item != this.constants.donated);;
      }
    });
  }

  bindRequest() {
    this.bookrequest.getbookrequest(false, true).subscribe((data: any) => {
      this.bookRequests = data.bookrequests;
      this.dtTrigger.next();
    }, err => {
      if (err.status == 401) {
        this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
      }
      else {
        this.router.navigate([this.constants.clientUrlNotFound]);
      }
    });
  }

  resetSearch() {
    this.userKeyword = null;
    this.status = undefined;
    this.transactionCompleted = false;
    this.hide = true;
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.bindSearchRequest();
    });
  }

  bindSearchRequest() {
    const model = {
      requesttype: false,
      requeststatus: this.status != undefined ? this.status : '',
      userKeyword: this.userKeyword != undefined ? this.userKeyword : '',
      transactionCompleted: this.transactionCompleted != undefined ? this.transactionCompleted : false
    }
    this.hide = true;
    this.bookrequest.getBySearch(model, true).subscribe((data: any) => {
      this.bookRequests = data.bookrequests;
      this.dtTrigger.next();
      this.hide = false;
      this.requestId = [];
      if(this.showRemarks = false){
        $('.model').css('display', 'none');
      }
    }, err => {
      if (err.status == 401) {
        this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
      }
      else {
        this.router.navigate([this.constants.clientUrlNotFound]);
      }
    });
  }

  SearchRequest() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
    });
    this.bindSearchRequest();
  }

  checkAll(ev) {
    var elementArray = document.getElementsByName("sizecb");
    for (var index = 0; index < elementArray.length; index++) {
      var checkBox = <HTMLInputElement>elementArray[index]
      if (this.requestId.length == 0) {
        this.requestId.push(elementArray[index].id);
      }
      else {
        var resultArray = this.requestId.filter(data => data == elementArray[index].id);
        if (resultArray.length == 0) {
          this.requestId.push(elementArray[index].id);
        } else {
          var arrayindex = this.requestId.indexOf(elementArray[index].id);
          this.requestId.splice(arrayindex, 1);
        }
      }
      checkBox.checked = ev.target.checked
    }
    if (this.requestId.length > 0) {
      this.showRemarks = true;
      $('.model').css('display', 'block');
    }
    else {
      this.showRemarks = false;
      $('.model').css('display', 'none');
    }
    if (!ev.target.checked) {
      $('.model').css('display', 'none');
    }
  }

  recordChecked(ev) {
    var id = ev.currentTarget.id;
    var rowCount = 0;
    var elementArray = document.getElementsByName("sizecb");
    var resultArray = this.requestId.filter(data => data == id);
    var elementId = document.getElementById(id);
    if (resultArray != null && resultArray.length != 0) {
      var index = this.requestId.findIndex(x => x === id)
      this.requestId.splice(index, 1);
      this.updateAllStatusVal = false;
      $("#checkAll").prop('checked', false);
    }
    else {
      this.requestId.push(id);
    }
    if (this.requestId.length > 0) {
      this.showRemarks = true;
      $('.model').css('display', 'block');
    }
    else {
      this.showRemarks = false;
      $('.model').css('display', 'none');
    }
    for (var index = 0; index < elementArray.length; index++) {
      var checkBox = <HTMLInputElement>elementArray[index]
      if (checkBox.checked == false) {
        rowCount = rowCount + 1;
      }
    }
    var selectedrow = elementArray.length - rowCount;
    if (selectedrow == elementArray.length) {
      $("#checkAll").prop('checked', true);
    }
  }

  putRemarks() {
    this.disableButton = true;
    const remarks = {
      bookrequestId: this.requestId,
      remarks: this.remarks,
      requeststatus: this.requeststatusid,
      requesttype: this.requesttype,
      sendemail: this.sendemail != undefined ? this.sendemail : 'false',
    }
    this.bookrequest.putbulkremarks(remarks, true).subscribe((data: any) => {
      this.disableButton = false;
      this.bookremark.reset();
      this.showRemarks = false;
      this.updateAllStatusVal = false;
      this.SearchRequest();
      if (data.success) {
        this.notificationService.showSuccess(data.msg);
      } else {
        this.notificationService.showError(data.msg);
      }
      this.requestId = [];
    }, err => {
      if (err.status == 401) {
        this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
      }
      else {
        this.router.navigate([this.constants.clientUrlNotFound]);
      }
    });
  }

  bulkDelete() {
    const deleteid = {
      deleteid: this.requestId
    };
    let disposable = this.dialogService.addDialog(ConfirmDialogComponent, {
      title: "Delete Confirmation",//this.constants.deleteConfirmationTitle"",
      message: "Are you sure you want to delete the selected record?",//this.constants.deleteConfirmation
    })
      .subscribe((isConfirmed) => {
        //Dialog result
        if (isConfirmed) {
          this.bookrequest.bulkDelete(deleteid, true).subscribe((data: any) => {
            if (data.success) {
                this.constants.removeCacheData('DashboardCounts', 0);
                // Change by Arpit
              this.constants.removeCacheData('DashboardBookList', 0);
              this.constants.removeCacheData('ListOfNeededBook', 0);
              this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Destroy the table first
                dtInstance.destroy();
                // Call the dtTrigger to rerender again
                this.bindSearchRequest();
                this.requestId = [];
              });
              this.notificationService.showSuccess(data.msg);
            }
            else {
              this.bindSearchRequest();
              this.notificationService.showError(data.msg);
            }
          });
        }
      });

    //We can close dialog calling disposable.unsubscribe();
    //If dialog was not closed manually close it by timeout
    setTimeout(() => {
      disposable.unsubscribe();
    }, 10000);
  }
}