import { AuthUserService } from './../services/authuser.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Constants } from './../constants';

@Injectable()
export class AuthUserGuard implements CanActivate {
    constructor(private authuserService: AuthUserService,
        private router: Router,
        public constants: Constants) {
    }

    // check whether the user is logged in or not
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (this.authuserService.loggedIn()) {
            return true;
        } else {
            this.router.navigate([this.constants.clientUrlSignIn], { queryParams: { returnUrl: state.url } });
            return false;
        }
    }
}
