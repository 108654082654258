import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { BookService } from "./../../../services/book.service";

import { element } from 'protractor';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DialogService } from "ng2-bootstrap-modal";

import { Constants } from '../../../constants';
import { DataTableDirective } from 'angular-datatables';
import { NotificationService } from './../../../services/notification.service';
import { MainSearchResultComponent } from './../main-searchResult/main-searchResult.component';

@Component({
    selector: 'app-main-searchBar',
    templateUrl: './main-searchBar.component.html',
    styleUrls: ['./main-searchBar.component.css'],
})
export class MainSearchBarComponent implements OnInit {
    @Output() notifyParent: EventEmitter<any> = new EventEmitter();
    search: String;
    constructor(public constants: Constants,
        private bookService: BookService,
        private router: Router,
        private route: ActivatedRoute,
    ) { }

    ngOnInit() {
        this.route.queryParams
            .subscribe(params => {
                this.search = params['search'];
            });
    }

    onSubmit() {
        var searchResult = this.search.trim();
        if (searchResult != undefined && searchResult != "") {
            this.router.navigate([this.constants.clientUrlUserSearch], { queryParams: { search: searchResult } });
            document.getElementById('navbar-collapse').classList.remove('in');
        }
    }
}