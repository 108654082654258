import { StreamService } from './../../../services/stream.service';
import { BookService } from './../../../services/book.service';
import { BookRequestService } from './../../../services/bookrequest.service'; 
import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { CustomValidators } from 'ng2-validation';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Constants } from './../../../constants';
import { NotificationService } from './../../../services/notification.service';
import { SubjectService } from "./../../../services/subject.service";
import { DropdownService } from './../../../services/dropdown.service';
import { AuthUserService } from './../../../services/authuser.service';
import { SyllabusService } from './../../../services/syllabus.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ExcelService } from 'services/excel.service'; 
import { ChangeDetectionStrategy,  } from '@angular/core';
import { BooksComponent } from 'admin/components/books/books.component';


class University_Stream {
  _id: String;
  name: String;
}

class yearORsemesters {
  yearORsem: any;
  primary: any;
}

@Component({
  selector: "app-syllabus",
  templateUrl: "./syllabus.component.html",
  styleUrls: ["./syllabus.component.scss"]
})

export class SyllabusComponent implements OnInit {
  avlVBook: any[];
  @ViewChild(DataTableDirective, { static: true }) dtElement: DataTableDirective;
  @Input('totalAvailableBooks') totalAvailableBooks: number;
  private datatableElement: DataTableDirective;
  syllabusForm: FormGroup;
  university: String;
  stream: String;
  isSemester: Boolean;
  totalActiveBook: number = 0;
  yearORSemester: any;
  isAvailableBook: boolean;
  userdata: any;
  requestId: String[] = [];
    selectedIndexs: any[] = [{
    id:undefined
  }];
  boxcolor = [{
    color: '#8bd22f'
  },
  {
    color: '#f95372'
  },
  {
    color: '#FF9800'
  },
  {
    color: '#00abff'
  },
  {
    color: '#d91a23'
  },
  {
    color: '#5bc0de'
  },
  {
    color: '#8bd22f'
  },
  {
    color: '#f95372'
  },
  ];
  incriment: Number = 0;
  bookid: String;
  userid: String;
  requestdate: Date;
  requesttype: Boolean;
  contactnumber: String;
  hide: Boolean;
  //Created By Arpit for #1806
  dtOptions = this.constants.dataTableSettings && {
    order: [2, 'asc'],
    columnDefs: [
      {
        "targets": [0],
        "orderable": false,
      }
    ],
    language: {
      "emptyTable": "No data available"
    },
    // fnDrawCallback: function () {
    //       if ((document.getElementById("syllabusdata_paginate")).click != null) {
    //         var table = $("#syllabusdata").DataTable();
    //         var rowchecked = 0;
    //         var ValueById = document.getElementsByName("sizecb")
    //         for (var index = 0; index < ValueById.length; index++) {
    //           if ($(ValueById[index]).prop("checked") == true) {
    //             //check all conter var ++
    //             rowchecked = rowchecked + 1;
    //           }
    //         }
    //         if (ValueById.length == rowchecked) {
    //           $("#checkAll").prop('checked', true);
    //           $('.model').css('display', 'block');
    //         } else {
    //           $("#checkAll").prop('checked', false);
    //         }
    //         if (rowchecked == 0) {
    //           $("#checkAll").prop('checked', false);
    //           $('.model').css('display', 'none');
    //         }
    //       }
    // }
  };
  
  constructor(
    private authuserservice: AuthUserService,
    private subjectService: SubjectService,
    private dropdownService: DropdownService,
    private departmentService: StreamService,
    private syllabusService: SyllabusService,
    private bookrequestService: BookRequestService,
    private bookService: BookService,
    private notificationService: NotificationService,
    public constants: Constants,
    private router: Router,
    fb: FormBuilder,
    private excelService: ExcelService,
  ) {
    this.userdata = this.authuserservice.getUserFromStorage();
    this.syllabusForm = fb.group({
      'university': [null, Validators.required],
      'stream': [null, Validators.required],
      // 'isSemester': [null, Validators.nullValidator],
      'yearORSemester': [null, Validators.required],
      'isAvailableBook': [null, Validators.nullValidator],
    });
  }

  syllabuses: any[] = [];
  primaryBooks: any[] = [];
  referenceBooks: any[] = [];
  dtTrigger: Subject<any> = new Subject<any>();

  ngOnInit() {
    this.getUniversity();
    this.bindGrid(0);
    this.userdata = this.authuserservice.getUserFromStorage();
  }

  universities = [];
  getUniversity() {
    this.dropdownService.getUniversity().subscribe((data: any) => {
      this.universities = data.universities;
    });
  }

  streams: University_Stream[] = [];
  getStream() {    
    this.selectedIndexs[0].id= undefined;
	this.dropdownService.getStream(this.university).subscribe((data: any) => {
      this.stream = undefined;
      this.yearORSemester = undefined;
      this.streams = data.streams;
      this.emptySyllabus();
    });

  }

  emptySyllabus() {
    if (this.syllabuses.length > 0) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // document.getElementById('data').className = "show1";
        // Call the dtTrigger to rerender again
        this.bindGrid(0);
        });
    }
    
  }

  yearSem = []
  yearORsemesters: yearORsemesters[] = [];

  getYearORSemester() {
    // this.yearSem.length = 0;
    var ids = {
      universityid: this.university,
      streamid: this.stream
    }
    this.subjectService.getAvailableBookCountYearSemesterWise(ids, true).subscribe((data: any) => {
      if (data.success) {
        this.avlVBook = data.subject;
        this.dropdownService.getYearORSemester().subscribe((data: any) => {
          if (this.streamDuration == null) {
            this.yearORsemesters = data.yearORsem;
          }
          else {
            this.yearORsemesters = data.yearORsem.filter(item => item <= this.streamDuration);
            var index = 0;
            this.yearORsemesters.forEach((item) => {
              this.avlVBook.forEach((data) => {
                if (data._id == item) {
                  this.yearSem.push({ id: item, bgcolor: this.boxcolor[index], availableBook: data.avlBook, totalBook: data.totalBook });
                }
              });
              index += 1;
            })
          }
        });
      }
    });
  }


  streamDuration: Number;
  streamDetail() {
    this.yearSem.length = 0;    
this.selectedIndexs[0].id= undefined;    
    this.departmentService.getDepartmentById(this.stream, true).subscribe((data: any) => {
      if (data.success) {
        this.isSemester = data.department.isSemester;
        this.streamDuration = data.department.yearORsemester;
        this.getYearORSemester();
        this.emptySyllabus();
         }
      // else{this.dtElement.dtInstance.destroy();}
      // else{this.getYearORSemester.length == 0;}

    });

  }

  onSubmit(yearORsem) {

    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // document.getElementById('data').className = "show1"; 
      // Call the dtTrigger to rerender again
      this.bindGrid(yearORsem);
    });
  }

  // Change By Arpit for #1806
  bindGrid(yearORsem) {
    this.yearORSemester = yearORsem;
    const syllabus = {
      universityId: this.university,
      streamId: this.stream,
      isSemester: this.isSemester,
      yearORSemester: this.yearORSemester,
      isAvailableBook: this.isAvailableBook
    }
    this.syllabusService.getStudentSyllabus(syllabus).subscribe((data: any) => {
      if (data.success) {
        this.totalActiveBook = 0;
        this.syllabuses = data.syllabus;
        if (this.syllabuses != null) {
          this.syllabuses.forEach(item => {
            if (item.primary.length != 0) {
              if (this.isAvailableBook === true) {
                var selectAvailableBook = item.primary;
                item.primary = [];
                for (var index = 0; index < selectAvailableBook.length; index++) {
                  var totalAvailableInstances = 0;
                  for (var data = 0; data < item.bookinstances.length; data++) {
                    var primary = selectAvailableBook[index].bookId;
                    var instance = item.bookinstances[data].bookid;
                    if (primary == instance && item.bookinstances[data].isallocated == false && item.bookinstances[data].isdelete == false) {
                      if (item.primary.length !== 0) {
                        var isAlreadyAvailable = false;
                        totalAvailableInstances = totalAvailableInstances + 1;
                        selectAvailableBook[index].totalBookInstances = totalAvailableInstances;
                        item.primary.filter((book) => {
                          if (book.bookId === primary) { isAlreadyAvailable = true; }
                        });
                        if (isAlreadyAvailable == false) {
                          this.totalActiveBook = this.totalActiveBook + 1;
                          selectAvailableBook[index].isActive = true;
                          item.primary.push(selectAvailableBook[index]);
                        }
                      } else {
                        this.totalActiveBook = this.totalActiveBook + 1;
                        selectAvailableBook[index].isActive = true;
                        totalAvailableInstances = totalAvailableInstances + 1;
                        selectAvailableBook[index].totalBookInstances = totalAvailableInstances;
                        item.primary.push(selectAvailableBook[index]);
                      }
                    }
                  }
                }
              } else {
                for (var index = 0; index < item.primary.length; index++) {
                  var isAlreadyAvailable = false;
                  var totalAvailableInstances = 0;
                  for (var data = 0; data < item.bookinstances.length; data++) {
                    var primary = item.primary[index].bookId;
                    var instance = item.bookinstances[data].bookid[0];
                    if (primary == instance && item.bookinstances[data].isallocated == false && item.bookinstances[data].isdelete == false) {
                      if (isAlreadyAvailable == false) { this.totalActiveBook = this.totalActiveBook + 1; isAlreadyAvailable = true; }
                      item.primary[index].isActive = true;
                      totalAvailableInstances = totalAvailableInstances + 1;
                      item.primary[index].totalBookInstances = totalAvailableInstances;
                    }
                  }
                }
              }
            }
          })
          this.syllabuses.forEach(item => {
            if (item.reference.length != 0) {
              if (this.isAvailableBook === true) {
                var selectReference = item.reference;
                item.reference = [];
                for (var index = 0; index < selectReference.length; index++) {
                  var totalAvailableInstances = 0;
                  for (var data = 0; data < item.bookinstances.length; data++) {
                    var reference = selectReference[index].bookId;
                    var instance = item.bookinstances[data].bookid;
                    if (reference == instance && item.bookinstances[data].isallocated == false && item.bookinstances[data].isdelete == false) {
                      if (item.reference.length !== 0) {
                        var isAlreadyAvailable = false;
                        totalAvailableInstances = totalAvailableInstances + 1;
                        selectReference[index].totalBookInstances = totalAvailableInstances;
                        item.reference.filter((book) => {
                          if (book.bookId === reference) { isAlreadyAvailable = true; }
                        });
                        if (isAlreadyAvailable == false) {
                          this.totalActiveBook = this.totalActiveBook + 1;
                          selectReference[index].isActive = true;
                          item.reference.push(selectReference[index]);
                        }
                      } else {
                        this.totalActiveBook = this.totalActiveBook + 1;
                        selectReference[index].isActive = true;
                        totalAvailableInstances = totalAvailableInstances + 1;
                        selectReference[index].totalBookInstances = totalAvailableInstances;
                        item.reference.push(selectReference[index]);
                      }
                    }
                  }
                }
              } else {
                for (var index = 0; index < item.reference.length; index++) {
                  var isAlreadyAvailable = false;
                  var totalAvailableInstances = 0;
                  for (var data = 0; data < item.bookinstances.length; data++) {
                    var reference = item.reference[index].bookId;
                    var instance = item.bookinstances[data].bookid[0];
                    if (reference == instance && item.bookinstances[data].isallocated == false && item.bookinstances[data].isdelete == false) {
                      if (isAlreadyAvailable == false) { this.totalActiveBook = this.totalActiveBook + 1; isAlreadyAvailable = true; }
                      item.reference[index].isActive = true;
                      totalAvailableInstances = totalAvailableInstances + 1;
                      item.reference[index].totalBookInstances = totalAvailableInstances;
                    }
                  }
                }
              }
            }
          })
        }
        this.dtTrigger.next();
      }
    });
  }

  checkAll(ev) {
    var elementArray = document.getElementsByName("sizecb");
    for (var index = 0; index < elementArray.length; index++) {
      var checkBox = <HTMLInputElement>elementArray[index]
      if (this.requestId.length == 0) {
        this.requestId.push(elementArray[index].id);
      }
      else {
        var resultArray = this.requestId.filter(data => data == elementArray[index].id);
        if (resultArray.length == 0) {
          this.requestId.push(elementArray[index].id);
        } else {
          var arrayindex = this.requestId.indexOf(elementArray[index].id);
          this.requestId.splice(arrayindex, 1);
        }
      }
      checkBox.checked = ev.target.checked
    }
    if (this.requestId.length > 0) {
      // this.showdelete = true;
      $('.model').css('display', 'block');
    }
    else {
      // this.showdelete = false;
      $('.model').css('display', 'none');
    }
    if (!ev.target.checked) {
      // this.requestId = [];
      $('.model').css('display', 'none');
    }
  }

  recordChecked(ev) {
    var id = ev.currentTarget.id;
    var rowCount = 0;
    var elementArray = document.getElementsByName("sizecb");
    var resultArray = this.requestId.filter(data => data == id);
    var elementId = document.getElementById(id);
    if (resultArray != null && resultArray.length != 0) {
      var index = this.requestId.findIndex(x => x === id)
      this.requestId.splice(index, 1);
      // this.deleteAll = false;
      $("#checkAll").prop('checked', false);
    }
    else {
      this.requestId.push(id);
    }
    if (this.requestId.length > 0) {
      // this.showdelete = true;
      $('.model').css('display', 'block');
    }
    else {
      // this.showdelete = false;
      $('.model').css('display', 'none');
    }
    for (var index = 0; index < elementArray.length; index++) {
      var checkBox = <HTMLInputElement>elementArray[index]
      if (checkBox.checked == false) {
        rowCount = rowCount + 1;
      }
    }
    var selectedrow = elementArray.length - rowCount;
    if (selectedrow == elementArray.length) {
      // this.deleteAll = true;
      $("#checkAll").prop('checked', true);
    }
  }

  // SendRequest(flag) {
  //   if (flag) {
  //     this.router.navigate([this.constants.clientUrlUserSeekingRequest], { queryParams: { flag: flag, searchKey: '', mid: this.requestId } });
  //   }
  //   else {
  //     this.router.navigate([this.constants.clientUrlUserContributeRequest], { queryParams: { flag: flag, searchKey: '', mid: this.requestId } });
  //   }
  // }

  SendRequest(flag, infoLinkId, title) {
    if (flag) {
      this.router.navigate([this.constants.clientUrlUserSeekingRequest], { queryParams: { flag: flag, searchKey: title, cid: infoLinkId } });
    }
    else {
      this.router.navigate([this.constants.clientUrlUserContributeRequest], { queryParams: { flag: flag, searchKey: title, cid: infoLinkId } });
    }
  }

  onChange(ev) {
    ev.target.checked ? this.isAvailableBook = true : this.isAvailableBook = false;
    if (this.yearORSemester) {
      this.onSubmit(this.yearORSemester);
    }
    else { return; }

  }

  exportAsXLSX(): void {
    var exportSyllabus = [];
    var primaryBooks = [];
    var referenceBooks = [];
    var university;
    var isbn_10;
    var stream;
    var yearORSemester
    var lableOFYearSem = this.isSemester ? this.constants.semester : this.constants.year;
    this.universities.forEach((data) => {
      data._id == this.university ? university = data.universityname : this.constants.notAvailable
    });
    this.streams.forEach((data) => { data._id == this.stream ? stream = data.name : this.constants.notAvailable });
    this.yearORsemesters.forEach((data) => {
      data == this.yearORSemester ? yearORSemester = this.yearORSemester : this.constants.notAvailable
    });
    this.syllabuses.forEach((item) => {debugger;

      item.book.forEach((book) => {

      if (book.isbnnumber != undefined && book.isbnnumber != "") {

        if (book.isbnnumber[0].identifier) {
          isbn_10 = book.isbnnumber[0] ? book.isbnnumber[0].identifier : this.constants.notAvailable
          isbn_10 += book.isbnnumber[1] ? "," + book.isbnnumber[1].identifier : '';
        } else {
          isbn_10 = book.isbnnumber ? book.isbnnumber : this.constants.notAvailable;
        }
      
      item.primary.forEach((books) => {
if(book._id == books.bookId){
 
        exportSyllabus.push(
          {
            "University": university,
            "Stream": stream,
            "Year/Semester": yearORSemester + "-" + lableOFYearSem, 
            "Subject Code": item.subjectCode[0],
            "Subject Name": item.subjectName[0],
            "Additional Info": item.additionalInfo[0] != "" && item.additionalInfo[0] != undefined && item.additionalInfo[0] != null ? item.additionalInfo[0] : this.constants.notAvailable,
            "Primary Book": books.title,
            "Reference Book": this.constants.notAvailable,
            "Author":book.author[0],
            "Instance": books.totalBookInstances ? books.totalBookInstances : 0,
            "ISBN Number": isbn_10

          }
        );
        // primaryBooks.push(books.title) 
        }
      });
    
      item.reference.forEach((books) => {
        if(book._id == books.bookId){
        exportSyllabus.push(
          {
            "University": university,
            "Stream": stream,
            "Year/Semester": yearORSemester + "-" + lableOFYearSem,
            "Subject Code": item.subjectCode[0],
            "Subject Name": item.subjectName[0],
            "Additional Info": item.additionalInfo[0] != "" && item.additionalInfo[0] != undefined && item.additionalInfo[0] != null ? item.additionalInfo[0] : this.constants.notAvailable,
            "Primary Book": this.constants.notAvailable,
            "Reference Book": books.title,
            "Author":book.author[0],
            "Instance": books.totalBookInstances ? books.totalBookInstances : 0,
            "ISBN Number": isbn_10

          }
        );
        //  referenceBooks.push(books.title) 
      }
    });
    }
    });
  });
    this.excelService.exportSyllabusAsExcelFile(exportSyllabus, 'Syllabus');
}
public setRow(__index) {
    this.selectedIndexs.length = 0;
        this.selectedIndexs.push({id:__index});
    return;
  }
  }
  