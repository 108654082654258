import { Title } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Constants } from './../constants';

@Injectable()
export class SubjectService {

    constructor(private http: HttpClient, public constants: Constants) { }

    //get Subject
    getSubject(tokenFlag) {
        let timeStamp = +new Date();
        let headers = tokenFlag == true ? this.constants.getHeadersWithAuthorization(tokenFlag) : this.constants.getHeaders();
        return this.http.get(this.constants.serversubjectGetAll + '?tsp=' + timeStamp, { headers: headers })

    }

    //get Subject
    getSubjectName(subject, tokenFlag) {
        let timeStamp = +new Date();
        let headers = tokenFlag == true ? this.constants.getHeadersWithAuthorization(tokenFlag) : this.constants.getHeaders();
        return this.http.post(this.constants.serversubjectGet + '?tsp=' + timeStamp, subject, { headers: headers })

    }

    //get Subject By SubjectCode
    getSubjectBySubjectCode(id, tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.get(this.constants.serverGetBySubjectCode + '/' + id + '?tsp=' + timeStamp, { headers: headers })

    }

    //get page by title
    getSubjectById(id, tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.get(this.constants.serversubjectGetById + '/' + id + '?tsp=' + timeStamp, { headers: headers })

    }

    // add-update page
    putSubject(subject, tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.put(this.constants.serversubjectPut + '?tsp=' + timeStamp, subject, { headers: headers })

    }

    // //get page by title
    // getDepartmentByStreamId(id, tokenFlag){
    //     let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
    //     return this.http.get(this.constants.serverdepartmentGetByStreamId + '/' + id, { headers: headers })
    //         
    // }

    // delete page
    deleteSubject(id, tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.delete(this.constants.serversubjectDelete + '/' + id + '?tsp=' + timeStamp, { headers: headers })

    }

    //bulk delete
    bulkDelete(deleteid, tokenFlag) {
        let timeStamp = +new Date();
        var headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.post(this.constants.serversubjectBulkDelete + '?tsp=' + timeStamp, deleteid, { headers: headers })

    }
    //Get year or sem wice total bookinstance and available bookinstance

    getAvailableBookCountYearSemesterWise(ids, tokenFlag) {
        let timeStamp = +new Date();
        let headers = tokenFlag == true ? this.constants.getHeadersWithAuthorization(tokenFlag) : this.constants.getHeaders();
        return this.http.post(this.constants.serversubjectGetAllSemORYearWise + '?tsp=' + timeStamp, ids, { headers: headers })

    }

}