import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { DialogService } from 'ng2-bootstrap-modal';

import { AuthAdminService } from "./../../../services/authadmin.service";
import { Constants } from './../../../constants';
import { ConfirmDialogComponent } from './../confirmDialog/confirmDialog.component';
import { NotificationService } from "./../../../services/notification.service";

import { GlobalState } from '../../../app/global.state';

@Component({
  selector: 'admin-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent implements OnInit {
  public isScrolled: boolean = false;
  public isMenuCollapsed: boolean = false;

  user: any;
  constructor(private authService: AuthAdminService,
    public constants: Constants,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private router: Router,
    private _state: GlobalState) {
    this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
      this.isMenuCollapsed = isCollapsed;
    });
    this.user = this.authService.getAdminFromStorage();
  }
  public toggleMenu() {
    this.isMenuCollapsed = !this.isMenuCollapsed;
    this._state.notifyDataChanged('menu.isCollapsed', this.isMenuCollapsed);
    return false;
  }

  public scrolledChanged(isScrolled) {
    this.isScrolled = isScrolled;
  }
  ngOnInit() {
  }

  onLogout() {
    // let disposable = this.dialogService.addDialog(ConfirmDialogComponent, {
    //   title: 'Logout',
    //   message: 'Are you sure you want to logout ?'
    // })
    //   .subscribe((isConfirmed) => {
    //     //Dialog result
    //     if (isConfirmed) {
          this.authService.logout();
          this.notificationService.showInfo("You are logged out successfully");
          this.router.navigate([this.constants.clientLogin]);
    //     }
    //   });

    // //We can close dialog calling disposable.unsubscribe();
    // //If dialog was not closed manually close it by timeout
    // setTimeout(() => {
    //   disposable.unsubscribe();
    // }, 10000);
  }

}
