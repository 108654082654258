import { UserAddressService } from './../../../services/userAddress.service';
import { RequeststatushistoryserviceService } from './../../../services/requeststatushistoryservice.service';
import { BookRequestService } from './../../../services/bookrequest.service';
import { BookService } from './../../../services/book.service';
import { element, browser } from 'protractor';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';

import { UserService } from './../../../services/user.service';
import { AuthUserService } from './../../../services/authuser.service';
import { Constants } from './../../../constants';
import { HandleError } from './../../../error';
import { NotificationService } from './../../../services/notification.service';

@Component({
  selector: 'app-manual-request',
  templateUrl: './manual-request.component.html',
  styleUrls: ['./manual-request.component.css']
})
export class ManualRequestComponent implements OnInit {
  userForm: FormGroup;
  returnUrl: String;
  submitButtonText: string = this.constants.Request;
  bookid: String;
  userdata: any;
  userid: String;
  requestdate: Date;
  requesttype: Boolean = true;
  contactnumber: String;
  preferredtime: String;
  preferredtimeFrom: String;
  preferredtimeTo: String;
  preferreddate: String;
  pickupaddress: String;
  isdropbyuser: Boolean;
  hide: Boolean;
  disableButton: Boolean = false;
  emtytext: boolean = false;
  quantity: number;
  title: String;
  bookname: string;
  isbn: string;
  addressid: String;
  otherdetails: string;
  datepickerOpts = {
    startDate: new Date(),
    autoclose: true,
    todayBtn: 'linked',
    todayHighlight: true,
    assumeNearbyYear: true,
    format: 'd-M-yyyy'
  }

  constructor(
    private fb: FormBuilder,
    public constants: Constants,
    private router: Router,
    private route: ActivatedRoute,
    private userAddress: UserAddressService,
    private bookService: BookService,
    private notificationService: NotificationService,
    private bookrequestService: BookRequestService,
    private authuserservice: AuthUserService,
    private requeststatushistoryserviceService: RequeststatushistoryserviceService
  ) {
    this.userForm = fb.group({
      'contactnumber': [null, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern('[0-9]*')])],
      'pickupaddress': [null, Validators.nullValidator],
      'preferredtimeFrom': [null, Validators.nullValidator],
      'preferredtimeTo': [null, Validators.nullValidator],
      'preferreddate': [null, Validators.nullValidator],
      'title': [null, Validators.nullValidator],
      'quantity': [null, Validators.nullValidator],
      'bookname': ["", Validators.compose([Validators.required, Validators.maxLength(150)])],
      'isbn': [null, Validators.nullValidator],
      'otherdetails': [null, Validators.nullValidator],
      'requesttype': [null, Validators.nullValidator],
    })
  }
  ngOnInit() {
    this.isdropbyuser = true;
    this.userdata = this.authuserservice.getUserFromStorage();
    this.contactnumber = this.userdata.contactno;
    this.bindGrid();
  }
  onSubmitClick() {
    this.disableButton = true;
    const bookrequest = {
      searchKey: '',
      googleid: '',
      isdelete: 0,
      bookid: '',
      userid: this.userdata.id,
      requestdate: new Date(),
      requesttype: this.requesttype,
      istransactioncomplete: 0,
      contactno: this.contactnumber,
      preferreddate: this.preferreddate != null && this.preferreddate != undefined ? this.preferreddate : '',
      preferredtime: this.preferredtimeFrom != null && this.preferredtimeFrom != undefined ? this.preferredtimeFrom + " To " + this.preferredtimeTo : '',
      address: this.pickupaddress,
      isdropbyuser: this.isdropbyuser,
      remarks: "",
      email: this.userdata.email,
      name: this.userdata.name,
      quantity: this.quantity,
      bookname: this.bookname,
      isbn: this.isbn,
      otherdetails: this.otherdetails
    }
    this.bookrequestService.putManualBookRequest(bookrequest, false).subscribe((data: any) => {
        if (data.success) {
          // Change by Arpit
        this.constants.removeCacheData('DashboardBookList', 0);
        this.constants.removeCacheData('ListOfNeededBook', 0);
        this.notificationService.showSuccess(data.msg);
        if (this.requesttype) {
          this.router.navigate([this.constants.clientUrlUserRequests])
        } else {
          this.router.navigate([this.constants.clientUrlUserDonations])
        }
      }
      else {
        this.notificationService.showError(data.msg);
        this.disableButton = false;
      }
    },
      err => {
        if (err.status == 401) {
          this.router.navigate([this.constants.clientUrlUnauthorized]);
        }
        else {
          this.router.navigate([this.constants.clientUrlNotFound]);
        }
      });

  }

  checkemtytext() {
    var text = this.bookname.trim();
    if (text == "") {
      this.emtytext = true;
    } else {
      this.emtytext = false;
    }
  }

  onClick() {
    this.hide = !this.hide;
    if (this.hide) {
      this.userForm = this.fb.group({
        'contactnumber': [this.contactnumber, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern('[0-9]*')])],
        'pickupaddress': [null, Validators.required],
        'preferredtimeFrom': [null, Validators.required],
        'preferredtimeTo': [null, Validators.required],
        'preferreddate': [null, Validators.required],
        'title': [null, Validators.required],
        'bookname': [this.bookname,  Validators.compose([Validators.required, Validators.maxLength(150)])],
        'isbn': [this.isbn, Validators.nullValidator],
        'otherdetails': [this.otherdetails, Validators.nullValidator],
        'requesttype': [this.requesttype, Validators.nullValidator],
        'quantity': [this.quantity, Validators.compose([Validators.required, Validators.min(1), Validators.max(10)])],
      });
    }
  }

  onSubmit() {
    // this.disableButton = true;
    const user = {
      addressid: this.addressid,
      userid: this.userid,
      title: this.title,
      address: this.pickupaddress,
    }
    //upsert users    
    this.userAddress.addAddress(user, false).subscribe((data: any) => {
      if (data.success) {
        this.router.navigate([this.constants.clientUrlUserprofile]);
        this.notificationService.showSuccess(data.msg);
      }
      else {
        this.notificationService.showError(data.msg);
        this.disableButton = false;
      }
    }, err => {
      if (err.status == 401) {
        this.router.navigate([this.constants.clientUrlUnauthorized]);
      }
      else {
        this.router.navigate([this.constants.clientUrlNotFound]);
      }
    });
  }

  addUserAddress: any[] = [];
  bindGrid() {
    this.userid = this.userdata.id;
    this.pickupaddress = undefined;
    if (this.userid != null) {
      this.userAddress.getAddressById(this.userid, false).subscribe((data: any) => {
        this.addUserAddress = data.userAddress;
      }, err => {
        if (err.status == 401) {
          this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
        }
        else {
          this.router.navigate([this.constants.clientUrlNotFound]);
        }
      });
    }
  }

  onSelectionChange(value) {
    this.isdropbyuser = !this.isdropbyuser;
    var options = document.getElementsByClassName('doptions');
    for (var i = 0; i < options.length; i++) {
      if (options[i].id == value) {
        options[i].setAttribute("style", "display:block");
      } else {
        options[i].setAttribute("style", "display:none");
      }
    }
    if (!this.isdropbyuser) {
      this.userForm = this.fb.group({
        'contactnumber': [this.contactnumber, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern('[0-9]*')])],
        'pickupaddress': [null, Validators.required],
        'preferredtimeFrom': [null, Validators.required],
        'preferredtimeTo': [null, Validators.required],
        'preferreddate': [null, Validators.required],
        'title': [null, Validators.nullValidator],
        'bookname': [this.bookname, Validators.compose([Validators.required, Validators.maxLength(150)])],
        'isbn': [this.isbn, Validators.nullValidator],
        'otherdetails': [this.otherdetails, Validators.nullValidator],
        'requesttype': [this.requesttype, Validators.nullValidator],
        'quantity': [this.quantity, Validators.compose([Validators.required, Validators.min(1), Validators.max(10)])],
      });
      this.pickupaddress = null;
    } else {
      this.userForm = this.fb.group({
        'contactnumber': [this.contactnumber, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern('[0-9]*')])],
        'pickupaddress': [null, Validators.nullValidator],
        'preferredtimeFrom': [null, Validators.nullValidator],
        'preferredtimeTo': [null, Validators.nullValidator],
        'preferreddate': [null, Validators.nullValidator],
        'bookname': [this.bookname, Validators.required],
        'title': [null, Validators.nullValidator],
        'isbn': [this.isbn, Validators.nullValidator],
        'otherdetails': [this.otherdetails, Validators.nullValidator],
        'requesttype': [this.requesttype, Validators.nullValidator],
        'quantity': [this.quantity, Validators.compose([Validators.required, Validators.min(1), Validators.max(10)])],
      });
    }
  }

  onRequestType(type) {
    if (type == "true") {
      this.requesttype = true;
      this.submitButtonText = this.constants.Request;
      this.userForm = this.fb.group({
        'contactnumber': [this.contactnumber, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern('[0-9]*')])],
        'pickupaddress': [undefined, Validators.nullValidator],
        'preferredtimeFrom': [null, Validators.nullValidator],
        'preferredtimeTo': [null, Validators.nullValidator],
        'preferreddate': [null, Validators.nullValidator],
        'bookname': [this.bookname, Validators.compose([Validators.required, Validators.maxLength(150)])],
        'title': [null, Validators.nullValidator],
        'isbn': [this.isbn, Validators.nullValidator],
        'otherdetails': [this.otherdetails, Validators.nullValidator],
        'requesttype': [this.requesttype, Validators.nullValidator],
        'quantity': [this.quantity, Validators.nullValidator],
      });
    }
    else {
      this.submitButtonText = this.constants.Contribute;
      this.requesttype = false;
      this.userForm = this.fb.group({
        'contactnumber': [this.contactnumber, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern('[0-9]*')])],
        'pickupaddress': [null, Validators.nullValidator],
        'preferredtimeFrom': [null, Validators.nullValidator],
        'preferredtimeTo': [null, Validators.nullValidator],
        'title': [null, Validators.nullValidator],
        'preferreddate': [null, Validators.nullValidator],
        'bookname': [this.bookname, Validators.compose([Validators.required, Validators.maxLength(150)])],
        'isbn': [this.isbn, Validators.nullValidator],
        'otherdetails': [this.otherdetails, Validators.nullValidator],
        'requesttype': [this.requesttype, Validators.nullValidator],
        'quantity': [this.quantity, Validators.compose([Validators.required, Validators.min(1), Validators.max(10)])],
      });
    }
  }
} 