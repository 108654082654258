import { Component } from '@angular/core';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { Constants } from './../../../constants';
export interface ConfirmModel {
  instanceNo: any;
  logHistory: string[];
}
@Component({
  selector: 'app-instanceLogModal',
  template: `<div class="modal-dialog">
  <div class="modal-content dialogscin">
   <div class="modal-header">
     <button type="button" class="close" (click)="close()" >&times;</button>
     <h4 class="modal-title" style="color:white">Instance Logs of ({{instanceNo}})</h4>
   </div>
   <div class="modal-body">
   <div class="row">
   <div class="col-md-12 log-overflow">
      <ul class="feed-messages-container">
        <li *ngFor="let log of logHistory">
            <div class="feed-message">
              <span class="ago-time">
                  <i class="fa fa-clock-o"></i>
                  {{log.bookrequesthistory.remarksdate| date: 'dd-MMM-yy hh:mm'}}
              </span>
              <a class="message-header header-color" [routerLink]="['secure/users/updateUsers',log.users[0]._id]" (click)="confirm()">{{log.users[0].name}} - {{log.users[0].email}}</a>
              <div class="message-content line-clamp">
                  <span class="header-color">Status:</span> <span>{{log.bookrequesthistory.status}}</span>
              </div>
            </div>
        </li>
      </ul>   
   </div>
  </div>
  </div>                  
  <div class="modal-footer">
     <button type="button" class="btn btnModal btn-sm" (click)="confirm()">OK</button>
   </div>
 </div>
</div>`,
  styleUrls: ['./instanceLogModal.component.scss']
})
export class InstanceLogModalComponent extends DialogComponent<ConfirmModel, boolean> implements ConfirmModel {
  instanceNo: any;
  logHistory: string[];
  constructor(dialogService: DialogService, constants: Constants) {
    super(dialogService);
  }

  confirm() {
    // we set dialog result as true on click on confirm button, 
    // then we can get dialog result from caller code 
    this.close();
  }
}