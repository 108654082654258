import { BookinstanceService } from './../../../services/bookinstance.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { Constants } from './../../../constants';
import { BookService } from './../../../services/book.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';

class BookIntances {

}

@Component({
  selector: 'app-view-book-instances',
  templateUrl: './view-book-instances.component.html',
  styleUrls: ['./view-book-instances.component.scss']
})
export class ViewBookInstancesComponent implements OnInit {
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  bookid: any;
  title: String = '';
  subtitle: String = '';
  description: String = '';
  categoryid: String = '';
  language: String = '';
  author: String = '';
  publisher: String = '';
  identifier_13: String = '';
  identifier_10: String;
  isdelete: Boolean;
  isactive: Boolean;
  submitButtonText: string;
  imageurl: any;
  imageurlPath: String;
  imagename: string;
  hide: Boolean = false;
  dtOptions = this.constants.dataTableSettings

  constructor(
    private activatedRoute: ActivatedRoute,
    private bookService: BookService,
    public constants: Constants,
    private bookInstanceService: BookinstanceService,
    private router: Router
  ) {

  }
  bookinstances: BookIntances[] = [];

  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<BookIntances> = new Subject<BookIntances>();
  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.bookid = params['id'];
      if (this.bookid != null) {
        this.hide = true;
        this.bookService.getBookById(this.bookid).subscribe((data:any) => {
          this.title = data.book.title && data.book.title != 'undefined' ? data.book.title : this.constants.notAvailable
          this.subtitle = data.book.subtitle && data.book.subtitle != 'undefined' ? data.book.subtitle : this.constants.notAvailable
          this.description = data.book.description && data.book.description != 'undefined' ? data.book.description : this.constants.notAvailable
          this.categoryid = data.book.categoryid[0] && data.book.categoryid[0] != 'undefined' ? data.book.categoryid[0] : this.constants.notAvailable
          this.language = data.book.language != '' ? data.book.language : undefined
          this.author = data.book.author && data.book.author != 'undefined' ? data.book.author : this.constants.notAvailable
          this.publisher = data.book.publisher && data.book.publisher != 'undefined' ? data.book.publisher : this.constants.notAvailable
          if (data.book.imageurl !== undefined && data.book.imageurl !== "") {
            this.imageurlPath = this.constants.serveruploadspath + data.book.imageurl
            this.imagename = data.book.imageurl
          }
          this.imageurl = data.book.imageurl;
          this.isactive = data.book.isactive;
          if (data.book.isbnnumber) {
            if (data.book.isbnnumber.length >=1 && data.book.isbnnumber.length <=2) {
              this.identifier_10 = data.book.isbnnumber[0].identifier ? data.book.isbnnumber[0].identifier : this.constants.notAvailable;
              this.identifier_10 += data.book.isbnnumber[1] ? ", " + data.book.isbnnumber[1].identifier : '';
            }else{
              this.identifier_10 = data.book.isbnnumber ? data.book.isbnnumber : this.constants.notAvailable;
            }
          } else {
            this.identifier_10 = data.book.isbnnumber ? data.book.isbnnumber : this.constants.notAvailable;
          }
          this.bindBookInstances();
        });
      }
    });
  }

  bindBookInstances() {
    this.hide = true;
    this.bookInstanceService.getByBookId(this.bookid, true).subscribe((data:any) => {
      this.bookinstances = data.bookinstances;
      this.dtTrigger.next();
      this.hide = false;
    }, err => {
      if (err.status == 401) {
        this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
      }
      else {
        this.router.navigate([this.constants.clientUrlNotFound]);
      }
    });
  }
}