import { ConfirmDialogComponent } from './../confirmDialog/confirmDialog.component';
import { DialogService } from 'ng2-bootstrap-modal';
// import { Angular2AdminLTEPage } from './../../../../e2e/app.po';
import { FormGroup, FormControl, Validators, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { UserService } from './../../../services/user.service';
import { UserAddressService } from './../../../services/userAddress.service';
import { AuthUserService } from './../../../services/authuser.service';
import { Constants } from '../../../constants';
import { HandleError } from './../../../error';
import { NotificationService } from './../../../services/notification.service';

class AddUserAddress {
  _id: any;
  title: any;
  address: any;
  userid: any;
}
@Component({
  selector: 'app-viewUserAddress',
  templateUrl: './viewUserAddress.component.html',
  styleUrls: ['./viewUserAddress.component.css']
})
export class ViewUserAddressComponent implements OnInit {
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  deleteAll: Boolean;
  showdelete: Boolean;
  requestId: String[] = [];
  isVisible: Boolean = false;
  tokenId: any;
  userid: any;
  user: any;

  dtOptions = this.constants.dataTableSettings && {
    columnDefs: [
      {
        "targets": [0, 1, 4, 5],
        "orderable": false,
      }
    ],
    fnDrawCallback: function () {
      if ((document.getElementById("useraddressdata_paginate")) != null) {
        if ((document.getElementById("useraddressdata_paginate")).click) {
          var table = $("#useraddressdata").DataTable();
          var rowchecked = 0;
          var ValueById = document.getElementsByName("sizerd")
          for (var index = 0; index < ValueById.length; index++) {
            if ($(ValueById[index]).prop("checked") == true) {
              //check all conter var ++
              rowchecked = rowchecked + 1;
            }
          }
          if (ValueById.length == rowchecked) {
            $("#checkAll").prop('checked', true);
            $('.model').css('display', 'block');
          } else {
            $('.model').css('display', 'none');
          }
          if (rowchecked == 0) {
            $("#checkAll").prop('checked', false);
            $('.model').css('display', 'none');
          }
        }
      }
    }
  };

  constructor(private authuserService: AuthUserService,
    private userAddress: UserAddressService,
    private userService: UserService,
    public constants: Constants,
    private activatedRoute: ActivatedRoute,
    private errorHandler: HandleError,
    private notificationService: NotificationService,
    private router: Router,
    fb: FormBuilder,
    private dialogService: DialogService
  ) {

  }

  addUserAddress: AddUserAddress[] = [];
  dtTrigger: Subject<AddUserAddress> = new Subject<AddUserAddress>()

  ngOnInit() {

    this.user = this.authuserService.getUserFromStorage();
    this.userid = this.user.id;
    this.bindGrid();
  }


  showConfirm(id) {
    let disposable = this.dialogService.addDialog(ConfirmDialogComponent, {
      title: "Delete Confirmation",//this.constants.deleteConfirmationTitle"",
      message: "Are you sure you want to delete the selected record?",//this.constants.deleteConfirmation
    })
      .subscribe((isConfirmed) => {
        //Dialog result
        if (isConfirmed) {
          this.userAddress.deleteUser(id, false).subscribe((data:any) => {
            if (data.success) {
              this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Destroy the table first
                dtInstance.destroy();
                // Call the dtTrigger to rerender again
                this.bindGrid();
              });
              this.notificationService.showSuccess(data.msg);
            }
            else {
              this.notificationService.showError(data.msg);
            }
          }, err => {
            if (err.status == 401) {
              this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
            }
            else {
              this.router.navigate([this.constants.clientUrlNotFound]);
            }
          });

        }
      });

    //We can close dialog calling disposable.unsubscribe();
    //If dialog was not closed manually close it by timeout
    setTimeout(() => {
      disposable.unsubscribe();
    }, 10000);
  }

  bindGrid() {
    if (this.userid != null) {
      this.userAddress.getAddressById(this.userid, false).subscribe((data:any) => {
        this.addUserAddress = data.userAddress;
        this.dtTrigger.next();
      }, err => {
        if (err.status == 401) {
          this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
        }
        else {
          this.router.navigate([this.constants.clientUrlNotFound]);
        }
      });
    }
  }

  checkAll(ev) {
    var elementArray = document.getElementsByName("sizerd");
    for (var index = 0; index < elementArray.length; index++) {
      var checkBox = <HTMLInputElement>elementArray[index]
      if (this.requestId.length == 0) {
        this.requestId.push(elementArray[index].id);
      }
      else {
        var resultArray = this.requestId.filter(data => data == elementArray[index].id);
        if (resultArray.length == 0) {
          this.requestId.push(elementArray[index].id);
        } else {
          var arrayindex = this.requestId.indexOf(elementArray[index].id);
          this.requestId.splice(arrayindex, 1);
        }
      }
      checkBox.checked = ev.target.checked
    }
    if (this.requestId.length > 0) {
      // this.showdelete = true;
      $('.model').css('display', 'block');
    }
    else {
      // this.showdelete = false;
      $('.model').css('display', 'none');
    }
    if (!ev.target.checked) {
      // this.requestId = [];
      // this.showdelete = false;
      $('.model').css('display', 'none');
    }
  }

  recordChecked(ev) {
    var id = ev.currentTarget.id;
    var rowCount = 0;
    var elementArray = document.getElementsByName("sizerd");
    var resultArray = this.requestId.filter(data => data == id);
    var elementId = document.getElementById(id);
    if (resultArray != null && resultArray.length != 0) {
      var index = this.requestId.findIndex(x => x === id)
      this.requestId.splice(index, 1);
      // this.deleteAll = false;
      $("#checkAll").prop('checked', false);
    }
    else {
      this.requestId.push(id);
    }
    if (this.requestId.length > 0) {
      // this.showdelete = true;
      $('.model').css('display', 'block');
    }
    else {
      // this.showdelete = false;
      $('.model').css('display', 'none');
    }
    for (var index = 0; index < elementArray.length; index++) {
      var checkBox = <HTMLInputElement>elementArray[index]
      if (checkBox.checked == false) {
        rowCount = rowCount + 1;
      }
    }
    var selectedrow = elementArray.length - rowCount;
    if (selectedrow == elementArray.length) {
      // this.deleteAll = true;
      $("#checkAll").prop('checked', true);
    }
  }

  bulkDelete() {
    const deleteid = {
      deleteid: this.requestId
    };
    let disposable = this.dialogService.addDialog(ConfirmDialogComponent, {
      title: "Delete Confirmation",//this.constants.deleteConfirmationTitle"",
      message: "Are you sure you want to delete the selected record?",//this.constants.deleteConfirmation
    })
      .subscribe((isConfirmed) => {
        //Dialog result
        if (isConfirmed) {
          this.userAddress.bulkDelete(deleteid, false).subscribe((data:any) => {
            if (data.success) {
              this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Destroy the table first
                dtInstance.destroy();
                // Call the dtTrigger to rerender again
                this.bindGrid();
                this.requestId = [];
              });
              this.notificationService.showSuccess(data.msg);
            }
            else {
              this.bindGrid();
              this.notificationService.showError(data.msg);
            }
          });
        }
      });

    //We can close dialog calling disposable.unsubscribe();
    //If dialog was not closed manually close it by timeout
    setTimeout(() => {
      disposable.unsubscribe();
    }, 10000);
  }

}