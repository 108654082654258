import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Constants } from './../constants';
import { Injectable } from '@angular/core';

@Injectable()
export class BookRequestService {
    bookrequest: any;
    remarks: any;
    constructor(private http: HttpClient, public constants: Constants) { }

    //get All Bookrequest...
    getbookrequest(flag, tokenFlag) {
        let timeStamp = +new Date();
        var headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.get(this.constants.serverbookrequestGetAll + '/' + flag + '?tsp=' + timeStamp, { headers: headers });            
    }

    //get searched book requests
    getBySearch(search, tokenFlag) {
        let timeStamp = +new Date();
        var headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.post(this.constants.serverbookrequestGetBySearch + '?tsp=' + timeStamp, search, { headers: headers });            
    }

    //get Bookrequest by id
    getbookrquestByUserId(id, tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.get(this.constants.serverbookrequestGetByUserId + '/' + id + '?tsp=' + timeStamp, { headers: headers });            
    }

    //get Bookrequest by id
    getbookrquestById(id, tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.get(this.constants.serverbookrequestGetById + '/' + id + '?tsp=' + timeStamp, { headers: headers });            
    }

    //update remarks...
    putremarks(remarks, tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.put(this.constants.servercontributedput + '?tsp=' + timeStamp, remarks, { headers: headers });            
    }

    //update remarks...
    putbulkremarks(remarks, tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.put(this.constants.serverputbulkupdateremarks + '?tsp=' + timeStamp, remarks, { headers: headers });            
    }

    //delete BookRequest...
    deletebookrequest(id, tokenFlag) {
        let timeStamp = +new Date();
        var headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.delete(this.constants.serverbookrequestDelete + '/' + id + '?tsp=' + timeStamp, { headers: headers });            
    }

    //bulk delete
    bulkDelete(deleteid, tokenFlag) {
        let timeStamp = +new Date();
        var headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.post(this.constants.serverbookrequestBulkDelete + '?tsp=' + timeStamp, deleteid, { headers: headers });            
    }

    //put book request
    putBookRequest(bookrequest, tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.put(this.constants.serverbookrequestPut + '?tsp=' + timeStamp, bookrequest, { headers: headers });            
    }

    //Get Realated Books To Verify
    getById(id, tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.get(this.constants.serverbookrequestGetById + '/' + id + '?tsp=' + timeStamp, { headers: headers });            
    }

    //verify and update the request
    LinkToThis(bookrequest, tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.post(this.constants.serverbookrequestLinkToThis + '?tsp=' + timeStamp, bookrequest, { headers: headers });            
    }

    //Request Status History Delete
    deleteRequestStatusHistory(id, tokenFlag) {
        let timeStamp = +new Date();
        var headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.delete(this.constants.serverrequeststatushistorydelete + '/' + id + '?tsp=' + timeStamp, { headers: headers });            
    }

    //put manual book request
    putManualBookRequest(bookrequest, tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.put(this.constants.servermanualbookrequestPut + '?tsp=' + timeStamp, bookrequest, { headers: headers });            
    }

    //put manual book request
    putBulckBookRequest(bookrequest, tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.put(this.constants.servermanualbulckbookrequestPut + '?tsp=' + timeStamp, bookrequest, { headers: headers });            
    }

    //Reject Manual Request
    rejectManualRequest(bookrequest, tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.put(this.constants.serverrejectmanualrequestPut + '?tsp=' + timeStamp, bookrequest, { headers: headers });            
    }
//put ReturnedAndContribute
    ReturnedAndaddtoContribution(bookrequest, tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.put(this.constants.serverReturnedAndaddtoContributionPut + '?tsp=' + timeStamp, bookrequest, { headers: headers });            
    }

    //instanceno --getInstanceLogDetails
    getInstanceLogDetails(instanceno, tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.get(this.constants.servergetInstanceLogDetails + '/' + instanceno + '?tsp=' + timeStamp, { headers: headers });            
    }
    bulkMailNotification(mailData, tokenFlag){
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.put(this.constants.serverBulkMailNotification + '/' +'?tsp=' + timeStamp, mailData , { headers: headers });
    }
}