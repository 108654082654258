import { ContactsComponent } from './../contact/contacts.component';
import { FeedBackService } from './../../../services/feedback.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NotificationService } from './../../../services/notification.service';
import { DialogService } from 'ng2-bootstrap-modal';
import { Constants } from './../../../constants';
import { Component, OnInit } from '@angular/core';
import { RatingModule } from "ng2-rating";

@Component({
  selector: 'app-feedback-modification',
  templateUrl: './feedback-modification.component.html',
  styleUrls: ['./feedback-modification.component.css']
})
export class FeedbackModificationComponent implements OnInit {
  feedbackId: any;
  name: String;
  email: String;
  rating: Number;
  message: String;
  createdate: Date;
  isvisible: Boolean;
  hide: Boolean = false;
  constructor(
    public constants: Constants,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private router: Router,
    private feedbackservice: FeedBackService,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.feedbackId = params['id'];
      if (this.feedbackId != null) {
        this.hide = true;
        this.feedbackservice.getFeedBackbyId(this.feedbackId, true).subscribe((data:any) => {
          this.name = data.feedback.name
          this.email = data.feedback.email
          this.rating = data.feedback.rating
          this.message = data.feedback.message
          this.createdate = data.feedback.createdate
          this.isvisible = data.feedback.isvisible
        }, err => {
          if (err.status == 401) {
            this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
          }
          else {
            this.router.navigate([this.constants.clientUrlNotFound]);
          }
        });
        this.hide = false;
      }
      else {
      }
    });
  }

  onChange() {
    this.isvisible ? true : false;
    const feedback = {
      feedbackId: this.feedbackId,
      name: this.name,
      email: this.email,
      rating: this.rating,
      message: this.message,
      createdate: this.createdate,
      isvisible: this.isvisible,
    }
    this.feedbackservice.putFeedBacks(feedback, true).subscribe((data:any) => {
      if (data.success) {
        this.notificationService.showSuccess(data.msg);
        this.router.navigate([this.constants.clientUrlFeedBack]);
      } else {
        this.notificationService.showError(data.msg);
      }
    }, err => {
      if (err.status == 401) {
        this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
      }
      else {
        this.router.navigate([this.constants.clientUrlNotFound]);
      }
    });
  }

}