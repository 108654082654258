import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from './../constants';

@Injectable()
export class AdminService {
    admin: any;
    constructor(private http: HttpClient, public constants: Constants) { }

    //get all admin users 
    getAdmin(tokenFlag) {
        let timeStamp = +new Date();
        var headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.get(this.constants.serveradminGetAll + '?tsp=' + timeStamp, { headers: headers });            
    }

    //get admin by id
    getAdminById(id,tokenFlag) {
        let timeStamp = +new Date();
        var headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.get(this.constants.serveradminGetById + '/' + id + '?tsp=' + timeStamp, { headers: headers });            
    }

    //update admin
    putAdmin(admin,tokenFlag) {
        let timeStamp = +new Date();
        var headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.put(this.constants.serveradminPut + '?tsp=' + timeStamp, admin, { headers: headers });            
    }

    //delete admin 
    deleteAdmin(id,tokenFlag) {
        let timeStamp = +new Date();
        var headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.delete(this.constants.serveradminDelete + '/' + id + '?tsp=' + timeStamp, { headers: headers });            
    }
    
    //bulk delete
    bulkDelete(deleteid,tokenFlag) {
        let timeStamp = +new Date();
        var headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.post(this.constants.serveradminBulkDelete + '?tsp=' + timeStamp, deleteid, { headers: headers });            
    }

    // reset password
    updateAdminPassword(admin) {
        let timeStamp = +new Date();
        var headers = this.constants.getHeaders();
        return this.http.post(this.constants.serveradminResetPassword + '?tsp=' + timeStamp, admin, { headers: headers });            
    }
}