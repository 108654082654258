import { ExcelService } from './../../../services/excel.service';
import { BookRequestService } from './../../../services/bookrequest.service';
import { UserService } from './../../../services/user.service';
import { ModalDialogComponent } from './../modalDialog/modalDialog.component';
import { BookinstanceService } from './../../../services/bookinstance.service';
import { element } from 'protractor';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";

import { ConfirmDialogComponent } from '../confirmDialog/confirmDialog.component';
import { Constants } from '../../../constants';
import { DataTableDirective } from 'angular-datatables';
import { NotificationService } from './../../../services/notification.service';
import { BookService } from './../../../services/book.service';

class Books {
    _id: any;
    bookid: any;
    title: String;
    subtitle: String;
    description: String;
    categoryid: String;
    language: any;
    author: String;
    publisher: String;
    frontimage: String;
    isbn10: String;
    isbn13: String;
    isdelete: Boolean;
    isactive: Boolean;
    bookinstancesCount: any;
    approvedBook: any;
    bookallocatedinstancesCount: any;
    //Export to excel
    isbnnumber: any;
}

@Component({
    selector: 'admin-books',
    templateUrl: './books.component.html',
    styleUrls: ['./books.component.scss']
})

export class BooksComponent implements OnInit {
    @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;
    deleteAll: Boolean;
    showdelete: Boolean;
    requestId: String[] = [];
    isVisible: Boolean = false;
    _id: any;
    bookid: any;
    title: String;
    subtitle: String;
    description: String;
    categoryid: String;
    langugage: Number;
    author: String;
    publisher: String;
    frontimage: String;
    isbn10: String;
    isbn13: String;
    isdelete: Boolean;
    isactive: Boolean;
    instances: Number;
    pathURL: String = this.constants.serveruploadspath + 'noimag.gif';
    dtOptions = this.constants.dataTableSettings && {
        order: [3, 'asc'],
        columnDefs: [
            {
                "targets": [0, 1, 2, 11, 12, 13],
                "orderable": false,
            }
        ],
        fixedColumns: true
        ,
        fnDrawCallback: function () {
            if ((document.getElementById("bookdata_paginate")) != null) {
                if ((document.getElementById("bookdata_paginate")).click) {
                    var table = $("#bookdata").DataTable();
                    var rowchecked = 0;
                    var ValueById = document.getElementsByName("sizecb")
                    for (var index = 0; index < ValueById.length; index++) {
                        if ($(ValueById[index]).prop("checked") == true) {
                            rowchecked = rowchecked + 1;
                        }
                    }
                    if (ValueById.length == rowchecked) {
                        $("#checkAll").prop('checked', true);
                        $('.model').css('display', 'block');
                    } else {
                        $("#checkAll").prop('checked', false);
                    }
                    if (rowchecked == 0) {
                        $("#checkAll").prop('checked', false);
                        $('.model').css('display', 'none');
                    }
                    else if (rowchecked > 0) {
                        $('.model').css('display', 'block');
                    }
                }
            }
        }
    };

    userdata = [];
    hide: Boolean = false;
    disableButton: Boolean = false;
    public users: string[]
    constructor(
        private excelService: ExcelService,
        public constants: Constants,
        private dialogService: DialogService,
        private notificationService: NotificationService,
        private router: Router,
        private bookService: BookService,
        private userservice: UserService,
        private bookrequestService: BookRequestService,
        private bookInstanceService: BookinstanceService
    ) {
        this.userservice.getUsers(false, true).subscribe((data: any) => {
            if (data.success) {
                this.users = data.users;
                data.users.forEach(user => {
                    this.userdata.push({ id: user._id, text: user.name + " (" + user.email + ")" })
                });
            }
        }, err => {
            if (err.status == 401) {
                this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
            }
            else {
                this.router.navigate([this.constants.clientUrlNotFound]);
            }
        });
    }
    ngAfterViewInit(): void {

    }

    //Show or Hide text box for add instances.
    showhide(id) {
        if (this.isVisible == false) {
            this.bookid = id
            this.isVisible = true
        }
        else {
            this.isVisible = false
        }
    }

    addInstances(id) {
        this.disableButton = true;
        const Instances = {
            bookid: id,
            instances: this.instances
        }

        this.bookInstanceService.addbookinstance(Instances, true).subscribe((data: any) => {
            if (data.success) {
                this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                    // Destroy the table first
                    dtInstance.destroy();
                    // Call the dtTrigger to rerender again
                    this.bindBooks();
                });
                this.notificationService.showSuccess(data.msg);
                this.isVisible = false;
                this.disableButton = false;
            } else {
                this.notificationService.showError(data.msg);
                this.disableButton = false;
            }
        }, err => {
            if (err.status == 401) {
                this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
            }
            else {
                this.router.navigate([this.constants.clientUrlNotFound]);
            }
        });
    }
    modal(id) {
        let disposable = this.dialogService.addDialog(ModalDialogComponent, {
            users: this.userdata
        }).subscribe((isConfirmed) => {
            //Dialog result
            if (isConfirmed) {
                this.userservice.getUserById(isConfirmed, true).subscribe((data: any) => {
                    const bookrequest = {
                        isdelete: 0,
                        bookid: id,
                        userid: data.user._id,
                        requestdate: new Date(),
                        requesttype: false,
                        quantity: 1,
                        istransactioncomplete: 0,
                        contactno: data.user.contactnumber,
                        email: data.user.email,
                        name: data.user.name,
                        isdropbyuser: true
                    }
                    this.bookrequestService.putManualBookRequest(bookrequest, true).subscribe((data: any) => {
                        if (data.success) {
                            // Change by Arpit
                            this.constants.removeCacheData('DashboardBookList', 0);
                            this.constants.removeCacheData('ListOfNeededBook', 0);
                            this.notificationService.showSuccess(data.msg);
                        }
                        else {
                            this.notificationService.showError(data.msg);
                            this.disableButton = false;
                        }
                    }, err => {
                        if (err.status == 401) {
                            this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
                        }
                        else {
                            this.router.navigate([this.constants.clientUrlNotFound]);
                        }
                    });
                }, err => {
                    if (err.status == 401) {
                        this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
                    }
                    else {
                        this.router.navigate([this.constants.clientUrlNotFound]);
                    }
                });
            }
        });
    }


    //To Delete Book
    showConfirm(id) {
        let disposable = this.dialogService.addDialog(ConfirmDialogComponent, {
            title: "Delete Confirmation",//this.constants.deleteConfirmationTitle"",
            message: "Are you sure you want to delete the selected record?",//this.constants.deleteConfirmation
        })
            .subscribe((isConfirmed) => {
                //Dialog result
                if (isConfirmed) {
                    this.bookService.deleteBook(id, true).subscribe((data: any) => {
                        if (data.success) {
                            this.constants.removeCacheData('DashboardCounts', 0);
                            this.bindBookDataTable();
                            this.notificationService.showSuccess(data.msg);
                        }
                        else {
                            this.notificationService.showError(data.msg);
                        }
                    }, err => {
                        if (err.status == 401) {
                            this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
                        }
                        else {
                            this.router.navigate([this.constants.clientUrlNotFound]);
                        }
                    });
                }
            });

        //We can close dialog calling disposable.unsubscribe();
        //If dialog was not closed manually close it by timeout
        setTimeout(() => {
            disposable.unsubscribe();
        }, 10000);
    }

    books: Books[] = [];

    // We use this trigger because fetching the list of persons can be quite long,
    // thus we ensure the data is fetched before rendering
    dtTrigger: Subject<Books> = new Subject<Books>();

    //define default get method
    ngOnInit() {
        localStorage.setItem('currentURL', this.router.url);
        this.bindBooks();
    }

    bindBookDataTable() {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            // Destroy the table first
            dtInstance.destroy();
            // Call the dtTrigger to rerender again
            this.bindBooks();
        });
    }

    bindBooks() {
        this.hide = true;
        this.bookService.getBooks(true).subscribe((data: any) => {
            this.books = data.books;
            this.dtTrigger.next();
            this.hide = false;
        }, err => {
            if (err.status == 401) {
                this.router.navigate([this.constants.clientUrlAdminUnauthorized]);
            }
            else {
                this.router.navigate([this.constants.clientUrlNotFound]);
            }
        });
    }

    checkAll(ev) {
        var elementArray = document.getElementsByName("sizecb");
        for (var index = 0; index < elementArray.length; index++) {
            var checkBox = <HTMLInputElement>elementArray[index]
            if (this.requestId.length == 0) {
                this.requestId.push(elementArray[index].id);
            }
            else {
                var resultArray = this.requestId.filter(data => data == elementArray[index].id);
                if (resultArray.length == 0) {
                    this.requestId.push(elementArray[index].id);
                } else {
                    var arrayindex = this.requestId.indexOf(elementArray[index].id);
                    this.requestId.splice(arrayindex, 1);
                }
            }
            checkBox.checked = ev.target.checked
        }
        if (this.requestId.length > 0) {
            $('.model').css('display', 'block');
        }
        else {
            $('.model').css('display', 'none');
        }
        if (!ev.target.checked) {
            $(".model").css('display', 'none');
        }
    }

    recordChecked(ev) {
        var id = ev.currentTarget.id;
        var rowCount = 0;
        var elementArray = document.getElementsByName("sizecb");
        var resultArray = this.requestId.filter(data => data == id);
        var elementId = document.getElementById(id);
        if (resultArray != null && resultArray.length != 0) {
            var index = this.requestId.findIndex(x => x === id)
            this.requestId.splice(index, 1);
            $("#checkAll").prop('checked', false);
        }
        else {
            this.requestId.push(id);
        }
        if (this.requestId.length > 0) {
            $('.model').css('display', 'block');
        }
        else {
            $('.model').css('display', 'none');
        }
        for (var index = 0; index < elementArray.length; index++) {
            var checkBox = <HTMLInputElement>elementArray[index]
            if (checkBox.checked == false) {
                rowCount = rowCount + 1;
            }
        }
        var selectedrow = elementArray.length - rowCount;
        if (selectedrow == elementArray.length) {
            // this.deleteAll = true;
            $("#checkAll").prop('checked', true);
        }
    }

    bulkDelete() {
        const deleteid = {
            deleteid: this.requestId
        };
        let disposable = this.dialogService.addDialog(ConfirmDialogComponent, {
            title: "Delete Confirmation",//this.constants.deleteConfirmationTitle"",
            message: "Are you sure you want to delete the selected record?",//this.constants.deleteConfirmation
        })
            .subscribe((isConfirmed) => {
                //Dialog result
                if (isConfirmed) {
                    this.bookService.bulkDelete(deleteid, true).subscribe((data: any) => {
                        if (data.success) {
                            this.constants.removeCacheData('DashboardCounts', 0);
                            this.bindBookDataTable();
                            this.requestId = [];
                            this.notificationService.showSuccess(data.msg);
                        }
                        else {
                            this.bindBookDataTable();
                            this.notificationService.showError(data.msg);
                        }
                    });
                }
            });

        //We can close dialog calling disposable.unsubscribe();
        //If dialog was not closed manually close it by timeout
        setTimeout(() => {
            disposable.unsubscribe();
        }, 10000);
    }

    exportAsXLSX(): void {
        var exportAvailableBooklist = [];
        var isbnnumbers;
        var language;

        this.books.forEach((item) => {
            if (item.bookinstancesCount > 0) {
                if (item.isbnnumber instanceof Array) {
                    isbnnumbers = '';
                    item.isbnnumber.forEach((item) => {
                        isbnnumbers += item.identifier ? item.identifier + ', ' : this.constants.notavailable;
                    });
                } else {
                    isbnnumbers = item.isbnnumber;
                }
            }
            exportAvailableBooklist.push(
                {
                    "Book Title": item.title,
                    "Sub Title": item.subtitle ? item.subtitle : '',
                    "ISBN Number": isbnnumbers,
                    "Author": item.author ? item.author[0] : '',
                    "Publisher": item.publisher ? item.publisher[0] : '',
                    "Instances": item.bookinstancesCount,
                    "Approved": item.approvedBook.length !== 0 ? item.approvedBook[0].count : 0,
                    "Allocated": item.bookallocatedinstancesCount
                }
            );
        });
        this.excelService.exportAsExcelFile(exportAvailableBooklist, 'AvailableBooks');
    }
}
