import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tokenNotExpired } from 'angular2-jwt';


import { Constants } from './../constants';

@Injectable()
export class FeedBackService {
    feedback: any;

    constructor(private http: HttpClient, public constants: Constants) { }

    //get all feedbacks
    getFeedBacks() {
        let timeStamp = +new Date();
        let headers = this.constants.getHeaders();
        return this.http.get(this.constants.servergetFeedBack + '?tsp=' + timeStamp, { headers: headers })
            
    }

    //get feedback by Id
    getFeedBackbyId(id, tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.get(this.constants.serverFeedBackbyId + '/' + id + '?tsp=' + timeStamp, { headers: headers })
            
    }

    //get feedback by userId
    getFeedBackbyuserId(id, tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.get(this.constants.serverFeedBackbyuserId + '/' + id + '?tsp=' + timeStamp, { headers: headers })
            
    }

    //put feedback
    putFeedBacks(feedback, tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.put(this.constants.serverputFeedBack + '?tsp=' + timeStamp, feedback, { headers: headers })
            
    }

    //delete feedback
    deleteFeedBacks(id, tokenFlag) {
        let timeStamp = +new Date();
        let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.delete(this.constants.serverFeedBackDelete + '/' + id + '?tsp=' + timeStamp, { headers: headers })
            
    }

    //bulk delete
    bulkDelete(deleteid, tokenFlag) {
        let timeStamp = +new Date();
        var headers = this.constants.getHeadersWithAuthorization(tokenFlag);
        return this.http.post(this.constants.serverFeedBackBulkDelete + '?tsp=' + timeStamp, deleteid, { headers: headers })
            
    }

    //feedback review count
    ratingWiseCount() {
        let timeStamp = +new Date();
        let headers = this.constants.getHeaders();
        return this.http.get(this.constants.serverFeedBackCount + '?tsp=' + timeStamp, { headers: headers })
            
    }
}