import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";

import { Constants } from './../../../constants';
import { DataTableDirective } from 'angular-datatables';
import { SelectModule } from 'ng2-select';

export interface ConfirmModel {
  title: string;
  users: string[];
  bindUser : string;
}
@Component({
  selector: 'modalDialog',
  template: `<div class="modal-dialog">
                  <div class="modal-content dialogscin">
                   <div class="modal-header">
                     <button type="button" class="close" (click)="close()" >&times;</button>
                     <h4 class="modal-title" style="color:white">{{'Active Users'}}</h4>
                   </div>
                   <div class="modal-body">
                   <div class="row">
                   <div class="col-md-1"></div>
                   <div class="col-md-10">
                      <ng-select class="User" bindValue="bindUser" [multiple]="false" [items]="users" placeholder="Select User" (selected)="selected($event)"></ng-select>
                   </div>
                   <div class="col-md-1"></div>
                  </div>
                  </div>                  
                  <div class="modal-footer">
                     <button type="button" class="btn btnModal btn-sm" (click)="confirm()">OK</button>
                     <button type="button" class="btn btnModal btn-sm" (click)="close()" >Cancel</button>
                   </div>
                 </div>
              </div>`,
  styleUrls: ['./modalDialog.component.css']
})
export class ModalDialogComponent extends DialogComponent<ConfirmModel, boolean> implements OnInit {
  //opts: ISlimScrollOptions;
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  userFilter: any = { name: '' };
  public users: String[];
  selectedUsers: String;
  constructor(
    dialogService: DialogService,
    constants: Constants
  ) {
    super(dialogService);
  }

  ngOnInit() {
  }
  userid: any;
  selected(data){
    this.userid = data.id;
  }
  confirm() {
    // we set dialog result as true on click on confirm button, 
    // then we can get dialog result from caller code
    this.result = this.userid;
    this.close();
  }

}