import { OurAssociateListComponent } from './../front/components/ourAssociateList/ourAssociateList.component';
import { UnauthorizedPageComponent } from './components/unauthorized-page/unauthorized-page.component';
import { ManualRequestComponent } from './../front/components/manual-request/manual-request.component';
import { FeedbackComponent } from './../front/components/feedback/feedback.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { SeekingContributeHomeComponent } from './../front/components/seeking-contribute-home/seeking-contribute-home.component';
import { AvailablebooksComponent } from './../front/components/availablebooks/availablebooks.component';
import { MainFooterComponent } from './../front/components/main-footer/main-footer.component';
import { MainHeaderComponent } from './../front/components/main-header/main-header.component';
import { Constants } from './../constants';
import { BreadcrumbService } from 'ng2-breadcrumb/ng2-breadcrumb';
import { RedirectionGuard } from './../guards/redirection.guard';
import { AuthUserGuard } from './../guards/authuser.guard';
import { AddUserAddressComponent }from './../front/components/addUserAddress/addUserAddress.component'
import { ContributeRequestComponent } from './../front/components/contribute-request/contribute-request.component';
import { SeekingRequestComponent } from './../front/components/seeking-request/seeking-request.component';
import { ViewDetailComponent } from './../front/components/view-detail/view-detail.component';
import { ViewRequestDetailsComponent } from './../front/components/view-request-details/view-request-details.component';
import { UserdonationComponent } from './../front/components/userdonation/userdonation.component';
import { UserRequestComponent } from './../front/components/userrequest/userrequest.component';
import { HowItWorksComponent } from './../front/components/how-it-works/how-it-works.component';
import { PrivacyPolicyComponent } from './../front/components/privacy-policy/privacy-policy.component';
import { FacebookDataDeletionInstructionsComponent } from './../front/components/facebook-data-deletion-instructions/facebook-data-deletion-instructions.component';
import { FaqComponent } from './../front/components/faq/faq.component';
import { MainSearchResultComponent } from './../front/components/main-searchResult/main-searchResult.component';
import { UserChangepasswordComponent } from './../front/components/user-changepassword/user-changepassword.component';
import { UserProfileComponent } from './../front/components/userprofile/userprofile.component';
import { UserloginComponent } from './../front/shared/userlogin/userlogin.component';
import { RegisterComponent } from './../front/shared/register/register.component';
import { ContactUsComponent } from './../front/components/contact-us/contact-us.component';
import { EmailActivationComponent } from './../front/shared/emailactivation/emailactivation.component';
import { RedirectionAdminGuard } from './../guards/redirectionadmin.guard';
import { SignupComponent } from './../front/shared/signup/signup.component';
import { UserHomeComponent } from './../front/components/user-home/user-home.component';
import { SyllabusComponent } from './../front/components/syllabus/syllabus.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SocialLoginComponent } from './../front/components/social-login/social-login.component'

import { AdminComponent } from './../admin/admin.component';
import { ForgetpasswordComponent } from './../admin/shared/forgetpassword/forgetpassword.component';
import { FrontForgetpasswordComponent } from './../front/shared/forgetpassword/forgetpassword.component';
import { LoginComponent } from "./../admin/shared/login/login.component";
import { ResetpasswordComponent } from "./../admin/shared/resetpassword/resetpassword.component";
import { ResetPasswordComponent } from "./../front/shared/resetpassword/resetpassword.component";
@NgModule({
    imports: [
        RouterModule.forRoot([
            { path: 'secure', component: AdminComponent },
            { path: 'secure/login', component: LoginComponent, data: { title: 'Login' }, canActivate: [RedirectionAdminGuard] },
            { path: 'secure/forgotpassword', component: ForgetpasswordComponent, data: { title: 'Forget Password' }, canActivate: [RedirectionAdminGuard] },
            { path: 'secure/resetpassword/:id', component: ResetpasswordComponent, data: { title: 'Reset Password' }, canActivate: [RedirectionAdminGuard] },
            { path: 'resetpassword/:id', component: ResetPasswordComponent, data: { title: 'Reset Password' } },

            { path: '', redirectTo: 'home', pathMatch: 'full' },

            { path: 'home', component: UserHomeComponent, data: { title: 'Home' } },
            { path: 'socialLogin', component: SocialLoginComponent, data: { title: 'Social Login' } },
            { path: 'email/activation/:id', component: EmailActivationComponent, data: { title: 'SignIn' }, canActivate: [RedirectionGuard] },
            { path: 'feedback', component: FeedbackComponent, data: { title: 'Feed Back' }},
            { path: 'contact', component: ContactUsComponent, data: { title: 'Contact Us' } },
            { path: 'forgotpassword', component: FrontForgetpasswordComponent, data: { title: 'Forgot Password' }, canActivate: [RedirectionGuard] },
            { path: 'signup', component: RegisterComponent, data: { title: 'Sign Up' }, canActivate: [RedirectionGuard] },
            { path: 'login', component: UserloginComponent, data: { title: 'Log In' }, canActivate: [RedirectionGuard] },
            { path: 'userprofile', component: UserProfileComponent, data: { title: 'User Profile' }, canActivate: [AuthUserGuard] },
            { path: 'userchangepassword', component: UserChangepasswordComponent, data: { title: 'User Change Password' }, canActivate: [AuthUserGuard] },
            { path: 'search', component: MainSearchResultComponent, data: { title: 'Search' } },
            { path: 'faq', component: FaqComponent, data: { title: 'FAQs' } },
            { path: 'howitworks', component: HowItWorksComponent, data: { title: 'How It Works' } },
            { path: 'privacypolicy', component: PrivacyPolicyComponent, data: { title: 'Privacy Policy' } },
            { path: 'facebook-data-deletion-instructions', component: FacebookDataDeletionInstructionsComponent, data: { title: 'Facebook Data Deletion Instructions' } },
            { path: 'studentzone', component: SyllabusComponent, data: { title: 'Student Zone' } },
            { path: 'myrequests', component: UserRequestComponent, data: { title: 'My Requests' }, canActivate: [AuthUserGuard] },
            { path: 'mycontributions', component: UserdonationComponent, data: { title: 'My Donations' }, canActivate: [AuthUserGuard] },
            { path: 'ourassociate', component: OurAssociateListComponent, data: { title: 'Our Associates' } },
            { path: 'requestdetails/:id', component: ViewRequestDetailsComponent, data: { title: 'View Request Detail' }, canActivate: [AuthUserGuard] },
            { path: 'viewbookdetails', component: ViewDetailComponent, data: { title: 'View Book Details' } },
            { path: 'seekingrequest', component: SeekingRequestComponent, data: { title: 'Seeking Request' }, canActivate: [AuthUserGuard] },
            { path: 'contributerequest', component: ContributeRequestComponent, data: { title: 'Donate Request' }, canActivate: [AuthUserGuard] },
            { path: 'addUserAddress', component: AddUserAddressComponent, data: { title: 'Add User Address' }, canActivate: [AuthUserGuard] },
            { path: 'updateUserAddress/:id', component: AddUserAddressComponent, data: { title: 'Update User Address' }, canActivate: [AuthUserGuard] },           
            { path: 'manualrequest', component: ManualRequestComponent, data: { title: 'Manual Request' }, canActivate: [AuthUserGuard] },
            { path: 'availablebooks', component: AvailablebooksComponent, data: { title: 'Available Books' } },
            { path: 'requiredbooks', component: AvailablebooksComponent, data: { title: 'Available Books' } },            
            { path: 'searchbooks', component: MainSearchResultComponent, data: { title: 'Search Books' } },
            { path: '**', component: NotfoundComponent, data: { title: 'Not Found' } },
            { path: 'unauthorized', component: UnauthorizedPageComponent, data: { title: 'Unauthorized' } },
        ])
    ],
    providers: [Constants],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {
    constructor(public constants: Constants,
        private breadcrumbService: BreadcrumbService) {

    }
}