import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import { Constants } from './../constants';

@Injectable()
export class UserService {
  authToken: any;
  user: any;

  constructor(private http: HttpClient, public constants: Constants) { }

  //get all users
  getUsers(isAbscondingUser, tokenFlag) {
    let timeStamp = +new Date();
    let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
    return this.http.get(this.constants.serveruserGetAll + '/' + isAbscondingUser + '?tsp=' + timeStamp, { headers: headers })
      
  }

  //get user by id
  getUserById(id, tokenFlag) {
    let timeStamp = +new Date();
    let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
    return this.http.get(this.constants.serveruserGetById + '/' + id + '?tsp=' + timeStamp, { headers: headers })
      
  }

  //register user
  registerUser(user) {
    let timeStamp = +new Date();
    let headers = this.constants.getHeaders();
    return this.http.post(this.constants.serveruserRegister + '?tsp=' + timeStamp, user, { headers: headers })
      
  }

  //update user
  putUser(user, tokenFlag) {
    let timeStamp = +new Date();
    let headers = tokenFlag == true ? this.constants.getHeadersWithAuthorization(tokenFlag) : this.constants.getHeaders();
    return this.http.put(this.constants.serveruserPut + '?tsp=' + timeStamp, user, { headers: headers })
      
  }

  //User Profile...
  updateProfile(user, tokenFlag) {
    let timeStamp = +new Date();
    let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
    return this.http.put(this.constants.serveruserProfileUpdate + '?tsp=' + timeStamp, user, { headers: headers })
      
  }

  //delete user
  deleteUser(id, tokenFlag) {
    let timeStamp = +new Date();
    let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
    return this.http.delete(this.constants.serveruserDelete + '/' + id + '?tsp=' + timeStamp, { headers: headers })
      
  }

  //bulk delete
  bulkDelete(deleteid, tokenFlag) {
    let timeStamp = +new Date();
    var headers = this.constants.getHeadersWithAuthorization(tokenFlag);
    return this.http.post(this.constants.serveruserBulkDelete + '?tsp=' + timeStamp, deleteid, { headers: headers })
      
  }

  //load token from storage
  loadToken() {
    const token = localStorage.getItem("userid_token");
    this.authToken = token;
    return token;
  }

  //activate user
  activeUser(user, tokenFlag) {
    let timeStamp = +new Date();
    let headers = this.constants.getHeadersWithAuthorization(tokenFlag);
    return this.http.post(this.constants.serveruserActiveUser + '?tsp=' + timeStamp, user, { headers: headers })
      
  }

  //Update user password...
  updateUserPassword(user) {
    let timeStamp = +new Date();
    var headers = this.constants.getHeaders();
    return this.http.post(this.constants.serveruserResetPassword + '?tsp=' + timeStamp, user, { headers: headers })
      
  }
}
