import { AuthUserService } from './../../../services/authuser.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Constants } from './../../../constants';
import { DashboardService } from 'services/dashboard.service';

@Component({
  selector: 'app-user-home',
  templateUrl: './user-home.component.html',
  styleUrls: ['./user-home.component.css']
})
export class UserHomeComponent implements OnInit {
  totalUser: any;
  booksCounts: any;
  totalSeeking: any;
  totalcontribute: any;
  totalRequestCount: any;
  totalAvailableBooks: Number = 0;
  todaysPickuprequest: any;
  ServedRequestCount: any;
  private _dashboardCounts;
  constructor(private router: Router,
    private authUserService: AuthUserService,
    public constants: Constants,
    private dashboardService: DashboardService
    ) {
    }

  ngOnInit() {
    var adminDetails = this.authUserService.getUserFromStorage();
    if (adminDetails != null) {
      var userdetailsTag = document.getElementById('sessionUsername');
      if (userdetailsTag) {
        document.getElementById('sessionUsername').innerHTML = adminDetails.name;        
      }
    }

    //Dashboard Counts
    this._dashboardCounts = this.constants.getCacheData('DashboardCounts');
    if (this._dashboardCounts === null || this._dashboardCounts === undefined) {
      this.dashboardService.getDashboardCounts(true).subscribe((data:any) => {
        this._dashboardCounts = data;
        this.constants.setCacheData('DashboardCounts', data);
        this.dashboardCounts();
      });
    } else {
      this.dashboardCounts();
    }
  }

  dashboardCounts() {
    this.totalUser = this._dashboardCounts.msg.usersCount;
    this.totalSeeking = this._dashboardCounts.msg.pendingSeekingRequestCount;
    this.totalcontribute = this._dashboardCounts.msg.pendingContributionRequestCount;
    this.totalAvailableBooks = this._dashboardCounts.msg.AB;
    this.todaysPickuprequest = this._dashboardCounts.msg.PR;
    this.ServedRequestCount = this._dashboardCounts.msg.SIC;
  }
}