import { BaOurAssociatesComponent } from './components/baMenu/components/baOurAssociates/baOurAssociates.component';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxUploaderModule } from 'ngx-uploader';
import { AppTranslationModule } from '../app.translation.module';

import {
  BaThemeConfig
} from './theme.config';

import {
  BaThemeConfigProvider
} from './theme.configProvider';

import {
  BaBackTop,
  BaContentTop,
  BaMenuItem,
  BaMenu,
  BaSidebar,
} from './components';

import { BaCardBlur } from './components/baCard/baCardBlur.directive';

import {
  BaSlimScroll,
} from './directives';

import {
  BaImageLoaderService,
  BaMenuService,
  BaThemePreloader,
  BaThemeSpinner
} from './services';

// import {
//   EmailValidator,
//   EqualPasswordsValidator
// } from './validators';

const NGA_COMPONENTS = [

  BaBackTop,
  BaContentTop,
  BaMenuItem,
  BaMenu,
  BaSidebar,
  BaOurAssociatesComponent
];

const NGA_DIRECTIVES = [
  BaSlimScroll,
];

const NGA_PIPES = [
];

const NGA_SERVICES = [
  BaImageLoaderService,
  BaThemePreloader,
  BaThemeSpinner,
  BaMenuService
];

const NGA_VALIDATORS = [
];

@NgModule({
  declarations: [
    ...NGA_PIPES,
    ...NGA_DIRECTIVES,
    ...NGA_COMPONENTS
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AppTranslationModule,
    NgxUploaderModule
  ],
  exports: [
    ...NGA_PIPES,
    ...NGA_DIRECTIVES,
    ...NGA_COMPONENTS
  ]
})
export class NgaModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: NgaModule,
      providers: [
        BaThemeConfigProvider,
        BaThemeConfig,
        ...NGA_VALIDATORS,
        ...NGA_SERVICES
      ],
    };
  }
}
